import { HraClassModelResponse } from "../CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"

export const convertHraClassModelToCustomClassDetails = (hraClassModels?: HraClassModelResponse[]) =>
  hraClassModels?.map(
    ({
      name: customClassName = "",
      id: classId,
      isSpecificGeography = false,
      geographyDescription = "",
      classStructure: reimbursementStructure,
      eligibleForReimbursement,
      waitingPeriod,
      healthBenefits,
      ...hraClass
    }) => ({
      customClassName,
      classId,
      isSpecificGeography,
      geographyDescription,
      isPartTime: hraClass.employeeTimeStatuses?.includes("PART_TIME") ?? false,
      isFullTime: hraClass.employeeTimeStatuses?.includes("FULL_TIME") ?? false,
      isSalary: hraClass.employeeSalaryStatuses?.includes("SALARIED") ?? false,
      isNonSalary: hraClass.employeeSalaryStatuses?.includes("HOURLY") ?? false,
      isSeasonal: hraClass.employeeSeasonalityStatuses?.includes("SEASONAL") ?? false,
      isNonSeasonal: hraClass.employeeSeasonalityStatuses?.includes("NON_SEASONAL") ?? false,
      reimbursementStructure,
      eligibleForReimbursement,
      employeeAmount: hraClass.reimbursements?.[0]?.reimbursementAmount ?? 0,
      employeeAndSpouseAmount: hraClass.reimbursements?.[1]?.reimbursementAmount ?? 0,
      employeeAndChildrenAmount: hraClass.reimbursements?.[2]?.reimbursementAmount ?? 0,
      employeeAndSpouseAndChildrenAmount: hraClass.reimbursements?.[3]?.reimbursementAmount ?? 0,
      waitingPeriod,
      healthBenefits,
    })
  )
