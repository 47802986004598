import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { CarrierMetadataFilterDrawer, CarrierUpdatesTable, defaultCarrierFilter } from "@/features/TCHub/components"
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined"
import { Button, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { useGetCarrierIntegrations, useGetCarriersUpdate } from "../tcHubService"
import { CarrierFilter } from "../tcHubTypes"
import { CarrierIntegrationTable } from "./CarrierIntegrationTable"
import { buildCarriersForFiltering } from "./shared/CarrierService"

export const TcHubCarrierMetadataPage = () => {
  const { isLoading: isLoadingCarrierUpdates, data: carriers } = useGetCarriersUpdate()
  const [carrierFilter, setCarrierFilter] = useState<CarrierFilter>(defaultCarrierFilter)
  const [displayCarrierFilter, setDisplayCarrierFilter] = useState(false)
  const { isLoading: isLoadingCarrierIntegration, data: carrierIntegrations } = useGetCarrierIntegrations()

  const onFilterClicked = () => {
    setDisplayCarrierFilter(true)
  }

  const onFilterClose = () => {
    setDisplayCarrierFilter(false)
  }

  const resetCarrierFilter = () => {
    setCarrierFilter(defaultCarrierFilter)
  }

  return (
    <Grid container data-qa="tc-hub-carriers">
      <Helmet title="TC Hub Carriers" />
      <Grid container justifyContent="space-between">
        <Grid item xs={4}>
          <Typography variant="h1" gutterBottom display="inline" data-qa="carriers">
            Carriers
          </Typography>
        </Grid>
        <Grid container item xs={4} spacing={4} alignContent="flex-end" justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={onFilterClicked}
              startIcon={<FilterAltOutlinedIcon />}
              data-qa="carrier-state-filter-button"
            >
              Filter
            </Button>

            <CarrierMetadataFilterDrawer
              onClose={onFilterClose}
              visible={displayCarrierFilter}
              carriers={buildCarriersForFiltering(carriers, carrierIntegrations)}
              carrierFilter={carrierFilter}
              setCarrierFilter={setCarrierFilter}
              resetCarrierFilter={resetCarrierFilter}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ pt: "2rem" }}>
        <Typography variant="h5" gutterBottom data-qa="carriers-new-to-review">
          New Data to Review
        </Typography>
        {isLoadingCarrierUpdates ? (
          <SkeletonTableLoader
            headerTitles={["Carrier", "State", "Logo URL", "Status", "Actions"]}
            bodyRowsCount={8}
            data-qa="skeleton-carrier-updates-table"
          />
        ) : (
          <CarrierUpdatesTable
            editable
            carriers={carriers ?? []}
            isLoadingCarrierUpdates={isLoadingCarrierUpdates}
            carrierFilter={carrierFilter}
          />
        )}
      </Grid>
      <Grid container sx={{ pt: "4rem" }}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom data-qa="carriers-completed">
            Carrier Metadata
          </Typography>
        </Grid>
        {isLoadingCarrierIntegration ? (
          <SkeletonTableLoader
            headerTitles={[
              "Carrier",
              "State",
              "Logo",
              "Pay later",
              "Cross state care",
              "Overdraft fee",
              "Exchange",
              "Link",
              "Enrollment",
              "AutoPay",
              "Premium pull day",
              "Cut-off date",
              "Actions",
            ]}
            bodyRowsCount={8}
            data-qa="skeleton-carrier-integrations-table"
          />
        ) : (
          <CarrierIntegrationTable
            carriers={carrierIntegrations ?? []}
            isLoading={isLoadingCarrierIntegration}
            carrierFilter={carrierFilter}
          />
        )}
      </Grid>
    </Grid>
  )
}
