import { appConfig } from "@/config"
import { EnrollmentModel } from "@/features/TCHub/tcHubTypes"
import { axiosInstance as axios } from "@/services/axios"
import { Patch, Uuid } from "@/utils/types"
import { AchDetails } from "../Settings/types/paymentTypes"
import {
  CarrierEnrollmentQuestionAnswer,
  CheckCoverageResponse,
  DrugSearchResponse,
  EnrollmentTimePeriod,
  EnrollmentTimePeriodId,
  GetAllowancesPayload,
  GetAllowancesResponse,
  GetCarriersResponse,
  HealthBenefitsElection,
  ManageShoppingPersonPayload,
  ManageShoppingPersonsResponse,
  ProviderSearchRequest,
  ProviderSearchResponse,
  ProviderType,
  SelfPaymentInformationRequest,
  SelfPaymentInformationResponse,
  SessionStats,
  ShoppingSession,
  UpdateAllowancePayload,
  WaiveCoveragePayload,
} from "./benefitsElectionTypes"
import { createDrugPreferences, createProviderPreferences } from "./benefitsElectionUtils"

const baseUrl = appConfig.baseApiUrl

export const benefitsElectionBaseUrl = `${baseUrl}/benefitelection`

const healthPlansUrl = baseUrl + "/health-plans/v1"
const shoppingSessionsUrl = `${benefitsElectionBaseUrl}/v1/shopping-sessions`
const providerSearchUrl = healthPlansUrl + "/providers/search"
const drugSearchUrl = healthPlansUrl + "/drugs"
const sessionUrl = (shoppingSessionId: string) => `${shoppingSessionsUrl}/${shoppingSessionId}`

export const searchProviders = async <T extends ProviderType>(type: T, body: ProviderSearchRequest) =>
  createProviderPreferences(
    type,
    (await axios.post<ProviderSearchResponse<T>>(providerSearchUrl, { type, ...body })).data.providers
  )

export const searchDrugs = async (searchTerm: string, requireFormulary?: boolean) =>
  createDrugPreferences(
    (
      await axios.get<DrugSearchResponse>(drugSearchUrl, {
        params: new URLSearchParams({ searchTerm, requireFormulary: String(requireFormulary) }),
      })
    ).data.drugs
  )

export const addWaiveCoverage = async (shoppingSessionId: string, waiveCoveragePayload: WaiveCoveragePayload) =>
  (await axios.put<WaiveCoveragePayload>(`${sessionUrl(shoppingSessionId)}/waive`, waiveCoveragePayload)).data

export const updateAllowance = async (shoppingSessionId: string, updateAllowancePayload: UpdateAllowancePayload) =>
  (await axios.put<ShoppingSession>(`${sessionUrl(shoppingSessionId)}/allowance`, updateAllowancePayload)).data

export const createHealthBenefitsElection = async (
  shoppingSessionId: Uuid,
  electionInfo: Omit<HealthBenefitsElection, "id">
) =>
  (
    await axios.post<HealthBenefitsElection>(
      `${benefitsElectionBaseUrl}/v1/shopping-sessions/${shoppingSessionId}/health-benefit-elections/`,
      electionInfo
    )
  ).data

export const updateHealthBenefitsElection = async (
  shoppingSessionId: Uuid,
  { id: healthBenefitsElectionId, ...benefitsElection }: Patch<HealthBenefitsElection>
) =>
  (
    await axios.patch<HealthBenefitsElection>(
      `${benefitsElectionBaseUrl}/v1/shopping-sessions/${shoppingSessionId}/health-benefit-elections/${healthBenefitsElectionId}`,
      benefitsElection
    )
  ).data

export const deleteHealthBenefitsElection = async (shoppingSessionId: Uuid, healthBenefitsElectionId: Uuid) =>
  (
    await axios.delete<HealthBenefitsElection>(
      `${benefitsElectionBaseUrl}/v1/shopping-sessions/${shoppingSessionId}/health-benefit-elections/${healthBenefitsElectionId}`
    )
  ).data

export const getEnrollmentTimePeriods = async (employmentId: string, onlyNowOrLater?: boolean) =>
  (
    await axios.get<EnrollmentTimePeriod[]>(
      `${benefitsElectionBaseUrl}/v1/employees/${employmentId}/enrollment-time-periods` +
        (onlyNowOrLater ? `?onlyNowOrLater=true` : "")
    )
  ).data

export const getSessionStats = async (companyId: string, planYear: number) =>
  (
    await axios.get<SessionStats>(
      `${benefitsElectionBaseUrl}/v1/companies/${companyId}/shopping-sessions/stats?planYear=${planYear}`
    )
  ).data

export const getShoppingSessions = async (employmentId: string, enrollmentTimePeriodId: string, create: boolean) =>
  (
    await axios.get<ShoppingSession[]>(
      `${benefitsElectionBaseUrl}/v1/employees/${employmentId}/shopping-sessions?create=${create}` +
        (enrollmentTimePeriodId ? `&timePeriod=${enrollmentTimePeriodId}` : "")
    )
  ).data

export const getShoppingSession = async (shoppingSessionId: Uuid) =>
  (await axios.get<ShoppingSession>(`${benefitsElectionBaseUrl}/v1/shopping-sessions/${shoppingSessionId}`)).data

export const getCarriers = async (searchTerm: string, stateCode?: string) =>
  (
    await axios.get<GetCarriersResponse>(`${healthPlansUrl}/carriers`, {
      params: { searchTerm, limit: 1000, ...(stateCode && { stateCode }) },
    })
  ).data.carriers

export const getCarrierEnrollmentQuestionAnswers = async (electionId: string) =>
  (
    await axios.get<CarrierEnrollmentQuestionAnswer[]>(
      `${benefitsElectionBaseUrl}/v1/health-benefit-elections/${electionId}/enrollment-question-answers`
    )
  ).data

export const createCarrierEnrollmentQuestionAnswers = async (
  electionId: string,
  answers: CarrierEnrollmentQuestionAnswer[]
) =>
  (
    await axios.post<CarrierEnrollmentQuestionAnswer[]>(
      `${benefitsElectionBaseUrl}/v1/health-benefit-elections/${electionId}/enrollment-question-answers`,
      answers
    )
  ).data

export const updateCarrierEnrollmentQuestionAnswers = async (
  electionId: string,
  answers: CarrierEnrollmentQuestionAnswer[]
) =>
  (
    await axios.patch<CarrierEnrollmentQuestionAnswer[]>(
      `${benefitsElectionBaseUrl}/v1/health-benefit-elections/${electionId}/enrollment-question-answers`,
      answers
    )
  ).data

// FUTURE: Typedef for this based on schema
type DeleteAnswerResponse = object
export const deleteCarrierEnrollmentQuestionAnswers = async (
  electionId: string,
  answers: CarrierEnrollmentQuestionAnswer[]
) =>
  (
    await axios.delete<DeleteAnswerResponse>(
      `${benefitsElectionBaseUrl}/v1/health-benefit-elections/${electionId}/enrollment-question-answers`,
      { data: answers.map(({ questionId, shoppingPersonId }) => ({ questionId, shoppingPersonId })) }
    )
  ).data

export const manageShoppingPersons = async (shoppingSessionId: string, values: ManageShoppingPersonPayload[]) =>
  (await axios.post<ManageShoppingPersonsResponse>(`${sessionUrl(shoppingSessionId)}/shopping-persons`, values)).data

export const getAllowances = async (
  companyId: string,
  planYear: number,
  employeeId: string,
  values: GetAllowancesPayload
) =>
  (
    await axios.post<GetAllowancesResponse>(
      `${baseUrl}/benefits/v1/companies/${companyId}/employees/${employeeId}/allowances/${planYear}`,
      values
    )
  ).data

export const searchHealthBenefitElections = async (searchRequest: {
  carrierId?: string
  state?: string
  planYear?: number
  limit?: number
  offset?: number
  createdBefore?: string
  employeeName?: string
  employeeEmail?: string
  companyName?: string
}) =>
  (
    await axios.post<{
      healthBenefitElections: EnrollmentModel[]
      meta: {
        total: number
      }
    }>(
      `${benefitsElectionBaseUrl}/v1/health-benefit-elections/search` +
        `?limit=${searchRequest.limit}&offset=${searchRequest.offset}`,
      searchRequest
    )
  ).data

export const getAchDetails = async (electionId: string, premiumAmountInCents: number | undefined) =>
  (
    await axios.get<AchDetails>(
      `${benefitsElectionBaseUrl}/v1/health-benefit-elections/${electionId}/ach-details?${premiumAmountInCents ? `premiumAmountInCents=${premiumAmountInCents}` : ""}`
    )
  ).data

export const checkCoverageForAllMembers = async (shoppingSessionId: string) =>
  (
    await axios.get<CheckCoverageResponse>(
      `${benefitsElectionBaseUrl}/v1/shopping-sessions/${shoppingSessionId}/check-coverage-all-members`
    )
  ).data

export const closeShoppingSession = async (shoppingSessionId: string) =>
  (await axios.put(`${benefitsElectionBaseUrl}/v1/shopping-sessions/${shoppingSessionId}/close`)).data

export const getHealthBenefitElection = async (electionId: string) =>
  (await axios.get<HealthBenefitsElection>(`${benefitsElectionBaseUrl}/v1/health-benefit-elections/${electionId}`)).data

export const submitSelfPaymentInformation = async (
  employmentId: Uuid,
  electionId: Uuid,
  request: SelfPaymentInformationRequest
) =>
  (
    await axios.post<SelfPaymentInformationResponse>(
      `${benefitsElectionBaseUrl}/v1/employees/${employmentId}/health-benefit-elections/${electionId}/payment-details`,
      request
    )
  ).data

export const resetShoppingSession = async (employmentId: Uuid, enrollmentInfo: EnrollmentTimePeriodId) =>
  (
    await axios.post<ShoppingSession>(
      `${benefitsElectionBaseUrl}/v1/employees/${employmentId}/reset-shopping`,
      enrollmentInfo
    )
  ).data
