import { PickRequired, WrapperProps } from "@/utils/types"
import { Card, Skeleton } from "@mui/material"
import { random } from "lodash"

interface WidgetLayoutProps extends PickRequired<WrapperProps, "children"> {
  isLoading?: boolean
}

export const WidgetLayout = ({ isLoading, children, sx = {}, "data-qa": dataQa }: WidgetLayoutProps) =>
  isLoading ? (
    <Skeleton height={`${random(10, 20)}rem`} variant="rounded" />
  ) : (
    <Card sx={{ p: 6, ...sx }} data-qa={dataQa ?? "widget"}>
      {children}
    </Card>
  )
