import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { MultipleClassesGrid } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/CustomClasses"
import { useGetCurrentClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import { DashboardLayout } from "@/features/Dashboard/components/DashboardLayout"
import { Uuid } from "@/utils/types"
import { Grid, Typography } from "@mui/material"
import { constant, noop } from "lodash"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { getCurrentPlanId } from "../../Auth/services/authService"
import { convertHraClassModelToCustomClassDetails } from "../benefitsUtils"

export const Benefits = () => {
  const [hraPlanId, setHraPlanId] = useState<string>("")
  const { user } = useAuth()
  //FUTURE Remove this unsafe type cast
  const companyId = getActiveCompany(user)?.companyId as Uuid
  const { data } = useGetCurrentClasses(companyId, hraPlanId)
  const customClassDetails = convertHraClassModelToCustomClassDetails(data) ?? []

  useEffect(() => {
    getCurrentPlanId(companyId).then(id => {
      setHraPlanId(id)
    })
  }, [companyId])

  return (
    <AuthGuard>
      <DashboardLayout>
        <Grid container>
          <Helmet title="Benefits" />
          <Grid container>
            <Grid item sx={{ mb: 12 }}>
              <Typography variant="h1" gutterBottom display="inline" data-qa="page-header">
                Benefits
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item sm={10}>
              <Typography variant="h5" gutterBottom>
                Compliance review
              </Typography>
            </Grid>
            <Grid item sx={{ mb: 9 }}>
              <Typography variant="body1" gutterBottom>
                To continue receiving reimbursements, employees must submit proof of health insurance, which Take
                Command will review for compliance <Typography variant="body1bold">annually.</Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item sm={10}>
              <Typography variant="h5" gutterBottom>
                Eligibility classes
              </Typography>
            </Grid>
            <Grid item sx={{ mb: 9 }}>
              <Typography variant="body1" gutterBottom>
                Reimbursements for insurance premiums + medical expenses
              </Typography>
            </Grid>
            <Grid item>
              <MultipleClassesGrid
                classes={customClassDetails}
                toggleSelection={noop}
                isSelected={constant(false)}
                createClass={noop}
                canAddNewClass={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </DashboardLayout>
    </AuthGuard>
  )
}
