import { ConfirmationModal } from "@/components/ConfirmationModal"
import { useNotifications } from "@/services/notificationService"
import { Button } from "@mui/material"
import { useState } from "react"
import { useBulkSubmitEnrollmentsToCarrier } from "../Compliance/tcHubComplianceService"
import { HealthBenefitElectionRequest } from "../tcHubTypes"

export interface SubmittedToCarrierButtonProps {
  ids: string[]
  disabled: boolean
}

export const SubmittedToCarrierButton = ({ ids, disabled }: SubmittedToCarrierButtonProps) => {
  const [numSubmitted, setNumSubmitted] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { notify } = useNotifications("submit-enrollments-to-carrier")
  const { mutateAsync: bulkSubmitEnrollmentsToCarrier } = useBulkSubmitEnrollmentsToCarrier()

  const submitEnrollmentsToCarrier = async () => {
    try {
      setIsModalOpen(true)
      const healthBenefitElectionRequest: HealthBenefitElectionRequest = {
        healthBenefitElectionIds: ids,
      }
      await bulkSubmitEnrollmentsToCarrier(healthBenefitElectionRequest)
      notify(`${numSubmitted} ${numSubmitted === 1 ? "record is" : "records are"} updated successfully`, "success")
      setIsModalOpen(false)
    } catch {
      const errorMessage = "Failed to submtit enrollments to carrier. Please try again later."
      setIsModalOpen(false)
      notify(errorMessage, "error")
    }
  }

  const handleClick = () => {
    setNumSubmitted(ids.length)
    setIsModalOpen(true)
  }

  return (
    <>
      <Button variant="contained" disabled={disabled} onClick={handleClick} data-qa="submitted-to-carrier-button">
        Mark as Submitted to Carrier
      </Button>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Submitted to Carrier"
        message={`${numSubmitted} ${numSubmitted === 1 ? "record is" : "records are"} selected to be submitted`}
        actionLabel="Continue"
        cancelLabel="Cancel"
        isCancelable
        onConfirm={() => {
          submitEnrollmentsToCarrier()
        }}
      />
    </>
  )
}
