import { Callout } from "@/components/Branding"
import { PLAN_PREMIUM_LABEL } from "@/constants"
import { createDataQa, DataQa } from "@/utils/dataQa"
import { createDateFromText, formatCents, formatDate, lowercaseFirstCharacter } from "@/utils/formatting"
import { Cents } from "@/utils/types"
import { Grid, Typography } from "@mui/material"
import { CHECKOUT, COMPLETE, DEPENDENT, SIGNATURES, SUMMARY } from "../../benefitsElectionConstants"
import { useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { Plan } from "../../benefitsElectionTypes"
import { getDisplayName, validateApplicants } from "../../benefitsElectionUtils"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { CoverageWarning } from "../../components/CoverageWarning"
import { usePurchasePlan, useShowCheckoutStep } from "../../hooks/usePurchasePlan"

const baseDataQa = "summary"

interface PriceProps {
  label?: string
  amount: Cents
  negative?: boolean
}

const Price = ({ label, amount, negative }: PriceProps) => (
  <Typography variant="body2bold" color={negative ? "error" : "inherit"} data-qa={createDataQa(baseDataQa, "price")}>
    {label} {formatCents(amount)}/mo
  </Typography>
)

interface LineItemProps {
  name: string
  content: string
  "data-qa"?: DataQa
}

const LineItem = ({ name, content, "data-qa": dataQa }: LineItemProps) => (
  <Typography variant="body2" data-qa={dataQa ?? createDataQa(baseDataQa, name)}>
    <Typography variant="body2bold">{name}: </Typography>
    {content}
  </Typography>
)

interface OrderSummaryProps {
  total: Cents
  contribution: Cents
  employeeName: string
  familyMemberNames: string[]
  plan: Plan
  planEffectiveDate: Date
}

const OrderSummary = ({
  total,
  contribution,
  employeeName,
  familyMemberNames,
  plan,
  planEffectiveDate,
}: OrderSummaryProps) => {
  const { planType, carrier } = plan
  const { name: carrierName, extraStepDescription } = carrier

  return (
    <>
      <Grid container justifyContent="space-between" direction="row" mt={5}>
        <Typography variant="h6" gutterBottom>
          Order summary
        </Typography>
        <Price amount={total} label={`${PLAN_PREMIUM_LABEL}: `} />
      </Grid>
      <Grid container direction="column" gap="1rem" mt={1}>
        <LineItem name="Employee" content={employeeName} />
        <LineItem name="Family Members" content={familyMemberNames.join(", ") || "None"} />
        <LineItem name="Insurance Carrier" content={carrierName} />
        <LineItem name="Plan" content={plan.name} />
        <LineItem name="Plan Type" content={planType} />
        <LineItem
          name="Coverage could start as soon as"
          data-qa={createDataQa(baseDataQa, "coveragestarts")}
          content={formatDate(planEffectiveDate)}
        />
        {extraStepDescription && (
          <Callout
            mainText={
              <>
                <Typography variant="body1bold">{carrierName}</Typography>
                {` requires you to ${lowercaseFirstCharacter(extraStepDescription)}. Take Command support may be limited.`}
              </>
            }
          />
        )}
        <Grid container justifyContent="space-between" py="2rem" direction="row">
          <Typography variant="body2">Your contribution:</Typography>
          <Price amount={contribution} negative={contribution > 0} />
        </Grid>
      </Grid>
    </>
  )
}

export const PlanSelectionFinalWarning = () => (
  <Grid container direction="column" alignItems="center" pt="2rem">
    <Typography variant="h3tiempos" gutterBottom>
      Plan selection is final
    </Typography>
    <Typography pb="2rem">Complete your purchase when you're sure you want to enroll in this health plan.</Typography>
  </Grid>
)

export const Summary = () => {
  const shoppingUrl = useShoppingUrl()
  const { purchasePlan, isPurchasePending } = usePurchasePlan("summary")
  const showCheckout = useShowCheckoutStep()

  // FUTURE: Remove unsafe non-null assertion
  const personalInformation = useBenefitsElectionStore(state => state.employee.personalInformation)!
  const familyMembers = useBenefitsElectionStore(state => state.familyMembers)
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)!
  const allowance = useBenefitsElectionStore(state => state.allowance?.amountCents) ?? 0
  const healthBenefitElections = useBenefitsElectionStore(state => state.currentShoppingSession.healthBenefitElections)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  // SAFETY: There is always one primary election with its effective date
  const planEffectiveDate = createDateFromText(healthBenefitElections.find(e => e.isPrimary)!.planEffectiveDate!)
  const previous = shoppingUrl + SIGNATURES
  const next = shoppingUrl + (showCheckout ? CHECKOUT : COMPLETE)
  const continueLabel = showCheckout ? undefined : "Complete purchase"
  const employeeName = getDisplayName(personalInformation)

  const familyMemberNames = validateApplicants(familyMembers, personalInformation.zipCode?.fipsCode!).map(
    member =>
      `${getDisplayName(member.personalInformation)} (${
        member.personalInformation.relationship === DEPENDENT ? "Child" : "Spouse"
      })`
  )

  const total = selectedPlan.premiumAmountCents
  const contribution = Math.max(total - allowance, 0)

  return (
    <BenefitsElectionStep
      title={showCheckout ? "Summary" : "Summary & Purchase"}
      description={
        <Typography gutterBottom>
          Review your health plan details below. All plan selections are{" "}
          <Typography variant="body1bold">final</Typography>
        </Typography>
      }
      previous={previous}
      next={next}
      isSubmitting={isPurchasePending}
      continueLabel={continueLabel}
      handleContinue={async () => {
        if (showCheckout) {
          if (currentStep === SUMMARY) {
            setCurrentStep(CHECKOUT)
          }
        } else {
          await purchasePlan()
        }
      }}
      required
      advanceOnSuccess
    >
      <CoverageWarning />
      <OrderSummary
        total={total}
        contribution={contribution}
        employeeName={employeeName}
        familyMemberNames={familyMemberNames}
        plan={selectedPlan}
        planEffectiveDate={planEffectiveDate}
      />
      {!showCheckout && <PlanSelectionFinalWarning />}
    </BenefitsElectionStep>
  )
}
