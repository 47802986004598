import Img404 from "@/assets/img/404-Error-Graphic.png"
import { ReturnToWebsiteButton } from "@/components/ErrorBoundary/ErrorPage"
import { Grid, Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { AuthLayout } from "./AuthLayout"

export const Page404 = () => (
  <AuthLayout>
    <Grid
      container
      sx={{
        margin: "0 auto",
        maxWidth: "32.5rem",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        minHeight: "100%",
        flexDirection: "column",
      }}
    >
      <Helmet title="404 Error" />
      <img src={Img404} alt="404 Error" />
      <Typography variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" align="center" gutterBottom>
        Page not found.
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        The page you are looking for might have been removed.
      </Typography>
      <ReturnToWebsiteButton />
    </Grid>
  </AuthLayout>
)
