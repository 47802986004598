import { createDataQa } from "@/utils/dataQa"
import { cleanText, CleanTextOptions } from "@/utils/formatting"
import { ContentCopyOutlined, ContentCopyTwoTone, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import { IconButton, InputAdornment, TextField, TextFieldProps, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { useFormikContext } from "formik"
import { trim } from "lodash"
import { forwardRef, useState } from "react"
import { IMaskInput } from "react-imask"

type ClampedTextFieldProps = TextFieldProps & {
  "data-qa": string
  options?: CleanTextOptions
}

export const ClampedTextField = ({
  onChange,
  value,
  variant,
  label,
  onBlur,
  name,
  options,
  "data-qa": dataQa,
  ...props
}: ClampedTextFieldProps) => (
  <TextField
    label={label}
    value={value}
    variant={variant}
    onChange={e => {
      e.target.value = cleanText(e.target.value, options ?? {})
      onChange?.(e)
    }}
    onBlur={e => {
      onBlur?.(e)
      e.target.value = cleanText(trim(e.target.value), options ?? {})
      onChange?.(e)
    }}
    {...props}
    inputProps={{
      "data-qa": dataQa,
      name,
      ...props.inputProps,
    }}
  />
)

export type ObscuredTextFieldProps = Omit<ClampedTextFieldProps, "data-qa">

export const ObscuredTextField = ({ name, ...props }: ObscuredTextFieldProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const dataQa = createDataQa(name, "obscured-field")
  const visibilityDataQa = createDataQa("visibility-icon", name)
  const inputDataQa = createDataQa(dataQa, "input")

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label={`toggle ${name} visibility`}
        edge="end"
        onClick={() => setIsVisible(!isVisible)}
        data-qa={visibilityDataQa}
      >
        {isVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <ClampedTextField
      name={name}
      type={isVisible ? "text" : "password"}
      fullWidth
      data-qa={dataQa}
      {...props}
      sx={{ my: 2, ...props.sx }}
      InputProps={{
        ...props.InputProps,
        endAdornment,
      }}
      inputProps={{ "data-qa": inputDataQa, ...props.inputProps }}
    />
  )
}

interface FirstNameValues {
  firstName: string
}

export const FirstNameTextField = (props: TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<FirstNameValues>()

  return (
    <ClampedTextField
      data-qa="firstName"
      name="firstName"
      label="First Name"
      variant="outlined"
      value={values.firstName ?? ""}
      error={Boolean(touched.firstName && errors.firstName)}
      helperText={touched.firstName && errors.firstName}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      required
      options={{
        personNameCharactersOnly: true,
      }}
      {...props}
    />
  )
}

interface MiddleNameValues {
  middleName: string
}

export const MiddleNameTextField = (props: TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<MiddleNameValues>()

  return (
    <ClampedTextField
      data-qa="middleName"
      name="middleName"
      label="Middle Name"
      variant="outlined"
      value={values.middleName}
      error={Boolean(touched.middleName && errors.middleName)}
      helperText={touched.middleName && errors.middleName}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      options={{
        personNameCharactersOnly: true,
      }}
      {...props}
    />
  )
}

interface LastNameValues {
  lastName: string
}

export const LastNameTextField = (props: TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<LastNameValues>()

  return (
    <ClampedTextField
      data-qa="lastName"
      name="lastName"
      label="Last Name"
      variant="outlined"
      value={values.lastName ?? ""}
      error={Boolean(touched.lastName && errors.lastName)}
      helperText={touched.lastName && errors.lastName}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      required
      options={{
        personNameCharactersOnly: true,
      }}
      {...props}
    />
  )
}

interface PreferredNameValues {
  preferredName: string
}

export const PreferredNameTextField = (props: TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<PreferredNameValues>()

  return (
    <ClampedTextField
      data-qa="preferredName"
      name="preferredName"
      label="Preferred Name"
      variant="outlined"
      value={values.preferredName ?? ""}
      error={Boolean(touched.preferredName && errors.preferredName)}
      helperText={touched.preferredName && errors.preferredName}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      options={{
        personNameCharactersOnly: true,
      }}
      {...props}
    />
  )
}

interface EmailValues {
  email: string
}

// This prevents overlap of credentials on login page SEG-1971
const ensureFloatingAutofill = {
  "&:has(.MuiInputBase-input:autofill) > label.MuiFormLabel-root": { transform: "translate(14px, -9px) scale(0.75)" },
  "&:has(.MuiInputBase-input:autofill) fieldset > legend": { maxWidth: "100%" },
}

export const EmailTextField = ({ sx = {}, ...props }: TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<EmailValues>()

  return (
    <ClampedTextField
      data-qa="email"
      name="email"
      label="Email"
      variant="outlined"
      value={values.email}
      error={Boolean(touched.email && errors.email)}
      helperText={touched.email && errors.email}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      required
      options={{ lowerCaseOnly: true }}
      inputProps={{
        style: { textTransform: "lowercase" },
      }}
      sx={{ ...ensureFloatingAutofill, ...sx }}
      {...props}
    />
  )
}

interface PasswordValues {
  password: string
}

export const PasswordTextField = ({ sx = {}, ...props }: ObscuredTextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<PasswordValues>()

  return (
    <ObscuredTextField
      name="password"
      label="Password"
      fullWidth
      required
      value={values.password}
      error={Boolean(touched.password && errors.password)}
      helperText={touched.password && errors.password}
      onBlur={handleBlur}
      onChange={handleChange}
      sx={{ ...ensureFloatingAutofill, ...sx }}
      {...props}
    />
  )
}

interface Street1Values {
  street1: string
}

export const Street1TextField = (props: TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<Street1Values>()

  return (
    <ClampedTextField
      name="street1"
      label="Street Address"
      variant="outlined"
      value={values.street1}
      error={Boolean(touched.street1 && errors.street1)}
      helperText={touched.street1 && errors.street1}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      required
      data-qa="street1"
      {...props}
    />
  )
}

interface Street2Values {
  street2: string
}

export const Street2TextField = (props: TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<Street2Values>()

  return (
    <ClampedTextField
      name="street2"
      label="Street 2 (optional)"
      variant="outlined"
      value={values.street2}
      error={Boolean(touched.street2 && errors.street2)}
      helperText={touched.street2 && errors.street2}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      data-qa="street2"
      {...props}
    />
  )
}

interface CityValues {
  city: string
}

export const CityTextField = (props: TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<CityValues>()

  return (
    <ClampedTextField
      name="city"
      label="City"
      variant="outlined"
      value={values.city}
      error={Boolean(touched.city && errors.city)}
      helperText={touched.city && errors.city}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      required
      data-qa="city"
      {...props}
    />
  )
}

const ZipMaskInput = forwardRef<
  HTMLElement,
  {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
  }
>(({ onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    mask="00000"
    inputRef={ref as never}
    onAccept={value =>
      onChange({
        target: {
          name: props.name,
          value,
        },
      })
    }
    overwrite
  />
))

interface ZipCodeValues {
  zipCode: string
}

export const ZipCodeTextField = (props: TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<ZipCodeValues>()

  return (
    <ClampedTextField
      name="zipCode"
      label="ZIP code"
      value={values.zipCode}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(touched.zipCode && errors.zipCode)}
      helperText={touched.zipCode && errors.zipCode}
      required
      fullWidth
      {...props}
      InputProps={{
        inputComponent: ZipMaskInput as never,
      }}
      data-qa="zip-code-text-field"
    />
  )
}

export interface ObscuredAccountNumberFieldProps {
  name: string
  value: string | undefined
  label: string
}

export const ObscuredAccountNumberField = ({ name, value, label }: ObscuredAccountNumberFieldProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const dataQa = createDataQa(name, "obscured-field")
  const valueDataQa = createDataQa(name, "obscured-field-value")
  const copyDataQa = createDataQa(name, "copy-icon")
  const visibilityDataQa = createDataQa(name, "visibility-icon")

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(value ?? "")
    setIsCopied(true)
  }

  const maskValue = () => value?.replace(/.(?=.{4})/g, "*")
  const [displayValue, setDisplayValue] = useState(maskValue())

  const obsureValue = () => {
    if (!isVisible) {
      setDisplayValue(value)
    } else {
      setDisplayValue(maskValue())
    }
    setIsVisible(!isVisible)
  }

  return (
    <Stack data-qa={dataQa} direction="row" spacing={1}>
      <Stack direction="row" spacing={2} width="20rem">
        <Typography variant="body1bold" alignSelf="center">
          {label}
        </Typography>
        <Typography alignSelf="center" data-qa={valueDataQa}>
          {displayValue}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={0.5}>
        <IconButton aria-label={`Copy ${name}`} edge="end" onClick={() => copyToClipboard()} data-qa={copyDataQa}>
          {isCopied ? <ContentCopyTwoTone /> : <ContentCopyOutlined />}
        </IconButton>
        <IconButton
          aria-label={`Toggle ${name} visibility`}
          edge="end"
          onClick={() => obsureValue()}
          data-qa={visibilityDataQa}
        >
          {isVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
        </IconButton>
      </Stack>
    </Stack>
  )
}

const PhoneMaskInput = forwardRef<
  HTMLElement,
  {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
  }
>(({ onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    mask="(000) 000-0000"
    inputRef={ref as never}
    onAccept={value =>
      onChange({
        target: {
          name: props.name,
          value,
        },
      })
    }
    overwrite
  />
))

interface PhoneNumberValues {
  phoneNumber: string
}

export const PhoneNumberTextField = (props: TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<PhoneNumberValues>()

  return (
    <ClampedTextField
      data-qa="zip-code-text-field"
      name="phoneNumber"
      label="Phone number"
      value={values.phoneNumber}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
      helperText={touched.phoneNumber && errors.phoneNumber}
      required
      fullWidth
      {...props}
      InputProps={{
        inputComponent: PhoneMaskInput as never,
      }}
    />
  )
}
