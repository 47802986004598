import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import {
  createNewFundingEvent,
  deleteFundingEvent,
  processFundingEvent,
  updateFundingEvent,
} from "@/features/TCHub/tcHubEndpoints"
import { useAutoPayCompanies } from "@/features/TCHub/tcHubService"
import {
  CreateFundingEventRequest,
  FundingEventModel,
  FundingEventStatus,
  FundingEventSummaryModel,
  FundingEventUpdate,
} from "@/features/TCHub/tcHubTypes"
import { toTitleCase } from "@/utils/formatting"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { sumBy } from "lodash"
import { DateTime } from "luxon"
import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"

export const fundingEventTimePeriodEnum = {
  OFF_CYCLE: "OFF_CYCLE",
  RECURRING: "RECURRING",
  NEW_EMPLOYER: "NEW_EMPLOYER",
  OPEN_ENROLLMENT: "OPEN_ENROLLMENT",
} as const

export const fundingEventTimePeriodOptions = [
  { value: fundingEventTimePeriodEnum.OFF_CYCLE, label: "Off Cycle" },
  { value: fundingEventTimePeriodEnum.RECURRING, label: "Recurring" },
  { value: fundingEventTimePeriodEnum.NEW_EMPLOYER, label: "New Employer" },
  { value: fundingEventTimePeriodEnum.OPEN_ENROLLMENT, label: "Open Enrollment" },
] as const

export const fundingEventStatusEnum = {
  FUNDED: "FUNDED",
  IN_PROGRESS: "IN_PROGRESS",
  SCHEDULED: "SCHEDULED",
  FAILED: "FAILED",
} as const

export const fundingEventStatusOptions = [
  { value: fundingEventStatusEnum.FUNDED, label: "Funded" },
  { value: fundingEventStatusEnum.IN_PROGRESS, label: "In Progress" },
  { value: fundingEventStatusEnum.SCHEDULED, label: "Scheduled" },
  { value: fundingEventStatusEnum.FAILED, label: "Failed" },
] as const

export const fundingEventDetailsInitialValues = {
  company: null as CompanyModel | null,
  timePeriod: fundingEventTimePeriodEnum.OFF_CYCLE as string | null,
  timePeriodLabel: null as string | null,
  periodStartAt: null as Date | null,
  periodEndAt: null as Date | null,
  fundingTimestamp: null as Date | null,
  numberOfEmployees: null as number | null,
  totalAmountCents: null as number | null,
  premiumsAmountCents: null as number | null,
  reserveAmountCents: null as number | null,
  status: null as FundingEventStatus | null,
  submit: false,
} as const

export const convertToFormFields = (companySet: Map<string, CompanyModel>, fundingEvent: FundingEventSummaryModel) =>
  ({
    company: companySet.get(fundingEvent.companyId) ?? null,
    timePeriod: fundingEvent.timePeriod ?? null,
    timePeriodLabel: fundingEvent.timePeriodLabel ?? null,
    periodStartAt: DateTime.fromISO(fundingEvent.periodStartAt).toJSDate(),
    periodEndAt: DateTime.fromISO(fundingEvent.periodEndAt).toJSDate(),
    fundingTimestamp: DateTime.fromISO(fundingEvent.expectedFundingAt).toJSDate(),
    numberOfEmployees: fundingEvent.numberOfEmployees,
    totalAmountCents: fundingEvent.totalFundingAmountCents,
    premiumsAmountCents: sumBy(fundingEvent.premiumAmounts, "amountCents"),
    reserveAmountCents: fundingEvent.replenishReserveAmountCents,
    status: fundingEvent.status ?? null,
    submit: false,
  }) satisfies typeof fundingEventDetailsInitialValues

export const useCompanySet = () => {
  const { data: companies } = useAutoPayCompanies()

  return useMemo(() => {
    const companySet = new Map<string, CompanyModel>()

    companies?.forEach(company => {
      companySet.set(company.id, company)
    })

    return companySet
  }, [companies])
}

export const useFundingEventUpdate = (companyId: string | null, fundingEventId: string | null) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (fundingEventUpdate: FundingEventUpdate) =>
      updateFundingEvent(companyId!, fundingEventId!, fundingEventUpdate),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["funding-events", fundingEventId],
      })
      queryClient.invalidateQueries({
        queryKey: ["getAllFundingEvents"],
      })
    },
  })
}

export const useFundingEventDelete = (companyId: string | null, fundingEventId: string | null) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      await deleteFundingEvent(companyId!, fundingEventId!)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["funding-events", fundingEventId],
      })
      queryClient.invalidateQueries({
        queryKey: ["getAllFundingEvents"],
      })
    },
  })
}

export const useFundingEventProcess = (companyId: string | null) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: { periodStartAt: string; periodEndAt: string }) =>
      processFundingEvent(companyId!, payload.periodStartAt, payload.periodEndAt),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === "funding-events",
      })
      queryClient.invalidateQueries({
        queryKey: ["getAllFundingEvents"],
      })
    },
  })
}

export const useFundingEventCreate = () =>
  useMutation({
    mutationFn: (payload: CreateFundingEventRequest) => createNewFundingEvent(payload.companyId, payload),
  })

export const useFundingEventsPageParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useMemo(() => {
    const currentParams = Object.fromEntries(searchParams.entries())

    return {
      fundingEventStatus: searchParams.get("status") ?? "",
      setFundingEventStatus: (status: string) => setSearchParams({ ...currentParams, status }),
      fundingEventTimePeriod: searchParams.get("timePeriod") ?? "",
      setFundingEventTimePeriod: (timePeriod: string) => setSearchParams({ ...currentParams, timePeriod }),
    }
  }, [searchParams, setSearchParams])
}

export const getFundingEventTimePeriod = (fundingEvents: FundingEventModel[] | undefined) => {
  const fundingEventTimePeriodChoices = new Set<string>()

  fundingEvents?.forEach(fundingEvent => {
    fundingEventTimePeriodChoices.add(fundingEvent.timePeriod)
  })

  return Array.from(fundingEventTimePeriodChoices).map(timePeriod => ({
    value: timePeriod,
    label: toTitleCase(timePeriod),
  }))
}

export const getFundingEventStatus = (fundingEvents: FundingEventModel[] | undefined) => {
  const fundingEventStatusChoices = new Set<string>()

  fundingEvents?.forEach(fundingEvent => {
    fundingEventStatusChoices.add(fundingEvent.status)
  })

  return Array.from(fundingEventStatusChoices).map(status => ({ value: status, label: toTitleCase(status) }))
}
