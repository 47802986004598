import {
  checkEinDuplicateWithCompanyId,
  validateBusinessStructure,
  validateCity,
  validateCompanyName,
  validateEin,
  validateIndustry,
  validateState,
  validateStreet1,
  validateStreet2,
  validateZip,
} from "@/utils/validations"
import * as Yup from "yup"

export const editCompanyValidationSchema = Yup.object({
  companyName: validateCompanyName,
  businessStructure: validateBusinessStructure,
  ein: validateEin,
  industry: validateIndustry,
  companyAddress: validateStreet1,
  companyAddressLine2: validateStreet2,
  companyCity: validateCity,
  companyZip: validateZip,
  companyState: validateState,
  packageName: Yup.string().required("Package Name is required"),
  hraStartDate: Yup.string().required("HRA start date is required"),
  benefitsAdministratorFirstName: Yup.string().required("First Name is required"),
  benefitsAdministratorLastName: Yup.string().required("Last Name is required"),
  benefitsAdministratorEmail: Yup.string().email("Invalid email").required("Email is required"),
  benefitsAdministratorPhoneNumber: Yup.string().required("Phone Number is required"),
  autoPayAvailableOption: Yup.boolean().required("Auto Pay Available Option is required"),
  isHrisEnabled: Yup.boolean().required("HRIS Enabled Option is required"),
  invitationEmailDate: Yup.string(),
})

export const editRegistrationTrackingValidationSchema = Yup.object({
  registrationStatus: Yup.string().required("Registration Status is required"),
  vendorApplicationFormId: Yup.string().required("Vendor Application Form ID is required"),
  vendorApplicationId: Yup.string().required("Vendor Application ID is required"),
  vendorCustomerId: Yup.string().required("Vendor Customer ID is required"),
  microDepositStatus: Yup.string(),
  microDepositAchReturnCode: Yup.string(),
  microDepositReturnDescription: Yup.string(),
  reapplyCount: Yup.number().required("Reapply Count is required").min(0, "Reapply Count must be greater than 0").max(4, "Reapply Count must be less than 5"),
})

export type EditRegistrationTrackingValues = Yup.InferType<typeof editRegistrationTrackingValidationSchema>

export const editCompanyValidationSchemaWithCompanyId = (companyId: string) =>
  Yup.object({
    companyName: validateCompanyName,
    businessStructure: validateBusinessStructure,
    ein: Yup.string()
      .required("EIN is required")
      .min(10, "EIN must be 9 digits")
      .notOneOf(["00-0000000"], "EIN is invalid")
      .test("isEinValid", "EIN already exists", async value => checkEinDuplicateWithCompanyId(value, companyId)),
    industry: validateIndustry,
    companyAddress: validateStreet1,
    companyAddressLine2: validateStreet2,
    companyCity: validateCity,
    companyZip: validateZip,
    companyState: validateState,
    packageName: Yup.string().required("Package Name is required"),
    hraStartDate: Yup.string().required("HRA start date is required"),
    autoPayAvailableOption: Yup.boolean().required("Auto Pay Available Option is required"),
    isHrisEnabled: Yup.boolean().required("HRIS Enabled Option is required"),
    invitationEmailDate: Yup.string(),
  })

export type EditCompanyValues = Yup.InferType<typeof editCompanyValidationSchema>
