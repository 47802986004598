import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { getHrisConfig, getHrisRoster, linkHrisConnection } from "@/features/Hris/hrisEndpoints"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

export const HRIS_NEW_EMPLOYEE = "NEW_EMPLOYEE"
export const HRIS_POSSIBLE_MATCH = "POSSIBLE_MATCH"
export const HRIS_NEED_REVIEW = "NEED_REVIEW"
export const HRIS_MISSING_INFO = "MISSING_INFO"
export const HRIS_SETTLED = "SETTLED"
export const HRIS_EXCLUDED = "EXCLUDED"

export const useHrisConfig = (companyId: string) =>
  useQuery({
    queryKey: ["hris-config", companyId],
    queryFn: () => getHrisConfig(companyId),
    enabled: Boolean(companyId),
  })

export const useHrisLink = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: { companyId: string; publicToken: string }) => {
      await linkHrisConnection(payload.companyId, payload.publicToken)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === "hris-config",
      })
    },
  })
}

export const useHrisRoster = (companyId: string) =>
  useQuery({
    queryKey: ["hris-roster", companyId],
    queryFn: () => getHrisRoster(companyId),
  })

export const useActiveHrisConfig = () => {
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId || ""
  const { data: hrisConfig } = useHrisConfig(companyId)
  return {
    hrisConfig,
    companyId,
  }
}
