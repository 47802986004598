import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable } from "@/components/Table/Table"
import { TABLE_CELL_PADDING } from "@/constants"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import { ALL_ENROLLMENT_TYPE_OPTIONS } from "@/features/BenefitsElection/benefitsElectionConstants"
import { colors, takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { DATE_FORMAT_MONTH_DAY_YEAR, formatCents, toTitleCase, transformDate } from "@/utils/formatting"
import FilterListIcon from "@mui/icons-material/FilterList"
import { Button, Chip, Grid, SxProps, TableCell, Typography } from "@mui/material"
import { constant } from "lodash"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { defaultMemoize as memoize } from "reselect"
import { APPROVED_RECURRING_PREMIUM, DECLINED_RECURRING_PREMIUM, PENDING_RECURRING_PREMIUM } from "../tcHubConstants"
import { RecurringPremium, RecurringPremiumsTableHeader, RecurringPremiumStatus } from "../tcHubTypes"
import { RecurringPremiumFilterDrawer } from "./tcHubRecurringPremiumFilterDrawer"
import { RecurringPremiumSearch, useRecurringPremiumSearch } from "./tcHubRecurringPremiumSearch"

const headers: RecurringPremiumsTableHeader[] = [
  {
    id: "firstName",
    label: "First Name",
    sortable: true,
    field: "firstName",
    alignment: "left",
  },
  {
    id: "lastName",
    label: "Last Name",
    sortable: true,
    field: "lastName",
    alignment: "left",
  },
  {
    id: "status",
    label: "Status",
    sortable: true,
    field: "status",
    alignment: "left",
  },
  {
    id: "premiumAmountCents",
    label: "Premium Amount",
    sortable: true,
    field: "premiumAmountCents",
    alignment: "left",
  },
  {
    id: "enrolledAllowanceCents",
    label: "Enrolled Allowance",
    sortable: true,
    field: "enrolledAllowanceCents",
    alignment: "left",
  },
  {
    id: "coverageStartDate",
    label: "Start Date",
    sortable: true,
    field: "coverageStartDate",
    alignment: "left",
  },
  {
    id: "coverageEndDate",
    label: "End Date",
    sortable: true,
    field: "coverageEndDate",
    alignment: "left",
  },
  { id: "companyName", label: "Company Name", sortable: true, field: "companyName", alignment: "left" },
  { id: "email", label: "Email", sortable: true, field: "email", alignment: "left" },
  {
    id: "insuranceType",
    label: "Insurance Type",
    sortable: true,
    field: "insuranceType",
    alignment: "left",
  },
  {
    id: "enrollmentType",
    label: "enrollment Type",
    sortable: true,
    field: "enrollmentType",
    alignment: "left",
  },
  {
    id: "submittedToCarrierTimestampUtc",
    label: "Submission date",
    sortable: true,
    field: "submittedToCarrierTimestampUtc",
    alignment: "left",
  },
]

interface RecurringPremiumStatusChipProps {
  dataQa?: string
  recurringPremiumStatus: RecurringPremiumStatus
  sx?: SxProps
}

export const getVariantConfigByStatus = memoize((recurringPremiumStatus: string) => {
  switch (recurringPremiumStatus.toUpperCase()) {
    case APPROVED_RECURRING_PREMIUM:
      return {
        label: "Approved",
        backgroundColor: colors.gumDropGreen,

        color: takeCommandPrimary.main,
      } as const
    case DECLINED_RECURRING_PREMIUM:
      return {
        label: "Declined",
        backgroundColor: "rgba(211, 47, 47, 0.7)",
        color: colors.white,
      } as const
    case PENDING_RECURRING_PREMIUM:
    default:
      return {
        label: "Pending",
        backgroundColor: "rgba(2, 136, 209, 0.1)",
        color: colors.waterBlue,
      } as const
  }
})

export const RecurringPremiumStatusChip = ({ dataQa, recurringPremiumStatus, sx }: RecurringPremiumStatusChipProps) => {
  const variantConfig = getVariantConfigByStatus(recurringPremiumStatus)

  return (
    <Chip
      data-qa={createDataQa(dataQa, "hris-status-chip")}
      sx={{
        backgroundColor: variantConfig.backgroundColor,
        color: variantConfig.color,
        px: 2,
        ...sx,
      }}
      label={
        <Typography variant="body2" sx={{ fontFamily: "Mark Pro Medium" }}>
          {variantConfig.label}
        </Typography>
      }
    />
  )
}

export const TcHubRecurringPremiumsPage = () => {
  const { isLoading, recurringPremiumData } = useRecurringPremiumSearch()
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(25)
  const navigate = useNavigate()

  return (
    <TcHubGuard requiredPermissions={["tc_hub_compliance"]}>
      <Grid container data-qa="tc-hub-recurring-premiums" display="grid">
        <Grid item xs={12} mb={5}>
          <Helmet title="TC Hub Recurring Premiums" />
          <Typography variant="h1" gutterBottom data-qa="recurring-premiums">
            Insurance Coverage
          </Typography>
          <Grid item xs={12}>
            <Typography display="block" gutterBottom mb={4}>
              All records for insurance coverages.
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} mb={2}>
          <Grid item xs={12} lg={10}>
            <RecurringPremiumSearch />
          </Grid>
          <Grid
            container
            xs={12}
            lg={2}
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", lg: "column" },
              alignItems: "flex-end",
            }}
          >
            <Grid item mt={1}>
              <Button
                variant="outlined"
                endIcon={<FilterListIcon />}
                onClick={() => {
                  setIsFilterDrawerOpen(!isFilterDrawerOpen)
                }}
              >
                More filters
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            overflowX: "auto",
          }}
        >
          {!isLoading ? (
            <BaseTable
              rows={recurringPremiumData ?? []}
              selected={[]}
              searchCriteria=""
              onToggleSelect={() => []}
              onToggleOrderBy={() => []}
              onToggleSelectAll={() => []}
              onPageChange={changedPage => setPage(changedPage)}
              onRowsPerPageChange={rows => setRowsPerPage(rows)}
              uniqueIdSelector={constant("")}
              headCells={headers ?? []}
              rowsPerPage={rowsPerPage}
              page={page}
              fullWidth
              orderBy={[{ headCellId: "", direction: "asc" }]}
              onRowClick={(row: RecurringPremium) => {
                navigate(`${row.id}/${row.personId}/${row.employmentId}/${row.companyId}`)
              }}
              exportCsv
              csvTitle="Insurance Coverages"
            >
              {({ row }) => (
                <>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="user-first-name">
                      {row.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="user-last-name">
                      {row.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="status">
                      <RecurringPremiumStatusChip recurringPremiumStatus={row.status} />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="premium-amount">
                      {formatCents(row.premiumAmountCents)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="enrolled-allowance">
                      {formatCents(row.enrolledAllowanceCents)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {transformDate(row.coverageStartDate, DATE_FORMAT_MONTH_DAY_YEAR)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {transformDate(row.coverageEndDate, DATE_FORMAT_MONTH_DAY_YEAR)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="company-name">
                      {row.companyName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="email">
                      {row.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="insurance-type">
                      {toTitleCase(row.insuranceType)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={{ padding: ".531rem 1rem" }} data-qa="enrollmentType">
                      {ALL_ENROLLMENT_TYPE_OPTIONS.find(option => option.value === row?.enrollmentType)?.label ??
                        row?.enrollmentType}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="submission-date">
                      {transformDate(row.submittedToCarrierTimestampUtc, DATE_FORMAT_MONTH_DAY_YEAR)}
                    </Typography>
                  </TableCell>
                </>
              )}
            </BaseTable>
          ) : (
            <SkeletonTableLoader
              data-qa="skeleton-table-loader-recurring-premiums"
              headerTitles={headers.map(column => `${column.label}`)}
              bodyRowsCount={10}
            />
          )}
        </Grid>
        <RecurringPremiumFilterDrawer
          open={isFilterDrawerOpen}
          setOpen={value => {
            setIsFilterDrawerOpen(value)
          }}
        />
      </Grid>
    </TcHubGuard>
  )
}
