import { AppContentWrapper } from "@/components/AppContentWrapper"
import { Footer } from "@/components/Footer"
import { Navbar } from "@/components/Navbar/Navbar"
import { RootWrapper } from "@/components/RootWrapper"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { BenefitsElectionGuard } from "@/features/Auth/guards/BenefitsElectionGuard"
import { Box, useMediaQuery } from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react"
import { Outlet, useOutletContext } from "react-router-dom"
import { BenefitsElectionStepper } from "./BenefitsElectionLayout"

interface FindPlanContext {
  drawerOpen: boolean
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
}

export const useFindPlanDrawer = () => useOutletContext<FindPlanContext>()

export const FindPlanLayout = () => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"))
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <AuthGuard>
      <BenefitsElectionGuard>
        <RootWrapper>
          <AppContentWrapper>
            <Navbar
              onDrawerToggle={() => setDrawerOpen(!drawerOpen)}
              hasLogo={isDesktop}
              hasMark={false}
              hasNotificationDrawer={false}
            />
            <Box id="find-plan-layout" data-qa="find-plan-layout" sx={{ bgcolor: "colors.white", p: "1rem" }}>
              <Box display="flex" justifyContent="center" mt={3}>
                <BenefitsElectionStepper />
              </Box>
              <Outlet context={{ drawerOpen, setDrawerOpen }} />
            </Box>
            <Footer />
          </AppContentWrapper>
        </RootWrapper>
      </BenefitsElectionGuard>
    </AuthGuard>
  )
}
