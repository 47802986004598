import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material"

interface FilterDropdownFieldProps {
  options: ReadonlyArray<{ value: string; label: string }>
  value: string
  onChange: (value: string) => void
  label: string
  clearable?: boolean
  clearLabel?: string
}

// FUTURE: Update the people page to make use of this generic filter dropdown field
export const FilterDropdownField = ({
  options,
  value,
  onChange,
  label,
  clearable = true,
  clearLabel = "All",
}: FilterDropdownFieldProps) => {
  const handleFilterChange = (event: SelectChangeEvent<string>) => onChange(event.target.value)

  return (
    <FormControl sx={{ minWidth: "12rem" }} size="small">
      <InputLabel id={`label-${label}`}>{label}</InputLabel>
      <Select
        labelId={`label-${label}`}
        label={label}
        data-qa="filter-dropdown"
        value={value}
        onChange={handleFilterChange}
        sx={{
          color: takeCommandPrimary.main,
          backgroundColor: "white",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: takeCommandPrimary.main,
            borderRadius: "8px",
          },
          "& .MuiSelect-select .MuiListItemText-root .MuiTypography-root": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
      >
        {clearable && (
          <MenuItem value="" data-qa={createDataQa("filter-item", "clear-all")}>
            <ListItemText primary={clearLabel} />
          </MenuItem>
        )}
        {options.map(option => (
          <MenuItem key={option.value} value={option.value} data-qa={createDataQa("filter-item", option.value)}>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
