import { AppContentWrapper } from "@/components/AppContentWrapper"
import { DrawerWrapper } from "@/components/DrawerWrapper"
import { Footer } from "@/components/Footer"
import { MainContentWrapper } from "@/components/MainContentWrapper"
import { Navbar } from "@/components/Navbar/Navbar"
import { RootWrapper } from "@/components/RootWrapper"
import { useDashboardItems } from "@/components/Sidebar/dashboardItems"
import { Sidebar } from "@/components/Sidebar/Sidebar"
import { DRAWER_WIDTH } from "@/constants"
import { isUserAdministrator } from "@/features/Auth/authUtils"
import { useAuth } from "@/features/Auth/useAuth"
import { BENEFITS_ELECTION } from "@/features/BenefitsElection/benefitsElectionConstants"
import { ACTIVE } from "@/features/Funding/fundingConstants"
import { useHrisConfig } from "@/features/Hris/hrisService"
import { HRIS_STATUS_NOT_STARTED } from "@/features/Hris/hrisTypes"
import { useAutoPayConfig } from "@/features/Settings/settingsService"
import { Uuid, WrapperProps } from "@/utils/types"
import { Box, Button } from "@mui/material"
import { useState } from "react"
import { Outlet } from "react-router-dom"

interface DashboardLayoutProps extends WrapperProps {}

export interface ReturnToShoppingButtonProps {
  shoppingSessionId: Uuid
}

export const ReturnToShoppingButton = ({ shoppingSessionId }: ReturnToShoppingButtonProps) => (
  <Button variant="outlined" color="inherit" href={`/${BENEFITS_ELECTION}/${shoppingSessionId}/welcome`}>
    Return to Shopping
  </Button>
)

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const { user, companyId } = useAuth()
  const { data: autoPayConfig } = useAutoPayConfig(companyId!)
  const { data: hrisConfig } = useHrisConfig(companyId!)
  const isAutoPayActive = autoPayConfig?.fundingStatus === ACTIVE
  const isAdmin = isUserAdministrator(user?.company?.roles ?? [])
  const showHrisNavItems = hrisConfig?.status !== HRIS_STATUS_NOT_STARTED
  const navItems = useDashboardItems(isAutoPayActive, isAdmin, showHrisNavItems)

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  return (
    <RootWrapper>
      <DrawerWrapper>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: DRAWER_WIDTH } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={navItems}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar PaperProps={{ style: { width: DRAWER_WIDTH } }} items={navItems} />
        </Box>
      </DrawerWrapper>
      <AppContentWrapper>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContentWrapper>
          {children}
          <Outlet />
        </MainContentWrapper>
        <Footer />
      </AppContentWrapper>
    </RootWrapper>
  )
}
