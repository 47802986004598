import { useAuth } from "@/features/Auth/useAuth"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SHOPPING, WELCOME } from "../benefitsElectionConstants"
import { useBenefitsElectionStore } from "../benefitsElectionStore"

export const useResetStore = (skipNavigate?: boolean) => {
  const clearBenefitsElectionStore = useBenefitsElectionStore(state => state.clearBenefitsElectionStore)
  const { user, refresh } = useAuth()
  const navigate = useNavigate()

  const [refreshDone, setRefreshDone] = useState(false)

  const storedShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const storedShoppingSessionId = storedShoppingSession.id

  const userShoppingSession = user?.shoppingSession
  const userShoppingSessionId = userShoppingSession?.id
  const shoppingSessionWasReset =
    refreshDone && !storedShoppingSessionId && userShoppingSessionId && userShoppingSession?.shoppingStatus === SHOPPING

  if (shoppingSessionWasReset && !skipNavigate) {
    navigate(`/benefits-election/${userShoppingSessionId}/${WELCOME}`)
  }

  useEffect(() => {
    const refreshData = async () => {
      try {
        clearBenefitsElectionStore()
        await refresh()
        setRefreshDone(true)
      } catch {
        console.error("Error getting user information")
      }
    }
    refreshData()
  }, [clearBenefitsElectionStore, refresh])
}
