import { MonthYearDatePickerField } from "@/components/DatePickerField"
import { SelectField } from "@/components/SelectField"
import { StateSelectField } from "@/components/StateSelectField"
import {
  CityTextField,
  ClampedTextField,
  Street1TextField,
  Street2TextField,
  ZipCodeTextField,
} from "@/components/TextFields"
import {
  ACH_ACCOUNT,
  AchDetails,
  BANK_ACCOUNT_TYPES,
  BANK_ACCOUNT_TYPE_OPTIONS,
  BankAccountType,
  CREDIT_CARD,
  PAYMENT_METHODS,
  PaymentMethod,
} from "@/features/Settings/types/paymentTypes"
import { UsaState } from "@/utils/States"
import {
  requiredMessage,
  validateCity,
  validateState,
  validateStreet1,
  validateStreet2,
  validateZip,
} from "@/utils/validations"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Grid, Tab, Typography } from "@mui/material"
import { Box, Stack, useMediaQuery } from "@mui/system"
import { Formik, useFormikContext } from "formik"
import { forwardRef } from "react"
import { IMaskInput } from "react-imask"
import { useSearchParams } from "react-router-dom"
import * as Yup from "yup"
import {
  BANK_ACCOUNT_PAYMENT_OPTION,
  COMPLETE,
  CREDIT_CARD_PAYMENT_OPTION,
  SUMMARY,
} from "../../benefitsElectionConstants"
import { submitSelfPaymentInformation } from "../../benefitsElectionEndpoints"
import { useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { SelfPaymentInformationRequest } from "../../benefitsElectionTypes"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { usePurchasePlan } from "../../hooks/usePurchasePlan"
import { PlanSelectionFinalWarning } from "./Summary"

export interface CreditCardDetails {
  cardNumber: string
  expirationDate: Date
  cvv: string
  billingZip: string
}

interface PaymentInfoForm {
  paymentMethod: PaymentMethod
  nameOnAccount: string
  street1: string
  street2: string
  city: string
  state: UsaState
  zipCode: string
  ach: AchDetails
  creditCard: Omit<CreditCardDetails, "billingZip">
}

const CardNumberMaskInput = forwardRef<
  HTMLElement,
  {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
  }
>(({ onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    // FUTURE: American Express and certain other credit cards do not match this pattern
    mask="0000 0000 0000 0000"
    inputRef={ref as never}
    onAccept={value =>
      onChange({
        target: {
          name: props.name,
          value,
        },
      })
    }
    overwrite
  />
))

const CardNumberTextField = () => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<PaymentInfoForm>()

  return (
    <ClampedTextField
      name="creditCard.cardNumber"
      label="Card number"
      value={values.creditCard.cardNumber}
      error={Boolean(touched.creditCard?.cardNumber && errors.creditCard?.cardNumber)}
      helperText={touched.creditCard?.cardNumber && errors.creditCard?.cardNumber}
      onChange={handleChange}
      onBlur={handleBlur}
      options={{ numbersOnly: true, maxCharacters: 16 }}
      required
      fullWidth
      InputProps={{
        inputComponent: CardNumberMaskInput as never,
      }}
      data-qa="card-number-text-field"
    />
  )
}

const ExpirationDatePickerField = () => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<PaymentInfoForm>()

  return (
    <MonthYearDatePickerField
      label="Expiry date"
      value={values.creditCard.expirationDate}
      onChange={handleChange}
      onBlur={handleBlur}
      name="creditCard.expirationDate"
      minDate={new Date()}
      required
      error={Boolean(touched.creditCard?.expirationDate && errors.creditCard?.expirationDate)}
      helperText={touched.creditCard?.expirationDate && (errors.creditCard?.expirationDate as never)}
      dateViews={["month", "year"]}
      dateInputFormat="MM/yyyy"
    />
  )
}

const SecurityCodeTextField = () => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<PaymentInfoForm>()

  return (
    <ClampedTextField
      name="creditCard.cvv"
      label="Security code"
      value={values.creditCard.cvv}
      error={Boolean(touched.creditCard?.cvv && errors.creditCard?.cvv)}
      helperText={touched.creditCard?.cvv && errors.creditCard?.cvv}
      onChange={handleChange}
      onBlur={handleBlur}
      options={{ numbersOnly: true, maxCharacters: 4 }}
      required
      data-qa="security-code-text-field"
    />
  )
}

interface NameOnAccountTextFieldProps {
  paymentMethod: PaymentMethod
}

export const NameOnAccountTextField = ({ paymentMethod }: NameOnAccountTextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<PaymentInfoForm>()

  const isBankAccount = paymentMethod === ACH_ACCOUNT

  return (
    <ClampedTextField
      name="nameOnAccount"
      label={`Name on ${isBankAccount ? "account" : "card"}`}
      value={values.nameOnAccount}
      error={Boolean(touched.nameOnAccount && errors.nameOnAccount)}
      helperText={touched.nameOnAccount && errors.nameOnAccount}
      onChange={handleChange}
      onBlur={handleBlur}
      required
      fullWidth
      data-qa="name-on-account-text-field"
    />
  )
}

export const FinancialInstitutionTextField = () => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<PaymentInfoForm>()

  return (
    <ClampedTextField
      name="ach.bankName"
      label="Financial institution"
      value={values.ach.bankName}
      error={Boolean(touched.ach?.bankName && errors.ach?.bankName)}
      helperText={touched.ach?.bankName && errors.ach?.bankName}
      onChange={handleChange}
      onBlur={handleBlur}
      required
      fullWidth
      data-qa="bank-name-text-field"
    />
  )
}

const BankAccountTypeSelectField = () => {
  const { values, handleChange, handleBlur } = useFormikContext<PaymentInfoForm>()

  return (
    <SelectField
      name="ach.accountType"
      label="Account type"
      placeholder="Select Account type"
      data={BANK_ACCOUNT_TYPE_OPTIONS}
      value={values.ach.accountType}
      onChange={handleChange}
      onBlur={handleBlur}
      required
    />
  )
}

const BankAccountNumberTextField = () => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<PaymentInfoForm>()

  return (
    <ClampedTextField
      name="ach.accountNumber"
      label="Account number"
      value={values.ach.accountNumber}
      error={Boolean(touched.ach?.accountNumber && errors.ach?.accountNumber)}
      helperText={touched.ach?.accountNumber && errors.ach?.accountNumber}
      onChange={handleChange}
      onBlur={handleBlur}
      options={{ numbersOnly: true, maxCharacters: 17 }}
      fullWidth
      required
      data-qa="account-number-text-field"
    />
  )
}

const BankRoutingNumberTextField = () => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<PaymentInfoForm>()

  return (
    <ClampedTextField
      name="ach.routingNumber"
      label="Routing number"
      value={values.ach.routingNumber}
      error={Boolean(touched.ach?.routingNumber && errors.ach?.routingNumber)}
      helperText={touched.ach?.routingNumber && errors.ach?.routingNumber}
      onChange={handleChange}
      onBlur={handleBlur}
      options={{ numbersOnly: true, maxCharacters: 9 }}
      fullWidth
      required
      data-qa="routing-number-text-field"
    />
  )
}

// FUTURE: Support using an existing address
export const BillingAddress = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"))

  return (
    <>
      <Typography variant="h6" mt={8}>
        Billing address
      </Typography>
      <Street1TextField sx={{ mt: 4 }} />
      <Street2TextField sx={{ mt: 4 }} />
      <Stack direction={isMobile ? "column" : "row"} mt={4} gap={4}>
        <CityTextField />
        <StateSelectField />
        <ZipCodeTextField />
      </Stack>
    </>
  )
}

const BankAccountTabPanel = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"))

  return (
    <TabPanel value={ACH_ACCOUNT} sx={{ width: "100%" }}>
      <Typography variant="h6" my={4}>
        Bank account information
      </Typography>
      <Stack gap={4}>
        <NameOnAccountTextField paymentMethod={ACH_ACCOUNT} />
        <FinancialInstitutionTextField />
      </Stack>
      <Stack direction={isMobile ? "column" : "row"} mt={4} gap={4}>
        <BankAccountNumberTextField />
        <BankRoutingNumberTextField />
        <BankAccountTypeSelectField />
      </Stack>
      <BillingAddress />
    </TabPanel>
  )
}

const CreditCardTabPanel = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"))

  return (
    <TabPanel value={CREDIT_CARD} sx={{ width: "100%" }}>
      <Typography variant="h6" my={4}>
        Credit/Debit card information
      </Typography>
      <NameOnAccountTextField paymentMethod={CREDIT_CARD} />
      <Stack direction={isMobile ? "column" : "row"} mt={4} gap={4}>
        <Box width={{ xs: "100%", md: "50%" }}>
          <CardNumberTextField />
        </Box>
        <ExpirationDatePickerField />
        <SecurityCodeTextField />
      </Stack>
      <BillingAddress />
    </TabPanel>
  )
}

export interface PaymentInformationProps {
  currentTab: PaymentMethod
  setCurrentTab: (tab: PaymentMethod) => void
  disableCreditCard?: boolean
  disableBankAccount?: boolean
}

const PaymentInformationEntry = ({
  currentTab,
  setCurrentTab,
  disableCreditCard,
  disableBankAccount,
}: PaymentInformationProps) => {
  const { values, setFieldValue, validateForm } = useFormikContext<PaymentInfoForm>()

  return (
    <TabContext value={currentTab}>
      <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={async (_, tab) => {
            setCurrentTab(tab)
            await setFieldValue("paymentMethod", tab)
            await validateForm({ ...values, paymentMethod: tab })
          }}
        >
          {!disableBankAccount && <Tab value={ACH_ACCOUNT} label="Bank Account" />}
          {!disableCreditCard && <Tab value={CREDIT_CARD} label="Credit Card" />}
        </TabList>
      </Box>
      <Grid container>
        {!disableBankAccount && <BankAccountTabPanel />}
        {!disableCreditCard && <CreditCardTabPanel />}
      </Grid>
    </TabContext>
  )
}

// FUTURE: Create a common tabbed interface component which handles the minutiae
// Move this method to shared definition
export const getCurrentTab = <T extends Readonly<string>>(
  params: URLSearchParams,
  values: readonly T[],
  defaultValue = values[0]
) => {
  const value = params.get("tab")
  if (values.includes(value as never)) {
    return value as T
  }

  return defaultValue
}

const CHECKOUT_INITIAL_VALUES: Omit<PaymentInfoForm, "paymentMethod"> = {
  nameOnAccount: "",
  street1: "",
  street2: "",
  city: "",
  state: "" as never,
  zipCode: "",
  ach: {
    bankName: "",
    accountType: "" as never,
    accountNumber: "",
    routingNumber: "",
  },
  creditCard: {
    cardNumber: "",
    expirationDate: null as never,
    cvv: "",
  },
}

const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string<PaymentMethod>().oneOf(PAYMENT_METHODS),
  nameOnAccount: Yup.string()
    .when("paymentMethod", {
      is: CREDIT_CARD,
      then: schema => schema.label("Name on card"),
      otherwise: schema => schema.label("Name on account"),
    })
    .required(requiredMessage),
  street1: validateStreet1,
  street2: validateStreet2,
  city: validateCity,
  state: validateState,
  zipCode: validateZip,
  ach: Yup.object().when("paymentMethod", {
    is: ACH_ACCOUNT,
    then: schema =>
      schema.shape({
        bankName: Yup.string().label("Financial institution").required(requiredMessage),
        accountType: Yup.string<BankAccountType>()
          .oneOf(BANK_ACCOUNT_TYPES)
          .label("Account type")
          .required(requiredMessage),
        accountNumber: Yup.string().label("Account number").required(requiredMessage),
        routingNumber: Yup.string().label("Routing number").required(requiredMessage),
      }),
  }),
  creditCard: Yup.object().when("paymentMethod", {
    is: CREDIT_CARD,
    then: schema =>
      schema.shape({
        cardNumber: Yup.string()
          .label("Card number")
          // FUTURE: 16 digits rules out certain credit cards such as American Express
          // This may need to be revisited should we need to support those cards
          .length(16, ({ label }) => `${label} must be 16 digits`)
          .required(requiredMessage),
        expirationDate: Yup.date()
          .label("Expiry date")
          .min(new Date(), ({ label }) => `${label} cannot be in the past`)
          .required(requiredMessage)
          .typeError("Use MM/YYYY format"),
        cvv: Yup.string().label("Security code").min(3).required(requiredMessage),
      }),
  }),
})

const mapPaymentFormToPayload = ({
  paymentMethod,
  nameOnAccount,
  ach,
  creditCard,
  street1,
  street2,
  city,
  state,
  zipCode,
}: PaymentInfoForm): SelfPaymentInformationRequest => {
  const isBankAccount = paymentMethod === ACH_ACCOUNT

  return {
    nameOnAccount,
    billingAddress: {
      street1,
      street2,
      city,
      state,
      zipCode,
      // FUTURE: Remove these hardcoded sentinel values
      county: "",
      fipsCode: "Unknown",
    },
    ach: isBankAccount ? ach : undefined,
    creditCard: isBankAccount ? undefined : { ...creditCard, billingZip: zipCode },
  }
}

export const Checkout = () => {
  const shoppingUrl = useShoppingUrl()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)!
  const carrierPaymentOption = selectedPlan.carrier.paymentOption ?? BANK_ACCOUNT_PAYMENT_OPTION
  const { purchasePlan, isPurchasePending, employmentId, electionId } = usePurchasePlan("checkout")

  const previous = shoppingUrl + SUMMARY
  const next = shoppingUrl + COMPLETE

  // If the payment option is only bank account, disable credit card
  // If the payment option is only credit card, disable bank account
  const disableCreditCard = carrierPaymentOption === BANK_ACCOUNT_PAYMENT_OPTION
  const disableBankAccount = carrierPaymentOption === CREDIT_CARD_PAYMENT_OPTION
  const defaultValue = disableBankAccount ? CREDIT_CARD : ACH_ACCOUNT

  const paymentMethod = getCurrentTab(searchParams, PAYMENT_METHODS, defaultValue)
  const setPaymentMethod = (tab: PaymentMethod) => setSearchParams(params => ({ tab, ...params }))

  return (
    <Formik
      initialValues={{ ...CHECKOUT_INITIAL_VALUES, paymentMethod }}
      validationSchema={validationSchema}
      onSubmit={async () => {}}
    >
      {({ values, dirty, isValid }) => (
        <BenefitsElectionStep
          title="Purchase plan"
          description="Let's gather your payment details to complete your first premium payment."
          continueLabel="Complete purchase"
          previous={previous}
          next={next}
          disabled={!dirty || !isValid}
          advanceOnSuccess
          isSubmitting={isPurchasePending}
          errorMessage="Error submitting payment information. Please try again later. If the issue persists, contact support for assistance."
          handleContinue={async () => {
            await submitSelfPaymentInformation(employmentId, electionId, mapPaymentFormToPayload(values))
            await purchasePlan()
          }}
          required
        >
          <PaymentInformationEntry
            currentTab={paymentMethod}
            setCurrentTab={setPaymentMethod}
            disableCreditCard={disableCreditCard}
            disableBankAccount={disableBankAccount}
          />
          <PlanSelectionFinalWarning />
        </BenefitsElectionStep>
      )}
    </Formik>
  )
}
