import helpIcon from "@/assets/svg/icon-megaphone.svg"
import { ConfirmationModal } from "@/components/ConfirmationModal"
import { ClampedTextField } from "@/components/TextFields"
import { EXTERNAL_LINKS } from "@/constants"
import {
  HOUSEHOLD_INCOME,
  RECOMMENDED_PLANS,
  USING_TAX_CREDITS,
  WAIVE_COVERAGE,
} from "@/features/BenefitsElection/benefitsElectionConstants"
import {
  useAddWaiveCoverage,
  useManageShoppingPersons,
  useShoppingSession,
  useShoppingUrl,
} from "@/features/BenefitsElection/benefitsElectionService"
import { useBenefitsElectionStore } from "@/features/BenefitsElection/benefitsElectionStore"
import {
  AffordabilityCheckPayload,
  AffordabilityCheckResponse,
} from "@/features/BenefitsElection/benefitsElectionTypes"
import {
  createAffordabilityCheckPayload,
  createAllPlansFilterPreferences,
  createPatchIncomePayload,
} from "@/features/BenefitsElection/benefitsElectionUtils"
import { BenefitsElectionStep } from "@/features/BenefitsElection/components/BenefitsElectionStep"
import { useCheckAffordability, useGetPlans } from "@/features/BenefitsElection/healthPlansService"
import { useNotifications } from "@/services/notificationService"
import { cleanUpNumber } from "@/utils/formatting"
import { StatesToExchangeLink, UsaState } from "@/utils/States"
import { TableViewOutlined } from "@mui/icons-material"
import { Avatar, Button, Card, CardContent, Grid, InputAdornment, Link, Typography } from "@mui/material"
import { Formik } from "formik"
import { isEqual } from "lodash"
import { forwardRef, RefCallback, useEffect, useState } from "react"
import { IMaskInput } from "react-imask"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { TaxCreditsDrawer } from "./TaxCreditsDrawer"

const HOUSEHOLD_INCOME_INITIAL_VALUES = {
  householdIncome: "",
}

const householdIncomeValidationSchema = Yup.object({
  householdIncome: Yup.string().required("Please enter your household income"),
})

const householdIncomeCurrency = () => <InputAdornment position="start">$</InputAdornment>

const handleSalaryEstimatingToolButton = () => {
  window.open("https://intercom.help/take-command-health/en/articles/9353231-household-income", "_blank")
}

const SalaryEstimatingToolCardWithAvatar = () => (
  // FUTURE: Prefer using Callout from @/components/Branding here
  <Grid container alignItems="start" sx={{ mt: 6 }} pl={3}>
    <Grid item xs={12} sm={1}>
      <Avatar src={helpIcon} sx={{ width: 60, height: 60 }} alt="Help avatar" />
    </Grid>
    <Grid item xs={12} sm={7} data-qa="salaryEstimating-Tool-Card">
      <Card variant="outlined">
        <CardContent>
          <Typography data-qa="salaryEstimating-Tool-Card-title" variant="body1bold">
            Multiple household income?
          </Typography>
          <Typography sx={{ pb: "1rem" }} data-qa="salaryEstimating-Tool-Card-description">
            We'll help you calculate your annual household income.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "colors.lightBlueShade",
              color: "colors.darkBody",
              "&:hover": {
                backgroundColor: "colors.mediumTealShade",
              },
            }}
            data-qa="salaryEstimating-Tool-Card-action"
            onClick={handleSalaryEstimatingToolButton}
          >
            <TableViewOutlined sx={{ pr: ".5rem" }} />
            <Typography variant="body1">Learn more</Typography>
          </Button>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
)

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const MaskHouseholdIncome = forwardRef<HTMLElement, CustomProps>(({ onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    mask={Number}
    inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
    onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
    overwrite
    thousandsSeparator=","
    normalizeZeros
    min={0}
    scale={0}
  />
))

export const useGetPlansForMetadata = () => {
  const benefitsElectionState = useBenefitsElectionStore(state => state)
  const filterPreferences = createAllPlansFilterPreferences(benefitsElectionState)
  const query = useGetPlans(1, 0, { ...filterPreferences, householdIncome: benefitsElectionState.householdIncome })
  const { data: plans, isLoading: isLoadingPlans, error: plansError } = query

  return { plans, isLoadingPlans, plansError, query }
}

const mapResponseToState = (affordabilityResponse: AffordabilityCheckResponse | undefined) => ({
  showPrePlans: Boolean(affordabilityResponse && affordabilityResponse.isAffordable),
  showTaxCredits: Boolean(affordabilityResponse && !affordabilityResponse.isAffordable),
})

export const HouseholdIncome = () => {
  const navigate = useNavigate()
  const shoppingSessionId = useShoppingSession()
  const shoppingUrl = useShoppingUrl()
  const { notify } = useNotifications("household-income")
  const benefitsElectionState = useBenefitsElectionStore(state => state)
  const { plans, isLoadingPlans, plansError } = useGetPlansForMetadata()

  const {
    currentStep,
    setCurrentStep,
    householdIncome: storedHouseholdIncome,
    setHouseholdIncome,
    allowance,
    employee,
    currentShoppingSession,
  } = benefitsElectionState

  const [showPrePlansModal, setShowPrePlansModal] = useState(false)
  const [showTaxCreditsDrawer, setShowTaxCreditsDrawer] = useState(false)
  const [affordabilityCheckPayload, setAffordabilityCheckPayload] = useState<AffordabilityCheckPayload>()
  const { data: affordabilityResponse, isLoading: isLoadingAffordability } =
    useCheckAffordability(affordabilityCheckPayload)
  const { mutateAsync: waiveEmployee, isPending: isWaivingCoverage } = useAddWaiveCoverage(
    shoppingSessionId,
    currentShoppingSession.planYear
  )
  const { mutateAsync: manageShoppingPersons, isPending } = useManageShoppingPersons(shoppingSessionId)

  const setNextStep = () => {
    if (currentStep === HOUSEHOLD_INCOME) {
      setCurrentStep(RECOMMENDED_PLANS)
    }
  }

  const handleContinueShopping = () => {
    setNextStep()
    navigate(shoppingUrl + RECOMMENDED_PLANS)
  }

  const handleConfirmationModalWaive = () => {
    navigate(shoppingUrl + WAIVE_COVERAGE)
  }

  const getExchangeLink = (state: UsaState | "" | undefined) => {
    if (state && StatesToExchangeLink[state]) {
      return StatesToExchangeLink[state]
    }
    return EXTERNAL_LINKS.HEALTH_SHERPA
  }

  const handleOptOutEmployee = async () => {
    try {
      await waiveEmployee({ reason: USING_TAX_CREDITS, otherReason: "" })
      const exchangeLink = getExchangeLink(employee.personalInformation?.zipCode?.state)
      window.location.replace(exchangeLink)
    } catch {
      notify(
        "Error accepting tax credits. Please try again later. If the issue persists, contact support for assistance.",
        "error"
      )
    }
  }

  const handleContinue = ({ householdIncome }: typeof HOUSEHOLD_INCOME_INITIAL_VALUES) => {
    const numberHouseholdIncome = Number(cleanUpNumber(householdIncome))

    if (numberHouseholdIncome !== storedHouseholdIncome) {
      const annualIncomeCents = numberHouseholdIncome * 100
      const patchIncomePayload = createPatchIncomePayload(employee.shoppingPersonId!, annualIncomeCents)

      manageShoppingPersons([patchIncomePayload])
    }

    setHouseholdIncome(numberHouseholdIncome)
    const createdAffordabilityCheckPayload = createAffordabilityCheckPayload(benefitsElectionState)
    createdAffordabilityCheckPayload.householdIncome = numberHouseholdIncome
    if (isEqual(affordabilityCheckPayload, createdAffordabilityCheckPayload)) {
      const { showPrePlans, showTaxCredits } = mapResponseToState(affordabilityResponse)
      setShowPrePlansModal(showPrePlans)
      setShowTaxCreditsDrawer(showTaxCredits)
    } else {
      setAffordabilityCheckPayload(createdAffordabilityCheckPayload)
    }
  }

  useEffect(() => {
    const { showPrePlans, showTaxCredits } = mapResponseToState(affordabilityResponse)
    setShowPrePlansModal(showPrePlans)
    setShowTaxCreditsDrawer(showTaxCredits)
  }, [affordabilityResponse])

  useEffect(() => {
    if (plansError) {
      notify(
        "Error obtaining tax credits. Please try again later. If the issue persists, contact support for assistance.",
        "error"
      )
    }
  }, [plansError, notify])

  return (
    <>
      <ConfirmationModal
        isOpen={showPrePlansModal}
        onClose={() => setShowPrePlansModal(false)}
        onConfirm={handleContinueShopping}
        isError={false}
        onCancelOverride={handleConfirmationModalWaive}
        title="Let's shop for the best coverage"
        message="Deciding on a plan can be tough, but we're here to help you find the best plan for your needs!"
        isCancelable
        cancelLabel="Waive"
        actionLabel="Continue"
      />
      <TaxCreditsDrawer
        open={showTaxCreditsDrawer}
        handleClose={() => setShowTaxCreditsDrawer(false)}
        handleAcceptTaxCredits={() => handleOptOutEmployee()}
        handleTakeHraOffer={() => {
          setShowTaxCreditsDrawer(false)
          handleContinueShopping()
        }}
        allowance={allowance?.amountCents ?? 99}
        taxCredits={plans?.meta.premiumTaxCreditCents!}
        isPending={isWaivingCoverage}
      />

      <Formik
        initialValues={
          storedHouseholdIncome
            ? { householdIncome: storedHouseholdIncome.toString() }
            : HOUSEHOLD_INCOME_INITIAL_VALUES
        }
        enableReinitialize
        validationSchema={householdIncomeValidationSchema}
        onSubmit={async values => {}}
      >
        {({ errors, handleChange, handleBlur, touched, values, isValid }) => (
          <BenefitsElectionStep
            title="Household income"
            description="Understanding total annual household income helps us see if you may be eligible for tax credits, maximizing your potential savings. Please enter combined income for all family members."
            previous={`/benefits-election/${shoppingSessionId}/my-info/family`}
            type="submit"
            handleContinue={() => {
              handleContinue(values)
            }}
            disabled={!(isValid && values.householdIncome)}
            required
            isSubmitting={isLoadingAffordability || isLoadingPlans || isPending}
          >
            <form noValidate data-qa="household-income-form">
              <Grid container sx={{ marginBottom: 8 }}>
                <Grid item xs={12} md={8} mt={5} mb={4}>
                  <Typography variant="h5" gutterBottom>
                    Add your total household income
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <ClampedTextField
                    data-qa="household-income"
                    name="householdIncome"
                    label="Total Annual Household Income"
                    variant="outlined"
                    value={values.householdIncome}
                    error={Boolean(touched.householdIncome && errors.householdIncome)}
                    helperText={touched.householdIncome && errors.householdIncome}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      startAdornment: householdIncomeCurrency(),
                      inputComponent: MaskHouseholdIncome as never,
                    }}
                    fullWidth
                    sx={{ my: 2 }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="caption">
                    Please note: Accurate income information is crucial to ensure proper tax credit eligibility, correct
                    coverage quotes, and avoiding potential payment issues or tax penalties. If you're unsure, click{" "}
                    <Link href={EXTERNAL_LINKS.HOUSEHOLD_INCOME_INFO}>here</Link>.
                  </Typography>
                </Grid>
                <SalaryEstimatingToolCardWithAvatar />
              </Grid>
            </form>
          </BenefitsElectionStep>
        )}
      </Formik>
    </>
  )
}
