import { isString } from "lodash"
import { useEffect, useState } from "react"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"
import { HraPlanModel } from "../PlanSetup/planSetupTypes"
import { PLAN_STRUCTURE_INITIAL_VALUES } from "./PlanStructure"
import {
  ClassCardKey,
  ClassesStore,
  CustomClassData,
  CustomClassDetails,
  HraClassModel,
  PlanStructureFormValues,
} from "./planStructureTypes"
import { convertDataToFormValues, createClass } from "./planStructureUtils"

const createClassesStore = create<ClassesStore>()(
  persist(
    (set, get) => ({
      classData: {},
      getClassData: key => {
        const data = get().classData[key]

        if (!data) {
          const newState = { classData: { ...get().classData, [key]: {} } }

          set(() => newState)

          return {}
        }

        return data
      },
      setClassData: (key, classes) => set(state => ({ classData: { ...state.classData, [key]: classes } })),
      clearClassData: key => set(state => ({ classData: { ...state.classData, [key]: {} } })),
    }),
    {
      name: "custom-classes-store",
      storage: createJSONStorage(() => localStorage),
      version: 0, // Storage version last set 08/23/2023
    }
  )
)

const useClassesStore = ({ classesStoreKey }: { classesStoreKey: string }) => {
  const classesStore = createClassesStore()

  const [customClassData, setCustomClassData, clearCustomClassData] = [
    classesStore.getClassData(classesStoreKey),
    (classes: CustomClassData) => classesStore.setClassData(classesStoreKey, classes),
    () => classesStore.clearClassData(classesStoreKey),
  ]

  const addCustomClassToStore = (customClass: CustomClassDetails) => {
    const classKey =
      customClass.classId && customClass.classId.trim() !== ""
        ? customClass.classId
        : "localid-" + window.crypto.randomUUID()
    const newClass = { ...customClass }

    newClass.classId = classKey
    newClass.ageCurveId = customClass.ageCurveId
    setCustomClassData({ ...customClassData, [classKey]: newClass })
  }

  const deleteCustomClassesFromStore = (classKeys: ClassCardKey[]): Readonly<CustomClassData> => {
    const newClasses = { ...customClassData }
    const deletedClasses = (newClasses.deleted as string[]) ?? []

    classKeys.forEach(element => {
      if (isString(element) && !element.startsWith("localid-")) {
        deletedClasses.push(element)
      }
      delete newClasses[element]
    })
    newClasses.deleted = deletedClasses
    setCustomClassData(newClasses)

    return newClasses
  }

  const addCustomClassesToStore = (customClassList: CustomClassDetails[]) => {
    const newClasses = { ...customClassData }
    const deletedClasses = (newClasses.deleted as string[]) ?? []

    customClassList.forEach(customClass => {
      const classKey = customClass.classId!

      if (!deletedClasses.includes(classKey) && !(classKey in newClasses) && customClass.customClassName) {
        newClasses[classKey] = customClass
      }
    })
    setCustomClassData(newClasses)
  }

  const { deleted: deletedClasses, ...rest } = customClassData

  return {
    addCustomClassesToStore,
    addCustomClassToStore,
    deleteCustomClassesFromStore,
    clearCustomClassesFromStore: clearCustomClassData,
    customClassData: rest,
    deletedClasses,
  }
}

export const useFormValuesWithCustomClasses = ({
  data,
  hraPlan,
  classesStoreKey,
}: {
  data: HraClassModel[] | undefined
  hraPlan: HraPlanModel
  classesStoreKey: string
}) => {
  const [initialFormValues, setInitialFormValues] = useState<PlanStructureFormValues>(PLAN_STRUCTURE_INITIAL_VALUES)
  const [loadedClassesType, setLoadedClassesType] = useState<"CUSTOM" | "FIXED" | null>(null)

  const {
    addCustomClassesToStore,
    addCustomClassToStore,
    deleteCustomClassesFromStore,
    clearCustomClassesFromStore,
    customClassData,
    deletedClasses,
  } = useClassesStore({ classesStoreKey })

  useEffect(
    () => {
      // Check if data exists and has at least one element
      if (data && data.length > 0) {
        const hraClasses = data.map(createClass)
        const firstClass = hraClasses[0]

        // Check if the first class has a custom class name
        if (!firstClass.customClassName) {
          // If no custom class, convert and set initial form values
          const formValues = convertDataToFormValues(firstClass)

          setInitialFormValues(formValues)
          setLoadedClassesType("FIXED")
        } else {
          // Load retrieved custom classes
          addCustomClassesToStore(hraClasses)
          setLoadedClassesType("CUSTOM")
          // Set reimbursement structure to open Custom Classes Panel
          setInitialFormValues(previousFormValues => ({
            ...previousFormValues,
            needMoreThanOneClass: true,
            reimbursementStructure: "CUSTOM",
          }))
        }
      }
    },
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  useEffect(() => {
    if (hraPlan) {
      const { partTimeHourQualification = "", seasonalMonthQualification = "" } = hraPlan

      //Set form part time and seasonal customizations values
      if (partTimeHourQualification || seasonalMonthQualification) {
        setInitialFormValues(previousFormValues => ({
          ...previousFormValues,
          hoursPerWeekAmountLetMeCreate: partTimeHourQualification.toString(),
          monthsPerYearSelectionLetMeCreate: seasonalMonthQualification.toString(),
        }))
      }
    }
  }, [hraPlan])

  return {
    initialFormValues,
    customClassData,
    addCustomClassToStore,
    deleteCustomClassesFromStore,
    clearCustomClassesFromStore,
    loadedClassesType,
    deletedClasses,
  }
}
