import { appConfig } from "@/config"
import { WrapperProps } from "@/utils/types"
import { Box, CircularProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { ReturnToWebsiteButton } from "../ErrorBoundary/ErrorPage"

const LoadingContainer = ({ children }: WrapperProps) => (
  <Box
    sx={{
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      overflow: "hidden",
    }}
  >
    {children}
  </Box>
)

const MAX_PROGRESS = 110

export const LoadingPage = () => {
  // Randomly increase the progress bar to simulate loading
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const intervalRef = setInterval(() => {
      setProgress(current => current + 0.127 * appConfig.maxLoadingTimeSeconds)
    }, appConfig.maxLoadingTimeMilliseconds / 26)

    return () => {
      if (intervalRef) {
        clearInterval(intervalRef)
      }
    }
  }, [])

  const displayProgess = Math.floor(Math.min(progress, 100))

  if (progress > MAX_PROGRESS) {
    return (
      <LoadingContainer>
        <Typography variant="h4">Loading is taking longer than expected. Please refresh the page.</Typography>
        <ReturnToWebsiteButton />
      </LoadingContainer>
    )
  }

  return (
    <LoadingContainer>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          mb: 4,
          p: 8,
        }}
      >
        <CircularProgress variant="determinate" value={displayProgess} size={75} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5" color="text.secondary">
            {displayProgess}%
          </Typography>
        </Box>
      </Box>
      <Typography variant="h5">Loading... Please wait</Typography>
    </LoadingContainer>
  )
}
