import { DatePickerField } from "@/components/DatePickerField"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { StateSelectField } from "@/components/StateSelectField"
import {
  CityTextField,
  FirstNameTextField,
  LastNameTextField,
  PhoneNumberTextField,
  PreferredNameTextField,
  Street1TextField,
  Street2TextField,
} from "@/components/TextFields"
import { ZipCodeCountyAutoComplete } from "@/components/ZipCodeCountyAutoComplete"
import { ADULT_MINIMUM_AGE, GENDER_OPTIONS, GENDER_VALUES, YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { ICHRA_EMPLOYEE_NOTICE } from "@/features/Documents/documentsConstants"
import { useEmployeeDocDownloadUrl, useEmployeeDocuments } from "@/features/Documents/documentsService"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { getOnlyDate } from "@/utils/dates"
import { transformDate } from "@/utils/formatting"
import { requiredMessage, validateMailingAddress, validateSelectedZipCode } from "@/utils/validations"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined"
import { Checkbox, FormControlLabel, Grid, Link, Stack, Typography } from "@mui/material"
import { Formik, useField, useFormikContext } from "formik"
import { ReactNode } from "react"
import { Helmet } from "react-helmet-async"
import * as Yup from "yup"
import {
  BENEFITS_ELECTION_PATHS,
  DOCTORS,
  FAMILY,
  FAMILY_RELATIONSHIPS,
  PERSONAL_INFO,
  RECURRING_REIMBURSEMENT,
  SELF,
  WAIVE_COVERAGE,
} from "../../benefitsElectionConstants"
import {
  useManageShoppingPersons,
  useShoppingSession,
  useShoppingUrl,
  useUpdateHealthBenefitsElection,
} from "../../benefitsElectionService"
import { useBenefitsElectionStore, useUpdateSteps } from "../../benefitsElectionStore"
import {
  HealthBenefitElectionsMailingAddress,
  PersonalInformation,
  ShoppingUrl,
  ZipCode,
} from "../../benefitsElectionTypes"
import {
  createUpdatedShoppingPersonPayload,
  formatStoredPersonalInformation,
  hasApplicantDataChanged,
  isDateOfBirthValid,
} from "../../benefitsElectionUtils"
import { MedicaidAlert, MedicareAlert } from "../../components/BenefitsElectionAlerts"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { useResetPlanSelection } from "../../hooks/useResetPlanSelection"
import { useValidateMedicareQualification } from "../../hooks/useValidateMedicareQualification"

const PERSONAL_INFO_INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  preferredName: "",
  isTobaccoUser: "",
  isEnrolledInMedicaid: "",
  acknowledgeTerms: false,
  gender: "",
  isMedicareEligible: false,
  relationship: SELF,
  phoneNumber: "",
  zipCode: null,
} as const

const minimumAdultDateOfBirth = new Date()

minimumAdultDateOfBirth.setFullYear(minimumAdultDateOfBirth.getFullYear() - ADULT_MINIMUM_AGE)

export const commonPersonalInformationValidationSchema = Yup.object()
  .shape({
    relationship: Yup.string().label("Relationship").oneOf(FAMILY_RELATIONSHIPS).defined().required(requiredMessage),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    dateOfBirth: Yup.date()
      .label("Date of birth")
      .transform((dateValue, originalValue) => {
        if (
          originalValue === null ||
          originalValue === "" ||
          !isDateOfBirthValid(originalValue) ||
          !isDateOfBirthValid(dateValue)
        ) {
          return null
        }

        return dateValue
      })
      .nullable()
      .required(requiredMessage)
      .max(new Date(), "Date of birth cannot be in the future"),
    gender: Yup.string()
      .label("Gender")
      .oneOf(GENDER_VALUES, "Please select a valid option.")
      .defined()
      .required(requiredMessage),
    zipCode: validateSelectedZipCode,
    isTobaccoUser: Yup.boolean().required(),
    isEnrolledInMedicaid: Yup.boolean().required(),
  })
  .test("dateOfBirth", "", obj => {
    if (obj.relationship !== SELF || (obj.relationship === SELF && obj.dateOfBirth <= minimumAdultDateOfBirth)) {
      return true
    }

    return new Yup.ValidationError("Employee must be at least 18 years old", null, "dateOfBirth")
  })

interface EmployeeMedicareAndMedicaidAlertsProps {
  shoppingUrl: ShoppingUrl
  isEmployeeMedicareEligible: boolean
  isEmployeeEnrolledInMedicaid: boolean
}

export const EmployeeMedicareAndMedicaidAlerts = ({
  shoppingUrl,
  isEmployeeMedicareEligible,
  isEmployeeEnrolledInMedicaid,
}: EmployeeMedicareAndMedicaidAlertsProps) => {
  const currentStep = useBenefitsElectionStore(state => state.currentStep)

  if (isEmployeeEnrolledInMedicaid) {
    return <MedicaidAlert shoppingUrl={shoppingUrl} />
  }

  if (isEmployeeMedicareEligible) {
    // FUTURE: Please fix this awful hack
    let isAvailable = false

    for (const step of BENEFITS_ELECTION_PATHS) {
      if (step === RECURRING_REIMBURSEMENT) {
        isAvailable = true
      }
      if (step === currentStep) break
    }

    return <MedicareAlert shoppingUrl={shoppingUrl} isAvailable={isAvailable} />
  }

  return null
}

const getNextStep = (isMedicare: boolean, isMedicaid: boolean) => {
  if (isMedicaid) return WAIVE_COVERAGE

  if (isMedicare) return FAMILY

  return DOCTORS
}
// Regular expression to match "PO Box", "P.O. Box", "P O Box", "Post Office Box", etc.
// FUTURE: replace the poBox regex validation with an endpoint call
const poBoxRegex = /\b(?:P\.?O\.?|Post(?:\s+Office)?)\s?Box\b/i
const poBoxErrorMessage = "Address invalid. Enter a valid address, PO Box is not allowed."

const validationSchema = Yup.object().shape({
  personalInformation: commonPersonalInformationValidationSchema.shape({
    preferredName: Yup.string(),
    phoneNumber: Yup.string().length(14, "Phone number must be 10 digits").required("Phone number is required"),
    acknowledgeTerms: Yup.boolean()
      .optional()
      .when("isEnrolledInMedicaid", {
        is: true,
        then: schema => schema,
        otherwise: schema => schema.isTrue().required(),
      }),
  }),

  // Omit zipCode because it is covered under personalInfo
  address: validateMailingAddress
    .omit(["zipCode"])
    .test("address.street1", "Does not contain PO box address", obj =>
      obj.street1 && (poBoxRegex.test(obj.street1) || obj.street1.toLowerCase().includes("locker"))
        ? new Yup.ValidationError(poBoxErrorMessage, obj.street1, `address.street1`)
        : true
    )
    .test("address.street2", "Does not contain PO box address", obj =>
      obj.street2 && (poBoxRegex.test(obj.street2) || obj.street2.toLowerCase().includes("locker"))
        ? new Yup.ValidationError(poBoxErrorMessage, null, "address.street2")
        : true
    ),
})

type Schema = Yup.InferType<typeof validationSchema>

const AddressInfo = () => {
  const { values, touched, errors, handleChange, handleBlur, setFieldValue } = useFormikContext<Schema>()

  const baseDataQa = createDataQa("mailing-address")
  const [street1Field, street1Meta] = useField<Schema["address"]["street1"]>("address.street1")

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" data-qa={`${baseDataQa}-information-label`}>
          Home Address
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Street1TextField
          {...street1Field}
          error={!!street1Meta.touched && !!street1Meta.error}
          helperText={street1Meta.touched && street1Meta.error}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Street2TextField
          name="address.street2"
          value={values.address?.street2 ?? ""}
          error={!!touched.address?.street2 && !!errors.address?.street2}
          helperText={touched.address?.street2 && errors.address?.street2}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CityTextField
          name="address.city"
          value={values.address?.city ?? ""}
          error={!!touched.address?.city && !!errors.address?.city}
          helperText={touched.address?.city && errors.address?.city}
          sx={{ my: 0 }}
        />
      </Grid>
      <Grid item xs={12} md={3.5}>
        <StateSelectField
          name="address.state"
          value={values.address.state}
          dataQa={`${baseDataQa}-state-select`}
          sx={{ mt: 0 }}
        />
      </Grid>
      <Grid item xs={12} md={4.5}>
        <ZipCodeCountyAutoComplete
          name="personalInformation.zipCode"
          touched={!!touched.personalInformation?.zipCode}
          error={!!errors.personalInformation?.zipCode}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          value={(values.personalInformation?.zipCode ?? null) as ZipCode}
          handleBlur={handleBlur}
        />
      </Grid>
    </>
  )
}

const convertFormValuesToMailingAddress = ({
  address: { street1, street2, city, state },
  personalInformation: {
    zipCode: { zipCode, countyName: county, fipsCode },
  },
}: Schema) => ({
  street1,
  street2,
  city,
  state,
  zipCode,
  county,
  fipsCode,
})

export const PersonalInfo = () => {
  const { user } = useAuth()
  // FUTURE: Remove these unsafe non-null assertions
  const companyId = user?.company?.companyId!
  const employeeId = user?.company?.employeeId!
  const { notify } = useNotifications("personal-information")

  const shoppingSessionId = useShoppingSession()
  const shoppingUrl = useShoppingUrl()
  const updateSteps = useUpdateSteps()
  const { unselectPlan, unselectComparePlans } = useResetPlanSelection()

  const { data: documents } = useEmployeeDocuments(companyId, employeeId, true)
  const { mutateAsync: manageShoppingPersons } = useManageShoppingPersons(shoppingSessionId)

  const personalInformation = useBenefitsElectionStore(state => state.employee.personalInformation)
  const setPersonalInformation = useBenefitsElectionStore(state => state.setEmployeePersonalInformation)
  const employee = useBenefitsElectionStore(state => state.employee)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)
  const setHealthBenefitElection = useBenefitsElectionStore(state => state.setHealthBenefitElection)
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)

  const primaryElection = currentShoppingSession.healthBenefitElections.find(x => x.isPrimary)
  const { street1 = "", street2 = "", state = "" as never, city } = primaryElection?.mailingAddress ?? {}

  const { mutateAsync: updateHealthBenefitsElection } = useUpdateHealthBenefitsElection(currentShoppingSession.id)

  const ichraEmployeeNoticeDocs = documents?.filter(doc => doc.documentType === ICHRA_EMPLOYEE_NOTICE)

  const currentEmployeeNotice = ichraEmployeeNoticeDocs
    ?.sort(
      (a, b) =>
        (new Date(b.createdAt).getTime()) -
        (new Date(a.createdAt).getTime())
    )?.[0]

  const { data: employeeNoticeUrl } = useEmployeeDocDownloadUrl(
    companyId,
    employeeId,
    currentEmployeeNotice?.documentId,
    true
  )

  const { isMedicareEligible, handleBirthDateChange, handleBirthDateTextChange } =
    useValidateMedicareQualification(employee)

  const initialValues = {
    personalInformation: personalInformation
      ? {
          ...PERSONAL_INFO_INITIAL_VALUES,
          ...formatStoredPersonalInformation(personalInformation),
          acknowledgeTerms: false,
          // SAFETY: The validation schema enforces this type
        }
      : PERSONAL_INFO_INITIAL_VALUES,
    address: {
      street1,
      street2,
      state,
      city,
    },
  } as Schema

  updateSteps(isMedicareEligible, !!personalInformation?.isEnrolledInMedicaid)

  const resetPlanSelection = async (valuesToStore: PersonalInformation) => {
    if (
      personalInformation &&
      hasApplicantDataChanged(valuesToStore, personalInformation, false) &&
      currentStep !== PERSONAL_INFO
    ) {
      unselectComparePlans()
      if (selectedPlan) {
        await unselectPlan()
      }

      const isPersonEnrolledInMedicaid = !!personalInformation.isEnrolledInMedicaid
      const nextStep = isPersonEnrolledInMedicaid
        ? PERSONAL_INFO
        : getNextStep(isMedicareEligible, isPersonEnrolledInMedicaid)
      setCurrentStep(nextStep)
    }
  }

  return (
    <>
      <Helmet title="Personal Information" />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async values => {}}
        validateOnMount
        validateOnChange
      >
        {({ values, touched, errors, isValid, handleChange, handleBlur, setFieldTouched, setFieldValue }) => {
          const {
            firstName,
            lastName,
            dateOfBirth,
            preferredName,
            isTobaccoUser,
            isEnrolledInMedicaid,
            acknowledgeTerms,
            gender,
            relationship,
            zipCode,
            phoneNumber,
          } = values.personalInformation

          updateSteps(isMedicareEligible, isEnrolledInMedicaid)

          return (
            <BenefitsElectionStep
              title="Personal information"
              description="This information allows us to show you accurate, personalized plan options"
              disabled={!isValid}
              next={shoppingUrl + getNextStep(isMedicareEligible, isEnrolledInMedicaid)}
              advanceOnSuccess
              data-qa={createDataQa("personal-info")}
              required
              continueLabel={isEnrolledInMedicaid ? "Waive" : undefined}
              handleContinue={async () => {
                try {
                  const valuesToStore: PersonalInformation = {
                    firstName,
                    lastName,
                    preferredName,
                    isTobaccoUser,
                    isEnrolledInMedicaid,
                    acknowledgeTerms,
                    gender,
                    isMedicareEligible,
                    zipCode,
                    phoneNumber: phoneNumber?.replace(/\D/g, ""),
                    dateOfBirth: transformDate(dateOfBirth),
                    relationship,
                  }

                  const patchEmployeePayload = createUpdatedShoppingPersonPayload({
                    ...employee,
                    personalInformation: valuesToStore,
                  })

                  const manageResult = await manageShoppingPersons([patchEmployeePayload])

                  if (manageResult.meta) {
                    resetPlanSelection(valuesToStore)
                  }
                  setPersonalInformation(valuesToStore)
                } catch (err) {
                  notify(
                    "Error saving personal information. Please try again later. If the issue persists, contact support for assistance.",
                    "error"
                  )
                  console.error(err)
                }

                const mailingAddress = convertFormValuesToMailingAddress(values) as HealthBenefitElectionsMailingAddress

                try {
                  if (!primaryElection) throw new Error("Missing primary election")

                  const updatedElection = await updateHealthBenefitsElection({
                    id: primaryElection.id,
                    employmentId: primaryElection.employmentId,
                    mailingAddress,
                  })
                  setHealthBenefitElection(updatedElection)
                  if (isEnrolledInMedicaid) {
                    setCurrentStep(PERSONAL_INFO)
                  } else if (currentStep === PERSONAL_INFO || currentStep === DOCTORS) {
                    setCurrentStep(getNextStep(isMedicareEligible, isEnrolledInMedicaid))
                  }
                  notify("Succesfully saved personal information", "success")
                } catch (err) {
                  notify(
                    "Error saving mailing address, please try again later. If the issue persists, contact support for assistance.",
                    "error"
                  )
                  console.error(err)
                }
              }}
            >
              <form data-qa="personal-info-form">
                <Grid container spacing={4} rowSpacing={6}>
                  <EmployeeMedicareAndMedicaidAlerts
                    shoppingUrl={shoppingUrl}
                    isEmployeeMedicareEligible={isMedicareEligible}
                    isEmployeeEnrolledInMedicaid={isEnrolledInMedicaid}
                  />
                  <Grid item xs={12} md={4}>
                    <FirstNameTextField
                      name="personalInformation.firstName"
                      value={values.personalInformation.firstName}
                      error={!!touched.personalInformation?.firstName && !!errors.personalInformation?.firstName}
                      helperText={touched.personalInformation?.firstName && errors.personalInformation?.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LastNameTextField
                      name="personalInformation.lastName"
                      value={values.personalInformation.lastName}
                      error={!!touched.personalInformation?.lastName && !!errors.personalInformation?.lastName}
                      helperText={touched.personalInformation?.lastName && errors.personalInformation?.lastName}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <PreferredNameTextField
                      name="personalInformation.preferredName"
                      value={values.personalInformation.preferredName}
                      error={
                        !!touched.personalInformation?.preferredName && !!errors.personalInformation?.preferredName
                      }
                      helperText={
                        touched.personalInformation?.preferredName && errors.personalInformation?.preferredName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SelectField
                      dataQa="gender-select"
                      data={GENDER_OPTIONS}
                      type="text"
                      label="Gender"
                      required
                      name="personalInformation.gender"
                      value={values.personalInformation.gender}
                      placeholder="Select one"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography variant="caption">
                      * Required for the insurance company. These are the only options they offer.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DatePickerField
                      data-qa="personal-info-birth-date"
                      dataQa="personal-info-birth-date"
                      name="personalInformation.dateOfBirth"
                      label="Date of birth"
                      required
                      fullWidth
                      variant="outlined"
                      type="date"
                      maxDate={new Date()}
                      value={getOnlyDate(values.personalInformation.dateOfBirth ?? null)}
                      error={!!touched.personalInformation?.dateOfBirth && !!errors.personalInformation?.dateOfBirth}
                      helperText={
                        (touched.personalInformation?.dateOfBirth &&
                          errors.personalInformation?.dateOfBirth) as ReactNode
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onDateAccept={date => {
                        handleBirthDateChange(date)
                        setFieldTouched("dateOfBirth")
                        updateSteps(isMedicareEligible, isEnrolledInMedicaid)
                      }}
                      onInputChange={handleBirthDateTextChange}
                    />
                    {isMedicareEligible && (
                      <Stack direction="row" alignItems="center" gap={1} sx={{ ml: 2, mt: 1 }}>
                        <WarningAmberOutlinedIcon
                          sx={{ height: "1.25rem", width: "1.25rem", color: "colors.lightWarning" }}
                        />
                        <Typography variant="body2">You are over the age of 65</Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <PhoneNumberTextField
                      name="personalInformation.phoneNumber"
                      value={values.personalInformation.phoneNumber}
                      error={!!touched.personalInformation?.phoneNumber && !!errors.personalInformation?.phoneNumber}
                      helperText={touched.personalInformation?.phoneNumber && errors.personalInformation?.phoneNumber}
                    />
                  </Grid>
                  <AddressInfo />
                  <Grid item xs={12}>
                    <RadioGroupCard
                      name="personalInformation.isTobaccoUser"
                      formName="personal-info-form"
                      elements={YES_NO_OPTIONS_BOOLEAN}
                      value={values.personalInformation.isTobaccoUser}
                      handleChange={setFieldValue}
                      required
                      label="Do you use tobacco?"
                      labelVariant="h5"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroupCard
                      name="personalInformation.isEnrolledInMedicaid"
                      formName="personal-info-form"
                      elements={YES_NO_OPTIONS_BOOLEAN}
                      value={values.personalInformation.isEnrolledInMedicaid ?? ""}
                      handleChange={(field, medicaidValue) => {
                        if (medicaidValue) {
                          setFieldValue("acknowledgeTerms", false)
                        }

                        updateSteps(isMedicareEligible, medicaidValue)

                        return setFieldValue(field, medicaidValue)
                      }}
                      required
                      label="Are you currently enrolled in Medicaid?"
                      labelVariant="h5"
                    />
                  </Grid>
                  {isEnrolledInMedicaid === false && (
                    <>
                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography variant="h5" gutterBottom data-qa="personal-info-documents-label">
                          Documents
                        </Typography>
                        <Typography variant="body1" component="span">
                          Review and acknowledge HRA{" "}
                        </Typography>
                        <Link href={employeeNoticeUrl} target="_blank" data-qa="personal-info-documents-link">
                          Employee Notice Agreement
                          <LaunchOutlinedIcon sx={{ width: "1rem", height: "1rem" }} />
                        </Link>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox required checked={!!values.personalInformation.acknowledgeTerms} />}
                          label="I acknowledge"
                          name="personalInformation.acknowledgeTerms"
                          sx={{ pl: 4, mt: -6 }}
                          value={values.personalInformation.acknowledgeTerms}
                          onChange={handleChange}
                          data-qa="personal-info-acknowledge-terms"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </BenefitsElectionStep>
          )
        }}
      </Formik>
    </>
  )
}
