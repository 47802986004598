import { useNotifications } from "@/services/notificationService"
import { colors } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { Uuid } from "@/utils/types"
import { useTheme } from "@emotion/react"
import { GetAppOutlined } from "@mui/icons-material"
import { Alert, Button, CircularProgress, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useDrawingArea } from "@mui/x-charts"
import { PieChart } from "@mui/x-charts/PieChart"
import { useGetEnrollmentReportCsv, useGetSessionStats } from "../../dashboardService"
import { WidgetLayout } from "../WidgetLayout"

interface PieCenterLabelProps {
  percentage: number
  label: string
}

const PieCenterLabel = ({ percentage, label }: PieCenterLabelProps) => {
  const theme = useTheme()
  const { width, height, left, top } = useDrawingArea()
  const centerX = left + width / 2
  const centerY = top + height / 2

  return (
    <>
      <text
        x={centerX}
        y={centerY - 10}
        textAnchor="middle"
        dominantBaseline="central"
        fill={theme.palette.text.primary}
      >
        {percentage}%
      </text>

      <text
        x={centerX}
        y={centerY + 10}
        textAnchor="middle"
        dominantBaseline="central"
        fill={theme.palette.text.primary}
      >
        {label}
      </text>
    </>
  )
}

interface DownloadButtonAdornmentProps {
  isLoading?: boolean
  isError?: boolean
}

const DownloadButtonAdornment = ({ isLoading, isError }: DownloadButtonAdornmentProps) => {
  if (isError) {
    return null
  }

  if (isLoading) {
    return <CircularProgress size={24} color="inherit" />
  }

  return <GetAppOutlined />
}

const getEnrollmentPlanYear = () => {
  const currentDate = new Date()
  const currentYear = new Date().getFullYear()
  return currentDate >= new Date(currentYear, 10, 1) ? currentYear + 1 : currentYear
}

interface EmployeeEnrollmentStatusWidgetProps {
  companyId: Uuid
}

export const EmployeeEnrollmentStatusWidget = ({ companyId }: EmployeeEnrollmentStatusWidgetProps) => {
  const { notify } = useNotifications("downloading-enrollment-report")
  const planYear = getEnrollmentPlanYear()

  const {
    isFetching: isEnrollmentReportCsvLoading,
    isError: isEnrollmentReportCsvError,
    refetch: refetchEnrollmentReportCsv,
  } = useGetEnrollmentReportCsv(companyId, planYear)

  const { data: sessionStats, isLoading, isError } = useGetSessionStats(planYear, companyId)

  const { countOfShopping = 0, countOfCompleted = 0, countOfWaived = 0, countOfNotStarted = 0 } = sessionStats ?? {}

  const totalCompleted = countOfCompleted + countOfWaived
  const totalNotStarted = countOfNotStarted
  const totalInProgress = countOfShopping

  const total = totalCompleted + totalNotStarted + totalInProgress
  const percentageCompleted = total === 0 ? 0 : Math.round((totalCompleted / total) * 100)
  const inProgressId = "inProgress"
  const completeId = "complete"
  const notStartedId = "notStarted"
  const hiddeAngle = percentageCompleted === 100 || percentageCompleted === 0

  const handleDownloadEnrollmentReportCsv = async () => {
    try {
      const { data } = await refetchEnrollmentReportCsv()

      if (data) {
        const blob = new Blob([data], { type: "text/csv" })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        const timestampString = new Date().toISOString().split("T")[0]
        a.download = `Employee Enrollment Report for ${timestampString}.csv`
        a.click()
        window.URL.revokeObjectURL(url)
      } else {
        notify("Failed to download the enrollment report. Please try again.", "error")
      }
    } catch (error) {
      console.error("Error fetching enrollment report CSV:", error)
      notify("An error occurred while downloading the report.", "error")
    }
  }

  const data = [
    { id: notStartedId, value: totalNotStarted, label: "Not Started", color: colors.bluePie },
    { id: inProgressId, value: totalInProgress, label: "In Progress", color: colors.orangePie },
    { id: completeId, value: totalCompleted, label: "Complete", color: colors.greenPie },
  ]

  return (
    <WidgetLayout isLoading={isLoading} data-qa={createDataQa("employee", "enrollment", "widget")}>
      {isError ? (
        <Box>
          <Typography variant="h2small" display="block" mb={3}>
            Employee enrollment status
          </Typography>
          <Alert severity="error">Error loading data, please refresh page.</Alert>
        </Box>
      ) : (
        <>
          <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center" gap={4}>
            <Typography display="block" variant="h2small" mb={{ xs: 4, md: 0 }} flexBasis="50%">
              {`Employee ${planYear} enrollment status`}
            </Typography>
            <Button
              variant="contained"
              endIcon={
                <DownloadButtonAdornment
                  isLoading={isEnrollmentReportCsvLoading}
                  isError={isEnrollmentReportCsvError}
                />
              }
              onClick={handleDownloadEnrollmentReportCsv}
              sx={{ flexBasis: "40%" }}
            >
              <Typography variant="body2">Download Report</Typography>
            </Button>
          </Box>
          <Box position="relative" display="flex" justifyContent="center" alignItems="center" mt={2}>
            <PieChart
              series={[
                {
                  data: data.filter(item => item.value > 0).map(item => ({ ...item })),
                  innerRadius: 80,
                  outerRadius: 100,
                  paddingAngle: hiddeAngle ? 0 : 1,
                },
              ]}
              width={400}
              height={300}
              slotProps={{
                legend: { hidden: true },
              }}
            >
              {total !== 0 && <PieCenterLabel percentage={percentageCompleted} label="Complete" />}
            </PieChart>
          </Box>
          <Box>
            {data.map((item, index) => (
              <Box
                key={item.label}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom={index < data.length - 1 ? `0.0625rem solid ${colors.lightGreyBorder}` : "none"}
                pb={1}
                mb={4}
              >
                <Box display="flex" alignItems="center">
                  <Box sx={{ width: 12, height: 12, backgroundColor: item.color, borderRadius: "50%", mr: 1 }} />
                  <Typography variant="body2">{item.label}</Typography>
                </Box>
                <Box display="flex" alignItems="center" flex="0 0 57%">
                  <Typography variant="body2">{item.value}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </WidgetLayout>
  )
}
