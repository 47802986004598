import { FilterDropdownField } from "@/components/FilterDropdownField"
import {
  getCarriersOptions,
  getEnrollmentStatusOptions,
  getYearOptions,
  useCarriers,
} from "@/features/TCHub/Enrollment/tcHubEnrollmentService"
import { STATE_OPTIONS } from "@/utils/States"

import { PLAN_MARKET_OPTIONS } from "@/features/BenefitsElection/benefitsElectionConstants"
import { useEnrollmentSearch } from "@/features/TCHub/Enrollment/EnrollmentSearch"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import { Box, Button, Drawer, Grid, Typography } from "@mui/material"

export const EnrollmentFilterDrawer = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const { filters, setFilter } = useEnrollmentSearch()
  const { data: carriers } = useCarriers()
  const carrierOptions = getCarriersOptions(carriers, filters?.state ?? "")

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
      <Grid
        sx={{
          backgroundColor: "colors.regionGray",
          height: "100%",
          px: 6,
          py: 4,
          width: 450,
        }}
      >
        <Button sx={{ padding: "0" }} variant="text" onClick={() => setOpen(false)}>
          <KeyboardArrowLeft />
          Back to Enrollments
        </Button>
        <Grid item container xs={12} justifyContent="space-between" alignItems="center" mt={6}>
          <Typography variant="h1" gutterBottom>
            Filters
          </Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setFilter("state", "")
              setFilter("carrierId", "")
              setFilter("enrollmentStatus", "")
              setFilter("planYear", "")
            }}
          >
            Clear all
          </Button>
        </Grid>
        <Box display="grid" gap={6} mt={6}>
          <FilterDropdownField
            options={STATE_OPTIONS}
            value={filters?.state ?? ""}
            onChange={state => setFilter("state", state)}
            label="Filter by State"
          />
          <FilterDropdownField
            options={carrierOptions}
            value={filters?.carrierId ?? ""}
            onChange={carrierId => setFilter("carrierId", carrierId)}
            label="Filter by Carrier"
          />
          <FilterDropdownField
            options={getEnrollmentStatusOptions()}
            value={filters?.enrollmentStatus ?? ""}
            onChange={status => setFilter("enrollmentStatus", status)}
            label="Filter by Status"
            clearable={false}
          />
          <FilterDropdownField
            options={getYearOptions()}
            value={filters?.planYear ?? ""}
            onChange={planYear => setFilter("planYear", planYear)}
            label="Filter by Year"
          />
          <FilterDropdownField
            options={PLAN_MARKET_OPTIONS}
            value={filters?.planMarket ?? ""}
            onChange={planMarket => setFilter("planMarket", planMarket)}
            label="Filter by Plan Market"
          />
        </Box>
      </Grid>
    </Drawer>
  )
}
