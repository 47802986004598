export const ACTIVE = "ACTIVE"
export const NOT_STARTED = "NOT_STARTED"
export const APPLICATION_PENDING = "APPLICATION_PENDING"
export const APPLICATION_DENIED = "APPLICATION_DENIED"
export const APPLICATION_AWAITING_DOCUMENTS = "APPLICATION_AWAITING_DOCUMENTS"
export const BANK_ACCOUNT_PENDING = "BANK_ACCOUNT_PENDING"
export const REAUTHENTICATION_NEEDED = "REAUTHENTICATION_NEEDED"
export const FUNDING_EVENT_STATUS_SCHEDULED = "SCHEDULED"
export const FROZEN = "FROZEN"
export const CLOSED = "CLOSED"

export const FUNDING_STATUSES = [
  ACTIVE,
  NOT_STARTED,
  APPLICATION_PENDING,
  APPLICATION_DENIED,
  APPLICATION_AWAITING_DOCUMENTS,
  BANK_ACCOUNT_PENDING,
  REAUTHENTICATION_NEEDED,
  FROZEN,
  CLOSED,
] as const

export const FUNDING_EVENT_STATUSES = [FUNDING_EVENT_STATUS_SCHEDULED] as const

export const REGISTRATION_STATUS_OPTIONS = [
  {
    value: "ACTIVE",
    label: "Active"
  },
  { 
    value: "NOT_STARTED", 
    label: "Not Started" 
  },
  { 
    value: "APPLICATION_PENDING", 
    label: "Application Pending" 
  },
  { 
    value: "APPLICATION_DENIED", 
    label: "Application Denied" 
  },
  { 
    value: "APPLICATION_AWAITING_DOCUMENTS", 
    label: "Application Awaiting Documents" 
  },
  { 
    value: "BANK_ACCOUNT_PENDING", 
    label: "Bank Account Pending" 
  },
  { 
    value: "REAUTHENTICATION_NEEDED", 
    label: "Reauthentication Needed"
  }
] as const

export const MICRO_DEPOSIT_STATUS_OPTIONS = [
  { value: "POSTED", label: "Posted" },
  { value: "PENDING", label: "Pending" },
  { value: "REVERSED", label: "Reversed" },
  { value: " ", label: "N/A" },
] as const
