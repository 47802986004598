import { TakeCommandLogoInline } from "@/components/Branding"
import { EXTERNAL_LINKS } from "@/constants"
import { useLoginRedirect } from "@/services/loginRedirectService"
import { useNotifications } from "@/services/notificationService"
import { colors } from "@/theme/palette"
import { verificationCodeValidationSchema } from "@/utils/validations"
import { Button, Chip, CircularProgress, Grid, Link, Typography } from "@mui/material"
import { Formik } from "formik"
import { get } from "lodash"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "../../useAuth"
import { mfaSmsCodeSend, mfaSmsVerificationSettle, useCognitoUser } from "./mfaService"
import { VerificationCodeField } from "./VerificationCodeField"

const initialValues = {
  verificationCode: "",
}

export const VerifySmsMfa = () => {
  const { signIn, signInWithMfa, refresh, user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { notify } = useNotifications("verify-mfa")
  const { email: stateEmail, phoneNumber: statePhoneNumber, password, rememberMe, isLoggedIn } = location.state ?? {}
  const email = stateEmail ?? user?.email ?? ""
  const { data: cognitoUser } = useCognitoUser(email, password, isLoggedIn)
  const { redirect } = useLoginRedirect()
  const [isResendingCode, setIsResendingCode] = useState(false)

  useEffect(() => {
    if (!email) {
      navigate("/")
    }
  }, [email, navigate])

  const handleAuthAppSelection = () => {
    navigate("/mfa/setup", {
      state: {
        ...(location.state ?? {}),
        email,
      },
    })
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ maxWidth: "36.667rem" }}>
      <TakeCommandLogoInline />
      <Chip
        label={email}
        color="default"
        sx={{
          mt: 8,
          border: "1pt solid black",
        }}
      />
      <Typography variant="h1" mt={3}>
        Enter the 6-digit code
      </Typography>
      <Typography variant="body1" mt={10}>
        Use the code from the SMS to access your HRA Hub account.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={verificationCodeValidationSchema}
        onSubmit={async (values, { setErrors, setStatus }) => {
          try {
            await (isLoggedIn
              ? mfaSmsVerificationSettle(cognitoUser!, values.verificationCode)
              : signInWithMfa({
                  email,
                  password,
                  verificationCode: values.verificationCode,
                  rememberMe,
                  mfaType: "SMS_MFA",
                }))
            await refresh()
            await redirect()
          } catch (error: any) {
            console.error(error)
            const message = error.message || "Something went wrong"

            setStatus({ success: false })
            setErrors({ verificationCode: message })
          }
        }}
      >
        {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
          <form style={{ width: "100%" }} onSubmit={handleSubmit} noValidate data-qa="sign-in-form">
            <VerificationCodeField
              sx={{
                mt: 8,
                width: "100%",
              }}
              required
              value={values.verificationCode}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(get(touched, `verificationCode`) && get(errors, `verificationCode`))}
              helperText={get(touched, `verificationCode`) && get(errors, `verificationCode`)}
              inputProps={{ "data-qa": "verification-code-input" }}
              data-qa="verification-code"
              name="verificationCode"
              label="Authentication Code"
            />
            <Button
              sx={{ mt: 6 }}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
              startIcon={isSubmitting && <CircularProgress size={20} />}
            >
              Authenticate
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 6, backgroundColor: colors.gumDropGreen, color: colors.darkBody }}
              fullWidth
              disabled={isResendingCode}
              startIcon={isResendingCode && <CircularProgress size={20} />}
              onClick={async () => {
                setIsResendingCode(true)
                // reauth to resend the code and update the user in challenge
                if (isLoggedIn) {
                  await mfaSmsCodeSend(cognitoUser!, statePhoneNumber)
                  setIsResendingCode(false)
                  return
                }
                signIn(email, password, rememberMe).catch(err => {
                  if (err.mfaRequired) {
                    notify("New code sent successfully", "success")
                    setIsResendingCode(false)
                    return
                  }
                  notify("Something went wrong", "error")
                  setIsResendingCode(false)
                })
              }}
            >
              Send the code again
            </Button>
          </form>
        )}
      </Formik>
      <Typography mt={3} variant="body1">
        <Button variant="text" onClick={handleAuthAppSelection}>
          Try with the app authentication
        </Button>
      </Typography>
      <Grid sx={{ mt: 6 }}>
        <Typography variant="caption">
          <Link href={EXTERNAL_LINKS.PRIVACY}>Privacy Policy</Link> |{" "}
          <Link href={EXTERNAL_LINKS.TERMS_OF_SERVICE}>Terms of Service</Link>
        </Typography>
      </Grid>
    </Grid>
  )
}
