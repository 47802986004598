import EditOutlined from "@mui/icons-material/EditOutlined"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Button, IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import { SyntheticEvent, useState } from "react"
import { CarrierIntegration } from "../tcHubTypes"
import { CarrierModal } from "./CarrierModal"

export interface CarrierStateItemDropdownMenuProps {
  itemId: string
  dataQa: string
  carrierIntegration: CarrierIntegration
  reloadCarriersInformation?: () => void
  isEditing?: boolean
}

export const CarrierStateItemDropdownMenu = ({
  itemId,
  dataQa,
  carrierIntegration,
  isEditing,
}: CarrierStateItemDropdownMenuProps) => {
  const [showItemActions, setShowItemActions] = useState<Element | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showMenu = (event: SyntheticEvent) => {
    setShowItemActions(event.currentTarget)
  }

  const closeMenu = () => {
    setShowItemActions(null)
  }

  const handleEdit = () => {
    setIsModalOpen(true)
    closeMenu()
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div data-qa={`carrier-state-more-actions-${itemId}`}>
      <Tooltip title="Actions">
        <IconButton
          sx={{ width: "2.5rem", height: "2.5rem", borderRadius: "50%" }}
          size="large"
          aria-haspopup="true"
          aria-owns="menu-carrier-state-item-options"
          data-qa={dataQa}
          onClick={showMenu}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        data-qa={`menu-${dataQa}-options`}
        id={`menu-${dataQa}-options`}
        anchorEl={showItemActions}
        open={Boolean(showItemActions)}
        onClose={closeMenu}
      >
        <MenuItem className="tch-menu-item" data-qa={`menu-item-${dataQa}`}>
          <Button startIcon={<EditOutlined />} onClick={handleEdit}>
            Edit
          </Button>
        </MenuItem>
      </Menu>
      {isModalOpen && (
        <CarrierModal
          isOpen={isModalOpen}
          onClose={closeModal}
          carrierIntegration={carrierIntegration}
          isEditing={isEditing}
        />
      )}
    </div>
  )
}
