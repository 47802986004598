import { RouteObject } from "react-router-dom"
import { AdpCreateCompany } from "./features/ADP/Companies/AdpCompanyCreate"

export const adpRoutes: RouteObject = {
  path: "/adp/",
  children: [
    {
      path: "create-company/:step?",
      element: <AdpCreateCompany />,
    },
  ],
}
