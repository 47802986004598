import { TakeCommandLogo } from "@/components/Branding"
import { EXTERNAL_LINKS } from "@/constants"
import { UserSignUpFormData, UserSignUpTokenData } from "@/features/Auth/authTypes"
import { useAuth } from "@/features/Auth/useAuth"
import { updatePersonTermsAcceptedDate } from "@/features/People/peopleManagementEndpoints"
import { useNotifications } from "@/services/notificationService"
import { signUpValidation } from "@/utils/validations"
import styled from "@emotion/styled"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { decodeIncomingParams } from "../authUtils"
import { AuthLayout, AuthWrapper } from "./AuthLayout"

const SignUpButton = styled(Button)`
  margin-top: 16px;
`

const TermsAndConditionsError = styled(FormHelperText)`
  color: ${props => props.theme.palette.error.main};
  padding-left: 16px;
`

const initialFormValues: UserSignUpFormData = {
  newPassword: "",
  confirmPassword: "",
  agreedToTermsOfService: false,
  submit: false,
}

const validateIncomingParams = (
  searchParams: URLSearchParams | undefined
): searchParams is URLSearchParams & UserSignUpTokenData => {
  const data = decodeIncomingParams<UserSignUpTokenData>(searchParams)

  if (!data) return false

  const { email, temporaryPassword, firstName, lastName, personId } = data

  return Boolean(email && temporaryPassword && firstName && lastName && personId)
}

const SignUpPanel = () => {
  const location = useLocation()
  const state = location.state ?? {}
  const navigate = useNavigate()
  const { notify } = useNotifications("sign-up")
  const { completeNewPasswordChallenge, refresh, isUserSignedUp } = useAuth()
  const [searchParams] = useSearchParams()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const [validatedData, setValidatedData] = useState<UserSignUpTokenData | null>()

  useEffect(() => {
    if (validateIncomingParams(searchParams)) {
      setValidatedData(decodeIncomingParams<UserSignUpTokenData>(searchParams))
    } else {
      navigate("/404")
    }
  }, [searchParams, navigate])

  useEffect(() => {
    const verifyUserSignUpStatus = async (email: string, password: string) => {
      try {
        const isSignedUpUser = await isUserSignedUp(email, password)
        if (isSignedUpUser) {
          notify("User has already signed up. Please login or reset your password.", "error")
          navigate("/sign-in")
        }
      } catch (error) {
        console.error("Error verifying sign-up link: ", error)
      }
    }
    if (validatedData?.email && validatedData?.temporaryPassword) {
      verifyUserSignUpStatus(validatedData.email, validatedData.temporaryPassword)
    }
  }, [validatedData, isUserSignedUp, notify, navigate])

  if (!validatedData) return null

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={signUpValidation()}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true)

        completeNewPasswordChallenge(
          validatedData.personId,
          validatedData.email,
          validatedData.temporaryPassword,
          values.newPassword,
          validatedData.firstName,
          validatedData.lastName
        )
          .then(async () => {
            await refresh()
            await updatePersonTermsAcceptedDate(validatedData.personId)
            navigate("/mfa/select", {
              state: {
                ...state,
                isLoggedIn: true,
              },
            })
            setSubmitting(false)
          })
          .catch((error: any) => {
            const message = error.message || error || "Something went wrong"

            setStatus({ success: false })
            setErrors({ submit: message })
            setSubmitting(false)
          })
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
        <form noValidate onSubmit={handleSubmit} data-qa="sign-up-form">
          {errors.submit && (
            <Alert sx={{ mt: 2, mb: 1 }} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            disabled
            fullWidth
            type="text"
            name="firstName"
            label="First name"
            value={validatedData.firstName}
            InputProps={{ readOnly: true }}
            sx={{ my: 3 }}
          />
          <TextField
            disabled
            fullWidth
            type="text"
            name="lastName"
            label="Last name"
            value={validatedData.lastName}
            InputProps={{ readOnly: true }}
            sx={{ my: 3 }}
          />
          <TextField
            disabled
            fullWidth
            type="email"
            name="email"
            label="Email address"
            value={validatedData.email}
            InputProps={{ readOnly: true }}
            sx={{ my: 3 }}
          />
          <TextField
            type={showPassword ? "text" : "password"}
            name="newPassword"
            label="Password"
            value={values.newPassword}
            error={Boolean(touched.newPassword && errors.newPassword)}
            fullWidth
            helperText={touched.newPassword && errors.newPassword}
            onBlur={handleBlur}
            onChange={e => {
              setFieldValue("newPassword", e.target.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(show => !show)}
                    onMouseDown={e => e.preventDefault()}
                    edge="end"
                    data-qa="visibility-icon-password"
                  >
                    {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ my: 3 }}
          />
          <TextField
            type={showPasswordConfirmation ? "text" : "password"}
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswordConfirmation(show => !show)}
                    onMouseDown={e => e.preventDefault()}
                    edge="end"
                    data-qa="visibility-icon-confirm-password"
                  >
                    {showPasswordConfirmation ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ my: 3 }}
          />
          <FormControlLabel
            control={<Checkbox value="remember" name="agreedToTermsOfService" color="primary" onClick={handleChange} />}
            label={
              <Typography variant="caption">
                I agree to the{" "}
                <Link color="primary" href={EXTERNAL_LINKS.TERMS_OF_SERVICE} target="_blank" rel="noopener">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link color="primary" href={EXTERNAL_LINKS.PRIVACY} target="_blank" rel="noopener">
                  Privacy Policy
                </Link>
              </Typography>
            }
          />
          {errors.agreedToTermsOfService && touched.agreedToTermsOfService && !values.agreedToTermsOfService && (
            <TermsAndConditionsError>{errors.agreedToTermsOfService}</TermsAndConditionsError>
          )}
          <SignUpButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            data-qa="sign-up-button"
          >
            Sign up
          </SignUpButton>
        </form>
      )}
    </Formik>
  )
}

export const SignUp = () => (
  <AuthLayout>
    <TakeCommandLogo />
    <AuthWrapper>
      <Helmet title="Sign Up" />
      <Typography data-qa="form-title" variant="h4" align="center" gutterBottom>
        Get started
      </Typography>
      <SignUpPanel />
    </AuthWrapper>
  </AuthLayout>
)
