import { Redirect } from "@/components/Navigation"
import { SUCCESS } from "@/features/EmployerOnboarding/employerOnboardingConstants"
import { useOnboardingStatuses } from "@/features/EmployerOnboarding/employerOnboardingService"
import { WrapperProps } from "@/utils/types"
import { useLocation } from "react-router-dom"

export const EmployerOnboardingGuard = ({ children }: WrapperProps) => {
  const { isOnboardingComplete } = useOnboardingStatuses()
  const location = useLocation()
  const locationIsSuccess = location.pathname.includes(`/employer-onboarding/${SUCCESS}`)

  if (isOnboardingComplete && !locationIsSuccess) {
    return <Redirect />
  }

  return children
}
