import helpIcon from "@/assets/svg/icon-megaphone.svg"
import { DatePickerField } from "@/components/DatePickerField"
import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { ClampedTextField } from "@/components/TextFields"
import { PackageCardInfo } from "@/features/CreateCompany/components/common/PackageCardInfo"
import { Stepper } from "@/features/CreateCompany/components/Stepper"
import {
  convertHraPlanToFormValues,
  useCreateHraPlan,
  useGetHraPlan,
  useUpdateHraPlan,
} from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupService"
import {
  CreateHraPlanValues,
  HraPlanFormValues,
  HraPlanModel,
} from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupTypes"
import { COMPLIANCE_FREQUENCY_OPTIONS, STATEMENT_FREQUENCIES } from "@/features/CreateCompany/createCompanyConstants"
import { useCreateCompanyAccount, useCreateCompanyHraPlan } from "@/features/CreateCompany/CreateCompanyProvider"
import { PurchaseHraStepProps } from "@/features/CreateCompany/pages/CreateCompany"
import { useNotifications } from "@/services/notificationService"
import { getNextSixMonthsStartDates } from "@/utils/dates"
import { PlanSetupValidationSchemaTcHub } from "@/utils/validations"
import { Avatar, Card, Grid, Alert as MuiAlert, styled, Typography } from "@mui/material"
import { spacing } from "@mui/system"
import { Formik } from "formik"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const Alert = styled(MuiAlert)(spacing)

const hasErrors = (touched: { [field: string]: boolean }, errors: { [field: string]: string }) =>
  Object.keys(touched).some(field => errors[field])

export const TcHubPlanSetup = ({ stepData }: PurchaseHraStepProps) => {
  const navigate = useNavigate()
  const { notify } = useNotifications("create-company")
  const { hraPlan, setCompanyHraPlan } = useCreateCompanyHraPlan()
  const [isLoading, setIsLoading] = useState(false)
  const { company } = useCreateCompanyAccount()
  const { data: currentHraPlans } = useGetHraPlan(company?.id)
  const currentHraPlan = currentHraPlans?.[0]
  const { mutateAsync: createHraPlan } = useCreateHraPlan(company?.id)
  const { mutateAsync: updateHraPlan } = useUpdateHraPlan(company?.id, currentHraPlan?.id ?? hraPlan?.id)

  const convertValuesToHraPlanModel = (values: HraPlanFormValues): HraPlanModel => ({
    companyId: company?.id ?? "",
    hraEndDate: "",
    dayOfMonth: 1,
    hraType: values.hraType,
    id: currentHraPlan?.id ?? hraPlan.id,
    isAutoPay: values.isAutoPay,
    partTimeHourQualification: 1,
    seasonalMonthQualification: 1,
    willKeepGroupPlan: false,
    willOfferHRA: false,
    hraStartDate: values.hraStartDate,
    statementFrequency: values.statementFrequency,
    statementCreationDate: values.statementCreationDate,
    complianceFrequency: values.complianceFrequency,
    eligibleEmployees: values.eligibleEmployees ?? 0,
    participatingEmployees: values.participatingEmployees ?? 0,
    packageName: values.packageName,
  })

  return (
    <Formik
      initialValues={convertHraPlanToFormValues(currentHraPlan ?? hraPlan)}
      enableReinitialize
      validationSchema={PlanSetupValidationSchemaTcHub}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setIsLoading(true)
        let successMessage = "Company Plan Creation Successful"
        let errorMessage = `Error creating plan for company ${company?.id}`

        try {
          const formValues: CreateHraPlanValues = {
            ...values,
            hraStartDate: values.hraStartDate,
            statementCreationDate: values.statementCreationDate,
            statementFrequency: values.statementFrequency,
            complianceFrequency: values.complianceFrequency,
            eligibleEmployees: values.eligibleEmployees,
            participatingEmployees: values.participatingEmployees,
            isAutoPay: values.packageName.toLowerCase() !== "starter",
            packageName: values.packageName,
            hraType: values.hraType,
          }
          if (currentHraPlan?.id || hraPlan?.id) {
            successMessage = "Company Plan Update Successful"
            errorMessage = `Error updating plan for company ${company?.id}`
            const planUpdated = await updateHraPlan(formValues)
            const updatedHraPlan = convertValuesToHraPlanModel(values)
            updatedHraPlan.id = planUpdated.id
            setCompanyHraPlan(updatedHraPlan)
          } else {
            const planCreated = await createHraPlan(formValues)
            const updatedHraPlan = convertValuesToHraPlanModel(values)
            updatedHraPlan.id = planCreated.id
            setCompanyHraPlan(updatedHraPlan)
          }
          notify(successMessage, "success")
          navigate("/admin/create-company/plan-structure")
        } catch (error: any) {
          notify(errorMessage, "error")
          const message = error.message || "Something went wrong. Please try again later."

          setStatus({ success: false })
          setErrors({ submit: message })
          setSubmitting(false)
        } finally {
          setIsLoading(false)
        }
      }}
    >
      {({ errors, handleChange, handleSubmit, handleBlur, touched, values, isValid, dirty, setFieldValue }) => {
        const isNewPlan = !hraPlan?.id
        const isPlanValid = hraPlan?.id && isValid
        const disabled = !(
          (isNewPlan &&
            isValid &&
            values.eligibleEmployees !== 0 &&
            Number(values.participatingEmployees) <= Number(values.eligibleEmployees)) ||
          isPlanValid
        )

        return (
          <>
            <Stepper steps={stepData} activeStepIndex={1} isError={hasErrors(touched, errors)} />
            <form noValidate onSubmit={handleSubmit} data-qa="plan-setup-form">
              {errors?.submit && (
                <Alert mt={2} mb={1} severity="warning" data-qa="error-banner-plan-setup-form">
                  {errors?.submit}
                </Alert>
              )}
              <Grid container spacing={4} mt={5}>
                <Grid item xs={12} mb={5}>
                  <Typography variant="h1" data-qa="plan-setup-title">
                    Plan Setup
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" data-qa="plan-setup-start-date">
                    When would you like your HRA to start?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SelectField
                    data-qa="hraToStartDate"
                    name="hraStartDate"
                    data={getNextSixMonthsStartDates()}
                    type="date"
                    label="Start Date"
                    value={values.hraStartDate}
                    placeholder="Please select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  {touched.hraStartDate && errors.hraStartDate && !values.hraStartDate}
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h5" data-qa="plan-setup-reimbursement-title">
                        How often do you want a reimbursement statement?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" data-qa="plan-setup-reimbursement-help">
                        You can see your automatic reimbursement statements on the Member Portal or run a manual
                        statement whenever you would like.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroupCard
                        name="statementFrequency"
                        value={values.statementFrequency}
                        formName="plan-setup"
                        handleChange={setFieldValue}
                        elements={STATEMENT_FREQUENCIES}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {values.hraStartDate && (
                    <Grid container spacing={4} mt={5}>
                      <Grid item xs={12}>
                        <Typography variant="h5" data-qa="plan-setup-statement-date">
                          Create statement on:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <DatePickerField
                          data-qa="statement-creation-date"
                          disableTextInput
                          name="statementCreationDate"
                          required
                          value={values.statementCreationDate}
                          fullWidth
                          defaultCalendarMonth={
                            new Date(
                              new Date(values.hraStartDate).getFullYear(),
                              new Date(values.hraStartDate).getMonth()
                            )
                          }
                          variant="outlined"
                          type="date"
                          onChange={handleChange}
                          sx={{ my: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {errors.statementCreationDate && (
                          <Typography variant="body1" color="error">
                            {errors.statementCreationDate}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h5" data-qa="plan-setup-frequency">
                        How often do you want us to verify that your employees have qualifying health coverage?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" data-qa="plan-setup-help">
                        To continue receiving reimbursements, employees must submit current proof of insurance, which we
                        will review for compliance.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroupCard
                        name="complianceFrequency"
                        formName="plan-setup"
                        value={values.complianceFrequency}
                        handleChange={setFieldValue}
                        elements={COMPLIANCE_FREQUENCY_OPTIONS}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} spacing={4} mt={5}>
                  <Grid item xs={12}>
                    <Typography variant="h5" data-qa="plan-setup-eligible-employees-title">
                      How many benefit eligible employees do you have?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ClampedTextField
                      data-qa="eligible-employees-text-field"
                      type="text"
                      name="eligibleEmployees"
                      label="Amount of employees"
                      required
                      value={values.eligibleEmployees}
                      error={Boolean(touched.eligibleEmployees && errors.eligibleEmployees)}
                      fullWidth
                      helperText={touched.eligibleEmployees && errors.eligibleEmployees}
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e)
                      }}
                      sx={{ my: 3 }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} spacing={4} mt={5}>
                  <Grid item xs={12}>
                    <Typography variant="h5" data-qa="plan-setup-participating-employees-title">
                      How many participating employees do you have?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ClampedTextField
                      data-qa="participating-employees-text-field"
                      type="text"
                      name="participatingEmployees"
                      label="Amount of employees"
                      required
                      value={values.participatingEmployees}
                      error={Boolean(touched.participatingEmployees && errors.participatingEmployees)}
                      fullWidth
                      helperText={touched.participatingEmployees && errors.participatingEmployees}
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e)
                      }}
                      sx={{ my: 3 }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} my={5} direction="row" alignItems="center">
                  <Grid item>
                    <Avatar
                      alt="Help avatar"
                      src={helpIcon}
                      sx={{ width: "3.75rem", height: "3.75rem" }}
                      variant="circular"
                    />
                  </Grid>
                  <Grid item>
                    <Card sx={{ maxWidth: "37.5rem", pl: "1.3rem", pr: "5rem" }}>
                      <Typography variant="body1" pt="1rem">
                        <Typography variant="body1bold">Great! </Typography> If you haven't already, you'll want to make
                        sure you offer an "affordable" reimbursement amount to avoid any potential penalties.
                      </Typography>
                      <Typography variant="body1" pb="1rem">
                        Please schedule to speak with us if you have questions.
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h5" data-qa="plan-setup-autopay">
                        What plan would you like to assign this company?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroupCard
                        name="packageName"
                        formName="plan-setup"
                        value={values.packageName}
                        handleChange={setFieldValue}
                        elements={[
                          {
                            title: "Starter",
                            value: "Starter",
                          },
                          {
                            title: "Growth",
                            value: "Growth",
                          },
                          {
                            title: "Partner",
                            value: "Partner",
                          },
                          {
                            title: "Professional",
                            value: "Professional",
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {values.packageName === "Partner" && (
                  <PackageCardInfo
                    clarificationTitle="Partner Plan Cost"
                    clarificationText="With this selection you qualify for our Partner Plan, here you will be charged $30 per employee per month (PEPM) (all participating employees) + $100 monthly platform fee."
                    planTitle="Partner Plan Clarification"
                    planDescription="Health benefits with a payment solution and integrations that service companies up to 49 employees."
                    planOptions=""
                    chipLabel="PARTNER"
                    chipColor="#FFF"
                    chipBackgroundColor="#00796B"
                  />
                )}

                {values.packageName === "Professional" && (
                  <PackageCardInfo
                    clarificationTitle="Professional Plan Cost"
                    clarificationText="With this selection you qualify for our Professional Plan, here you will be charged $40 per employee per month (PEPM) (all participating employees) + $100 monthly platform fee."
                    planTitle="Professional Plan Clarification"
                    planDescription="Personalized benefits solution with elevated & high-touch service. For companies that are ALEs, over 50 employees, and/or those moving from Group Plans."
                    planOptions=""
                    chipLabel="PROFESSIONAL"
                    chipColor="#FFF"
                    chipBackgroundColor="#27322F"
                  />
                )}
                {values.packageName === "Starter" && (
                  <PackageCardInfo
                    clarificationTitle="Starter Plan Cost"
                    clarificationText="With this selection you qualify for our Starter Plan, here you will be charged $20 per employee per month (PEPM) (all non-waived employees) + $40 monthly platform fee."
                    planTitle="Starter Plan Clarification"
                    planDescription="Easy & cost-effective solution for small employers new-to-benefits, up to 49 employees."
                    planOptions=""
                    chipLabel="STARTER"
                    chipColor=""
                    chipBackgroundColor="colors.lightGrayText"
                  />
                )}
                {values.packageName === "Growth" && (
                  <PackageCardInfo
                    clarificationTitle="Growth Plan Cost"
                    clarificationText="With this selection you qualify for our Growth Plan, here you will be charged $30 per employee per month (PEPM) (all non-waived employees) + $100 monthly platform fee."
                    planTitle="Growth Plan Clarification"
                    planDescription="Health benefits with a payment solution and integrations that serve growing companies up to 49 employees."
                    planOptions=""
                    chipLabel="GROWTH"
                    chipColor="#FFF"
                    chipBackgroundColor="#D27D56"
                  />
                )}
                <FlowNavigationButtons
                  handleBack={() => {
                    const updatedHraPlan = convertValuesToHraPlanModel(values)
                    updatedHraPlan.id = currentHraPlan?.id ?? hraPlan.id
                    setCompanyHraPlan(updatedHraPlan)
                    navigate("/admin/create-company/account")
                  }}
                  type="submit"
                  disabled={disabled}
                  isSubmitting={isLoading}
                />
              </Grid>
            </form>
          </>
        )
      }}
    </Formik>
  )
}
