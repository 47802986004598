import { Typography } from "@mui/material"
import { AuthGuard } from "./features/Auth/guards/AuthGuard"
import {
  OtherPremiumsCore,
  OtherPremiumsDescription,
  otherPremiumsTitle,
} from "./features/BenefitsElection/pages/purchase/OtherPremiums"
import { DashboardLayout } from "./features/Dashboard/components/DashboardLayout"

export const AdditionalPremiums = () => (
  <AuthGuard>
    <DashboardLayout>
      <Typography variant="h1" gutterBottom>
        {otherPremiumsTitle}
      </Typography>
      <OtherPremiumsDescription />
      <OtherPremiumsCore />
    </DashboardLayout>
  </AuthGuard>
)
