import { Redirect } from "@/components/Navigation"
import { WAIVED, WELCOME } from "@/features/BenefitsElection/benefitsElectionConstants"
import { useShoppingSession, useShoppingUrl } from "@/features/BenefitsElection/benefitsElectionService"
import { useBenefitsElectionStore } from "@/features/BenefitsElection/benefitsElectionStore"
import { useNotifications } from "@/services/notificationService"
import { WrapperProps } from "@/utils/types"
import { useLocation } from "react-router-dom"
import { useAuth } from "../useAuth"

// For routes that can only be accessed by employees eligible to shop
export const BenefitsElectionGuard = ({ children }: WrapperProps) => {
  const location = useLocation()
  const { notify } = useNotifications("benefits-election")
  const shoppingSessionIdParam = useShoppingSession()
  const shoppingUrl = useShoppingUrl()
  const storedShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const clearBenefitsElectionStore = useBenefitsElectionStore(state => state.clearBenefitsElectionStore)
  const { user } = useAuth()

  if (!user) {
    console.error("User object was null, did you mean to put this behind an AuthGuard?")
    return null
  }

  const storedShoppingSessionId = storedShoppingSession.id
  const shoppingSessionId = user.shoppingSession?.id
  const shoppingStatus = user.shoppingSession?.shoppingStatus
  const planYear = user.shoppingSession?.planYear
  const employeeId = user.employeeId
  const shoppingSessionChanged = storedShoppingSessionId !== shoppingSessionId

  if (!employeeId) {
    notify("Unable to verify employee. Please contact your benefits administrator", "error", "unverified-employee")

    return <Redirect />
  }

  if (!shoppingSessionId) {
    const message =
      "Failed to acquire shopping session. Try logging out and logging back in. If this does not help, please contact your benefits administrator"

    notify(message, "error", "expired-shopping-window")
    console.error(message)
    console.info(user)

    return <Redirect />
  }

  // FUTURE: Revisit if this is needed
  // Per @Danny Feng this is Legacy Code
  if (shoppingStatus === WAIVED) {
    notify("Your coverage has been waived for " + planYear + " .", "info", "waived-shopping-window")

    return <Redirect />
  }

  if (shoppingSessionChanged) {
    const locationIsWelcome = location.pathname.includes(WELCOME)

    clearBenefitsElectionStore()

    return locationIsWelcome ? children : <Redirect to={`/benefits-election/${shoppingSessionId}/${WELCOME}`} />
  }

  if (shoppingSessionIdParam !== shoppingSessionId) {
    return <Redirect to={shoppingUrl + WELCOME} />
  }

  return children
}
