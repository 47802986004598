import { Box, Card, CardContent, CardHeader, Grid, Skeleton, SxProps, Typography } from "@mui/material"

const PlanCardSkeleton = ({ displayStyle = {} }: { displayStyle?: SxProps }) => (
  <Grid item xs={12} sm={6} xl={4} sx={displayStyle}>
    <Card
      sx={{
        border: "2px solid",
        borderColor: "colors.borderGray",
        height: "100%",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      data-qa="plans-card-loading"
    >
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingBottom: 0,
        }}
        avatar={
          <Skeleton
            variant="circular"
            width="3.5rem"
            height="3.5rem"
            sx={{
              mb: 4,
            }}
          />
        }
        title={
          <Skeleton variant="rectangular">
            <Typography variant="h6" gutterBottom sx={{ width: { xs: "20rem", lg: "40rem" } }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
            </Typography>
          </Skeleton>
        }
      />

      <CardContent>
        <Skeleton variant="rectangular" sx={{ mt: 2, mb: 6 }}>
          <Box sx={{ height: "7.375rem", width: { xs: "20rem", lg: "40rem" } }} />
        </Skeleton>
        <Skeleton variant="rectangular">
          <Box sx={{ height: "6rem", width: { xs: "20rem", lg: "40rem" } }} />
        </Skeleton>
        <Skeleton variant="rectangular" sx={{ mt: 6 }}>
          <Box sx={{ height: "9.75rem", width: { xs: "20rem", lg: "40rem" } }} />
        </Skeleton>

        <Grid container spacing={2} sx={{ mt: 6 }}>
          <Grid item xs={6}>
            <Skeleton variant="rounded">
              <Box sx={{ height: "2.5rem", width: { xs: "20rem", lg: "40rem" } }} />
            </Skeleton>
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rounded">
              <Box sx={{ height: "2.5rem", width: { xs: "20rem", lg: "40rem" } }} />
            </Skeleton>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rounded">
              <Box sx={{ height: "2.5rem", width: { xs: "20rem", lg: "40rem" } }} />
            </Skeleton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
)

export const LoadingGrid = () => (
  <Grid item container spacing={4} sx={{ mb: 4 }}>
    <PlanCardSkeleton />
    <PlanCardSkeleton displayStyle={{ display: { xs: "none", sm: "block" } }} />
    <PlanCardSkeleton displayStyle={{ display: { xs: "none", xl: "block" } }} />
  </Grid>
)
