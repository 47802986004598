import { ADMINISTRATIVE_ROLES, YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { Uuid } from "@/utils/types"
import {
  dateOfBirthValidation,
  validateCity,
  validateClass,
  validateCounty,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
  validateState,
  validateStreet1,
  validateStreet2,
  validateZip,
} from "@/utils/validations"
import * as Yup from "yup"

interface EditPersonRoles {
  administrativeRole: string
  employeeRole: boolean
  benefitsConsultantRole: boolean
}

/**
 * Validates that the user has at least one role selected.
 * @param roles - The roles object to validate.
 * @returns True if the user has at least one role selected, false otherwise.
 */
const emptyRoleValidation = (roles: EditPersonRoles) => {
  const { administrativeRole, employeeRole } = roles

  return !(administrativeRole === ADMINISTRATIVE_ROLES[2].value && employeeRole === YES_NO_OPTIONS_BOOLEAN[1].value)
}

/**
 * Validates if a user is a benefits consultant and has no other roles selected.
 * @param roles - The roles object to validate.
 * @returns True if the user is a benefits consultant and has no other roles selected, false otherwise.
 */
const consultantCombinationValidation = (roles: EditPersonRoles) => {
  const { administrativeRole, employeeRole, benefitsConsultantRole } = roles

  return !(
    benefitsConsultantRole === YES_NO_OPTIONS_BOOLEAN[0].value &&
    (administrativeRole !== ADMINISTRATIVE_ROLES[2].value || employeeRole === YES_NO_OPTIONS_BOOLEAN[0].value)
  )
}

export const editPersonSchema = Yup.object({
  id: Yup.string<Uuid>(),
  firstName: validateFirstName,
  lastName: validateLastName,
  businessUnit: Yup.string(),
  dateOfBirth: dateOfBirthValidation,
  email: validateEmail,
  phoneNumber: validatePhoneNumber,
  homeAddress: Yup.object({
    id: Yup.string(),
    street1: validateStreet1,
    street2: validateStreet2,
    zip: validateZip,
    city: validateCity,
    county: validateCounty,
    state: validateState,
  }),
  currentClass: validateClass,
  employmentId: Yup.string(),
  companyId: Yup.string(),
  roles: Yup.object({
    administrativeRole: Yup.string()
      .oneOf(
        ADMINISTRATIVE_ROLES.map(role => role.value),
        "Invalid administrative role"
      )
      .required("Administrative role is required"),
    employeeRole: Yup.boolean().required("Employee role is required"),
    benefitsConsultantRole: Yup.boolean().required("Benefits consultant role is required"),
  })
    .test("empty-role-validation", "User must have at least one role.", (roles: EditPersonRoles) =>
      emptyRoleValidation(roles)
    )
    .test("consultant-combination-validation", "Consultant role must be selected alone.", (roles: EditPersonRoles) =>
      consultantCombinationValidation(roles)
    ),
})
export type EditPersonValues = Yup.InferType<typeof editPersonSchema>

export const hraHubEditPersonSchema = Yup.object({
  id: Yup.string<Uuid>(),
  firstName: validateFirstName,
  lastName: validateLastName,
  employeeId: Yup.string(),
  email: validateEmail,
  phoneNumber: validatePhoneNumber,
  homeAddress: Yup.object({
    id: Yup.string<Uuid>(),
    street1: validateStreet1,
    street2: validateStreet2,
    zip: validateZip,
    city: validateCity,
    county: validateCounty,
    state: validateState,
  }),
})

export type HraHubEditPersonValues = Yup.InferType<typeof hraHubEditPersonSchema>
