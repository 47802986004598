import { CheckBoxGroupCard } from "@/components/CheckBoxGroupCard"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { InfoCard, makeInfoItems, StyledCard } from "@/components/StyledCard"
import { YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { useGetAllRecurringPremiums } from "@/features/BenefitsElection/recurringPremiumsService"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { AboutCard, TerminationDateCard } from "@/features/Profile/pages/Profile"
import { createDateFromText, formatDate, getDisplayPhoneNumber } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import { Delete, Mail } from "@mui/icons-material"
import { Button, CircularProgress, Grid } from "@mui/material"
import { useFeatureFlag } from "configcat-react"
import { noop } from "lodash"
import { Dispatch, SetStateAction, useState } from "react"
import { useSignUpLink } from "../hooks/useSendSignupLink"
import { PersonModel } from "../peopleTypes"

export const ROLE_OPTIONS = [
  { title: "Admin", value: "administrator" },
  { title: "Employee", value: "employee" },
  { title: "None", value: "none" },
]

export const getRoleValues = (roles: string[] = []) => {
  const lowerCaseRoles = roles.map(role => role.toLowerCase())

  const isAdmin = lowerCaseRoles.includes("administrator") || lowerCaseRoles.includes("system administrator")
  const isEmployee = lowerCaseRoles.includes("employee")

  const roleValues = {
    administrator: isAdmin,
    employee: isEmployee,
  }

  return {
    ...roleValues,
    none: !isAdmin && !isEmployee,
  }
}

interface CoverageInfoCardProps {
  carrierName: string | undefined
  startDate: Date | undefined
  endDate: Date | undefined
  coveredMembers: string[] | undefined
}

const CoverageInfoCard = ({ carrierName, startDate, endDate, coveredMembers }: CoverageInfoCardProps) => (
  <InfoCard
    title="Coverage Information"
    items={makeInfoItems([
      ["Carrier", carrierName],
      ["Start Date", formatDate(startDate)],
      ["End Date", formatDate(endDate)],
      ["Covered Members", coveredMembers?.join(", ")],
    ])}
  />
)

export interface PeopleDrawerProfileDetailsProps {
  setIsTerminateOpen: Dispatch<SetStateAction<boolean>>
  personProfileId: Uuid | undefined
  person: PersonModel | undefined
  companyId: Uuid | undefined
  company: CompanyModel | undefined
}

export const PeopleDrawerProfileDetails = ({
  setIsTerminateOpen,
  personProfileId,
  person,
  companyId,
  company,
}: PeopleDrawerProfileDetailsProps) => {
  const { handleSendSignUpLink } = useSignUpLink()
  const [isSendingLink, setIsSendingLink] = useState(false)
  const { value: scheduleTerminationFromProfileValue } = useFeatureFlag("scheduleTerminationFromProfile", false)

  const firstName = person?.firstName ?? ""
  const lastName = person?.lastName ?? ""
  const employmentId = person?.employmentId
  const fullAddress = person?.location ?? ""
  const emailNew = person?.email ?? ""
  const phoneNumber = getDisplayPhoneNumber(person?.phoneNumber ?? "")
  const roles = person?.roles?.map(role => role.name.toLowerCase()) ?? []
  const roleValues = getRoleValues(roles)
  const benefitsEligible = person?.isBenefitsEligible ?? false
  const companyName = company?.companyInfo.companyName ?? ""
  const employee = person?.employments?.[0]

  const { data: recurringPremiums } = useGetAllRecurringPremiums(companyId, employmentId)
  const primaryElection = recurringPremiums?.find(x => x.isPrimary)

  return (
    <>
      <Grid item xs={12}>
        <AboutCard
          firstName={firstName}
          lastName={lastName}
          employmentId={employmentId ?? ("" as never)}
          companyName={companyName}
          fullAddress={fullAddress}
          email={emailNew}
          phoneNumber={phoneNumber}
        />
      </Grid>
      <Grid item xs={12}>
        {primaryElection && (
          <CoverageInfoCard
            carrierName={primaryElection.carrierName}
            startDate={createDateFromText(primaryElection.coverageStartDate)}
            endDate={createDateFromText(primaryElection.coverageEndDate)}
            coveredMembers={primaryElection.coveredFamilyMembers?.map(x => `${x.firstName} ${x.lastName}`)}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <StyledCard>
          <CheckBoxGroupCard
            disabled
            formName="profile-details"
            elements={ROLE_OPTIONS}
            values={roleValues}
            handleChange={noop}
            error={false}
            label="Roles"
          />
        </StyledCard>
      </Grid>
      <Grid item xs={12}>
        <StyledCard>
          <RadioGroupCard
            disabled
            name="benefitsEligible"
            formName="profile-details"
            elements={YES_NO_OPTIONS_BOOLEAN}
            value={benefitsEligible}
            handleChange={noop}
            label="Benefits Eligible"
          />
        </StyledCard>
      </Grid>
      <Grid item xs={12}>
        <StyledCard>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                variant="outlined"
                data-qa="send-signup-link"
                onClick={async () => {
                  setIsSendingLink(true)
                  try {
                    await handleSendSignUpLink(companyId, personProfileId)
                  } finally {
                    setIsSendingLink(false)
                  }
                }}
                disabled={isSendingLink}
                startIcon={isSendingLink ? <CircularProgress size={20} /> : <Mail />}
              >
                Send Sign-up Link
              </Button>
            </Grid>
            {!employee?.terminatedDate && scheduleTerminationFromProfileValue && (
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  data-qa="terminate-employee"
                  onClick={() => setIsTerminateOpen(true)}
                  startIcon={<Delete />}
                >
                  Terminate Employee
                </Button>
              </Grid>
            )}
          </Grid>
        </StyledCard>
      </Grid>
      {employee?.terminatedDate && (
        <Grid item xs={12}>
          <TerminationDateCard
            // FUTURE: Remove these unsafe non-null assertions
            personId={person?.id!}
            employmentId={employee?.id!}
            terminatedDate={employee?.terminatedDate}
          />
        </Grid>
      )}
    </>
  )
}
