import { convertDateString } from "@/utils/dates"
import { DATE_FORMAT_MONTH_DAY_YEAR, formatCents, transformDate } from "@/utils/formatting"
import styled from "@emotion/styled"
import ArrowDownwardOutlined from "@mui/icons-material/ArrowDownwardOutlined"
import DeleteIcon from "@mui/icons-material/Delete"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import FilterListIcon from "@mui/icons-material/FilterList"
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import { TablePaginationProps } from "@mui/material/TablePagination/TablePagination"
import { spacing } from "@mui/system"
import { isFunction, isNumber, isString, keyBy, orderBy as orderByFn } from "lodash"
import { ReactNode } from "react"
import { CSVLink } from "react-csv"
import { FIVE, TEN, TWENTY_FIVE } from "./tableConstants"

const Paper = styled(MuiPaper)(spacing)

const Spacer = styled.div`
  flex: 1 1 100%;
`

const ToolbarTitle = styled.div`
  min-width: 150px;
`

const reservedIds = ["select", "actions"]

export interface OrderByItemType {
  headCellId: string
  direction: "asc" | "desc"
}

export type ColumnType = "string" | "number" | "date" | "currencyCents" | "currencyDollars"

interface BaseTableProps<T extends object> {
  rows: T[]
  selected: T[]
  orderBy: OrderByItemType[]
  searchCriteria?: string
  onToggleOrderBy: (id: string) => void
  onToggleSelect: (selected: T) => void
  onToggleSelectAll: () => void
  onPageChange: (page: number) => void
  onRowsPerPageChange: (rowsPerPage: number) => void
  uniqueIdSelector: (row: T) => string
  headCells: {
    id: string
    label: string
    type?: ColumnType
    field?: keyof T | ((row: T) => string)
    alignment?: "left" | "right" | "center"
    disablePadding?: boolean
    sortable?: boolean
  }[]
  children: (drillingProps: {
    row: T
    rowId: string
    isSelected: boolean
    labelId: string
    toggleSelect: () => void
  }) => ReactNode
  rowsPerPage: number
  page: number
  title?: string
  showFilters?: boolean
  rowHeight?: number
  fullWidth?: boolean
  onRowClick?: (row: T) => void
  hidePagination?: boolean
  showDeleteIcon?: boolean
  hideEmptyTableMessage?: boolean
  capitalizeHeaders?: boolean
  hover?: boolean
  exportCsv?: boolean
  csvTitle?: string
  noRecordsMessage?: string
}

type TchTablePaginationProps = TablePaginationProps & { component: "div" }

export const TchTablePagination = styled(TablePagination)<TchTablePaginationProps>`
  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const BaseTable = <T extends object>({
  children,
  headCells,
  onPageChange,
  onRowsPerPageChange,
  onToggleOrderBy,
  onToggleSelect,
  onToggleSelectAll,
  orderBy,
  page,
  rows,
  rowsPerPage,
  selected,
  title,
  searchCriteria,
  uniqueIdSelector,
  showFilters,
  rowHeight,
  fullWidth,
  onRowClick,
  hidePagination,
  showDeleteIcon,
  hideEmptyTableMessage = false,
  capitalizeHeaders = true,
  hover = true,
  exportCsv = false,
  csvTitle,
  noRecordsMessage,
}: BaseTableProps<T>) => {
  const numSelected = selected.length
  const rowCount = rows.length
  const headCellsById = keyBy(headCells, "id")
  const headCellsCount = headCells.length
  const orderByFields = orderBy.map(order => headCellsById[order.headCellId]?.field ?? order.headCellId).filter(Boolean)
  const orderByDirections = orderBy.map(order => order.direction).filter(Boolean)

  const processedRows = orderByFn(rows, orderByFields, orderByDirections).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  const noRecordToDisplayMessage = noRecordsMessage ?? "There are no records to display yet."

  const csvData = (selected.length > 0 ? selected : rows).map(row =>
    headCells.map(head => {
      if (isFunction(head.field)) {
        return head.field(row)
      }
      if (head.field && isNumber(row[head.field]) && head.type === "currencyCents" && head.field in row) {
        return formatCents(Number(row[head.field]))
      }
      if (isString(head.field) && head.field in row) {
        const fieldValue = row[head.field]
        if (isString(fieldValue) && !isNaN(Date.parse(fieldValue))) {
          return transformDate(convertDateString(fieldValue), DATE_FORMAT_MONTH_DAY_YEAR)
        }
      }
      if (isString(head.field) && head.field in row) {
        return row[head.field]
      }
    })
  )

  const csvHeaders = headCells.map(cell => cell.label)

  return (
    <Paper sx={{ width: fullWidth ? "100%" : null, marginTop: 6, position: "relative" }}>
      {exportCsv && (
        <Grid container justifyContent="flex-end" mb={2}>
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={`${csvTitle ?? "export"}` + " " + `${transformDate(new Date(), DATE_FORMAT_MONTH_DAY_YEAR)}.csv`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              variant="text"
              color="primary"
              startIcon={<FileDownloadOutlinedIcon />}
              sx={{ marginLeft: "16px", width: "12rem", mt: 2, mr: 2 }}
            >
              Export {selected.length > 0 ? "Selected" : "all"} to CSV
            </Button>
          </CSVLink>
        </Grid>
      )}
      <Toolbar
        variant="dense"
        sx={{
          position: "absolute",
          top: "-2.7rem",
        }}
      >
        <ToolbarTitle>
          <Typography color="inherit" visibility={numSelected ? "visible" : "hidden"}>
            {`${numSelected} selected`}
          </Typography>
        </ToolbarTitle>
        <Spacer />
        <div>
          {numSelected > 0 && showDeleteIcon ? (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            showFilters && (
              <Tooltip title="Filter list">
                <IconButton aria-label="Filter list" data-qa="filter-button">
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            )
          )}
        </div>
      </Toolbar>
      <TableContainer>
        <Table aria-labelledby="table-title" size="medium" aria-label="base table">
          <TableHead>
            <TableRow>
              {headCells.find(head => head.id === "select") && (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onToggleSelectAll}
                    inputProps={{ "aria-label": "select all" }}
                  />
                </TableCell>
              )}
              {headCells
                .filter(head => !reservedIds.includes(head.id))
                .map(head => {
                  const isOrdering = orderBy.some(order => order.headCellId === head.id)
                  const direction = orderBy.find(order => order.headCellId === head.id)?.direction

                  return (
                    <TableCell
                      key={`${head.id}-header`}
                      align={head.alignment}
                      padding={head.disablePadding ? "none" : "normal"}
                      sortDirection={isOrdering ? direction : false}
                      size="small"
                    >
                      {head.sortable ? (
                        <TableSortLabel
                          active={isOrdering}
                          direction={isOrdering ? direction : "asc"}
                          onClick={() => head.id && onToggleOrderBy(head.id)}
                          IconComponent={ArrowDownwardOutlined}
                          sx={theme => ({
                            "&.MuiTableSortLabel-root.MuiButtonBase-root.Mui-active .MuiSvgIcon-root": {
                              color: theme.palette.primary.main,
                            },
                          })}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ textTransform: capitalizeHeaders ? "capitalize" : "none" }}
                          >
                            {head.label}
                          </Typography>
                        </TableSortLabel>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          sx={{ textTransform: capitalizeHeaders ? "capitalize" : "none" }}
                        >
                          {head.label}
                        </Typography>
                      )}
                    </TableCell>
                  )
                })}
              {headCells.find(head => head.id === "actions") && <TableCell padding="checkbox" />}
            </TableRow>
          </TableHead>
          <TableBody data-qa="base-table-body">
            {processedRows.map((row, index) => {
              const rowId = uniqueIdSelector(row)
              const isSelected = selected.some(selectedRow => uniqueIdSelector(selectedRow) === rowId)
              const labelId = `base-table-checkbox-${rowId}`
              const toggleSelect = () => onToggleSelect(row)

              return (
                <TableRow
                  sx={{
                    height: `${rowHeight}px`,
                    cursor: onRowClick ? "pointer" : "default",
                  }}
                  role="checkbox"
                  aria-checked={isSelected}
                  hover={hover}
                  key={`${rowId}-${index}`}
                  selected={isSelected}
                  onClick={() => onRowClick?.(row)}
                >
                  {children({ row, rowId, isSelected, labelId, toggleSelect })}
                </TableRow>
              )
            })}
            {!hideEmptyTableMessage && processedRows.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={headCellsCount} sx={{ height: "300px" }}>
                  {searchCriteria === ""
                    ? noRecordToDisplayMessage
                    : "There are no records available that match your search criteria."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!hidePagination && (
        <TchTablePagination
          rowsPerPageOptions={[FIVE, TEN, TWENTY_FIVE]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, changedPage) => onPageChange(changedPage)}
          onRowsPerPageChange={event => onRowsPerPageChange(Number(event.target.value))}
          data-qa="table-pagination"
          nextIconButtonProps={{ children: "Next" }}
        />
      )}
    </Paper>
  )
}
