import { ReturnButton } from "@/components/Buttons"
import { PLAN_PREMIUM_LABEL } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { formatCents } from "@/utils/formatting"
import { Cents, Uuid } from "@/utils/types"
import { dedupe } from "@/utils/util"
import {
  AssignmentIndOutlined,
  DomainAddOutlined,
  PaidOutlined,
  PregnantWomanOutlined,
  PsychologyOutlined,
  TollOutlined,
} from "@mui/icons-material"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  SvgIconTypeMap,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { noop, times } from "lodash"
import { ReactNode, useEffect, useRef, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { DRUG_TIERS, RECOMMENDED_PLANS } from "../../benefitsElectionConstants"
import { useIsCompanyAutoPay, useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import {
  DoctorPreference,
  DrugPreference,
  FilterPreferences,
  HospitalPreference,
  Plan,
  PlanRawData,
} from "../../benefitsElectionTypes"
import {
  createRecommendedFilterPreferences,
  getColorByMetal,
  getInAndOutOfNetworkValues,
  getPlanBalance,
} from "../../benefitsElectionUtils"
import { WarningAlert } from "../../components/BenefitsElectionAlerts"
import { CarrierLogo, ChoosePlanButton, PerMonthSpan, PlanDetailsChips } from "../../components/PlanComponents"
import { useSelectPlan } from "../../hooks/useSelectPlan"
import { PlanDetailsDrawer } from "./PlanDetailsDrawer"
import { WithCoverage } from "./PlanDetailsElements"

const dataQa = "comparison-tool"

const NetworkTitle = () => (
  <Typography variant="body2bold" color="colors.lightGrayText" data-qa={createDataQa(dataQa, "network-title")}>
    In Network / Out of Network
  </Typography>
)

interface TitleIconProps {
  IconComponent: OverridableComponent<SvgIconTypeMap>
}

const TitleIcon = ({ IconComponent }: TitleIconProps) => (
  <IconComponent sx={{ width: "1.5rem", height: "1.5rem", color: takeCommandPrimary.main }} />
)

interface TitleColumnProps {
  icon?: ReactNode
  title?: string
}

const TitleColumn = ({ icon, title }: TitleColumnProps) => (
  <Box sx={{ width: "11.75rem", mr: 4, maxWidth: "11.75rem" }}>
    {icon && title && (
      <Stack direction="row" gap={2} padding={{ md: 1, lg: 2 }} justifyContent="flex-start" alignItems="center">
        {icon}
        <Typography variant="body1bold" data-qa={createDataQa(dataQa, "section-title")}>
          {title}
        </Typography>
      </Stack>
    )}
  </Box>
)

const contentColumnContainerProps = {
  width: "100%",
  p: 4,
  spacing: 4,
}

// FUTURE: refactor this renderColumnContent prop to use a component instead of a render function
interface DynamicContentColumnProps {
  plansToCompare: Plan[]
  renderColumnContent: (plan: Plan) => ReactNode
}

const DynamicContentColumn = ({ plansToCompare, renderColumnContent }: DynamicContentColumnProps) => (
  <Grid container {...contentColumnContainerProps}>
    {plansToCompare.map(plan => (
      <Grid item key={plan.id} xs={12 / plansToCompare.length}>
        {renderColumnContent(plan)}
      </Grid>
    ))}
  </Grid>
)

interface ComparisonProperty {
  propertyName: keyof PlanRawData
  label: string
}

interface ComparisonSection {
  icon: OverridableComponent<SvgIconTypeMap>
  title: string
  properties: ComparisonProperty[]
}

interface ComparisonContentSectionProps {
  plansToCompare: Plan[]
  properties: ComparisonProperty[]
}

const ComparisonContentSection = ({ plansToCompare, properties }: ComparisonContentSectionProps) => {
  const numberOfPlans = plansToCompare.length
  const spacePerColumn = 12 / numberOfPlans

  return (
    <Grid container {...contentColumnContainerProps}>
      {times(numberOfPlans, index => (
        <Grid item key={index} xs={spacePerColumn}>
          <NetworkTitle />
        </Grid>
      ))}

      {properties.map(({ label, propertyName }) =>
        plansToCompare.map(plan => {
          const rawValue = plan.rawData[propertyName]
          const { inNetworkValue, outOfNetworkValue } = getInAndOutOfNetworkValues(rawValue)

          return (
            <Grid item key={plan.id} xs={spacePerColumn} px={4}>
              <Typography
                data-qa={createDataQa(dataQa, "section-property")}
              >{`${label}: ${inNetworkValue} / ${outOfNetworkValue}`}</Typography>
            </Grid>
          )
        })
      )}
    </Grid>
  )
}

interface ComparisonSectionContainerProps {
  plansToCompare: Plan[]
  comparisonSection: ComparisonSection
}

const ComparisonSectionContainer = ({ plansToCompare, comparisonSection }: ComparisonSectionContainerProps) => (
  <>
    <Box sx={{ p: 2, display: "flex", width: "100%" }} data-qa={createDataQa(dataQa, "section-container")}>
      <TitleColumn icon={<TitleIcon IconComponent={comparisonSection.icon} />} title={comparisonSection.title} />
      <ComparisonContentSection plansToCompare={plansToCompare} properties={comparisonSection.properties} />
    </Box>
    <Divider sx={{ width: "100%", borderBottomWidth: 2, borderColor: "colors.lightGrayText" }} />
  </>
)

type DynamicComparisonSectionContainerProps = TitleColumnProps &
  DynamicContentColumnProps & {
    showDivider?: boolean
  }

const DynamicComparisonSectionContainer = ({
  plansToCompare,
  renderColumnContent,
  showDivider = true,
  icon,
  title,
}: DynamicComparisonSectionContainerProps) => (
  <>
    <Box sx={{ p: 2, display: "flex", mb: showDivider ? 0 : 4, width: "100%" }}>
      <TitleColumn icon={icon} title={title} />
      <DynamicContentColumn plansToCompare={plansToCompare} renderColumnContent={renderColumnContent} />
    </Box>
    {showDivider && <Divider sx={{ width: "100%", borderBottomWidth: 2, borderColor: "colors.lightGrayText" }} />}
  </>
)

const desktopCardDataQa = createDataQa(dataQa, "desktop-card")

interface ComparePlanDesktopCardProps {
  plan: Plan
  allowanceCents: Cents
  isAutoPay: boolean
  isSelectingPlan: boolean
  isSelected: boolean
  isSelectingThisPlan: boolean
  filterPreferences: FilterPreferences
  handlePlanSelect: () => void
}

const ComparePlanDesktopCard = ({
  plan,
  allowanceCents,
  isAutoPay,
  isSelectingPlan,
  isSelected,
  isSelectingThisPlan,
  filterPreferences,
  handlePlanSelect,
}: ComparePlanDesktopCardProps) => {
  const {
    displayName,
    level,
    logoUrl,
    premiumAmountCents,
    carrier,
    planType,
    onExchange,
    offExchange,
    providers,
    coverages,
  } = plan
  const { doctorPreferences, hospitalPreferences, drugPreferences } = filterPreferences

  const uniqueProviders = dedupe(providers)
  const uniqueDrugs = dedupe(coverages)

  const doctorsFound = uniqueProviders
    .map(doctor => {
      const doctorResult = doctorPreferences?.find(pref => pref.npi === doctor.npi)
      return doctorResult ? { ...doctorResult, covered: doctor.inNetwork } : undefined
    })
    .filter((doctorPreference): doctorPreference is WithCoverage<DoctorPreference> => !!doctorPreference)

  const hospitalsFound = uniqueProviders
    .map(hospital => {
      const hospitalResult = hospitalPreferences?.find(pref => pref.npi === hospital.npi)
      return hospitalResult ? { ...hospitalResult, covered: hospital.inNetwork } : undefined
    })
    .filter((hospitalPreference): hospitalPreference is WithCoverage<HospitalPreference> => !!hospitalPreference)

  const drugsFound = uniqueDrugs
    .map(drug => {
      const drugResult = drugPreferences?.find(pref => pref.medId.toString() === drug.drugPackageId)
      return drugResult ? { ...drugResult, covered: drug.tier !== DRUG_TIERS.NOT_COVERED } : undefined
    })
    .filter((drugPreference): drugPreference is WithCoverage<DrugPreference> => !!drugPreference)

  const levelColor = getColorByMetal(level)
  const { balanceLabel, balanceValue, balanceLabelColor } = getPlanBalance(allowanceCents, premiumAmountCents)
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false)

  return (
    <>
      <PlanDetailsDrawer
        open={showDetailsDrawer}
        handleClose={() => setShowDetailsDrawer(false)}
        plan={plan}
        handlePlanSelect={handlePlanSelect}
        isSelectingPlan={isSelectingPlan}
        isComparing
        uniqueDoctors={doctorsFound}
        uniqueHospitals={hospitalsFound}
        uniqueDrugs={drugsFound}
      />
      <Card
        data-qa={desktopCardDataQa}
        sx={{
          border: "2px solid",
          borderColor: levelColor,
          height: "100%",
          borderRadius: "0.5rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          maxWidth: "20rem",
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingBottom: 0,
            paddingX: { md: 3, lg: 4 },
          }}
          avatar={
            <CarrierLogo
              carrierName={carrier.name}
              logoUrl={logoUrl}
              sx={{ height: "1.125rem" }}
              data-qa={createDataQa(desktopCardDataQa, "logo")}
            />
          }
          title={
            <Typography data-qa={createDataQa(desktopCardDataQa, "name")} variant="h6" gutterBottom mt={6}>
              {displayName}
            </Typography>
          }
        />

        <CardContent sx={{ paddingX: { md: 3, lg: 4 } }}>
          <Typography variant="body2" gutterBottom data-qa={createDataQa(desktopCardDataQa, "monthly-premium")}>
            {PLAN_PREMIUM_LABEL}
          </Typography>
          <Typography variant="h5" color="primary" data-qa={createDataQa(desktopCardDataQa, "premium-amount")}>
            {formatCents(premiumAmountCents)}
            <PerMonthSpan />
          </Typography>

          <Typography variant="body2" mt={2} gutterBottom data-qa={createDataQa(desktopCardDataQa, "balance")}>
            {balanceLabel}
          </Typography>
          <Typography
            variant="body2bold"
            sx={{ color: balanceLabelColor }}
            data-qa={createDataQa(desktopCardDataQa, "balance-amount")}
          >
            {balanceValue}
            <PerMonthSpan />
          </Typography>

          <Box mt={2} mb={6}>
            <PlanDetailsChips
              level={level}
              planType={planType ?? ""}
              data-qa={desktopCardDataQa}
              enrollmentType={carrier.enrollment}
              payLater={carrier.payLater}
              onExchange={onExchange}
              offExchange={offExchange}
              isAutoPay={isAutoPay}
            />
          </Box>

          <ChoosePlanButton
            dataQa={createDataQa(desktopCardDataQa, "choose-button")}
            isSelectingPlan={isSelectingPlan}
            isSelectingThisPlan={isSelectingThisPlan}
            handleClick={handlePlanSelect}
          />

          <Button
            variant="outlined"
            fullWidth
            sx={{ mt: 2, whiteSpace: "nowrap" }}
            onClick={() => setShowDetailsDrawer(true)}
            data-qa={createDataQa(desktopCardDataQa, "details-button")}
          >
            View details
          </Button>
        </CardContent>
      </Card>
    </>
  )
}

export const comparisonSections: ComparisonSection[] = [
  {
    icon: PaidOutlined,
    title: "Co-pays",
    properties: [
      { propertyName: "primaryCarePhysician", label: "Doctor" },
      { propertyName: "specialist", label: "Specialist" },
    ],
  },
  {
    icon: AssignmentIndOutlined,
    title: "Prescriptions",
    properties: [
      { propertyName: "genericDrugs", label: "Generic" },
      { propertyName: "preferredBrandDrugs", label: "Preferred brand" },
      { propertyName: "nonPreferredBrandDrugs", label: "Non-preferred brand" },
      { propertyName: "specialtyDrugs", label: "Specialty" },
    ],
  },
  {
    icon: DomainAddOutlined,
    title: "Hospitals",
    properties: [
      { propertyName: "inpatientFacility", label: "Inpatient" },
      { propertyName: "outpatientFacility", label: "Outpatient" },
      { propertyName: "imagingCenter", label: "Imaging" },
      { propertyName: "labTest", label: "Labs & tests" },
    ],
  },
  {
    icon: PregnantWomanOutlined,
    title: "Pregnancy",
    properties: [
      { propertyName: "prenatalCare", label: "Prenatal care" },
      { propertyName: "postnatalCare", label: "Postnatal care" },
      { propertyName: "inpatientBirth", label: "Inpatient birth" },
    ],
  },
  {
    icon: PsychologyOutlined,
    title: "Mental health",
    properties: [
      { propertyName: "outpatientMentalHealth", label: "Therapy" },
      { propertyName: "inpatientMentalHealth", label: "Inpatient stay" },
    ],
  },
  {
    icon: TollOutlined,
    title: "Emergency care",
    properties: [
      { propertyName: "emergencyRoom", label: "Emergency room" },
      { propertyName: "urgentCare", label: "Urgent care" },
    ],
  },
]

interface ComparePlansProps {
  plansToCompare: Plan[]
  allowanceCents: Cents
  clickedPlanId: string
  isAutoPay: boolean
  isSelectingPlan: boolean
  filterPreferences: FilterPreferences
  selectedPlan?: Plan
  handlePlanSelect: (plan: Plan) => void
}

const ComparePlansDesktop = ({
  plansToCompare,
  allowanceCents,
  isAutoPay,
  isSelectingPlan,
  clickedPlanId,
  filterPreferences,
  selectedPlan,
  handlePlanSelect,
}: ComparePlansProps) => (
  <>
    <DynamicComparisonSectionContainer
      plansToCompare={plansToCompare}
      renderColumnContent={plan => (
        <ComparePlanDesktopCard
          plan={plan}
          allowanceCents={allowanceCents}
          handlePlanSelect={() => handlePlanSelect(plan)}
          isSelectingPlan={isSelectingPlan}
          isSelected={selectedPlan?.id === plan.id}
          isSelectingThisPlan={clickedPlanId === plan.id}
          isAutoPay={isAutoPay}
          filterPreferences={filterPreferences}
        />
      )}
      showDivider={false}
    />
    <DynamicComparisonSectionContainer
      plansToCompare={plansToCompare}
      renderColumnContent={plan => (
        <>
          <Typography data-qa={createDataQa(dataQa, "person-deductible")}>
            {formatCents(plan.individualMedicalDeductibleAmountCents)} / person
          </Typography>
          <Typography pt={3} data-qa={createDataQa(dataQa, "family-deductible")}>
            {formatCents(plan.familyMedicalDeductibleAmountCents)} / family
          </Typography>
        </>
      )}
      icon={<TitleIcon IconComponent={PaidOutlined} />}
      title="Deductible"
    />

    {comparisonSections.map(currentSection => (
      <ComparisonSectionContainer
        key={currentSection.title}
        plansToCompare={plansToCompare}
        comparisonSection={currentSection}
      />
    ))}
  </>
)

const flexColumnStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 2,
  mt: 4,
}

const mobileCardDataQa = createDataQa(dataQa, "mobile-card")

interface MobileComparisonCardProps {
  plan: Plan
  allowanceCents: Cents
  scrollPosition: number
  isAutoPay: boolean
  isSelectingPlan: boolean
  isSelected: boolean
  isSelectingThisPlan: boolean
  filterPreferences: FilterPreferences
  handlePlanSelect: () => void
  setScrollPosition: (x: number) => void
}

const MobileComparisonCard = ({
  plan,
  allowanceCents,
  scrollPosition,
  isAutoPay,
  isSelected,
  isSelectingPlan,
  isSelectingThisPlan,
  filterPreferences,
  handlePlanSelect,
  setScrollPosition,
}: MobileComparisonCardProps) => {
  const {
    displayName,
    level,
    logoUrl,
    premiumAmountCents,
    individualMedicalDeductibleAmountCents,
    familyMedicalDeductibleAmountCents,
    carrier,
    planType,
    onExchange,
    offExchange,
    providers,
    coverages,
  } = plan

  const { doctorPreferences, hospitalPreferences, drugPreferences } = filterPreferences

  const uniqueProviders = dedupe(providers)
  const uniqueDrugs = dedupe(coverages)

  const doctorsFound = uniqueProviders
    .map(doctor => {
      const doctorResult = doctorPreferences?.find(pref => pref.npi === doctor.npi)
      return doctorResult ? { ...doctorResult, covered: doctor.inNetwork } : undefined
    })
    .filter((doctorPreference): doctorPreference is WithCoverage<DoctorPreference> => !!doctorPreference)

  const hospitalsFound = uniqueProviders
    .map(hospital => {
      const hospitalResult = hospitalPreferences?.find(pref => pref.npi === hospital.npi)
      return hospitalResult ? { ...hospitalResult, covered: hospital.inNetwork } : undefined
    })
    .filter((hospitalPreference): hospitalPreference is WithCoverage<HospitalPreference> => !!hospitalPreference)

  const drugsFound = uniqueDrugs
    .map(drug => {
      const drugResult = drugPreferences?.find(pref => pref.medId.toString() === drug.drugPackageId)
      return drugResult ? { ...drugResult, covered: drug.tier !== DRUG_TIERS.NOT_COVERED } : undefined
    })
    .filter((drugPreference): drugPreference is WithCoverage<DrugPreference> => !!drugPreference)

  const levelColor = getColorByMetal(level)
  const { balanceLabel, balanceValue, balanceLabelColor } = getPlanBalance(allowanceCents, premiumAmountCents)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollPosition
    }
  }, [scrollPosition])

  return (
    <>
      <PlanDetailsDrawer
        open={showDetailsDrawer}
        handleClose={() => setShowDetailsDrawer(false)}
        plan={plan}
        handlePlanSelect={noop}
        isSelectingPlan={false}
        isComparing
        uniqueDoctors={doctorsFound}
        uniqueHospitals={hospitalsFound}
        uniqueDrugs={drugsFound}
      />
      <Card
        data-qa={mobileCardDataQa}
        sx={{
          border: "2px solid",
          borderColor: levelColor,
          borderRadius: "0.5rem",
          overflow: "visible",
        }}
      >
        <CardContent>
          <Box sx={flexColumnStyle}>
            <CarrierLogo
              carrierName={carrier.name}
              logoUrl={logoUrl}
              sx={{ height: "1.5rem" }}
              data-qa={createDataQa(mobileCardDataQa, "logo")}
            />
            <Typography data-qa={createDataQa(mobileCardDataQa, "name")} variant="h5">
              {displayName}
            </Typography>

            <Typography variant="h5" color="primary" data-qa={createDataQa(mobileCardDataQa, "premium-amount")}>
              {formatCents(premiumAmountCents)}
              <PerMonthSpan />
            </Typography>

            <Typography mt={2} gutterBottom data-qa={createDataQa(mobileCardDataQa, "balance")}>
              {`${balanceLabel}: `}
              <Typography component="span" color={balanceLabelColor}>
                {balanceValue}
                /mo
              </Typography>
            </Typography>
          </Box>

          <Box
            ref={scrollRef}
            onScroll={(e: any) => {
              if (scrollRef.current) {
                setScrollPosition(e.target.scrollLeft)
              }
            }}
            sx={{ overflowX: "auto" }}
          >
            <Box sx={{ width: "max-content", display: { xs: "block", lg: "flex" }, gap: 8, py: 6 }}>
              <Box width="16rem" sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column", gap: 2 }}>
                <Typography variant="body1bold" data-qa={createDataQa(mobileCardDataQa, "enrollment-type")}>
                  Enrollment type
                </Typography>
                <Box>
                  <PlanDetailsChips
                    level={level}
                    planType={planType ?? ""}
                    data-qa={mobileCardDataQa}
                    enrollmentType={carrier.enrollment}
                    payLater={carrier.payLater}
                    onExchange={onExchange}
                    limitHeight={false}
                    offExchange={offExchange}
                    isAutoPay={isAutoPay}
                  />
                </Box>

                <Typography variant="body1bold" mt={2} data-qa={createDataQa(mobileCardDataQa, "deductible")}>
                  Deductible
                </Typography>
                <Typography data-qa={createDataQa(mobileCardDataQa, "person-deductible")}>
                  {formatCents(individualMedicalDeductibleAmountCents)} / person
                </Typography>
                <Typography data-qa={createDataQa(mobileCardDataQa, "family-deductible")}>
                  {formatCents(familyMedicalDeductibleAmountCents)} / family
                </Typography>
              </Box>

              {comparisonSections.map(({ title, properties }) => (
                <Box key={title} sx={{ display: "flex", flexDirection: "column", gap: 2, width: "24rem", mt: "2rem" }}>
                  <Typography variant="body1bold" data-qa={createDataQa(mobileCardDataQa, "section-title")}>
                    {title}
                  </Typography>
                  <NetworkTitle />
                  {properties.map(({ propertyName, label }) => {
                    const rawValue = plan.rawData[propertyName]
                    const { inNetworkValue, outOfNetworkValue } = getInAndOutOfNetworkValues(rawValue)

                    return (
                      <Typography
                        key={label}
                        data-qa={createDataQa(mobileCardDataQa, "section-property")}
                      >{`${label}: ${inNetworkValue} / ${outOfNetworkValue}`}</Typography>
                    )
                  })}
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={flexColumnStyle}>
            <ChoosePlanButton
              dataQa={createDataQa(mobileCardDataQa, "choose-button")}
              isSelectingPlan={isSelectingPlan}
              isSelectingThisPlan={isSelectingThisPlan}
              handleClick={handlePlanSelect}
            />
            <Button
              variant="outlined"
              onClick={() => setShowDetailsDrawer(true)}
              data-qa={createDataQa(mobileCardDataQa, "details-button")}
            >
              View details
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

const ComparePlansMobile = ({
  plansToCompare,
  allowanceCents,
  clickedPlanId,
  isAutoPay,
  isSelectingPlan,
  selectedPlan,
  filterPreferences,
  handlePlanSelect,
}: ComparePlansProps) => {
  const [scrollPosition, setScrollPosition] = useState(0)

  return (
    <>
      <Grid item xs={12} mb={8}>
        <WarningAlert data-qa={createDataQa(dataQa, "mobile-alert")}>
          For the best comparison experience we recommend using desktop.
        </WarningAlert>
      </Grid>
      <Grid container spacing={4} data-qa={createDataQa(dataQa, "mobile")}>
        {plansToCompare.map(plan => (
          <Grid item xs={12} key={plan.id}>
            <MobileComparisonCard
              plan={plan}
              allowanceCents={allowanceCents}
              scrollPosition={scrollPosition}
              setScrollPosition={setScrollPosition}
              handlePlanSelect={() => handlePlanSelect(plan)}
              isSelectingPlan={isSelectingPlan}
              isSelected={selectedPlan?.id === plan.id}
              isSelectingThisPlan={clickedPlanId === plan.id}
              isAutoPay={isAutoPay}
              filterPreferences={filterPreferences}
            />
          </Grid>
        ))}
      </Grid>{" "}
    </>
  )
}

export const ComparePlans = () => {
  const { user } = useAuth()
  const companyId = user?.company?.companyId as Uuid

  const { isAutoPay } = useIsCompanyAutoPay(companyId)
  const navigate = useNavigate()
  const shoppingUrl = useShoppingUrl()
  const materialTheme = useTheme()
  const isDesktop = useMediaQuery(materialTheme.breakpoints.up("md"))
  const { isSelectingPlan, clickedPlanId, selectPlan } = useSelectPlan()

  const plansToCompare = useBenefitsElectionStore(state => state.plansToCompare)
  const allowance = useBenefitsElectionStore(state => state.allowance)!
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)
  const benefitsElectionState = useBenefitsElectionStore(state => state)

  const ComparePlansComponent = isDesktop ? ComparePlansDesktop : ComparePlansMobile
  const amountOfPlansToCompare = plansToCompare.length
  const filterPreferences = createRecommendedFilterPreferences(benefitsElectionState)

  if (amountOfPlansToCompare < 2) {
    return <Navigate to={shoppingUrl + RECOMMENDED_PLANS} />
  }

  return (
    <Grid container sx={{ bgcolor: "colors.white", py: { xs: 6, lg: 8 }, px: { xs: 6, md: 4, lg: 40 } }}>
      <Grid item>
        <ReturnButton
          data-qa={createDataQa(dataQa, "back-button")}
          text="Plan Selection"
          handleClose={() => navigate(-1)}
        />
      </Grid>
      <Grid item xs={12} mt={8}>
        <Typography variant="h1" gutterBottom data-qa={createDataQa(dataQa, "title")}>
          Comparison tool
        </Typography>
      </Grid>
      <Grid item xs={12} mb={8}>
        <Typography data-qa={createDataQa(dataQa, "description")}>
          Choosing a health plan is a balancing act between the costs you definitely have to pay each month (
          {PLAN_PREMIUM_LABEL.toLowerCase()}) vs. costs you might have to pay if you need care (your prescriptions,
          doctor visits, etc).
        </Typography>
      </Grid>
      <ComparePlansComponent
        plansToCompare={plansToCompare}
        allowanceCents={allowance.amountCents}
        handlePlanSelect={selectPlan}
        isSelectingPlan={isSelectingPlan}
        clickedPlanId={clickedPlanId}
        selectedPlan={selectedPlan}
        isAutoPay={isAutoPay}
        filterPreferences={filterPreferences}
      />
    </Grid>
  )
}
