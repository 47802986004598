import { useNotifications } from "@/services/notificationService"
import { Uuid } from "@/utils/types"
import { sendSignUpLinkPersonId } from "../peopleManagementEndpoints"

const SIGN_UP_LINK_SENT_SUCCESS = "Invite email sent successfully"
const SIGN_UP_LINK_INVALID_PARAMS = "Failed to create the request. Please try again."
const SIGN_UP_LINK_SENT_ERROR = "Failed to send the invite email. Please try again."

export const useSignUpLink = () => {
  const { notify } = useNotifications("email-information")

  const handleSendSignUpLink = async (companyId: Uuid | undefined, personId: Uuid | undefined) => {
    if (!companyId || !personId) {
      notify(SIGN_UP_LINK_INVALID_PARAMS, "error")
      return
    }

    try {
      await sendSignUpLinkPersonId(companyId, personId)
      notify(SIGN_UP_LINK_SENT_SUCCESS, "success")
    } catch {
      notify(SIGN_UP_LINK_SENT_ERROR, "error")
    }
  }

  return { handleSendSignUpLink }
}
