import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import { useState } from "react"
import { COMPLETE, RECURRING_REIMBURSEMENT } from "../../benefitsElectionConstants"
import { useShoppingUrl } from "../../benefitsElectionService"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { CoverageWarning } from "../../components/CoverageWarning"
import { usePurchasePlan } from "../../hooks/usePurchasePlan"

export const FinalCheck = () => {
  const shoppingUrl = useShoppingUrl()
  const previous = shoppingUrl + RECURRING_REIMBURSEMENT
  const next = shoppingUrl + COMPLETE
  const [removedTaxCredits, setRemovedTaxCredits] = useState(false)
  const { purchasePlan } = usePurchasePlan("final-check")

  return (
    <BenefitsElectionStep
      title="You will not be able to make changes to your plan after this point"
      description="By continuing to the next page, you will lock your choices of health plan and coverages. Please make sure all your information is accurate before continuing."
      required
      previous={previous}
      next={next}
      advanceOnSuccess
      disabled={!removedTaxCredits}
      handleContinue={async () => {
        await purchasePlan()
      }}
    >
      <CoverageWarning />
      <FormGroup sx={{ mt: 10 }}>
        <FormControlLabel
          label="I acknowledge my plan will be locked after this point and I will not be able to go back to change it."
          control={
            <Checkbox
              checked={removedTaxCredits}
              onClick={() => setRemovedTaxCredits(!removedTaxCredits)}
              data-qa="tax-credits-checkbox"
            />
          }
        />
      </FormGroup>
    </BenefitsElectionStep>
  )
}
