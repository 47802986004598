import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { HraPlanModel } from "./planSetupTypes"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PickRequired, Uuid } from "@/utils/types"

const baseApiUrl = appConfig.baseApiUrl

export const getHraPlan = async (companyId: Uuid) => {
  try {
    const plan = (await axios.get(`${baseApiUrl}/benefits/v1/companies/${companyId}/plans`)).data as HraPlanModel[]

    return plan
  } catch {
    console.warn("Failed to get plan for company id: companyId=" + companyId)
  }
}

export const createHraPlan = async (hraPlan: Partial<HraPlanModel>) => {
  const newCompany = (await axios.post(`${baseApiUrl}/benefits/v1/companies/${hraPlan.companyId}/plans`, hraPlan))
    .data as HraPlanModel

  return newCompany
}

// FUTURE: Consider using PickRequired here
/** {@link PickRequired} */

export const updateHraPlan = async (hraPlan: Partial<HraPlanModel>, hraPlanId: Uuid) => {
  hraPlan.id = hraPlanId
  const updatedCompany = (
    await axios.patch(`${baseApiUrl}/benefits/v1/companies/${hraPlan.companyId}/plans/${hraPlanId}`, hraPlan)
  ).data as HraPlanModel

  return updatedCompany
}
