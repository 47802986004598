import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import { PeopleProfileDrawer } from "@/features/People/components/PeopleProfileDrawer"
import { UsersSearch } from "@/features/TCHub/components/UsersSearch"
import { TcHubUserTable } from "@/features/TCHub/Users/components/TcHubUserTable"
import { TcHubAddPersonModal } from "@/features/TCHub/Users/TcHubAddPersonModal/TcHubAddPersonModal" //
import { Uuid } from "@/utils/types"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import { Button, Grid, Typography } from "@mui/material"
import { useState } from "react"

const PageContent = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [personProfileId, setPersonProfileId] = useState<Uuid>()
  const [selectedPersonCompanyId, setSelectedPersonCompanyId] = useState<Uuid>()
  const [isAddingPerson, setIsAddingPerson] = useState(false)

  return (
    <>
      <Grid container display="grid">
        <Grid item xs={12} mb={5}>
          <Typography variant="h1" gutterBottom display="inline" data-qa="page-header">
            Users
          </Typography>
        </Grid>

        <Grid container alignItems="center" justifyContent="space-between" spacing={8}>
          <Grid item sx={{ flexGrow: 1 }}>
            <UsersSearch />
          </Grid>
          <Grid item>
            <Button
              data-qa="add-person-button"
              variant="contained"
              onClick={() => setIsAddingPerson(true)}
              startIcon={<AddOutlinedIcon />}
            >
              Add new
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={4}>
        <TcHubUserTable
          setOpen={setOpen}
          setPersonProfileId={setPersonProfileId}
          setCompanyId={setSelectedPersonCompanyId}
        />
      </Grid>
      <PeopleProfileDrawer
        open={open}
        setOpen={setOpen}
        personProfileId={personProfileId}
        companyId={selectedPersonCompanyId}
      />
      {isAddingPerson && <TcHubAddPersonModal onClose={() => setIsAddingPerson(false)} />}
    </>
  )
}

export const TcHubUsersPage = () => (
  <TcHubGuard requiredPermissions={["tc_hub_users"]}>
    <PageContent />
  </TcHubGuard>
)
