import { ALL_COMPANY_ACCESS_ID } from "@/constants"
import { CompanyId, Uuid } from "@/utils/types"
import { useContext } from "react"
import { AuthContext } from "./components/CognitoContext"

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) throw new Error("AuthContext must be placed within AuthProvider")

  const { isAuthenticated, isInitialized } = context
  const isLoggedIn = isAuthenticated && isInitialized

  return { ...context, isLoggedIn }
}

export const useCompanyId = <T extends boolean, R extends T extends true ? CompanyId | undefined : Uuid | undefined>(
  includeStar: T
) => {
  const { user } = useAuth()
  const companyId = user?.company?.companyId

  if (companyId === ALL_COMPANY_ACCESS_ID && !includeStar) {
    return
  }

  return companyId as R
}

export const useCompanyEmployment = () => {
  const { user } = useAuth()
  const companyId = useCompanyId(false)
  const employmentId = user?.company?.employeeId

  if (!companyId) throw new Error("CompanyId missing")
  if (!employmentId) throw new Error("EmployeeId missing")

  return { companyId, employmentId }
}
