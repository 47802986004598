import { Card, CardProps, Divider, Grid, Typography, TypographyProps } from "@mui/material"
import { isNil } from "lodash"
import { ReactNode } from "react"
import { Typographify } from "./Text"

export const StyledCard = ({ sx = {}, ...props }: CardProps) => (
  <Card sx={{ width: "100%", p: 6, mt: 5, ...sx }} {...props} />
)

interface InfoItem {
  label: string
  value: string
}

export const makeInfoItems = <
  const V extends Readonly<string>,
  const T extends Readonly<[Readonly<string>, V | null | undefined]>,
  const R extends { label: T[0]; value: V },
>(
  values: T[]
) => values.filter(x => !isNil(x[1])).map(([label, value]) => ({ label, value }) as R)

const getItemKey = ({ label, value }: InfoItem) => `${label}-${value}`

interface InfoCardItemProps extends InfoItem {
  topVariant?: TypographyProps["variant"]
  bottomVariant?: TypographyProps["variant"]
}

export const InfoCardItem = ({ label, value, topVariant = "caption", bottomVariant = "body1" }: InfoCardItemProps) => (
  <Grid item>
    <Typography variant={topVariant}>{label}</Typography>
    <Typography variant={bottomVariant}>{value}</Typography>
  </Grid>
)

interface InfoCardProps extends Omit<CardProps, "title"> {
  title: ReactNode
  items: InfoCardItemProps[]
}

export const InfoCard = ({ title, items, children, ...props }: InfoCardProps) => (
  <StyledCard {...props}>
    <Typographify variant="h5">{title}</Typographify>
    <Divider sx={{ my: 3 }} />
    <Grid container direction="column" spacing={4}>
      {items.map(itemProps => (
        <InfoCardItem key={getItemKey(itemProps)} {...itemProps} />
      ))}
      {children}
    </Grid>
  </StyledCard>
)
