import { MAX_CARDS_PER_ROW } from "@/constants"
import { Box, Checkbox, FormControl, FormGroup, Grid, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import { noop } from "lodash"

interface CheckBoxCardProps {
  title: string
  subtitle?: string
  name: string
  handleChange: (field: string, value: boolean) => void
  dataQa: string
  small?: boolean
  value?: boolean
  disabled?: boolean
}

export const CheckBoxCard = ({
  handleChange,
  dataQa,
  small,
  name,
  title,
  subtitle,
  value,
  disabled,
}: CheckBoxCardProps) => {
  const formik = useFormikContext<any>()
  const isSelected = value ?? formik?.values?.[name]

  let borderColor = "colors.borderGray"
  if (isSelected) {
    borderColor = disabled ? "colors.borderGray" : "colors.borderGreen"
  }

  let backgroundColor = "colors.regionGray"
  if (isSelected) {
    backgroundColor = disabled ? "action.disabledBackground" : "colors.lightTealShade"
  }

  let checkboxSize: "small" | "medium" = "medium"
  if (small) {
    checkboxSize = "small"
  }

  return (
    <Box
      onClick={() => {
        handleChange(name, !isSelected)
      }}
      data-qa={dataQa}
      sx={{
        color: disabled ? "text.disabled" : undefined,
        border: "1px solid",
        borderColor,
        borderRadius: "5px",
        backgroundColor,
        cursor: disabled ? "not-allowed" : "pointer",
        minHeight: small ? "3.125rem" : "5rem ",
        pl: 4,
        pr: isSelected ? 2 : 4,
        py: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="body1"> {title}</Typography>
        <Typography variant="caption" sx={{ display: "block", lineHeight: "1.162rem" }}>
          {subtitle}
        </Typography>
      </Box>
      {isSelected && (
        <Checkbox color="primary" data-qa={dataQa} size={checkboxSize} checked={isSelected} disabled={disabled} />
      )}
    </Box>
  )
}

interface CheckBoxGroupCardProps {
  elements: { title: string; value: string }[]
  formName: string
  small?: boolean
  handleChange: (field: string, value: boolean) => void
  error: boolean
  disabled?: boolean
  label?: string
  values?: Record<string, boolean>
}

export const CheckBoxGroupCard = ({
  small,
  formName,
  elements,
  error,
  handleChange,
  disabled = false,
  label,
  values,
}: CheckBoxGroupCardProps) => {
  const spacePerCard = elements.length <= MAX_CARDS_PER_ROW ? 12 / elements.length : 12 / MAX_CARDS_PER_ROW

  return (
    <Grid item xs={12} minHeight={small ? "" : "4.75rem"}>
      <FormControl required error={error} component="fieldset" variant="standard" fullWidth disabled={disabled}>
        {label && (
          <Typography variant="body1bold" mb={4} data-qa={`${formName}-label`}>
            {label}
          </Typography>
        )}
        <FormGroup>
          <Grid container spacing={4}>
            {elements.map(element => (
              <Grid item xs={12} sm={6} md={spacePerCard} key={element.value}>
                <CheckBoxCard
                  key={element.value}
                  title={element.title}
                  name={element.value}
                  handleChange={disabled ? noop : handleChange}
                  dataQa={`${formName}-${element.value}-checkBoxCard`}
                  small={small}
                  value={values?.[element.value]}
                  disabled={disabled}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </FormControl>
    </Grid>
  )
}
