import { useCheckCoverageForAllMembers } from "../benefitsElectionService"
import { useBenefitsElectionStore } from "../benefitsElectionStore"
import { WarningAlert } from "./BenefitsElectionAlerts"

export const CoverageWarning = () => {
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const { data: checkCoverageForAllResponse } = useCheckCoverageForAllMembers(currentShoppingSession.id)
  const showCoverageWarning = checkCoverageForAllResponse && !checkCoverageForAllResponse.isAllMembersHaveCoverage

  if (!showCoverageWarning) return null

  return (
    <WarningAlert data-qa="medicaid-alert">
      All shopping family members must be enrolled in a medical plan to be elligible for the full allowance.
    </WarningAlert>
  )
}
