import { DatePickerField } from "@/components/DatePickerField"
import { StyledCard } from "@/components/StyledCard"
import { ClampedTextField } from "@/components/TextFields"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { useNotifications } from "@/services/notificationService"
import { colors, takeCommandPrimary } from "@/theme/palette"
import { formatDateToMmDdYyyy, formatDateToYyyyMmDd, getLastDayOfMonth } from "@/utils/dates"
import { Uuid } from "@/utils/types"
import { Button, Grid, TextField, Typography } from "@mui/material"
import { Formik } from "formik"
import { Dispatch, SetStateAction } from "react"
import * as Yup from "yup"
import { useTerminatePerson } from "../peopleService"
import { PersonModel } from "../peopleTypes"

export interface PeopleDrawerTerminateProps {
  isTerminateOpen: boolean
  setIsTerminateOpen: Dispatch<SetStateAction<boolean>>
  personProfileId: Uuid | undefined
  person: PersonModel
  company: CompanyModel
}

const currentDate = new Date()
currentDate.setHours(0, 0, 0, 0)
const currentDatePlus60Days = new Date(currentDate)
currentDatePlus60Days.setDate(currentDate.getDate() + 60)

const dateErrorMessage = `Please select a date between ${formatDateToMmDdYyyy(currentDate)} and ${formatDateToMmDdYyyy(currentDatePlus60Days)}`
const TERMINATE = "TERMINATE"
const TERMINATE_REQUIRED_MESSAGE = `You must type in ${TERMINATE}`

const terminatePersonInitialValues = {
  terminationDate: currentDate,
  terminationConfirmation: "",
}

const terminatePersonValidationSchema = Yup.object().shape({
  terminationDate: Yup.date()
    .min(currentDate, dateErrorMessage)
    .max(currentDatePlus60Days, dateErrorMessage)
    .required(),
  terminationConfirmation: Yup.string()
    .equals([TERMINATE], TERMINATE_REQUIRED_MESSAGE)
    .required(TERMINATE_REQUIRED_MESSAGE),
})

export const PeopleDrawerTerminate = ({
  isTerminateOpen,
  setIsTerminateOpen,
  personProfileId,
  person,
  company,
}: PeopleDrawerTerminateProps) => {
  const { notify } = useNotifications("terminate-person")
  const { mutateAsync: terminatePerson, isPending: isTerminatingPersonLoading } = useTerminatePerson(
    company?.id,
    personProfileId
  )

  return (
    <Formik
      initialValues={terminatePersonInitialValues}
      validationSchema={terminatePersonValidationSchema}
      onSubmit={async values => {
        try {
          terminatePerson(formatDateToYyyyMmDd(values.terminationDate))
          setIsTerminateOpen(false)
          notify("Successfully terminated user", "success")
        } catch (error) {
          const errorMessage = "Error terminating user"
          console.error(errorMessage, error)
          notify(`${errorMessage}`, "error")
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Grid container item spacing={4}>
            <Grid container item>
              <StyledCard>
                <Grid item xs={12} pb={2}>
                  <Typography variant="h5">Employee information</Typography>
                </Grid>
                <Grid container item xs={12} py={4}>
                  <Grid item xs={6} pr={1}>
                    <TextField
                      data-qa="firstName"
                      name="firstName"
                      label="First Name"
                      variant="standard"
                      value={person?.firstName}
                      required
                      fullWidth
                      aria-readonly
                      InputLabelProps={{
                        style: { color: takeCommandPrimary.main },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      data-qa="lastName"
                      name="lastName"
                      label="Last Name"
                      variant="standard"
                      value={person?.lastName}
                      required
                      fullWidth
                      aria-readonly
                      InputLabelProps={{
                        style: { color: takeCommandPrimary.main },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-qa="email"
                    name="email"
                    label="Email"
                    variant="standard"
                    value={person?.email}
                    required
                    fullWidth
                    aria-readonly
                    InputLabelProps={{
                      style: { color: takeCommandPrimary.main },
                    }}
                  />
                </Grid>
              </StyledCard>
            </Grid>

            <Grid container item xs={12}>
              <StyledCard>
                <Grid item>
                  <Typography variant="h5">Schedule termination</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    Benefits will be discontinued at the end of the termination month.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DatePickerField
                    name="terminationDate"
                    fullWidth
                    minDate={currentDate}
                    maxDate={currentDatePlus60Days}
                    value={values.terminationDate}
                    onChange={handleChange}
                    onClick={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.terminationDate && errors.terminationDate)}
                    helperText={
                      touched.terminationDate &&
                      values.terminationDate &&
                      (errors.terminationDate
                        ? dateErrorMessage
                        : !errors.terminationDate &&
                          `Benefits will be discontinued on ${formatDateToMmDdYyyy(getLastDayOfMonth(formatDateToYyyyMmDd(new Date(values.terminationDate))))}`)
                    }
                  />
                </Grid>
              </StyledCard>
            </Grid>

            <Grid container item xs={12}>
              <StyledCard>
                <Grid item>
                  <Typography variant="caption">
                    Removing an employee
                    <b> will remove all of their information from the system and cannot be reversed.</b> Please proceed
                    with caution.
                  </Typography>
                  <ClampedTextField
                    name="terminationConfirmation"
                    variant="outlined"
                    placeholder={`Type in ${TERMINATE}`}
                    value={values.terminationConfirmation}
                    error={Boolean(touched.terminationConfirmation && errors.terminationConfirmation)}
                    helperText={touched.terminationConfirmation && errors.terminationConfirmation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    required
                    data-qa="confirmationValue"
                  />
                </Grid>
              </StyledCard>
            </Grid>
          </Grid>
          <Grid container item justifyContent="flex-end" spacing={2} mt="7rem">
            <Grid item>
              <Button
                variant="text"
                sx={{ color: colors.darkBody }}
                onClick={() => {
                  setIsTerminateOpen(false)
                }}
              >
                Close
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={() => handleSubmit}
                disabled={!(isValid && dirty) && !isTerminatingPersonLoading}
                type="submit"
                variant="contained"
                color="error"
              >
                Schedule Termination
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}
