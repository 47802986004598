import { SELF_ENROLL } from "@/constants"
import { useNotifications } from "@/services/notificationService"
import { transformDate } from "@/utils/formatting"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  BOTH_MARKETS,
  CARRIER_QUESTIONS,
  OFF_MARKET,
  ON_MARKET,
  PLAN_SELECTED,
  RECOMMENDED_PLANS,
  SELF_ENROLL_PURCHASE,
} from "../benefitsElectionConstants"
import { useShoppingSession, useShoppingUrl, useUpdateHealthBenefitsElection } from "../benefitsElectionService"
import { useBenefitsElectionStore } from "../benefitsElectionStore"
import { Plan, PlanView } from "../benefitsElectionTypes"

const getPlanMarket = (onExchange: boolean, offExchange: boolean) => {
  if (onExchange && offExchange) return BOTH_MARKETS

  if (onExchange) return ON_MARKET

  if (offExchange) return OFF_MARKET
}

export const useSelectPlan = (planView?: PlanView) => {
  const navigate = useNavigate()
  const { notify } = useNotifications("select-plan")
  const shoppingSessionId = useShoppingSession()
  const shoppingUrl = useShoppingUrl()
  const { mutateAsync: updateHealthBenefitsElection, isPending } = useUpdateHealthBenefitsElection(shoppingSessionId)
  const [clickedPlanId, setClickedPlanId] = useState("")
  const personalInformation = useBenefitsElectionStore(state => state.employee.personalInformation)
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const renewalHealthPlanId = useBenefitsElectionStore(state => state.renewalPlanId)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)
  const setSelectedPlan = useBenefitsElectionStore(state => state.setSelectedPlan)
  const setPlanView = useBenefitsElectionStore(state => state.setPlanView)
  const setHealthBenefitElection = useBenefitsElectionStore(state => state.setHealthBenefitElection)
  const getEnrollmentType = useBenefitsElectionStore(state => state.getEnrollmentType)
  const getEligibleApplicants = useBenefitsElectionStore(state => state.getEligibleApplicants)

  const setNextStep = (plan: Plan) => {
    const hasSelectedPlan = !!selectedPlan
    const enrollmentType = getEnrollmentType()!
    const selectedPlanHasDifferentCarrier = hasSelectedPlan && selectedPlan.carrierName !== plan.carrierName
    const currentStepIsPlans = currentStep === RECOMMENDED_PLANS
    const nextStep = enrollmentType === SELF_ENROLL ? SELF_ENROLL_PURCHASE : CARRIER_QUESTIONS

    if (!hasSelectedPlan || currentStepIsPlans || selectedPlanHasDifferentCarrier) {
      setCurrentStep(nextStep)
      // FUTURE: invalidate carrier questions if there were filled previously
    }

    return nextStep
  }

  const selectPlan = async (plan: Plan) => {
    try {
      if (planView) {
        setPlanView(planView)
      }

      setClickedPlanId(plan.id)
      const currentBenefitElection = currentShoppingSession.healthBenefitElections[0]
      const lastDayOfPlanYear = new Date(currentShoppingSession.planYear, 11, 31)

      const {
        id: healthPlanId,
        name: healthPlanName,
        planType: healthPlanType,
        carrier,
        premiumAmountCents,
        hsaEligible,
        onExchange,
        offExchange,
        cutOffDate,
      } = plan

      const updatedPlanBenefitsElection = {
        id: currentBenefitElection.id,
        employmentId: currentBenefitElection.employmentId,
        healthPlanId,
        healthPlanName,
        healthPlanType,
        carrierId: carrier?.id,
        carrierName: carrier?.name,
        ...(personalInformation?.zipCode?.state && { state: personalInformation?.zipCode?.state }),
        premiumAmountCents,
        enrollmentStatus: PLAN_SELECTED,
        enrollmentType: carrier.enrollment,
        isHsaQualified: hsaEligible,
        planMarket: getPlanMarket(onExchange, offExchange),
        shoppingPersons: getEligibleApplicants().map(applicant => ({ id: applicant.shoppingPersonId })),
        cutOffDate,
        planEndDate: transformDate(lastDayOfPlanYear),
        renewalHealthPlanId,
      } as const

      const updatedBenefitElection = await updateHealthBenefitsElection(updatedPlanBenefitsElection)

      setHealthBenefitElection(updatedBenefitElection)

      setSelectedPlan(plan)
      const nextStep = setNextStep(plan)

      navigate(shoppingUrl + nextStep)
    } catch (error: any) {
      notify(
        "Error selecting plan. Please try again later. If the issue persists, contact support for assistance.",
        "error"
      )
      console.error(error.message)
    }
  }

  return { isSelectingPlan: isPending, clickedPlanId, selectPlan }
}
