import { ClearableSearchInput } from "@/components/ClearableSearchInput/ClearableSearchInput"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable } from "@/components/Table/Table"
import { TABLE_CELL_PADDING } from "@/constants"
import { useCompanies, useRegistrationTracking } from "@/features/TCHub/tcHubService"
import { RegistrationTrackingModel, RegistrationTrackingTableHeader } from "@/features/TCHub/tcHubTypes"
import { toTitleCase } from "@/utils/formatting"
import { Grid, TableCell, Typography } from "@mui/material"
import { constant } from "lodash"
import { useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { TcHubGuard } from "../../../Auth/guards/TcHubGuard"
import { CompanyModel } from "../../../CreateCompany/createCompanyEndpoints"

const headers: RegistrationTrackingTableHeader[] = [
  { id: "companyName", label: "Company Name", sortable: true, field: "companyName", alignment: "left" },
  {
    id: "registrationStatus",
    label: "Registration Status",
    sortable: true,
    field: "registrationStatus",
    alignment: "left",
  },
  {
    id: "microDepositStatus",
    label: "Micro Deposit Status",
    sortable: true,
    field: "microDepositStatus",
    alignment: "left",
  },
]

export const CompanySet = (companies: CompanyModel[]) => {
  const companySet = new Map<string, string>()

  companies.forEach(company => {
    companySet.set(company.id, company.companyInfo.companyName)
  })

  return companySet
}

const matchAccountName = (registrationTracking: RegistrationTrackingModel, searchQueryLowerCase: string) =>
  (registrationTracking.name ?? "").toLowerCase().includes(searchQueryLowerCase)

const matchCompanyName = (
  registrationTracking: RegistrationTrackingModel,
  companyId: string,
  companySet: Map<string, string>,
  searchQueryLowerCase: string
) => companySet.get(companyId)?.toLowerCase().includes(searchQueryLowerCase)

export const TcHubOnboardingRegistrationTrackingPage = () => {
  const navigate = useNavigate()
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(25)

  const { data: registrationTrackingItems, isLoading: isRegistrationTrackingLoading } = useRegistrationTracking()
  const { data: companies, isLoading: isCompaniesLoading } = useCompanies()
  const companySet = useMemo(() => CompanySet(companies ?? []), [companies])

  const filteredRegistrationTracking = useMemo(
    () =>
      (registrationTrackingItems ?? [])
        .filter(registrationTracking => {
          const searchQueryLowerCase = searchInputValue.toLowerCase()

          return (
            matchAccountName(registrationTracking, searchQueryLowerCase) ||
            matchCompanyName(registrationTracking, registrationTracking.companyId, companySet, searchQueryLowerCase)
          )
        })
        .map(registrationTracking => ({
          ...registrationTracking,
          companyName: companySet.get(registrationTracking.companyId),
        })),
    [registrationTrackingItems, searchInputValue, companySet]
  )

  return (
    <TcHubGuard requiredPermissions={["tc_hub_autopay"]}>
      <Grid container data-qa="tc-hub-registration-tracking-page">
        <Helmet title="TC Hub Registration Tracking" />
        <Grid item xs={12} mb={5}>
          <Typography variant="h1" data-qa="registration-tracking" gutterBottom>
            Registration Tracking
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ClearableSearchInput
            onChange={event => setSearchInputValue(event.target.value)}
            handleClearClick={() => setSearchInputValue("")}
            data-qa="registration-tracking-search-input"
          />
        </Grid>
        {!isCompaniesLoading && !isRegistrationTrackingLoading ? (
          <BaseTable
            rows={filteredRegistrationTracking}
            selected={[]}
            searchCriteria=""
            onToggleSelect={() => []}
            onToggleOrderBy={() => []}
            onToggleSelectAll={() => []}
            onPageChange={changedPage => setPage(changedPage)}
            onRowsPerPageChange={rows => setRowsPerPage(rows)}
            uniqueIdSelector={constant("")}
            headCells={headers ?? []}
            rowsPerPage={rowsPerPage}
            page={page}
            fullWidth
            orderBy={[{ headCellId: "", direction: "asc" }]}
            onRowClick={row => navigate(`${row.id}`)}
            exportCsv
            csvTitle="Registration Tracking"
          >
            {({ row }) => (
              <>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="funding-entity-name">
                    {companySet.get(row.companyId)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="registration-status">
                    {row.registrationStatus ? toTitleCase(row.registrationStatus) : ""}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                    {row.microDepositStatus ? toTitleCase(row.microDepositStatus) : ""}
                  </Typography>
                </TableCell>
              </>
            )}
          </BaseTable>
        ) : (
          <SkeletonTableLoader
            data-qa="skeleton-table-loader-registration-tracking"
            headerTitles={["Company Name", "Registration Status", "Micro Deposit Status"]}
            bodyRowsCount={10}
          />
        )}
      </Grid>
    </TcHubGuard>
  )
}
