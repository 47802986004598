import { PLAN_PREMIUM_LABEL } from "@/constants"
import { InsuranceType } from "@/features/BenefitsElection/benefitsElectionTypes"
import { PlanLevelChip, PlanTypeChip } from "@/features/BenefitsElection/components/PlanComponents"
import { renderInsuranceType } from "@/features/BenefitsElection/pages/purchase/OtherPremiums"
import { RecurringPremium, RecurringPremiumFamilyMember } from "@/features/BenefitsElection/recurringPremiumsTypes"
import { useGetPlanByIdAndYear } from "@/features/TCHub/tcHubService"
import { colors, takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { createDateFromText, formatCents, formatDate } from "@/utils/formatting"
import { Box, Divider, Grid, Typography } from "@mui/material"
import { WidgetLayout } from "./WidgetLayout"

const EMPTY_MONTHLY_PREMIUM = "$--"
const NOT_AVAILABLE_DATE = "N/A"
const EMPLOYEE = "EMPLOYEE"

const getCarrierLogoUrl = (data?: string) => data ?? ""
const getPlanName = (data?: string) => data ?? ""
const getPlanLevel = (data?: string) => data ?? ""
const getPlanType = (data?: string) => data ?? ""
const getInsuranceType = (data?: InsuranceType) => (data ? renderInsuranceType(data) : "")
const getStartDate = (data?: string) => data ?? ""
const getEndDate = (data?: string) => data ?? ""

const getMissingHealthPlanInfo = (data: string) =>
  getPlanName(data) || getPlanLevel(data) || getPlanType(data) || getCarrierLogoUrl(data)

const getDisplayPremium = (data: number) => (data ? formatCents(data) : EMPTY_MONTHLY_PREMIUM)

const getFamilyMembers = (data: RecurringPremiumFamilyMember[] | undefined) => data || []

interface RecurringPremiumCardProps {
  isLoading: boolean
  data: RecurringPremium
  planYear: string | number
}

export const RecurringPremiumCard = ({ data, isLoading, planYear }: RecurringPremiumCardProps) => {
  const { data: planDetails, isLoading: isLoadingPlan } = useGetPlanByIdAndYear(data.healthPlanId, planYear)

  const carrierLogoUrl = planDetails?.carrier.logoUrl
  const planLevel = planDetails?.level
  const planType = planDetails?.planType

  const familyMembers = getFamilyMembers(data.coveredFamilyMembers)
  const startDate = getStartDate(data.coverageStartDate)
  const endDate = getEndDate(data.coverageEndDate)
  const employee = familyMembers.find(f => f.relationship === EMPLOYEE)
  const employeeName = employee ? `${employee.firstName} ${employee.lastName}` : ""
  const missingHealthPlanInfo = getMissingHealthPlanInfo(data.healthPlanName)
  const isPrimary = data.isPrimary

  const displayPremium = getDisplayPremium(data.premiumAmountCents)

  return (
    <WidgetLayout
      key={data.healthBenefitId}
      isLoading={isLoading || isLoadingPlan}
      data-qa={createDataQa(isPrimary ? "primary" : "secondary", "card")}
    >
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <Typography variant="h2small" data-qa="health-insurance-title">
            Health insurance
          </Typography>
        </Grid>
        {missingHealthPlanInfo && (
          <Grid item xs={12}>
            <Box
              sx={{
                minHeight: "5.5rem",
                maxHeight: "5.5rem",
              }}
            >
              <img
                src={carrierLogoUrl ?? ""}
                alt={`${data.healthPlanName} logo`}
                style={{
                  width: "5.625rem",
                  objectFit: "contain",
                  minHeight: "5.5rem",
                  maxHeight: "5.5rem",
                }}
                data-qa={createDataQa("card", "logo")}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h2small" data-qa={createDataQa(isPrimary ? "plan-name" : "insurance-type")}>
            {isPrimary ? getPlanName(data.healthPlanName) : getInsuranceType(data.insuranceType)}
          </Typography>
        </Grid>

        {missingHealthPlanInfo && (
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", gap: 2, py: 2 }}>
            <PlanLevelChip dataQa={createDataQa("plan", "level", "chip")} level={planLevel ?? ""} />
            <PlanTypeChip dataQa={createDataQa("plan", "type", "chip")} planType={planType ?? ""} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="body1bold" data-qa={createDataQa("plan", "premium", "label")}>
            {PLAN_PREMIUM_LABEL}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            display="inline"
            variant="h5"
            color={takeCommandPrimary.main}
            data-qa={createDataQa("plan", "monthly", "premium")}
          >
            {displayPremium}
          </Typography>
          <Typography display="inline" variant="body1" color={takeCommandPrimary.main}>
            /mo
          </Typography>
        </Grid>

        {familyMembers.length > 0 && (
          <Grid container item xs={12} py={4}>
            <Divider style={{ width: "100%" }} />
            <Grid item pt={4}>
              <Typography variant="body2" data-qa={createDataQa("employee", "name")}>
                <Typography variant="body2bold">Covered members:</Typography> {employeeName}
              </Typography>
            </Grid>
            {familyMembers
              .filter(({ relationship }) => relationship !== EMPLOYEE)
              .map(({ firstName, lastName }, i) => (
                <Grid item xs={12} py={1} key={i}>
                  <Typography variant="body2" data-qa={createDataQa("family", "member", "name")}>
                    {`${firstName} ${lastName}`}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        )}
        <Divider style={{ width: "100%" }} />
        <Grid container item xs={12} py={4} mb="auto" pb={0} justifyContent="space-between" alignItems="flex-start">
          <Grid container item xs={6} direction="column">
            <Grid item>
              <Typography variant="body1bold" data-qa={createDataQa("start", "date", "label")}>
                Start date
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" color={colors.lightGrayText} data-qa={createDataQa("start", "date")}>
                {startDate ? formatDate(createDateFromText(data.coverageStartDate)) : NOT_AVAILABLE_DATE}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6} direction="column" textAlign="right">
            <Grid item pr={3}>
              <Typography variant="body1bold" data-qa={createDataQa("end", "date", "label")}>
                End date
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" color={colors.lightGrayText} data-qa={createDataQa("end", "date")}>
                {endDate ? formatDate(createDateFromText(data.coverageEndDate)) : NOT_AVAILABLE_DATE}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </WidgetLayout>
  )
}
