import { CompanyId, Uuid } from "@/utils/types"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"
import { getFundingStatus, getPaymentAccountInformation } from "./fundingEndpoints"

const ONE_HOUR_MS = 1000 * 60 * 60

export const useGetFundingStatus = (companyId: CompanyId | undefined) =>
  useQuery({
    queryKey: [companyId, "autopay-status"],
    queryFn: async () => getFundingStatus(companyId!),
    enabled: !!companyId,
    staleTime: ONE_HOUR_MS,
  })

export const usePrefetchFundingStatus = (companyId: Uuid) => {
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: [companyId, "autopay-status"],
      queryFn: async () => getFundingStatus(companyId),
      staleTime: ONE_HOUR_MS,
    })
  }, [queryClient, companyId])
}

export const useGetPaymentAccountInformation = (companyId: Uuid | undefined, employmentId: Uuid | undefined) =>
  useQuery({
    queryKey: ["payment-information", companyId, employmentId],
    // SAFETY: Enabled enforces that these values are non-null
    queryFn: () => getPaymentAccountInformation(companyId!, employmentId!),
    enabled: !!employmentId && !!companyId,
  })
