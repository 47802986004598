import { useQuery } from "@tanstack/react-query"
import { useCallback, useEffect, useState } from "react"
import { getTaskById } from "./taskManagementEndpoints"
import { TaskModel } from "./taskTypes"

// Constants
import {
  MAX_TASK_WAIT_DURATION_IN_MILLISECONDS,
  TASK_STATUS_COMPLETED,
  TASK_STATUS_FAILED,
} from "@/features/TCHub/tcHubConstants"

export const usePollingTaskStatus = (interval = 3000) => {
  const [error, setError] = useState<string | null>(null)
  const [isPolling, setIsPolling] = useState<boolean>(false)
  const [taskId, setTaskId] = useState<string | null>(null)
  const { data: task } = useQuery<TaskModel>({
    queryKey: ["task", taskId ?? ""],
    queryFn: async () => {
      try {
        return await getTaskById(taskId!)
      } catch (err) {
        console.error(err)
        setError("Failed to get task status. Please try again later.")
        throw err
      }
    },
    enabled: !!(taskId && isPolling),
    staleTime: 200,
    refetchInterval: interval,
  })

  const isCompleted = task?.status === TASK_STATUS_COMPLETED
  const isFailed = task?.status === TASK_STATUS_FAILED || Boolean(error)

  const stopPolling = useCallback(() => {
    setIsPolling(false)
  }, [])

  const startPolling = useCallback((paramTaskId: string) => {
    setTaskId(paramTaskId)
    setIsPolling(true)
    setError(null)
  }, [])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (isCompleted) {
      setIsPolling(false)
    } else if (isFailed) {
      setError("Task failed. Please try again later.")
      setIsPolling(false)
    } else if (isPolling) {
      timeout = setTimeout(() => {
        setError("Task time out. Please try again later.")
        setIsPolling(false)
      }, MAX_TASK_WAIT_DURATION_IN_MILLISECONDS)
    }
    return () => timeout && clearTimeout(timeout)
  }, [isPolling, isCompleted, isFailed])

  return { task, error, isPolling, startPolling, stopPolling }
}
