import { appConfig } from "@/config"
import { ROLES } from "@/roles_permissions"
import { axiosInstance as axios } from "@/services/axios"
import { getDateFromBackendDateObject } from "@/utils/dates"
import { IsoDateString, Uuid } from "@/utils/types"
import { DateTime } from "luxon"
import { CompanyAssociationResponse } from "../Auth/authTypes"
import {
  AddressModel,
  EmployeeClassRequest,
  EmploymentModel,
  Person,
  PersonModel,
  PersonUpdateRequest,
} from "./peopleTypes"

const peopleManagementBaseUrl = appConfig.baseApiUrl

export const BASE_PEOPLE_MANAGEMENT_URL = `${appConfig.baseApiUrl}/people`

export const getPeople = async (companyId: string) => {
  const people = (
    (await axios.get(`${peopleManagementBaseUrl}/v1/companies/${companyId}/people`)).data?.people ?? []
  ).map((person: any) => {
    if (person.lastLoginIso) {
      try {
        person.lastLoginAt = new Date(person.lastLoginIso).toLocaleString()
      } catch {
        person.lastLoginAt = null
      }
    }

    return person
  }) as Person[]

  return people
}

// FUTURE: Remove this function once regex patterns are stable and not changing. Until then,
// this function is a patch to make sure that the data is serialized correctly when sending
// to the backend.
const getSerializedPerson = (person: Partial<PersonModel>) => ({
  ...person,
  dateOfBirth: person.dateOfBirth ? DateTime.fromISO(person.dateOfBirth.toISOString()).toISODate() : null,
  phoneNumber: {
    value: person.phoneNumber && person.phoneNumber.length > 0 ? person.phoneNumber : null,
  },
  employments: person.employments?.map(item => {
    const newRecord = { ...item }

    if (item.eligibilityDate) {
      // FUTURE: Remove this unsafe non-null assertion
      newRecord.eligibilityDate = DateTime.fromISO(item.eligibilityDate).toISODate()!
    }

    return newRecord
  }),
})

export const addPerson = async (companyId: string, person: Partial<PersonModel>) => {
  const serializedPerson = getSerializedPerson(person)

  const newPerson = (await axios.post(`${peopleManagementBaseUrl}/v1/companies/${companyId}/people`, serializedPerson))
    .data as Person

  return newPerson
}

export const sendSignUpLinkPersonId = async (companyId: Uuid, personId: Uuid) =>
  (await axios.get(`${peopleManagementBaseUrl}/v1/companies/${companyId}/people/${personId}/invite`)).data

export const sendSignUpLinkByEmail = async (email: string) => {
  return (await axios.post(`${peopleManagementBaseUrl}/v1/people/invite`, { email })).data
}

export const getUserHashForIntercom = async (personId: string) => {
  const message = (await axios.get(`${peopleManagementBaseUrl}/v1/people/${personId}/chat`)).data

  return message
}

export const addEmployeeToClass = async (
  companyId: string,
  planId: string,
  classId: string,
  employeeClass: EmployeeClassRequest
) => {
  const newEmployeeClass = await axios.post(
    `${peopleManagementBaseUrl}/benefits/v1/companies/${companyId}/plans/${planId}/classes/${classId}/health-benefit`,
    employeeClass
  )

  return newEmployeeClass
}

const getRole = (person: PersonModel) => {
  let externalEmployeeRole = null
  const externalEmployeeRoleRecord = ROLES.filter(row => row.name && row.name.includes("Employee"))

  if (
    externalEmployeeRoleRecord &&
    Array.isArray(externalEmployeeRoleRecord) &&
    externalEmployeeRoleRecord.length > 0
  ) {
    externalEmployeeRole = externalEmployeeRoleRecord[0].id
  }

  const role = person.supportPerson?.roleId ?? person.companyAdministrators?.[0].roleId ?? externalEmployeeRole

  return role
}

const getLocation = (address: AddressModel) =>
  `${address.street1} ${address.street2}, ${address.city}-${address.state}, Zip Code: ${address.zip} (${address.county})`

export const getPersonById = async (companyId: string, personId: string) => {
  const person = (await axios.get(`${peopleManagementBaseUrl}/v1/companies/${companyId}/people/${personId}`)).data

  if (person.dateOfBirth) {
    person.dateOfBirth = getDateFromBackendDateObject(person.dateOfBirth)
  }

  if (person.employments && Array.isArray(person.employments) && person.employments.length > 0) {
    const currentEmployment = person.employments[0]

    person.jobTitle = currentEmployment.jobTitle
    person.hireDate = getDateFromBackendDateObject(currentEmployment.activeFrom)
    person.role = getRole(person)
  }

  if (person.addresses && Array.isArray(person.addresses) && person.addresses.length > 0) {
    const currentAddress = person.addresses[0]

    person.location = getLocation(currentAddress)
  }

  if (person.mobilePhoneNumber) {
    person.mobilePhoneNumber = person.mobilePhoneNumber.value
  }

  if (person.phone) {
    person.phone = person.phone.value
  }

  if (person.phoneNumber) {
    person.phoneNumber = person.phoneNumber.value
  }

  if (person.ssn) {
    person.ssn = person.ssn.value
  }

  return person as PersonModel
}

export const updatePerson = async (companyId: string, person: PersonModel) => {
  let employmentRecords: EmploymentModel[] = []

  if (person.employments && Array.isArray(person.employments)) {
    employmentRecords = person.employments.map(item => {
      const newRecord = { ...item }

      // FUTURE: Remove this unsafe non-null assertion
      newRecord.activeFrom = DateTime.fromISO(person.hireDate).toISODate()!
      newRecord.jobTitle = person.jobTitle
      if (newRecord.hasOwnProperty("createdAt")) {
        delete newRecord.createdAt
      }
      if (newRecord.hasOwnProperty("updatedAt")) {
        delete newRecord.updatedAt
      }

      return newRecord
    })
  }

  let addressRecords: AddressModel[] = []

  if (person.addresses && Array.isArray(person.addresses)) {
    addressRecords = person.addresses.map(item => {
      if (item.hasOwnProperty("createdAt")) {
        delete item.createdAt
      }
      if (item.hasOwnProperty("updatedAt")) {
        delete item.updatedAt
      }

      return item
    })
  }

  const inputPerson: PersonUpdateRequest = {
    ssn: { value: person.ssn },
    id: person.id,
    firstName: person.firstName,
    lastName: person.lastName,
    middleName: person.middleName,
    preferredName: person.preferredName,
    email: person.email,
    phoneNumber: person.phoneNumber,
    dateOfBirth: person.dateOfBirth.toISOString(),
    addresses: addressRecords,
    employments: employmentRecords,
  }

  return (
    await axios.patch(`${peopleManagementBaseUrl}/v1/companies/${companyId}/people/${inputPerson.id}`, inputPerson)
  ).data as PersonModel
}

export const updatePersonTermsAcceptedDate = async (personId: string) =>
  (await axios.patch(`${peopleManagementBaseUrl}/v1/update-terms-accepted/${personId}`)).data as PersonModel

export const cancelTerminateDate = async (personId: string, employmentId: string) =>
  (
    await axios.patch<PersonModel>(
      `${peopleManagementBaseUrl}/v1/people/${personId}/employments/${employmentId}/cancel-termination`
    )
  ).data

export const archivePerson = async (personId: string) => {
  const archiveUrl = `${peopleManagementBaseUrl}/${personId}?op=archive`
  const response = await axios.delete(archiveUrl)

  return response
}

export const terminatePerson = async (personId: string, companyId: string, employmentTerminatedDate: IsoDateString) => {
  const terminateUrl = `${peopleManagementBaseUrl}/v1/companies/${companyId}/people/${personId}/terminate`
  const payload = { employmentTerminatedDate }
  const response = await axios.patch(terminateUrl, payload)
  return response
}

export const updateWelcomeEmail = async (welcomeEmailDate: string, companyId: string) => {
  const welcomeEmailDateRequest = {
    welcomeEmailDate,
  }

  const updateWelcomeEmailUrl = `${peopleManagementBaseUrl}/v1/companies/${companyId}/people/set-welcome-email-date`
  const response = await axios.patch(updateWelcomeEmailUrl, welcomeEmailDateRequest)

  return response
}

export const reinstatePerson = async (companyId: string, personId: string) => {
  const reinstateUrl = `${peopleManagementBaseUrl}/v1/companies/${companyId}/people/${personId}/reinstate`
  const response = await axios.patch(reinstateUrl)

  return response
}

export const waivePerson = async (companyId: string, personId: string) => {
  const waiveUrl = `${peopleManagementBaseUrl}/v1/companies/${companyId}/people/${personId}/waive`
  const response = await axios.patch(waiveUrl)

  return response
}

export const getAssociatedCompanies = async () => {
  const companyAssociations = (
    await axios.get<CompanyAssociationResponse>(`${peopleManagementBaseUrl}/v1/people/company-associations`)
  ).data
  return companyAssociations.companies
}
