import { SelectMultiField } from "@/components/SelectMultiField"
import { takeCommandPrimary } from "@/theme/palette"
import { toTitleCase } from "@/utils/formatting"
import { Option } from "@/utils/types"
import { Grid, SelectChangeEvent } from "@mui/material"
import { NO_CLASS_OPTION, PEOPLE_ACTIVE_STATUS } from "../peopleConstants"

interface PeopleTableFilterDropdownProps {
  filterStatusValues: string[]
  handleStatusValuesChange: (statusValues: string[]) => void
  classOptions?: Option<string, string>[]
  filterClassValues: string[]
  handleClassValuesChange: (statusValues: string[]) => void
}

export const PeopleTableFilterDropdown = ({
  filterStatusValues,
  handleStatusValuesChange,
  filterClassValues,
  handleClassValuesChange,
  classOptions,
}: PeopleTableFilterDropdownProps) => {
  const handleStatusFilterChange = (event: SelectChangeEvent<typeof filterStatusValues>) => {
    handleStatusValuesChange(Array.from(event.target.value))
  }

  const handlClassFilterChange = (event: SelectChangeEvent<typeof filterClassValues>) => {
    handleClassValuesChange(Array.from(event.target.value))
  }

  const sortedClassOptions = classOptions?.toSorted((classA, classB) => classA.label.localeCompare(classB.label)) ?? []
  const classCompleteOptions = [NO_CLASS_OPTION, ...sortedClassOptions]

  return (
    <>
      <Grid item>
        <SelectMultiField
          data-qa="people-table-filter-dropdown"
          selectedValues={filterStatusValues}
          fieldLabel="Filter by status"
          data={Object.entries(PEOPLE_ACTIVE_STATUS).map(([statusName, statusLabel]) => ({
            label: toTitleCase(statusLabel),
            value: statusName,
          }))}
          name="status-filter"
          onChange={handleStatusFilterChange}
          formControlSx={{ width: "14rem" }}
          sx={{
            color: takeCommandPrimary.main,
            backgroundColor: "white",
          }}
          small
          renderValue={selected => selected.map(option => toTitleCase(option)).join(", ")}
        />
      </Grid>
      {classOptions && (
        <Grid item>
          <SelectMultiField
            data-qa="people-table-class-filter-dropdown"
            selectedValues={filterClassValues}
            fieldLabel="Filter by class"
            data={classCompleteOptions}
            name="class-filter"
            onChange={handlClassFilterChange}
            formControlSx={{ width: "14rem" }}
            sx={{
              color: takeCommandPrimary.main,
              backgroundColor: "white",
            }}
            small
            renderValue={selected =>
              selected
                .map(option => classCompleteOptions.find(classOption => classOption.value === option)?.label)
                .join(", ")
            }
          />
        </Grid>
      )}
    </>
  )
}
