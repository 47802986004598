import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { Uuid } from "@/utils/types"
import { GenericAbortSignal } from "axios"
import {
  CarrierIntegration,
  CarrierPreSignUrlResponse,
  CarrierQuestion,
  CarrierQuestionToMove,
  CarrierUpdate,
  CreateCarrierQuestionRequest,
} from "../TCHub/tcHubTypes"
import {
  AffordabilityCheckPayload,
  AffordabilityCheckResponse,
  CarrierEnrollmentQuestion,
  PlanResponse,
  PlanSearchPayload,
  PlanSearchResponse,
} from "./benefitsElectionTypes"

const baseUrl = appConfig.baseApiUrl
const healthPlansBaseUrl = `${baseUrl}/health-plans`
const carrierIntegrationsUrl = `${healthPlansBaseUrl}/v1/carrier-integrations`

export const searchPlans = async (
  limit: number,
  offset: number,
  planSearchPayload: PlanSearchPayload,
  signal?: GenericAbortSignal
) =>
  (
    await axios.post<PlanSearchResponse>(`${healthPlansBaseUrl}/v1/plans/search`, planSearchPayload, {
      params: { limit, offset },
      signal,
    })
  ).data

export const getCarrierUpdates = async () =>
  (await axios.get<CarrierUpdate[]>(`${healthPlansBaseUrl}/v1/carrier-updates`)).data

export const checkForCarrierUpdates = async () => axios.get(`${healthPlansBaseUrl}/v1/carrier-updates/check`)

export const getCarrierIntegrations = async () =>
  (await axios.get<CarrierIntegration[]>(`${healthPlansBaseUrl}/v1/carrier-integrations`)).data

export const getPlanByIdAndPlanYear = async (id: string, planYear: string | number) =>
  (await axios.get<PlanResponse>(`${healthPlansBaseUrl}/v1/plans/${id}?planYear=${planYear}`)).data

export const updateCarrierIntegration = async (carrierIntegration: CarrierIntegration, applyAllStates: boolean) => {
  const url = `${healthPlansBaseUrl}/v1/carrier-integrations/${carrierIntegration.id}?apply-all-states=${applyAllStates}`
  const paymentResponse = (await axios.put<CarrierIntegration[]>(url, carrierIntegration)).data

  return paymentResponse
}

export const createCarrierIntegration = async (carrierIntegration: CarrierIntegration) =>
  (await axios.post<CarrierIntegration>(carrierIntegrationsUrl, carrierIntegration)).data

export const getSignedUrl = async (fileName: string) => {
  const url = `${healthPlansBaseUrl}/v1/carrier-integrations/signed-url`

  const paymentResponse = (
    await axios.post<CarrierPreSignUrlResponse>(url, fileName, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).data

  return paymentResponse
}

export const getCarrierQuestions = async (carrierId: string, stateCodes?: string) => {
  const questionsUrl = `${healthPlansBaseUrl}/v1/carriers/${carrierId}/enrollment-questions`
  const queryParam = stateCodes ? `?states=${stateCodes}` : ""
  const enrollmentQuestions = (await axios.get<CarrierEnrollmentQuestion[]>(`${questionsUrl}${queryParam}`)).data

  enrollmentQuestions.forEach(question => (question.carrierId = carrierId as Uuid))

  return enrollmentQuestions
}

export const deleteCarrierQuestions = async (carrierId: string, questionId: string) => {
  const deletedCarrierQuestion = (
    await axios.delete<CarrierQuestion[]>(
      `${healthPlansBaseUrl}/v1/carriers/${carrierId}/enrollment-questions/${questionId}`
    )
  ).data

  return deletedCarrierQuestion
}

export const updateCarrierQuestion = async (carrierId: string, carrierQuestion: CarrierQuestion) => {
  const url = `${healthPlansBaseUrl}/v1/carriers/${carrierId}/enrollment-questions/${carrierQuestion.id}`

  return (await axios.patch<CarrierQuestion>(url, carrierQuestion)).data
}

export const moveCarrierQuestion = async (carrierQuestionToMove: CarrierQuestionToMove) => {
  const url = `${healthPlansBaseUrl}/v1/carriers/${carrierQuestionToMove.carrierId}/enrollment-questions/${carrierQuestionToMove.questionId}/move`
  return (await axios.put<CarrierQuestion>(url, carrierQuestionToMove)).data
}

export const addCarrierQuestion = async (carrierId: string, data: CreateCarrierQuestionRequest) => {
  try {
    const response = await axios.post(`${healthPlansBaseUrl}/v1/carriers/${carrierId}/enrollment-questions`, data)

    return response.data
  } catch (error) {
    console.error("Error adding new carrier question:", error)
    throw error
  }
}

export const checkAffordability = async (affordabilityCheckPayload: AffordabilityCheckPayload) =>
  (
    await axios.post<AffordabilityCheckResponse>(
      `${healthPlansBaseUrl}/v1/plans/check-affordability`,
      affordabilityCheckPayload
    )
  ).data
