import { ClearableSearchInput } from "@/components/ClearableSearchInput/ClearableSearchInput"
import { ConfirmationModal, useConfirmationControl } from "@/components/ConfirmationModal"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable, OrderByItemType } from "@/components/Table/Table"
import { TABLE_CELL_PADDING } from "@/constants"
import { deleteBusinessUnit } from "@/features/CreateCompany/createCompanyEndpoints"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Button, Grid, IconButton, Menu, MenuItem, TableCell, Tooltip, Typography } from "@mui/material"
import Fuse from "fuse.js"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { useGetBusinessUnit } from "../tcHubService"
import { BusinessUnit, BusinessUnitTableHeader } from "../tcHubTypes"
import { TcHubCreateBusinessUnitModal } from "./Modals/TcHubCreateBusinessUnitModal"
import { TcHubEditBusinessUnitModal } from "./Modals/TcHubEditBusinessUnitModal"

interface TcHubBusinessUnitDropdownProps {
  companyId: string
  businessUnitId: string
  onEditClick: () => void
  onDeleteClick: () => void
}

export const TcHubBusinessUnitDropdown = ({
  companyId,
  businessUnitId,
  onEditClick,
  onDeleteClick,
}: TcHubBusinessUnitDropdownProps) => {
  const [showCompanieOptions, setShowCompaniesOptions] = useState<any>(null)
  const { confirmationModal, setConfirmationModal, clearConfirmationModal, setConfirmationSubmitting } =
    useConfirmationControl()

  const showMenu = (event: React.SyntheticEvent) => {
    setShowCompaniesOptions(event.currentTarget)
  }

  const closeMenu = () => {
    setShowCompaniesOptions(null)
  }

  const handleDeleteClick = () => {
    setConfirmationModal({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this Business Unit? This action cannot be undone.",
      actionLabel: "Delete",
      onConfirm: async () => {
        setConfirmationSubmitting(true)
        try {
          await deleteBusinessUnit(companyId, businessUnitId)
          onDeleteClick()
          clearConfirmationModal()
        } catch (error) {
          console.error("Failed to delete business unit", error)
          clearConfirmationModal()
        }
      },
    })
  }

  const handleEditClick = () => {
    closeMenu()
    onEditClick()
  }

  return (
    <div data-qa={`icon-more-actions-${companyId}`}>
      <Tooltip title="Options">
        <IconButton
          data-qa={`dropdown-button-${companyId}`}
          aria-owns={showCompanieOptions ? "menu-business-unit-options" : undefined}
          aria-haspopup="true"
          size="large"
          onClick={showMenu}
          sx={{ width: "2.5rem", height: "2.5rem", borderRadius: "50%" }}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        data-qa="menu-business-unit-options"
        id="menu-business-unit-options"
        anchorEl={showCompanieOptions}
        open={Boolean(showCompanieOptions)}
        onClose={closeMenu}
      >
        <MenuItem
          className="tch-companies-menu-item-edit"
          data-qa="manage-business-units-edit"
          onClick={handleEditClick}
        >
          Edit
        </MenuItem>
        <MenuItem
          className="tch-companies-menu-item-delete"
          data-qa="manage-business-units-delete"
          onClick={() => {
            closeMenu()
            handleDeleteClick()
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      {confirmationModal && <ConfirmationModal {...confirmationModal} isOpen onClose={clearConfirmationModal} />}
    </div>
  )
}

interface BusinessUnitTableProps {
  businessSelector: (businessUnit: BusinessUnit[]) => BusinessUnit[]
  searchCriteria: string
  companyId: string
}

const defaultOrder = { headCellId: "name", direction: "asc" } as const

export const TcHubBusinessUnitTable = ({ businessSelector, searchCriteria, companyId }: BusinessUnitTableProps) => {
  const { data: businessUnits, isLoading: isBusinessUnitLoading } = useGetBusinessUnit(companyId)
  const businessUnitsResult = businessUnits ?? []
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selected, setSelected] = useState<BusinessUnit[]>([])
  const [orderBy, setOrderBy] = useState<OrderByItemType[]>([defaultOrder])
  const [editingBusinessUnit, setEditingBusinessUnit] = useState<BusinessUnit | null>(null)
  const [businessUnitsState, setBusinessUnitsState] = useState<BusinessUnit[]>(businessUnitsResult)
  const fuseOptions = {
    keys: ["name"],
    includeMatches: true,
    threshold: 0.3,
  }

  const fuse = new Fuse(businessUnitsState, fuseOptions)
  let fuseSearchResults: BusinessUnit[] = []

  if (searchCriteria) {
    fuseSearchResults = fuse.search(searchCriteria).map(result => result.item)
  }

  let filteredCompanies: BusinessUnit[] = []

  filteredCompanies = searchCriteria.length === 0 ? businessUnitsState : fuseSearchResults

  const handleUpdateBusinessUnitName = (businessUnitId: string, updatedName: string) => {
    setBusinessUnitsState(prevState =>
      prevState.map(unit => (unit.id === businessUnitId ? { ...unit, name: updatedName } : unit))
    )
  }

  const headers: BusinessUnitTableHeader[] = [
    {
      id: "businessUnitName",
      label: "Business Unit Name",
      field: row => row.name,
      sortable: true,
      alignment: "left",
    },
    {
      id: "assignedAmmount",
      label: "# Assigned",
      field: row => row.assignedAmount!,
      sortable: true,
      alignment: "left",
    },
    { id: "actions", label: "", sortable: false },
  ]

  return (
    <>
      {isBusinessUnitLoading ? (
        <SkeletonTableLoader
          data-qa="skeleton-table-loader-business-uniit"
          headerTitles={headers.map(cell => `${cell.label}`)}
          bodyRowsCount={10}
        />
      ) : (
        <BaseTable
          rows={filteredCompanies}
          selected={selected}
          orderBy={orderBy}
          onToggleOrderBy={headId =>
            setOrderBy(state => {
              const result = [] as OrderByItemType[]

              if (state && state.length > 0 && state[0].headCellId === headId) {
                result.push({
                  headCellId: headId,
                  direction: state[0].direction === "asc" ? "desc" : "asc",
                })
              } else {
                result.push({ headCellId: headId, direction: "asc" })
              }
              if (defaultOrder.headCellId !== headId) {
                result.push(defaultOrder)
              }

              return result
            })
          }
          onToggleSelect={selection =>
            setSelected(state => {
              if (state.some(r => r.id === selection.id)) {
                return state.filter(r => r.id !== selection.id)
              }

              return [...state, selection]
            })
          }
          onToggleSelectAll={() => {
            setSelected(state => (state.length > 0 ? [] : businessUnitsState))
          }}
          uniqueIdSelector={row => row.id.toString()}
          headCells={headers ?? []}
          onPageChange={changedPage => setPage(changedPage)}
          onRowsPerPageChange={rows => setRowsPerPage(rows)}
          page={page}
          rowsPerPage={rowsPerPage}
        >
          {({ row, isSelected, toggleSelect, labelId, rowId }) => (
            <>
              <TableCell component="th" id={labelId} scope="row" data-qa="business">
                <Typography variant="body1" data-qa="business-unit-name" sx={TABLE_CELL_PADDING}>
                  {row.name}
                </Typography>
              </TableCell>
              <TableCell data-qa="amount">
                <Typography variant="body1" data-qa="business-assigned-amount" sx={TABLE_CELL_PADDING}>
                  {row.assignedAmount}
                </Typography>
              </TableCell>
              <TableCell>
                <TcHubBusinessUnitDropdown
                  companyId={row.id}
                  businessUnitId={row.id}
                  onEditClick={() => setEditingBusinessUnit(row)}
                  onDeleteClick={() => setBusinessUnitsState(state => state.filter(unit => unit.id !== row.id))}
                />
              </TableCell>
            </>
          )}
        </BaseTable>
      )}
      {editingBusinessUnit && (
        <TcHubEditBusinessUnitModal
          isOpen={Boolean(editingBusinessUnit)}
          companyId={companyId}
          businessUnit={editingBusinessUnit}
          onClose={() => setEditingBusinessUnit(null)}
          onUpdateName={updatedName => handleUpdateBusinessUnitName(editingBusinessUnit.id, updatedName)}
        />
      )}
    </>
  )
}

export const TcHubBusinessUnitPage = () => {
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  const { companyId } = useParams<{ companyId: string }>()
  const [isAddingBusinessUnit, setIsAddingBusinessUnit] = useState<boolean>(false)

  const handleCloseCreateBusinessUnitModal = () => {
    setIsAddingBusinessUnit(false)
  }

  return (
    <Grid container data-qa="tc-hub-business-unit" justifyContent="space-between" spacing={3}>
      <Grid item xs={12} mb={5}>
        <Typography variant="h1" gutterBottom display="inline" data-qa="business-units">
          Business Units
        </Typography>
      </Grid>
      <Grid item>
        <Grid container justifyContent="flex-start" spacing={3}>
          <Grid item>
            <ClearableSearchInput
              onChange={event => setSearchInputValue(event.target.value)}
              handleClearClick={() => {
                setSearchInputValue("")
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          data-qa="hub-button"
          //FUTURE: SEG-6828 change the implementation to use confirmations service
          onClick={() => setIsAddingBusinessUnit(true)}
          variant="contained"
          startIcon={<AddOutlinedIcon />}
        >
          Add new
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TcHubBusinessUnitTable
          businessSelector={businessUnit => businessUnit}
          searchCriteria={searchInputValue}
          companyId={companyId!}
        />
      </Grid>
      <TcHubCreateBusinessUnitModal
        isOpen={isAddingBusinessUnit}
        companyId={companyId!}
        onClose={handleCloseCreateBusinessUnitModal}
      />
    </Grid>
  )
}
