import { SidebarItem } from "@/components/Sidebar/types"
import { SELF_ENROLL } from "@/constants"
import {
  AccountBalanceWalletOutlined,
  AccountCircleOutlined,
  AddShoppingCartOutlined,
  AdminPanelSettingsOutlined,
  AdsClickOutlined,
  ArchiveOutlined,
  AssignmentIndOutlined,
  BeenhereOutlined,
  Diversity1Outlined,
  InboxOutlined,
  LocalHospitalOutlined,
  MedicalInformationOutlined,
  MedicationOutlined,
  ShoppingBasketOutlined,
  TollOutlined,
  UploadOutlined,
} from "@mui/icons-material"
import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import {
  CARRIER_QUESTIONS,
  CHECKOUT,
  DOCTORS,
  FAMILY,
  FINAL_CHECK,
  FIND_PLAN,
  HOSPITALS,
  HOUSEHOLD_INCOME,
  MY_INFO,
  PERSONAL_INFO,
  PRESCRIPTIONS,
  PURCHASE,
  RECOMMENDED_PLANS,
  RECURRING_REIMBURSEMENT,
  SELF_ENROLLED_PLAN,
  SELF_ENROLL_PURCHASE,
  SIGNATURES,
  SSN,
  SUMMARY,
} from "../benefitsElectionConstants"
import { useShoppingUrl } from "../benefitsElectionService"
import { useBenefitsElectionStore } from "../benefitsElectionStore"
import { EnrollmentType, ShoppingUrl } from "../benefitsElectionTypes"
import { useShowCheckoutStep } from "./usePurchasePlan"

const getSelfEnrollPurchaseItems = (baseUrl: ShoppingUrl): SidebarItem[] => [
  {
    href: baseUrl + SELF_ENROLL_PURCHASE,
    title: "Self enroll",
    icon: AccountBalanceWalletOutlined,
  },
  {
    href: baseUrl + RECURRING_REIMBURSEMENT,
    title: "Recurring premiums",
    icon: UploadOutlined,
  },
  {
    href: baseUrl + FINAL_CHECK,
    title: "Final check",
    icon: TollOutlined,
  },
]

const getSelfEnrolledPlanItems = (baseUrl: ShoppingUrl): SidebarItem[] => [
  {
    href: baseUrl + SELF_ENROLLED_PLAN,
    title: "Plan questions",
    icon: AccountBalanceWalletOutlined,
  },
  {
    href: baseUrl + RECURRING_REIMBURSEMENT,
    title: "Recurring premiums",
    icon: UploadOutlined,
  },
  {
    href: baseUrl + FINAL_CHECK,
    title: "Final check",
    icon: TollOutlined,
  },
]

const getMedicarePurchaseItems = (baseUrl: ShoppingUrl): SidebarItem[] => [
  {
    href: baseUrl + RECURRING_REIMBURSEMENT,
    title: "Recurring premiums",
    icon: UploadOutlined,
  },
]

const getEasyEnrollPurchaseItems = (baseUrl: ShoppingUrl, showCheckout: boolean): SidebarItem[] => [
  {
    href: baseUrl + CARRIER_QUESTIONS,
    title: "Carrier questions",
    icon: AddShoppingCartOutlined,
  },
  {
    href: baseUrl + SSN,
    title: "SSN",
    icon: MedicalInformationOutlined,
  },
  {
    href: baseUrl + SIGNATURES,
    title: "Signature(s)",
    icon: BeenhereOutlined,
  },
  {
    href: baseUrl + SUMMARY,
    title: "Summary",
    icon: AdminPanelSettingsOutlined,
  },
  {
    href: baseUrl + CHECKOUT,
    title: "Checkout",
    icon: ShoppingBasketOutlined,
    hidden: !showCheckout,
  },
]

const getPurchaseItems = (
  baseUrl: ShoppingUrl,
  enrollmentType: EnrollmentType | undefined,
  showMedicarePurchaseFlow: boolean,
  selfEnrolledPlan: boolean,
  showCheckout: boolean
) => {
  if (showMedicarePurchaseFlow) return getMedicarePurchaseItems(baseUrl)

  if (selfEnrolledPlan) {
    return getSelfEnrolledPlanItems(baseUrl)
  }

  if (!enrollmentType) return

  if (enrollmentType === SELF_ENROLL) {
    return getSelfEnrollPurchaseItems(baseUrl)
  }

  return getEasyEnrollPurchaseItems(baseUrl, showCheckout)
}

const getBenefitsElectionItems = (
  baseUrl: ShoppingUrl,
  enrollmentType: EnrollmentType | undefined,
  showPreferences: boolean,
  showFamily: boolean,
  showFindPlan: boolean,
  showPurchase: boolean,
  showMedicarePurchaseFlow: boolean,
  selfEnrolledPlan: boolean,
  isAutoPay: boolean
): SidebarItem[] => [
  {
    href: baseUrl + MY_INFO + "/",
    title: "My info",
    icon: ArchiveOutlined,
    children: [
      {
        href: baseUrl + PERSONAL_INFO,
        title: "Personal info",
        icon: AccountCircleOutlined,
      },
      {
        href: baseUrl + DOCTORS,
        title: "Doctors",
        icon: AssignmentIndOutlined,
        hidden: !showPreferences,
      },
      {
        href: baseUrl + HOSPITALS,
        title: "Hospitals",
        icon: LocalHospitalOutlined,
        hidden: !showPreferences,
      },
      {
        href: baseUrl + PRESCRIPTIONS,
        title: "Prescriptions",
        icon: MedicationOutlined,
        hidden: !showPreferences,
      },
      {
        href: baseUrl + FAMILY,
        title: "Family",
        icon: Diversity1Outlined,
        hidden: !showFamily,
      },
      {
        href: baseUrl + HOUSEHOLD_INCOME,
        title: "Household income",
        icon: AccountBalanceWalletOutlined,
        hidden: !showFindPlan,
      },
    ],
  },
  {
    href: baseUrl + FIND_PLAN,
    title: "Find a plan",
    icon: InboxOutlined,
    hidden: !showFindPlan,
    children: [
      {
        href: baseUrl + RECOMMENDED_PLANS,
        title: "Recommended plans",
        icon: AdsClickOutlined,
      },
    ],
  },
  {
    href: baseUrl + PURCHASE,
    title: "Purchase",
    icon: AddShoppingCartOutlined,
    children: getPurchaseItems(baseUrl, enrollmentType, showMedicarePurchaseFlow, selfEnrolledPlan, isAutoPay),
    hidden: !showPurchase,
  },
]

const isPlansRoute = (href: string) => href.match(/-plans/)

const useBaseItems = () => {
  const location = useLocation()
  const shoppingUrl = useShoppingUrl()
  const showPreferences = useBenefitsElectionStore(state => state.showPreferences)
  const showFamily = useBenefitsElectionStore(state => state.showFamily)
  const showFindPlan = useBenefitsElectionStore(state => state.showFindPlan)
  const showPurchase = useBenefitsElectionStore(state => state.showPurchase)
  const showMedicarePurchaseFlow = useBenefitsElectionStore(state => state.showMedicarePurchaseFlow)
  const getEnrollmentType = useBenefitsElectionStore(state => state.getEnrollmentType)
  const enrollmentType = getEnrollmentType()
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)
  const selfEnrolledPlan = !selectedPlan && location.pathname.includes(PURCHASE)
  const showMedicareFlow = showMedicarePurchaseFlow && location.pathname.includes(PURCHASE)
  const showCheckout = useShowCheckoutStep()

  return useMemo(
    () =>
      getBenefitsElectionItems(
        shoppingUrl,
        enrollmentType,
        showPreferences,
        showFamily,
        showFindPlan,
        showPurchase,
        showMedicareFlow,
        selfEnrolledPlan,
        showCheckout
      ),
    [
      shoppingUrl,
      enrollmentType,
      showPreferences,
      showFamily,
      showFindPlan,
      showPurchase,
      selfEnrolledPlan,
      showMedicareFlow,
      showCheckout,
    ]
  )
}

export const useBenefitsElectionItems = () => {
  const shoppingUrl = useShoppingUrl()
  const currentStep = useBenefitsElectionStore(state => state.currentStep)

  const baseItems = useBaseItems()

  return useMemo(() => {
    let reachedPivot = false

    return baseItems.map(({ children, ...parent }) => ({
      ...parent,
      disabled: reachedPivot || (parent.href.includes(PURCHASE) && !children),
      children: children?.map(child => {
        let isPivot = false

        if (child.href === shoppingUrl + currentStep) {
          reachedPivot = true
          isPivot = true
        }
        // Avoid putting check marks in the plans page
        const childComplete = !reachedPivot && !isPivot && !isPlansRoute(child.href)

        return {
          ...child,
          complete: childComplete,
          disabled: reachedPivot && !isPivot,
        }
      }),
      complete: !reachedPivot && !parent.href.includes(PURCHASE),
    }))
  }, [baseItems, shoppingUrl, currentStep])
}
