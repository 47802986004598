import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { benefitsElectionBaseUrl } from "../BenefitsElection/benefitsElectionEndpoints"
import { ShoppingSession } from "../BenefitsElection/benefitsElectionTypes"
import {
  DashboardWidgetResult,
  HraPlanAutoPayCutOffDateResponse,
  QleAvailabilityResponse,
  QlePayload,
  QleResponse,
} from "./dashboardTypes"

const baseUrl = appConfig.baseApiUrl
const dashboardBaseUrl = `${baseUrl}/dashboard/v1`
const benefitsBaseUrl = `${baseUrl}/benefits/v1`
const benefitElectionBaseUrl = `${baseUrl}/benefitelection/v1`

export const getDashboardWidgetData = async (companyId: string) => {
  const dashboardData = (await axios.get<DashboardWidgetResult[]>(`${dashboardBaseUrl}/companies/${companyId}`)).data

  return dashboardData
}

export const getEnrollmentReportCsv = async (companyId: string, planYear: number) =>
  (
    await axios.get<string>(
      `${benefitElectionBaseUrl}/companies/${companyId}/shopping-sessions/enrollment-report?planYear=${planYear}`
    )
  ).data

export const getShoppingSessionsByCompanyAndEmploymentId = async (companyId: string, employmentId: string) =>
  (
    await axios.get<ShoppingSession[]>(
      `${benefitElectionBaseUrl}/company/${companyId}/employees/${employmentId}/shopping-sessions`
    )
  ).data

export const createQle = async (payload: QlePayload) =>
  (
    await axios.post<QleResponse>(
      `${benefitsElectionBaseUrl}/v1/employees/${payload.employmentId}/qualifying-life-events/`,
      payload
    )
  ).data

export const checkQleAvailability = async (employeeId: string) =>
  (
    await axios.get<QleAvailabilityResponse>(
      `${benefitsElectionBaseUrl}/v1/employees/${employeeId}/qualifying-life-event/check-availability`
    )
  ).data

export const getAutoPayCutOffDate = async (companyId: string, planId: string) =>
  (
    await axios.get<HraPlanAutoPayCutOffDateResponse>(
      `${benefitsBaseUrl}/companies/${companyId}/plans/${planId}/autopay-cutoff-date`
    )
  ).data
