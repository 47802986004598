import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { PermissionGuard } from "@/features/Auth/guards/PermissionGuard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { takeCommandPrimary } from "@/theme/palette"
import { getValueLabelsFromYearsToNext, months } from "@/utils/dates"
import { DATE_FORMAT_MONTH_DAY_YEAR, formatCents, transformDate } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import { Box, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { useState } from "react"
import { FUNDING_EVENT_STATUS_SCHEDULED } from "../Funding/fundingConstants"
import { useCompanyFundingEventEntries, useCompanyFundingEvents, useCompanyFundingEventSummary } from "./autopayService"
import { AutoPayFundingReportsTable } from "./components/AutoPayFundingReportsTable"

interface AutoPayFudingReportSnapshotProps {
  latestScheduledEvent: any
}

const AutoPayFudingReportSnapshot = ({ latestScheduledEvent }: AutoPayFudingReportSnapshotProps) => (
  <Card>
    <CardContent>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems="center"
      >
        <Box sx={{ textAlign: { xs: "center", sm: "left" }, mb: { xs: 2, sm: 0 } }}>
          <Typography variant="body1">Next Funding Amount</Typography>
          <Typography variant="h5">
            {latestScheduledEvent.totalFundingAmountCents
              ? formatCents(latestScheduledEvent.totalFundingAmountCents)
              : "—"}
          </Typography>
        </Box>
        <Box sx={{ textAlign: { xs: "center", sm: "left" }, mb: { xs: 2, sm: 0 } }}>
          <Typography variant="caption" color={takeCommandPrimary.main} fontWeight="bold">
            FOR
          </Typography>
        </Box>
        <Box sx={{ textAlign: { xs: "center", sm: "left" }, mb: { xs: 2, sm: 0 } }}>
          <Typography variant="body1"># of Employees</Typography>
          <Typography variant="h5">{latestScheduledEvent.numberOfEmployees || "—"}</Typography>
        </Box>
        <Box sx={{ textAlign: { xs: "center", sm: "left" }, mb: { xs: 2, sm: 0 } }}>
          <Typography variant="caption" color={takeCommandPrimary.main} fontWeight="bold">
            ON
          </Typography>
        </Box>
        <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
          <Typography variant="body1">Transfer Date</Typography>
          <Typography variant="h5">
            {latestScheduledEvent.periodEndAt
              ? transformDate(latestScheduledEvent.periodEndAt, DATE_FORMAT_MONTH_DAY_YEAR)
              : "—"}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
)

export const AutoPayFundingReports = () => {
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>()
  const [selectedYear, setSelectedYear] = useState<number | undefined>()

  const { user } = useAuth()
  const company = getActiveCompany(user)
  const companyId = company?.companyId
  const { data: companyFundingEvents, isLoading: loadingCompanyFundingEvents } = useCompanyFundingEvents(companyId)
  const { data: companyFundingEventEntries, isLoading: loadingCompanyFundingEventEntries } =
    useCompanyFundingEventEntries({ companyId, month: selectedMonth, year: selectedYear })

  const scheduledEvents =
    companyFundingEvents?.companyFundingEvents.filter(event => event.status === FUNDING_EVENT_STATUS_SCHEDULED) ?? []
  const { data: latestScheduledEvent } = useCompanyFundingEventSummary(companyId, scheduledEvents?.[0]?.id)

  return (
    <PermissionGuard in="accounts_account_management">
      {latestScheduledEvent && <AutoPayFudingReportSnapshot latestScheduledEvent={latestScheduledEvent} />}
      <Grid container direction="row" spacing={4} sx={{ mt: 6 }}>
        <Grid item>
          <FormControl variant="outlined" size="small" sx={{ backgroundColor: "white", minWidth: 150 }}>
            <InputLabel id="month">Month</InputLabel>
            <Select
              labelId="month"
              id="month"
              value={selectedMonth}
              onChange={e => setSelectedMonth(Number(e.target.value))}
              sx={{ height: "2.5rem" }}
              label="Month"
            >
              {months.map(month => (
                <MenuItem key={`month-select-${month.value}`} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl size="small" sx={{ backgroundColor: "white", minWidth: 120 }}>
            <InputLabel id="year">Year</InputLabel>
            <Select
              id="year"
              labelId="year"
              value={selectedYear}
              onChange={e => setSelectedYear(Number(e.target.value))}
              label="Year"
            >
              {getValueLabelsFromYearsToNext(2024).map(year => (
                <MenuItem key={`year-select-${year.value}`} value={year.value}>
                  {year.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 0 }}>
        <Grid item xs={12}>
          {selectedMonth &&
          selectedYear &&
          companyId &&
          (loadingCompanyFundingEvents || loadingCompanyFundingEventEntries) ? (
            <SkeletonTableLoader
              headerTitles={["Name", "Amount", "Date"]}
              bodyRowsCount={7}
              data-qa="skeleton-table-loader-company-funding-events"
            />
          ) : (
            <AutoPayFundingReportsTable
              events={companyFundingEventEntries ?? []}
              companyId={(companyId as Uuid) ?? null}
            />
          )}
        </Grid>
      </Grid>
    </PermissionGuard>
  )
}
