import { createDataQa } from "@/utils/dataQa"
import { STATE_OPTIONS, UsaState } from "@/utils/States"
import { useFormikContext } from "formik"
import { SelectField, SelectFieldProps } from "./SelectField"

interface StateValues {
  state: UsaState
}

export const StateSelectField = ({
  dataQa = createDataQa("state-select"),
  sx = {},
  ...props
}: Partial<SelectFieldProps>) => {
  const { values, handleChange, handleBlur } = useFormikContext<StateValues>()

  return (
    <SelectField
      data={STATE_OPTIONS}
      type="text"
      name="state"
      label="State"
      required
      value={values.state}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="Select State"
      dataQa={dataQa}
      sx={{ mt: 0, ...sx }}
      {...props}
    />
  )
}
