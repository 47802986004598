import { useGetRecurringPremiumSearch } from "@/features/TCHub/Compliance/tcHubComplianceService"
import { colors } from "@/theme/palette"
import SearchIcon from "@mui/icons-material/Search"
import { Box, Button, Grid, Paper, TextField } from "@mui/material"
import { useMemo } from "react"
import { create } from "zustand"

const useRecurringPremiumFilterState = create<{
  filters: {
    employeeName?: string
    employeeEmail?: string
    companyName?: string
    planYear?: string
    insuranceType?: string
    enrollmentType?: string
    status?: string
    autoPay?: string
    olderThan10Days?: string
  }
  setFilter: (filter: string, value: string) => void
}>(set => ({
  filters: { status: "PENDING" },
  setFilter: (field, value) =>
    set(state => ({
      filters: { ...state.filters, [field]: value },
    })),
}))

const getBooleanFromString = (str?: string) => {
  if (str === "Yes") {
    return true
  } else if (str === "No") {
    return false
  }
}

export const useRecurringPremiumSearch = () => {
  const { filters, setFilter } = useRecurringPremiumFilterState()
  const { data, isLoading, refetch, isRefetching } = useGetRecurringPremiumSearch(
    {
      employeeName: filters?.employeeName,
      employeeEmail: filters?.employeeEmail,
      companyName: filters?.companyName,
      planYear: filters?.planYear,
      insuranceType: filters?.insuranceType,
      enrollmentType: filters?.enrollmentType,
      status: filters?.status,
      autoPay: getBooleanFromString(filters?.autoPay),
      olderThan10Days: getBooleanFromString(filters?.olderThan10Days),
    },
    { enabled: false }
  )
  const handleSearch = () => refetch()
  const recurringPremiumData = useMemo(() => {
    if (data) {
      return data
    }
    return []
  }, [data])
  return {
    recurringPremiumData,
    setFilter,
    handleSearch,
    filters,
    isLoading: isLoading || isRefetching,
  }
}

export const RecurringPremiumSearch = () => {
  const { setFilter, handleSearch } = useRecurringPremiumSearch()

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} width="100%">
      <Paper elevation={3} sx={{ px: 3, pt: 4, pb: 6, mb: 3, width: "100%" }}>
        <Grid container>
          <Grid xs={12} lg={10} container spacing={4} alignItems="center">
            <Grid item xs={6}>
              <TextField
                fullWidth
                placeholder="Search by Name"
                onChange={event => setFilter("employeeName", event.target.value)}
                onKeyUp={event => {
                  if (event.key === "Enter") {
                    handleSearch()
                  }
                }}
                variant="outlined"
                data-qa="recurring-premiums-search-input"
                size="small"
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 2 }} />,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                placeholder="Search by Email"
                onChange={event => setFilter("employeeEmail", event.target.value)}
                onKeyUp={event => {
                  if (event.key === "Enter") {
                    handleSearch()
                  }
                }}
                data-qa="recurring-premiums-email-input"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 2 }} />,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                placeholder="Search by Company"
                onChange={event => setFilter("companyName", event.target.value)}
                onKeyUp={event => {
                  if (event.key === "Enter") {
                    handleSearch()
                  }
                }}
                data-qa="recurring-premiums-company-input"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 2 }} />,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Button
              disableElevation
              data-qa="insurance-coverage-search"
              variant="contained"
              onClick={handleSearch}
              sx={{
                mx: { xs: 0, lg: 2 },
                my: { xs: 2, lg: 0 },
                width: "100%",
                backgroundColor: colors.boneChilling,
                "&:hover": { backgroundColor: "colors.mintGreen" },
                color: theme => theme.palette.primary.main,
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
