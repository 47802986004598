import { ConfirmationModal, ModalConfig } from "@/components/ConfirmationModal"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable, OrderByItemType } from "@/components/Table/Table"
import { ChipPersonStatus } from "@/features/People/components/ChipPersonStatus"
import { PeopleOptionsDropdown } from "@/features/People/components/PeopleOptionsDropdown"
import { STATUSES_TO_HIDE } from "@/features/People/peopleConstants"
import { Person, PersonModel } from "@/features/People/peopleTypes"
import { usePeopleSearch } from "@/features/TCHub/components/UsersSearch"
import { toTitleCase } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import styled from "@emotion/styled"
import { Avatar, TableCell } from "@mui/material"
import { Box } from "@mui/system"
import { Dispatch, SetStateAction, useState } from "react"
import { useNavigate } from "react-router-dom"

const defaultOrder = { headCellId: "name", direction: "asc" } as const

const roleSelector = (person: PersonModel) => person.roles?.map(role => role.name).join(", ")

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export interface PeopleTableProps {
  hideSendSignUpLink?: boolean
  hideWaive?: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setPersonProfileId: Dispatch<SetStateAction<Uuid | undefined>>
  setCompanyId: Dispatch<SetStateAction<Uuid | undefined>>
  classField?: (row: PersonModel) => string
}

export const TcHubUserTable = ({
  hideSendSignUpLink,
  hideWaive,
  setOpen,
  setPersonProfileId,
  setCompanyId,
  classField,
}: PeopleTableProps) => {
  const [page, setPage] = useState(0)
  const initialRowsPerPage = 25
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)
  const [selected, setSelected] = useState<Person[]>([])
  const [orderBy, setOrderBy] = useState<OrderByItemType[]>([defaultOrder])
  const [modal, setModal] = useState<ModalConfig>(null)
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)
  const { peopleData, isLoading, isRefetching } = usePeopleSearch()
  const navigate = useNavigate()

  return (
    <>
      {isLoading || isRefetching ? (
        <SkeletonTableLoader
          data-qa="skeleton-table-loader-people"
          headerTitles={["Name/Email", "Status", "Role", "Company Name", "Actions"]}
          bodyRowsCount={10}
        />
      ) : (
        <BaseTable
          rows={peopleData}
          selected={selected}
          orderBy={orderBy}
          onToggleOrderBy={headId =>
            setOrderBy(state => {
              const result = [] as OrderByItemType[]

              if (state && state.length > 0 && state[0].headCellId === headId) {
                result.push({
                  headCellId: headId,
                  direction: state[0].direction === "asc" ? "desc" : "asc",
                })
              } else {
                result.push({ headCellId: headId, direction: "asc" })
              }
              if (defaultOrder.headCellId !== headId) {
                result.push(defaultOrder)
              }

              return result
            })
          }
          onToggleSelect={selection =>
            setSelected(state => {
              if (state.some(r => r.id === selection.id)) {
                return state.filter(r => r.id !== selection.id)
              }

              return [...state, selection]
            })
          }
          onToggleSelectAll={() => {
            setSelected(state => (state.length > 0 ? [] : peopleData))
          }}
          uniqueIdSelector={row => row.id.toString()}
          headCells={[
            {
              id: "name",
              label: "Name/Email",
              field: (row: PersonModel) => toTitleCase(row.firstName) + toTitleCase(row.lastName) + row.email,
              sortable: true,
            },
            { id: "status", label: "Status", sortable: true },
            {
              id: "role",
              label: "Role",
              field: (row: PersonModel) => row.roles?.map(role => role.name).join(", "),
              sortable: true,
            },
            {
              id: "company",
              label: "Company Name",
              field: (row: PersonModel) => row.companyName,
              sortable: true,
            },
            { id: "actions", label: "", sortable: false },
          ].filter(Boolean)}
          onPageChange={changedPage => setPage(changedPage)}
          onRowsPerPageChange={rows => setRowsPerPage(rows)}
          page={page}
          rowsPerPage={rowsPerPage}
        >
          {({ row, isSelected, toggleSelect, labelId, rowId }) => {
            const shouldHideWaive = STATUSES_TO_HIDE.includes(row.status)
            return (
              <>
                <TableCell component="th" id={labelId} scope="row" data-qa="person">
                  <Customer
                    onClick={() => {
                      navigate(`${row.id}/${row.employmentId}/${row.companyId}`)
                    }}
                  >
                    <Avatar>
                      {row.firstName.charAt(0).toUpperCase()}
                      {row.lastName.charAt(0).toUpperCase()}
                    </Avatar>
                    <Box ml={3}>
                      {row.firstName} {row.lastName}
                      <br />
                      {row.email}
                    </Box>
                  </Customer>
                </TableCell>
                <TableCell data-qa="status-chip">
                  <ChipPersonStatus status={row.status} />
                </TableCell>
                {classField && <TableCell data-qa="class">{classField(row)}</TableCell>}
                <TableCell data-qa="role">{roleSelector(row)}</TableCell>
                <TableCell data-qa="company">{row.companyName}</TableCell>
                <TableCell>
                  <PeopleOptionsDropdown
                    personId={row.id}
                    employmentId={row.employmentId}
                    status={row.status}
                    setModal={setModal}
                    setIsModalSubmitting={setIsModalSubmitting}
                    setOpen={setOpen}
                    setPersonProfileId={personId => {
                      setPersonProfileId(personId)
                      setCompanyId(row.companyId)
                    }}
                    hideSendSignUpLink={hideSendSignUpLink}
                    hideWaive={hideWaive || shouldHideWaive}
                    companyId={row.companyId}
                  />
                </TableCell>
              </>
            )
          }}
        </BaseTable>
      )}

      {modal && (
        <ConfirmationModal
          isOpen={!!modal}
          {...modal}
          onClose={() => {
            setModal(null)
          }}
          isSubmitting={isModalSubmitting}
        />
      )}
    </>
  )
}
