import { useNotifications } from "@/services/notificationService"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { isEmpty } from "lodash"
import { useMemo } from "react"
import {
  CarrierIntegration,
  CarrierQuestion,
  CarrierQuestionToMove,
  CreateCarrierQuestionRequest,
} from "../TCHub/tcHubTypes"
import { AffordabilityCheckPayload, FilterPreferences } from "./benefitsElectionTypes"
import { createPlanSearchFilterPayload } from "./benefitsElectionUtils"
import {
  addCarrierQuestion,
  checkAffordability,
  deleteCarrierQuestions,
  getCarrierQuestions,
  moveCarrierQuestion,
  searchPlans,
  updateCarrierQuestion,
} from "./healthPlansEndpoints"

export const MAX_PLANS_LIMIT = 300

const THIRTY_MINUTES_MS = 1000 * 60 * 30

export const useGetPlans = (limit: number, offset: number, searchFilterPreferences: FilterPreferences) => {
  const { notify } = useNotifications("search-plans")
  const filterPayload = useMemo(() => createPlanSearchFilterPayload(searchFilterPreferences), [searchFilterPreferences])

  return useQuery({
    queryKey: ["plans", "search", filterPayload, limit, offset],
    queryFn: async ({ signal }) => {
      try {
        return await searchPlans(limit, offset, filterPayload, signal)
      } catch (e: any) {
        if (e.isCancel) {
          throw new Error("Previous request was cancelled")
        }

        notify(`Error retrieving plans. Please try again later - Request canceled`, "error")
        return {
          meta: {
            total: 0,
            eligibleForChipMedicaid: false,
            premiumTaxCreditCents: 0,
          },
          plans: [],
        }
      }
    },
    enabled: !isEmpty(searchFilterPreferences),
    refetchOnWindowFocus: false,
    staleTime: THIRTY_MINUTES_MS,
  })
}

export const useGetCarrierQuestionsByStates = (carrierId: string, stateCodes?: string) =>
  useQuery({
    queryKey: ["carrierQuestions", carrierId, stateCodes],
    queryFn: () => getCarrierQuestions(carrierId, stateCodes),
    enabled: !!carrierId,
    refetchOnWindowFocus: false,
  })

export const useGetCarrierQuestions = (carrierId: string, carrierIntegrations: CarrierIntegration[] | undefined) =>
  useQuery({
    queryKey: ["carrierQuestions", carrierId],
    queryFn: () => getCarrierQuestions(carrierId),
    enabled: !!carrierId && carrierIntegrations && carrierIntegrations?.length > 0,
  })

export const useUpdateCarrierQuestion = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: { carrierId: string; carrierQuestion: CarrierQuestion }) =>
      updateCarrierQuestion(payload.carrierId, payload.carrierQuestion),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["carrierQuestions"],
      })
    },
  })
}

export const useMoveCarrierQuestion = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: { carrierQuestionToMove: CarrierQuestionToMove }) =>
      moveCarrierQuestion(payload.carrierQuestionToMove),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["carrierQuestions"],
      })
    },
  })
}

export const useDeleteCarrierQuestion = (carrierId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: { carrierId: string; questionId: string }) =>
      deleteCarrierQuestions(payload.carrierId, payload.questionId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["carriers", carrierId],
      })
      queryClient.invalidateQueries({
        queryKey: ["carrierQuestions"],
      })
    },
  })
}

export const useAddCarrierQuestion = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: { carrierId: string; data: CreateCarrierQuestionRequest }) =>
      addCarrierQuestion(payload.carrierId, payload.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["carrierQuestions"],
      })
    },
  })
}

export const useCheckAffordability = (checkAffordabilityPayload?: AffordabilityCheckPayload) =>
  useQuery({
    queryKey: ["check-affordability", checkAffordabilityPayload],
    queryFn: () => checkAffordability(checkAffordabilityPayload!),
    enabled: !isEmpty(checkAffordabilityPayload),
    refetchOnWindowFocus: false,
  })
