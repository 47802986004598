import { ConfirmationModal } from "@/components/ConfirmationModal"
import { getTaskById } from "@/features/Tasks/taskManagementEndpoints"
import { useNotifications } from "@/services/notificationService"
import { Button } from "@mui/material"
import { useState } from "react"
import {
  MAX_TASK_WAIT_DURATION_IN_MILLISECONDS,
  TASK_STATUS_COMPLETED,
  TASK_STATUS_FAILED,
  TASK_STATUS_IDLE,
  TASK_STATUS_STARTED,
} from "../tcHubConstants"
import { createApplicationPdfDownloadRequest } from "../tcHubEndpoints"

export interface ExportEnrollmentToPdfButtonProps {
  ids: string[]
  disabled: boolean
}

export const ExportEnrollmentToPdfButton = ({ ids, disabled }: ExportEnrollmentToPdfButtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = useState(TASK_STATUS_IDLE)
  const [numSubmitted, setNumSubmitted] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { notify } = useNotifications("pdf-application-download")

  const handleError = (errorMessage: string) => {
    setStatus(TASK_STATUS_FAILED)
    setIsModalOpen(false)
    notify(errorMessage, "error")
  }

  const pollTaskStatus = (taskId: string) => {
    const startTime = Date.now()

    const interval = setInterval(async () => {
      if (Date.now() - startTime >= MAX_TASK_WAIT_DURATION_IN_MILLISECONDS) {
        clearInterval(interval)
        const errorMessage = "Task timed out. Please try again later."

        handleError(errorMessage)

        return
      }

      try {
        const response = await getTaskById(taskId)
        const taskStatus = response.status
        if (taskStatus === TASK_STATUS_COMPLETED) {
          clearInterval(interval)
          setStatus(TASK_STATUS_COMPLETED)
          notify("Application download completed", "success")
        } else if (taskStatus === TASK_STATUS_FAILED) {
          const errorMessage = "Task failed. Please try again later."

          clearInterval(interval)
          handleError(errorMessage)
        }
      } catch {
        const errorMessage = "Failed to get task status. Please try again later."

        clearInterval(interval)
        handleError(errorMessage)
      }
    }, 3000)
  }

  const startExport = async () => {
    try {
      setStatus(TASK_STATUS_STARTED)
      setIsModalOpen(true)
      setNumSubmitted(ids.length)
      const response = await createApplicationPdfDownloadRequest(ids)

      pollTaskStatus(response.taskId)
    } catch {
      const errorMessage = "Failed to start export. Please try again later."

      handleError(errorMessage)
    }
  }

  const handleCloseDownloadModal = () => {
    setIsModalOpen(false)
    setStatus(TASK_STATUS_IDLE)
    setNumSubmitted(0)
  }

  return (
    <>
      <Button variant="contained" disabled={disabled} onClick={startExport}>
        Export as PDF
      </Button>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`We're getting your file${numSubmitted > 1 ? "s" : ""} ready`}
        message={`We are getting ${numSubmitted} file${
          numSubmitted > 1 ? "s" : ""
        } ready for you. Once completed, you will find the application in OneDrive.`}
        actionLabel="Ok"
        isCancelable={false}
        onConfirm={() => {
          handleCloseDownloadModal()
        }}
      />
    </>
  )
}
