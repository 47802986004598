import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from "@mui/material"
import { noop } from "lodash"
import { HTMLAttributeAnchorTarget, ReactNode, useCallback, useState } from "react"

export interface ConfirmationModalProps extends Partial<DialogProps> {
  isOpen: boolean
  isCancelable?: boolean
  disableCloseIcon?: boolean
  isError?: boolean
  cancelLabel?: string
  actionLabel?: string
  title: string
  message?: string
  children?: ReactNode
  isSubmitting?: boolean
  href?: string
  target?: HTMLAttributeAnchorTarget
  disabled?: boolean
  onConfirm: () => void
  onClose?: () => void
  onCancelOverride?: () => void
}

export type ModalConfig = null | Omit<ConfirmationModalProps, "isOpen" | "onClose">
export const useConfirmationControl = () => {
  const [confirmationModal, setConfirmationModal] = useState<ModalConfig>(null)

  const setConfirmationSubmitting = useCallback(
    (isSubmitting: boolean) =>
      setConfirmationModal(
        currentConfig =>
          currentConfig && {
            ...currentConfig,
            isSubmitting,
          }
      ),
    []
  )

  const clearConfirmationModal = useCallback(() => setConfirmationModal(null), [])

  return { confirmationModal, setConfirmationModal, setConfirmationSubmitting, clearConfirmationModal }
}

export const ConfirmationModal = ({
  isOpen,
  isError = false,
  title,
  message,
  isCancelable = true,
  disableCloseIcon = false,
  cancelLabel = "Cancel",
  actionLabel = "Confirm",
  children,
  isSubmitting = false,
  href,
  target = "_blank",
  disabled = false,
  onClose = noop,
  onConfirm,
  onCancelOverride,
  ...props
}: ConfirmationModalProps) => {
  const overrideCancel = !!onCancelOverride
  const linkStyle = href ? { ":hover": { color: "colors.white" } } : {}

  return (
    <Dialog
      open={isOpen}
      onClose={isCancelable ? onClose : noop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-qa="modal-confirmation"
      PaperProps={{ sx: { maxWidth: "28.125rem" } }}
      {...props}
    >
      <DialogTitle id="alert-dialog-title" variant="h3tiempos">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h3tiempos" sx={{ py: 2 }}>
            {title}
          </Typography>
          {isCancelable && !disableCloseIcon && (
            <Box ml={8}>
              <IconButton
                data-qa="alert-dialog-close-icon-button"
                aria-label="close"
                onClick={onClose}
                sx={{
                  color: theme => theme.palette.grey[900],
                  padding: 0,
                }}
                disabled={isSubmitting}
              >
                <CloseIcon className="dialog-close-icon" />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        {message && (
          <DialogContentText id="alert-dialog-description" data-qa={actionLabel} color="colors.darkBody">
            {message}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions sx={{ mb: 4, ml: 4, mr: 4 }}>
        {isCancelable && (
          <Button
            type="submit"
            color="inherit"
            onClick={overrideCancel ? onCancelOverride : onClose}
            data-qa="alert-dialog-cancel-button"
            disabled={isSubmitting}
            sx={{ mr: 2 }}
          >
            {cancelLabel}
          </Button>
        )}
        <Button
          color={isError ? "error" : "primary"}
          type="submit"
          variant="contained"
          data-qa="alert-dialog-confirm-button"
          autoFocus
          disabled={isSubmitting || disabled}
          startIcon={isSubmitting && <CircularProgress size={20} />}
          onClick={onConfirm}
          {...(href && { component: Link, href, target })}
          sx={{ ...linkStyle }}
        >
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
