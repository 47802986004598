import { getStateLabel, STATE_OPTIONS, UsaState } from "@/utils/States"
import { uniq } from "lodash"
import { CarrierIntegration, CarrierToFilter, CarrierUpdate } from "../../tcHubTypes"

export const buildCarriersForFiltering = (
  carriersUpdate: CarrierUpdate[] | undefined,
  carrierIntegrations: CarrierIntegration[] | undefined
): CarrierToFilter[] => {
  const carriersKeys = carriersUpdate?.map(({ id, name, state }) => ({ id, name, state })) ?? []
  const carrierIntegrationKeys = carrierIntegrations?.map(({ id, name, state }) => ({ id, name, state })) ?? []
  const carriersToFilterSet = new Set<CarrierToFilter>([...carriersKeys, ...carrierIntegrationKeys])

  return Array.from(carriersToFilterSet)
}

export const buildCarrierStateOptions = (carriersToFilter: CarrierToFilter[], carriers?: string[]) => {
  const compareStates = (a: { label: string }, b: { label: string }) => {
    if (a.label < b.label) return -1
    if (a.label > b.label) return 1

    return 0
  }

  if (!carriers || carriers.length === 0) {
    return STATE_OPTIONS.toSorted(compareStates)
  }
  const filteredCarrierUpdates = carriersToFilter.filter(c => carriers.includes(c.name))
  const stateSet = new Set<UsaState>()

  filteredCarrierUpdates.forEach(c => {
    stateSet.add(c.state)
  })

  const stateOptions: { label: string; value: string }[] = []

  stateSet.forEach(state => {
    stateOptions.push({
      label: getStateLabel(state),
      value: state,
    })
  })

  return stateOptions.toSorted(compareStates)
}

export const buildCarrierOptions = (carriers: CarrierToFilter[]) => {
  const uniqueCarrierNames = uniq(carriers.map(carrier => carrier.name))
  const sortedOptions = uniqueCarrierNames.toSorted()

  return ["", ...sortedOptions]
}
