import { DrawerForm } from "@/components/DrawerForm"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { SelectMultiField } from "@/components/SelectMultiField"
import { YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { getStateLabel } from "@/utils/States"
import { validateCarrierFilter } from "@/utils/validations"
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined"
import { Autocomplete, Button, Grid, TextField, Typography } from "@mui/material"
import { Formik } from "formik"
import { useMemo, useState } from "react"
import { BOTH_EXCHANGE, ENROLLMENT_OPTIONS, EXCHANGE_OPTIONS, OFF_EXCHANGE, ON_EXCHANGE } from "../tcHubConstants"
import { CarrierFilter, CarrierToFilter } from "../tcHubTypes"
import { buildCarrierOptions, buildCarrierStateOptions } from "./shared/CarrierService"

export const defaultCarrierFilter: CarrierFilter = {
  states: [],
  names: [],
  onExchange: undefined,
  offExchange: undefined,
  bothExchanges: undefined,
  payLater: undefined,
  crossStateCare: undefined,
  premiumPullDate: undefined,
  cutOffDate: undefined,
  enrollment: undefined,
}

export interface CarierMetadataDrawerProps {
  visible: boolean
  onClose: () => void
  carriers: CarrierToFilter[]
  carrierFilter: CarrierFilter
  setCarrierFilter?: (carrierFilter: CarrierFilter) => void
  resetCarrierFilter: () => void
}

const getInitialExchanges = (carrierFilter: CarrierFilter) => {
  const initialExchange = []

  if (carrierFilter.offExchange) {
    initialExchange.push(OFF_EXCHANGE)
  }
  if (carrierFilter.onExchange) {
    initialExchange.push(ON_EXCHANGE)
  }
  if (carrierFilter.bothExchanges) {
    initialExchange.push(BOTH_EXCHANGE)
  }

  return initialExchange
}

export const baseDataQa = "carrier-filter"

export const CarrierMetadataFilterDrawer = ({
  visible,
  onClose,
  carriers,
  carrierFilter,
  setCarrierFilter,
  resetCarrierFilter,
}: CarierMetadataDrawerProps) => {
  const carrierOptions = useMemo(() => buildCarrierOptions(carriers), [carriers])
  const [selectedExchange, setSelectedExchange] = useState<string[]>(() => getInitialExchanges(carrierFilter))

  const [states, setStates] = useState<{ label: string; value: string }[]>(() =>
    buildCarrierStateOptions(carriers, carrierFilter.names)
  )

  const [selectedStates, setSelectedStates] = useState(
    carrierFilter.states
      ? carrierFilter.states.map(state => ({
          label: getStateLabel(state),
          value: state,
        }))
      : []
  )

  const handleStateChange = (event: any, newValues: any) => {
    setSelectedStates(newValues || [])
  }

  const handleExchangeChange = (event: any) => {
    setSelectedExchange(event.target.value)
  }

  const handleCarrierChange = (carriersToFilter: string[]) => {
    const stateOptions = buildCarrierStateOptions(carriers, carriersToFilter)

    setStates(stateOptions)
  }

  return (
    <DrawerForm
      open={visible}
      onClose={onClose}
      paperStyle={{ padding: "1.5rem 2rem", width: { xs: "90%", md: "80%" }, maxWidth: "37.5rem" }}
      data-qa={baseDataQa}
    >
      <Formik
        initialValues={carrierFilter}
        validationSchema={validateCarrierFilter}
        onSubmit={values => {
          const filter: CarrierFilter = {
            ...values,
            onExchange: selectedExchange.length > 0 && selectedExchange.includes(ON_EXCHANGE) ? true : undefined,
            offExchange: selectedExchange.length > 0 && selectedExchange.includes(OFF_EXCHANGE) ? true : undefined,
            bothExchanges: selectedExchange.length > 0 && selectedExchange.includes(BOTH_EXCHANGE) ? true : undefined,
            states: selectedStates.map(state => state.value),
            premiumPullDate: values.premiumPullDate?.toString() === "" ? undefined : values.premiumPullDate,
            cutOffDate: values.cutOffDate?.toString() === "" ? undefined : values.cutOffDate,
          }

          if (setCarrierFilter) {
            setCarrierFilter(filter)
          }
          onClose()
        }}
      >
        {({ values, setValues, handleChange, handleBlur, handleSubmit, setFieldValue, isValid, errors, touched }) => {
          const cleanForm = () => {
            setSelectedExchange([])
            setSelectedStates([])
            setValues(defaultCarrierFilter)
            handleCarrierChange([])
            resetCarrierFilter()
          }

          return (
            <form noValidate data-qa="carrier-state-filter-form" onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    variant="text"
                    startIcon={<KeyboardArrowLeftOutlinedIcon />}
                    onClick={onClose}
                    sx={{ color: "colors.darkBody" }}
                    data-qa={`${baseDataQa}-back-button`}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid container justifyContent="space-between" sx={{ mt: 8, mb: 10 }}>
                  <Grid item>
                    <Typography variant="h1" data-qa={`${baseDataQa}-title`} gutterBottom>
                      Advanced Filtering
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      sx={{ color: "colors.darkBody", borderColor: "colors.darkBody" }}
                      data-qa={`${baseDataQa}-clear-all-button`}
                      onClick={() => {
                        cleanForm()
                      }}
                    >
                      Clear all
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Autocomplete
                      autoComplete
                      data-qa="carrier-options"
                      options={carrierOptions}
                      multiple
                      value={values.names}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Please select carrier"
                          label="Carriers"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      filterOptions={(options, { inputValue }) =>
                        options.filter(option => option.toLowerCase().includes(inputValue.toLowerCase()))
                      }
                      onChange={(event, newValues) => {
                        setFieldValue("names", newValues)
                        handleCarrierChange(newValues)
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      autoComplete
                      multiple
                      data-qa="state-options"
                      options={states}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      value={selectedStates}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Please select state"
                          label="States"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      filterOptions={(options, { inputValue }) =>
                        options.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase()))
                      }
                      onChange={handleStateChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectMultiField
                      selectedValues={selectedExchange}
                      fieldLabel="Exchange"
                      data={EXCHANGE_OPTIONS}
                      name="exchangeFilter"
                      placeholder="Please select exchange types"
                      data-qa="exchange-options"
                      onChange={handleExchangeChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      dataQa="enrollment-type-options"
                      data={ENROLLMENT_OPTIONS}
                      type="text"
                      name="enrollment"
                      label="Enrollments"
                      value={values.enrollment}
                      placeholder="Please select enrollment types"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={5}>
                  <RadioGroupCard
                    name="payLater"
                    label="Pay later"
                    value={values.payLater ?? ""}
                    formName="payLater"
                    elements={YES_NO_OPTIONS_BOOLEAN}
                    handleChange={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} mt={5}>
                  <RadioGroupCard
                    name="crossStateCare"
                    label="Cross state care"
                    value={values.crossStateCare ?? ""}
                    formName="crossStateCare"
                    elements={YES_NO_OPTIONS_BOOLEAN}
                    handleChange={setFieldValue}
                  />
                </Grid>
                <Grid container spacing={4} mt={5}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="premiumPullDate"
                      label="Premium pull day"
                      data-qa="premium-date-filter"
                      value={values.premiumPullDate ?? ""}
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ min: 1, max: 31 }}
                      error={Boolean(touched.premiumPullDate && errors.premiumPullDate)}
                      helperText={touched.premiumPullDate && errors.premiumPullDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="cutOffDate"
                      label="Cut-off date"
                      value={values.cutOffDate ?? ""}
                      InputLabelProps={{ shrink: true }}
                      data-qa="cut-off-date-filter"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ min: 1, max: 31 }}
                      error={Boolean(touched.cutOffDate && errors.cutOffDate)}
                      helperText={touched.cutOffDate && errors.cutOffDate}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="flex-end" mt={4}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        cleanForm()
                        onClose()
                      }}
                      data-qa="carrier-state-filter-cancel"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      disabled={!isValid}
                      variant="contained"
                      data-qa="carrier-state-filter-search"
                      color="primary"
                    >
                      Show Results
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </DrawerForm>
  )
}
