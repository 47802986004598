import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { ReactNode } from "react"

const AppContent = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
`

interface AppContentWrapperProps {
  children?: ReactNode
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps) => (
  <AppContent id="app-content-wrapper">{children}</AppContent>
)
