import { appConfig } from "@/config"
import { HrisStatus } from "@/features/Hris/hrisTypes"
import { axiosInstance as axios } from "@/services/axios"

const hrisBaseUrl = `${appConfig.baseApiUrl}`

export interface HrisConfig {
  hrisConfigId: string
  hrisType: string
  providerType: string
  status: HrisStatus
}

export interface HrisRosterResponse {
  roster: {
    hrisId: string
    companyId: string
    reconcileStatus: string
    rosterStatus: string
    firstName: string
    lastName: string
    email: string
    dateOfBirth: string
  }[]
}

export const getHrisConfig = async (companyId: string) => {
  const response = await axios.get<HrisConfig>(`${hrisBaseUrl}/v1/companies/${companyId}/hris-config`)

  return response.data
}
export const linkHrisConnection = async (companyId: string, publicToken: string) => {
  const response = await axios.patch<HrisConfig>(`${hrisBaseUrl}/v1/companies/${companyId}/link-hris`, {
    publicToken,
  })

  return response.data
}

export const getHrisRoster = async (companyId: string) => {
  const response = await axios.get<HrisRosterResponse>(`${hrisBaseUrl}/v1/companies/${companyId}/hris-roster`)
  return response.data
}
