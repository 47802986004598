import {
  CARRIERS,
  CARRIER_QUESTIONS,
  CHECKOUT,
  COMPARE_PLANS,
  COMPLETE,
  DOCTORS,
  FAMILY,
  FINAL_CHECK,
  HEALTH_NEEDS,
  HOSPITALS,
  HOUSEHOLD_INCOME,
  PERSONAL_INFO,
  PLAN_TYPE,
  PRESCRIPTIONS,
  RECOMMENDED_PLANS,
  RECURRING_REIMBURSEMENT,
  SELF_ENROLLED_PLAN,
  SELF_ENROLL_PURCHASE,
  SIGNATURES,
  SSN,
  SUMMARY,
  WAIVE_COVERAGE,
  WAIVE_COVERAGE_SUCCESS,
  WELCOME,
} from "@/features/BenefitsElection/benefitsElectionConstants"
import { BenefitsElectionLayout } from "@/features/BenefitsElection/pages/BenefitsElectionLayout"
import { ComparePlans } from "@/features/BenefitsElection/pages/findMyPlan/ComparePlans"
import { Carriers } from "@/features/BenefitsElection/pages/findMyPlan/questions/Carriers"
import { HealthNeeds } from "@/features/BenefitsElection/pages/findMyPlan/questions/HealthNeeds"
import { PlanType } from "@/features/BenefitsElection/pages/findMyPlan/questions/PlanType"
import { Doctors } from "@/features/BenefitsElection/pages/myInfo/Doctors"
import { Family } from "@/features/BenefitsElection/pages/myInfo/Family/Family"
import { Hospitals } from "@/features/BenefitsElection/pages/myInfo/Hospitals"
import { HouseholdIncome } from "@/features/BenefitsElection/pages/myInfo/HouseholdIncome/HouseholdIncome"
import { PersonalInfo } from "@/features/BenefitsElection/pages/myInfo/PersonalInfo"
import { Prescriptions } from "@/features/BenefitsElection/pages/myInfo/Prescriptions"
import { CarrierQuestions } from "@/features/BenefitsElection/pages/purchase/CarrierQuestions"
import { Complete } from "@/features/BenefitsElection/pages/purchase/Complete"
import { SelfEnroll } from "@/features/BenefitsElection/pages/purchase/SelfEnroll"
import { Signatures } from "@/features/BenefitsElection/pages/purchase/Signatures"
import { Ssn } from "@/features/BenefitsElection/pages/purchase/Ssn"
import { Summary } from "@/features/BenefitsElection/pages/purchase/Summary"
import { EmployeeWelcome } from "@/features/BenefitsElection/pages/welcome/EmployeeWelcome"
import { EmployeeWelcomeLayout } from "@/features/BenefitsElection/pages/welcome/EmployeeWelcomeLayout"
import { WaiveCoverage } from "@/features/BenefitsElection/pages/welcome/WaiveCoverage"
import { WaiveCoverageSuccess } from "@/features/BenefitsElection/pages/welcome/WaiveCoverageSuccess"
import { BenefitsElectionWithoutGuardLayout } from "./features/BenefitsElection/pages/BenefitsElectionWithoutGuardLayout"
import { FindPlan } from "./features/BenefitsElection/pages/findMyPlan/FindPlan"
import { FindPlanLayout } from "./features/BenefitsElection/pages/FindPlanLayout"
import { Checkout } from "./features/BenefitsElection/pages/purchase/Checkout"
import { FinalCheck } from "./features/BenefitsElection/pages/purchase/FinalCheck"
import { RecurringReimbursement } from "./features/BenefitsElection/pages/purchase/RecurringReimbursement"
import { SelfEnrolledPlan } from "./features/BenefitsElection/pages/purchase/SelfEnrolledPlan"

const baseUrl = "/benefits-election/:shoppingSessionId/"

export const benefitsElectionRoutes = [
  {
    path: baseUrl,
    element: <BenefitsElectionLayout />,
    children: [
      {
        path: PERSONAL_INFO,
        element: <PersonalInfo />,
      },
      {
        path: DOCTORS,
        element: <Doctors />,
      },
      {
        path: HOSPITALS,
        element: <Hospitals />,
      },
      {
        path: PRESCRIPTIONS,
        element: <Prescriptions />,
      },
      {
        path: FAMILY,
        element: <Family />,
      },
      {
        path: HOUSEHOLD_INCOME,
        element: <HouseholdIncome />,
      },
      {
        path: HEALTH_NEEDS,
        element: <HealthNeeds />,
      },
      {
        path: PLAN_TYPE,
        element: <PlanType />,
      },
      {
        path: CARRIERS,
        element: <Carriers />,
      },
      {
        path: CARRIER_QUESTIONS,
        element: <CarrierQuestions />,
      },
      {
        path: SSN,
        element: <Ssn />,
      },
      {
        path: SIGNATURES,
        element: <Signatures />,
      },
      {
        path: SELF_ENROLL_PURCHASE,
        element: <SelfEnroll />,
      },
      {
        path: RECURRING_REIMBURSEMENT,
        element: <RecurringReimbursement />,
      },
      {
        path: FINAL_CHECK,
        element: <FinalCheck />,
      },
      {
        path: SUMMARY,
        element: <Summary />,
      },
      {
        path: CHECKOUT,
        element: <Checkout />,
      },
      {
        path: RECURRING_REIMBURSEMENT,
        element: <RecurringReimbursement />,
      },
      {
        path: SELF_ENROLLED_PLAN,
        element: <SelfEnrolledPlan />,
      },
    ],
  },
  {
    element: <FindPlanLayout />,
    children: [
      {
        path: baseUrl + RECOMMENDED_PLANS,
        element: <FindPlan />,
      },
      {
        path: baseUrl + COMPARE_PLANS,
        element: <ComparePlans />,
      },
    ],
  },
  {
    element: <EmployeeWelcomeLayout />,
    children: [
      {
        path: baseUrl + WELCOME,
        element: <EmployeeWelcome />,
      },
      {
        path: baseUrl + WAIVE_COVERAGE,
        element: <WaiveCoverage />,
      },

      {
        path: baseUrl + COMPARE_PLANS,
        element: <ComparePlans />,
      },
    ],
  },
  {
    element: <BenefitsElectionWithoutGuardLayout />,
    children: [
      {
        path: baseUrl + COMPLETE,
        element: <Complete />,
      },
      {
        path: baseUrl + WAIVE_COVERAGE_SUCCESS,
        element: <WaiveCoverageSuccess />,
      },
    ],
  },
]
