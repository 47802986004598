import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { HeadCell, SimpleTable } from "@/components/Table/SimpleTable"
import { EXTERNAL_LINKS } from "@/constants"
import { isUserAdministrator, isUserEmployee } from "@/features/Auth/authUtils"
import { useAuth } from "@/features/Auth/useAuth"
import { useNotifications } from "@/services/notificationService"
import { createDataQa, DataQa } from "@/utils/dataQa"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  TableCell,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { orderBy, uniqBy } from "lodash"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { ServicesAgreementModal as AcknowledgementModal } from "../components/ServiceAgreement/ServiceAgreementModal"
import {
  APPLICATION_PDF,
  EMPLOYEE_DOCUMENT_TYPES,
  ICHRA_ADOPTION_AGREEMENT,
  PAYROLL_REPORT,
  RECONCILIATION_PAYROLL_REPORT,
  SERVICES_AGREEMENT,
  SERVICES_AGREEMENT_DOCUMENT_NAME,
  STATIC_DOCUMENTS,
} from "../documentsConstants"
import {
  useCompanyDocDownloadUrl,
  useCompanyDocuments,
  useEmployeeDocDownloadUrl,
  useEmployeeDocuments,
} from "../documentsService"
import { ServiceDocument } from "../documentsTypes"

const DOCUMENT_TYPES_TO_FILTER = [PAYROLL_REPORT, RECONCILIATION_PAYROLL_REPORT, ICHRA_ADOPTION_AGREEMENT]

const headCells: HeadCell[] = [
  { id: "name", name: "name", label: "Name", align: "left", padding: "none" },
  { id: "status", name: "status", label: "Status", align: "left", padding: "none" },
  { id: "action", name: "action", label: "Action", align: "center", padding: "none" },
]

export const getUrl = (url: string) => STATIC_DOCUMENTS.find(doc => doc.url === url)?.file ?? url

const getDocumentRowUrl = (document: ServiceDocument) => (document.urlType === "static" ? document.url : "")

const isServiceAgreementDocument = (docType: string) =>
  docType.includes(SERVICES_AGREEMENT_DOCUMENT_NAME) || docType.includes(SERVICES_AGREEMENT)

interface DownloadIconButtonProps {
  dataQa: DataQa
  onClick: () => void
  ariaLabel?: string
  isLoading: boolean
}

const DownloadIconButton = ({ dataQa, onClick, ariaLabel, isLoading }: DownloadIconButtonProps) => (
  <IconButton data-qa={dataQa} onClick={onClick} aria-label={ariaLabel} size="large">
    {isLoading ? <CircularProgress size={24} /> : <VisibilityOutlinedIcon color="inherit" />}
  </IconButton>
)

const openLink = (url: string) => {
  window.open(url, "_blank")
}

interface HyperLinkCellProps {
  name: string
  url: string
  onDocClick?: (documentId?: string | undefined) => void
}

export const HyperLinkCell = ({ name, url, onDocClick }: HyperLinkCellProps) => {
  const isServiceAgreement: boolean = name.includes("Services Agreement")
  return (
    <Link
      href={isServiceAgreement ? EXTERNAL_LINKS.SERVICE_AGREEMENT : (getUrl(url) ?? "#")}
      data-qa={createDataQa("hyperlink-cell", name)}
      underline="always"
      target="_blank"
      download={!isServiceAgreement && `${name}.pdf`}
      onClick={event => {
        if (!url && !isServiceAgreement) {
          event.stopPropagation()
          event.preventDefault()
          if (onDocClick) {
            onDocClick()
          }
        }
      }}
    >
      {name}
    </Link>
  )
}

export const getStatus = ({ isAcknowledgementRequired, acknowledgedAt, createdAt }: ServiceDocument) => {
  if (isAcknowledgementRequired && acknowledgedAt) {
    return `Acknowledged ${moment(acknowledgedAt).format("MM/DD/YYYY")}`
  }
  if (isAcknowledgementRequired && !acknowledgedAt) {
    return "Attention Required"
  }

  return `Created ${moment(createdAt).format("MM/DD/YYYY")}`
}

export const getOrderByFunction = (document: ServiceDocument, sortKey: string | undefined) => {
  if (sortKey === "name") {
    return document.name
  }

  if (sortKey === "status") {
    return getStatus(document)
  }

  if (sortKey === "action") {
    return document.isAcknowledgementRequired && !document.acknowledgedAt
  }
}

const concatDocuments = (arrayA: ServiceDocument[], arrayB: ServiceDocument[]) =>
  uniqBy(arrayA.concat(arrayB), "documentId")

interface DocumentsTableProps {
  isEROnboarding: boolean
}

export const DocumentsTable = ({ isEROnboarding }: DocumentsTableProps) => {
  const { notify } = useNotifications("documents")
  const { user } = useAuth()
  // FUTURE: remove these unsafe non-null assertions
  const company = user?.company
  const companyId = company?.companyId!
  const employeeId = company?.employeeId!
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"))
  const roles = company?.roles ?? []
  const isAdmin = isUserAdministrator(roles)
  const isEmployee = isUserEmployee(roles)
  const [acknowledgedDocumentId, setAcknowledgedDocumentId] = useState<string | undefined>("")
  const [month, setMonth] = useState(moment().format("MMMM"))
  const [openAcknowledgementModal, setOpenAcknowledgementModal] = useState(false)
  const [order, setOrder] = useState<"desc" | "asc" | undefined>()
  const [retryCount, setRetryCount] = useState(1)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | undefined>("")
  const [selectedDocumentType, setSelectedDocumentType] = useState<string | undefined>()
  const [sortKey, setSortKey] = useState<string | undefined>("")
  const [year, setYear] = useState(moment().year())

  const {
    data: companyDocuments,
    status: companyDocumentsStatus,
    isLoading: isCompanyDocumentsLoading,
    refetch: refetchCompanyDocuments,
  } = useCompanyDocuments(companyId, isAdmin)

  const {
    data: employeeDocuments,
    status: employeeDocumentsStatus,
    isLoading: isEmployeeDocumentsLoading,
    refetch: refetchEmployeeDocuments,
  } = useEmployeeDocuments(companyId, employeeId, isEmployee)

  const {
    data: companyUrl,
    isLoading: isLoadingDocument,
    isError: hasUrlFetchingError,
  } = useCompanyDocDownloadUrl(companyId, selectedDocumentId, isAdmin, isEmployee)

  const {
    data: employeeUrl,
    isLoading: isLoadingEmployeeDocument,
    isError: hasEmployeeUrlFetchingError,
  } = useEmployeeDocDownloadUrl(companyId, employeeId, selectedDocumentId, isEmployee)

  const viewAdminDocumentsOnly = isAdmin && companyDocuments && !isEmployee && retryCount
  const viewEmployeeDocumentsOnly = isEmployee && employeeDocuments && !isAdmin && retryCount
  const viewEmployeeAndAdminDocuments = isEmployee && employeeDocuments && isAdmin && companyDocuments
  const isSelectedEmployeeDoc = EMPLOYEE_DOCUMENT_TYPES.find(doc => doc.toString() === selectedDocumentType)

  //Filter out the latest adoption agreement and payroll reports
  const documents = useMemo(() => {
    let documentsByRole: ServiceDocument[] = []

    const latestIchraAdoptionAgreement = companyDocuments
      ?.filter(doc => doc.documentType === ICHRA_ADOPTION_AGREEMENT)
      ?.reduce(
        (latest, current) => (new Date(current?.createdAt) > new Date(latest?.createdAt) ? current : latest),
        companyDocuments[0]
      )

    const filteredCompanyDocuments =
      companyDocuments?.filter(
        doc =>
          !DOCUMENT_TYPES_TO_FILTER.includes(doc.documentType) ||
          doc.documentId === latestIchraAdoptionAgreement?.documentId
      ) ?? []

    const filteredEmployeeDocuments = employeeDocuments?.filter(doc => doc.documentType !== APPLICATION_PDF) ?? []

    if (viewEmployeeAndAdminDocuments) {
      documentsByRole = concatDocuments(filteredCompanyDocuments, filteredEmployeeDocuments)
    } else if (viewAdminDocumentsOnly) {
      documentsByRole = [...filteredCompanyDocuments]
    } else if (viewEmployeeDocumentsOnly) {
      documentsByRole = [...filteredEmployeeDocuments]
    }

    if (documentsByRole.length > 0 && order && sortKey) {
      const sortedDocuments = orderBy(documentsByRole, document => getOrderByFunction(document, sortKey), order)

      return sortedDocuments
    }

    return documentsByRole
  }, [
    companyDocuments,
    employeeDocuments,
    viewAdminDocumentsOnly,
    viewEmployeeDocumentsOnly,
    viewEmployeeAndAdminDocuments,
    order,
    sortKey,
  ])

  const yearsList = useMemo(() => {
    const years = documents.map(doc => new Date(doc.createdAt).getFullYear())

    return [...new Set(years)]
  }, [documents])

  useEffect(() => {
    if (isSelectedEmployeeDoc && employeeUrl) {
      openLink(employeeUrl)
      setSelectedDocumentId(undefined)
      setSelectedDocumentType(undefined)
    }
    if (!isSelectedEmployeeDoc && companyUrl) {
      openLink(companyUrl)
      setSelectedDocumentId(undefined)
      setSelectedDocumentType(undefined)
    }
  }, [companyUrl, employeeUrl, isSelectedEmployeeDoc])

  useEffect(() => {
    if ((isSelectedEmployeeDoc && hasEmployeeUrlFetchingError) || (!isSelectedEmployeeDoc && hasUrlFetchingError)) {
      notify(
        "Unable to retrieve the document URL. Please reach out to the system administrator for assistance.",
        "error"
      )
      setSelectedDocumentId(undefined)
      setSelectedDocumentType(undefined)
    }
  }, [hasUrlFetchingError, hasEmployeeUrlFetchingError, isSelectedEmployeeDoc, notify])

  useEffect(() => {
    if (companyDocumentsStatus === "error" || employeeDocumentsStatus === "error") {
      notify("Error fetching all documents", "error")
    }

    if (companyDocumentsStatus === "success" || employeeDocumentsStatus === "success") {
      setOrder("asc")
      setSortKey("name")
    }
  }, [companyDocumentsStatus, employeeDocumentsStatus, notify])

  const handleSortClick = (id: string) => {
    const isAsc = sortKey === id && order === "asc"

    setOrder(isAsc ? "desc" : "asc")
    setSortKey(id)
  }

  const onDocumentNameClicked = (documentId: string, documentType: string) => {
    setSelectedDocumentId(documentId)
    setSelectedDocumentType(documentType)
  }

  const handleDownloadIconButtonClick = (documentId: string, documentType: string) => {
    if (isServiceAgreementDocument(documentType.toString())) {
      openLink(EXTERNAL_LINKS.SERVICE_AGREEMENT)
    } else {
      onDocumentNameClicked(documentId, documentType)
    }
  }

  const createRows = (row: ServiceDocument) => (
    <>
      <TableCell align="left">
        <HyperLinkCell
          name={row.name}
          url={getDocumentRowUrl(row)}
          onDocClick={() => onDocumentNameClicked(row.documentId, row.documentType)}
        />
      </TableCell>
      <TableCell align="left">{getStatus(row)}</TableCell>
      <TableCell align="left">
        <Box display="flex" flexDirection="row" justifyContent="center" gap={2}>
          {row.isAcknowledgementRequired && !row.acknowledgedAt && (
            <Button
              variant="outlined"
              onClick={() => {
                setAcknowledgedDocumentId(row.documentId)
                setOpenAcknowledgementModal(true)
              }}
              sx={{
                color: "inherit",
                maxHeight: "2.5rem",
                my: 1,
              }}
            >
              Acknowledge
            </Button>
          )}
          <DownloadIconButton
            isLoading={(isLoadingDocument || isLoadingEmployeeDocument) && row.documentId === selectedDocumentId}
            dataQa={createDataQa("document", "item", "download", row.documentId)}
            onClick={() => handleDownloadIconButtonClick(row.documentId, row.documentType)}
            ariaLabel="download-document"
          />
        </Box>
      </TableCell>
    </>
  )

  return (
    <Grid container data-qa="documents-container">
      <AcknowledgementModal
        isOpen={openAcknowledgementModal}
        onClose={() => {
          setOpenAcknowledgementModal(false)
          setAcknowledgedDocumentId(undefined)
        }}
        showClose
        user={user}
        documentId={acknowledgedDocumentId}
        companyId={companyId}
        isAdmin={isAdmin}
        isEmployee={isEmployee}
        documentName={documents.find(doc => doc.documentId === acknowledgedDocumentId)?.name}
        onSuccessfulAcknowledgement={async () => {
          await refetchEmployeeDocuments()
          await refetchCompanyDocuments()
          setRetryCount(prevRetryCount => prevRetryCount + 1)
        }}
      />

      <Grid item xs={12} mb={isMobile ? 6 : 0}>
        <Typography data-qa="documents-description">
          View and sign documents provided to you by Take Command.
        </Typography>
      </Grid>
      {!isEROnboarding && (
        <Grid container gap={4} item mt={{ xs: 6, md: 12 }}>
          <Grid item>
            <FormControl sx={{ width: "10rem" }}>
              <InputLabel id="month-select-label">Month</InputLabel>
              <Select
                id="month"
                label="Month"
                value={month}
                onChange={event => {
                  setMonth(event.target.value)
                }}
                data-qa="documents-month-select"
                size="small"
                sx={{ backgroundColor: "white" }}
              >
                {moment.months().map(item => (
                  <MenuItem key={item} value={item} data-qa={`menuitem-${item}`}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <InputLabel id="year-select-label">Year</InputLabel>
              <Select
                id="year"
                label="Year"
                value={year}
                onChange={event => {
                  setYear(event.target.value as number)
                }}
                data-qa="documents-year-select"
                size="small"
                sx={{ backgroundColor: "white" }}
              >
                {yearsList.map(yearItem => (
                  <MenuItem key={yearItem} value={yearItem} data-qa={`menuitem-${yearItem}`}>
                    {yearItem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}

      {!isMobile ? (
        <Grid item xs={12} pt={4}>
          <Paper data-qa="documents-Table">
            {!(isCompanyDocumentsLoading || isEmployeeDocumentsLoading) ? (
              <SimpleTable
                hasPagination={false}
                headCells={headCells}
                isSortable
                sortBy={sortKey}
                sortDirection={order}
                handleSortClick={handleSortClick}
                rowData={documents}
                rowHover={false}
                rowCells={createRows}
                tableName="documents"
                getUniqueId={doc => doc.documentId}
              />
            ) : (
              <SkeletonTableLoader
                data-qa="skeleton-table-loader-documents"
                headerTitles={headCells.map(cell => `${cell.label}`)}
                bodyRowsCount={10}
              />
            )}
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12} pt={8}>
          <Paper>
            <Stack direction="column" divider={<Divider orientation="horizontal" flexItem />} spacing={0} py={2}>
              {documents.map(doc => {
                const documentStatus = getStatus(doc)

                return (
                  <Grid container item xs={12} key={doc.documentId} px={2}>
                    <Grid item xs={10} pt={2} px={2}>
                      <HyperLinkCell
                        name={doc.name}
                        url={getDocumentRowUrl(doc)}
                        onDocClick={() => {
                          onDocumentNameClicked(doc.documentId, doc.documentType)
                        }}
                      />
                    </Grid>
                    <Grid item container xs={12} pt={4} px={2}>
                      <Grid item xs={4}>
                        Status
                      </Grid>
                      <Grid item xs={8}>
                        {documentStatus}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{ pb: 2, px: 2 }}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <p>Action</p>
                      </Grid>
                      {doc.isAcknowledgementRequired && !doc.acknowledgedAt && (
                        <Grid item xs={6}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setAcknowledgedDocumentId(doc.documentId)
                              setOpenAcknowledgementModal(true)
                            }}
                          >
                            Acknowledge
                          </Button>
                        </Grid>
                      )}
                      <Grid item xs={2}>
                        <DownloadIconButton
                          isLoading={
                            (isLoadingDocument || isLoadingEmployeeDocument) && doc.documentId === selectedDocumentId
                          }
                          dataQa={createDataQa("document", "item", "download", doc.documentId)}
                          onClick={() => handleDownloadIconButtonClick(doc.documentId, doc.documentType)}
                          ariaLabel="download-document"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            </Stack>
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}
