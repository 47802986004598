import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable } from "@/components/Table/Table"
import { BANK_ACCOUNT_PAYMENT_OPTION } from "@/features/BenefitsElection/benefitsElectionConstants"
import { CarrierStateItemDropdownMenu } from "@/features/TCHub/Carriers/CarrierStateItemDropdownMenu"
import { CarrierFilter, CarrierUpdate, CarrierUpdatesTableHeader } from "@/features/TCHub/tcHubTypes"
import { createDataQa } from "@/utils/dataQa"
import { getStateLabel } from "@/utils/States"
import { Box, CircularProgress, TableCell, Tooltip, Typography } from "@mui/material"
import { constant } from "lodash"
import { useState } from "react"
import { useMultiSelectHeader } from "../tcHubService"

export interface CarrierUpdatesTableProps {
  editable?: boolean
  multiSelect?: boolean
  carriers: CarrierUpdate[]
  isLoadingCarrierUpdates?: boolean
  carrierFilter?: CarrierFilter
}

const headers: CarrierUpdatesTableHeader[] = [
  { id: "select", label: "", sortable: false, alignment: "center" },
  { id: "carrier", label: "Carrier", sortable: true, field: "name", alignment: "left" },
  { id: "state", label: "State", sortable: true, field: "state", alignment: "center" },
  { id: "logoUrl", label: "Logo", sortable: false, field: "logoUrl", alignment: "center" },
  { id: "status", label: "Status", sortable: false, field: "status", alignment: "center" },
  {
    id: "action-item",
    label: "Actions",
    alignment: "center",
  },
]

const getFilteredCarriers = (carrierFilter: CarrierFilter, carriers: CarrierUpdate[]) => {
  let filteredCarriers: CarrierUpdate[] = [...carriers]

  if (carrierFilter.names.length > 0) {
    filteredCarriers = filteredCarriers.filter(carrier => carrierFilter.names.includes(carrier.name))
  }

  if (carrierFilter.states && carrierFilter.states.length > 0) {
    filteredCarriers = filteredCarriers.filter(carrier => carrierFilter.states.includes(carrier.state))
  }

  return filteredCarriers
}

export const CarrierUpdatesTable = ({
  editable,
  multiSelect,
  carriers,
  isLoadingCarrierUpdates,
  carrierFilter,
}: CarrierUpdatesTableProps) => {
  const displayHeaders: CarrierUpdatesTableHeader[] = useMultiSelectHeader(headers, multiSelect)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const defaultOrder = { headCellId: "updatedAt", direction: "desc" } as const
  const filteredCarriers = carrierFilter ? getFilteredCarriers(carrierFilter, carriers) : carriers

  if (isLoadingCarrierUpdates) {
    return (
      <SkeletonTableLoader
        headerTitles={["Carrier", "State", "Logo URL", "Status", "Actions"]}
        bodyRowsCount={8}
        data-qa="skeleton-carrier-state-table"
      />
    )
  }

  return (
    <BaseTable
      rows={filteredCarriers}
      selected={[]}
      searchCriteria=""
      onToggleSelect={() => []}
      onToggleOrderBy={() => []}
      onToggleSelectAll={() => []}
      onPageChange={changedPage => setPage(changedPage)}
      onRowsPerPageChange={rows => setRowsPerPage(rows)}
      uniqueIdSelector={constant("")}
      headCells={displayHeaders}
      rowsPerPage={rowsPerPage}
      page={page}
      fullWidth
      orderBy={[defaultOrder]}
    >
      {({ row }) => (
        <>
          {isLoadingCarrierUpdates && (
            <TableCell>
              <CircularProgress size={24} />
            </TableCell>
          )}
          <TableCell>
            <Typography variant="body1">{row.name}</Typography>
          </TableCell>
          <TableCell align="center" sx={{ pl: 0, pr: 5 }}>
            <Typography variant="body1">{getStateLabel(row.state)}</Typography>
          </TableCell>
          <TableCell align="center">
            <Box
              sx={{
                minHeight: "3.5rem",
                maxHeight: "3.5rem",
              }}
            >
              <img
                src={row.logoUrl}
                alt={`${row.name} logo`}
                style={{
                  width: "90px",
                  objectFit: "contain",
                  minHeight: "3.5rem",
                  maxHeight: "3.5rem",
                }}
                data-qa={createDataQa("carrier-integration-table", "carrier-logo")}
              />
            </Box>
          </TableCell>
          <TableCell align="center">
            {row.status === "UPDATE" ? (
              <Tooltip title={row.description} data-qa="custom-tooltip">
                <Typography variant="body1">{row.status.toLocaleLowerCase()}</Typography>
              </Tooltip>
            ) : (
              <Typography variant="body1">{row.status.toLocaleLowerCase()}</Typography>
            )}
          </TableCell>

          {editable && (
            <TableCell align="center">
              <CarrierStateItemDropdownMenu
                itemId={`${row.state}-${row.name}`}
                dataQa={`${row.state}-${row.name}-menu`}
                carrierIntegration={{
                  id: row.id,
                  name: row.name,
                  state: row.state,
                  logoUrl: row.logoUrl,
                  onExchangePaymentOption: BANK_ACCOUNT_PAYMENT_OPTION,
                  offExchangePaymentOption: BANK_ACCOUNT_PAYMENT_OPTION,
                  bothExchangesPaymentOption: BANK_ACCOUNT_PAYMENT_OPTION,
                }}
              />
            </TableCell>
          )}
        </>
      )}
    </BaseTable>
  )
}
