import { Uuid } from "@/utils/types"
import { DateTime } from "luxon"
import { PAYLOAD_ADDRESS_TYPE } from "../People/AddPersonModal/types"
import { ROLE_NAMES_TO_IDS } from "../People/peopleConstants"
import { EmploymentModel, PersonModel, Roles } from "../People/peopleTypes"
import { EditPersonValues } from "./editPersonValidations"

const filterRoles = (roles: any) =>
  [
    { id: ROLE_NAMES_TO_IDS["System Administrator"], name: "System Administrator" },
    { id: ROLE_NAMES_TO_IDS.Administrator, name: "Administrator" },
    { id: ROLE_NAMES_TO_IDS.Employee, name: "Employee" },
    { id: ROLE_NAMES_TO_IDS["Benefits Consultant"], name: "Benefits Consultant" },
  ].filter(
    role =>
      (roles.administrativeRole === "system_admin" && role.name === "System Administrator") ||
      (roles.administrativeRole === "admin" && role.name === "Administrator") ||
      (roles.employeeRole && role.name === "Employee") ||
      (roles.benefitsConsultantRole && role.name === "Benefits Consultant")
  ) as Roles[]

export const addPersonFormToPayload = (
  {
    id,
    firstName,
    lastName,
    dateOfBirth,
    email,
    phoneNumber,
    homeAddress: { id: idAddress, street1, street2, city, county, state, zip },
    roles,
  }: EditPersonValues,
  companyId: Uuid
): Partial<PersonModel> => {
  const payload: Partial<PersonModel> = {}

  payload.id = id
  payload.firstName = firstName
  payload.lastName = lastName
  payload.dateOfBirth = dateOfBirth
  payload.email = email
  payload.phoneNumber = phoneNumber
  payload.addresses = [
    {
      id: idAddress ?? "",
      type: PAYLOAD_ADDRESS_TYPE,
      street1: street1 ?? "",
      street2: street2 ?? "",
      city: city ?? "",
      county: county ?? "",
      state: state ?? "",
      zip: zip ?? "",
    },
  ]
  payload.roles = filterRoles(roles)
  return payload
}

export const updatePersonFormToPayload = (
  {
    id,
    firstName,
    lastName,
    dateOfBirth,
    email,
    phoneNumber,
    homeAddress: { id: idAddress, street1, street2, city, county, state, zip },
    businessUnit,
    roles,
  }: EditPersonValues,
  companyId: string,
  employmentId: string
): Partial<PersonModel> => {
  const payload: Partial<PersonModel> = {}

  payload.id = id
  payload.firstName = firstName
  payload.lastName = lastName
  payload.dateOfBirth = dateOfBirth
  payload.email = email
  payload.phoneNumber = phoneNumber
  payload.addresses = [
    {
      id: idAddress ?? "",
      type: PAYLOAD_ADDRESS_TYPE,
      street1: street1 ?? "",
      street2: street2 ?? "",
      city: city ?? "",
      county: county ?? "",
      state: state ?? "",
      zip: zip ?? "",
    },
  ]

  const employment: Partial<EmploymentModel> = {
    id: employmentId as Uuid,
    companyId,
    personId: id,
    businessUnitId: businessUnit as Uuid,
  }
  payload.employments = [employment as EmploymentModel]

  payload.roles = filterRoles(roles)

  return payload
}

export const getPersonWithFormatDate = (person: Partial<PersonModel>) => ({
  ...person,
  dateOfBirth: person.dateOfBirth ? DateTime.fromISO(person.dateOfBirth.toISOString()).toISODate() : null,
  phoneNumber: {
    value: person.phoneNumber && person.phoneNumber.length > 0 ? person.phoneNumber : null,
  },
})
