import { Footer } from "@/components/Footer"
import { EXTERNAL_LINKS } from "@/constants"
import { IchraResult } from "@/features/HRAPlanDesign/components/IchraResult"
import { QsehraResult } from "@/features/HRAPlanDesign/components/QsehraResult"
import { Button, Grid, Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import { HraQuizNavbar } from "../components/HraQuizNavbar"

type HraType = "qsehra" | "ichra-diy" | "ichra-non-diy"

const renderResults = (plan: HraType) => {
  switch (plan) {
    case "qsehra":
      return <QsehraResult />
    case "ichra-diy":
      return <IchraResult plan="diy" />
    case "ichra-non-diy":
      return <IchraResult plan="non-diy" />
    default:
      return <div>ERROR</div>
  }
}

const renderHelmetTitle = (plan: HraType) => {
  switch (plan) {
    case "qsehra":
      return "QSEHRA Results"
    case "ichra-diy":
      return "ICHRA DIY Results"
    case "ichra-non-diy":
      return "ICHRA Results"
    default:
      return "Error"
  }
}

interface Params extends Record<string, string | undefined> {
  plan: HraType
}

export const HraPlanDesignResults = () => {
  const { plan } = useParams<Params>()

  return (
    <>
      <HraQuizNavbar />
      {plan && <Helmet title={`HRA Prescreen - ${renderHelmetTitle(plan)} `} />}
      <Grid container justifyContent="center" sx={{ pt: 15 }}>
        {plan && renderResults(plan)}
      </Grid>
      <Grid container sx={{ backgroundColor: "primary.dark", mt: 15 }} justifyContent="center">
        <Grid item>
          <Typography
            variant="h1"
            align="center"
            sx={{ mt: 12 }}
            color="primary.contrastText"
            data-qa="HRA-plan-design-more-questions-title"
          >
            Have More Questions?
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            align="center"
            data-qa="HRA-plan-design-more-questions-body"
            color="primary.contrastText"
            sx={{ my: 8, px: 20 }}
          >
            Additional help is available. Get on the right path to designing the ideal benefits solution for your
            organization. Our expert team of HRA plan design specialists will help you out.
          </Typography>
        </Grid>
        <Button
          variant="outlined"
          data-qa="HRA-plan-design-more-questions-get-in-touch-button"
          href={EXTERNAL_LINKS.SUPPORT}
          sx={{
            my: 8,
            backgroundColor: "primary.contrastText",
            color: "colors.darkBody",
          }}
        >
          Get in touch
        </Button>
      </Grid>
      <Footer />
    </>
  )
}
