import { appConfig } from "@/config"
import { ALL_COMPANY_ACCESS_ID, TOKEN_REFRESH_THRESHOLD_MS } from "@/constants"
import { INITIALIZE, SIGN_OUT } from "@/features/Auth/authConstants"
import { AuthState, AuthUser, CognitoActions, CompanyAssociation, CompanyRole } from "@/features/Auth/authTypes"
import { getEnrollmentTimePeriods, getShoppingSessions } from "@/features/BenefitsElection/benefitsElectionEndpoints"
import { EnrollmentTimePeriod } from "@/features/BenefitsElection/benefitsElectionTypes"
import { getHraPlan } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupEndpoints"
import { getCurrentClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureEndpoints"
import { getCompany, getOnboardingStatus } from "@/features/CreateCompany/createCompanyEndpoints"
import { EMPLOYEE_EXTERNAL_ROLE_ID } from "@/features/People/peopleConstants"
import { sendSignUpLinkByEmail } from "@/features/People/peopleManagementEndpoints"
import { axiosInstance as axios } from "@/services/axios"
import { Uuid } from "@/utils/types"
import { isValidUuid } from "@/utils/validations"
import { datadogRum } from "@datadog/browser-rum"
import {
  AuthenticationDetails,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js"
import { some } from "lodash"
import moment from "moment"
import { Dispatch } from "react"
import { isUserAdministrator } from "../authUtils"
import { getAuthenticatedUser } from "../pages/Mfa/mfaService"
import { fetchProfileDetails } from "./profileService"

const internals: {
  userInChallenge: CognitoUser | null
  challengeName?: string
} = {
  userInChallenge: null,
  challengeName: undefined,
}

export const UserPool = new CognitoUserPool({
  UserPoolId: appConfig.userPoolId || "",
  ClientId: appConfig.clientId || "",
})

export const sendPasswordResetEmail = async (email: string) => {
  const user = new CognitoUser({
    Username: email,
    Pool: UserPool,
  })

  return new Promise<boolean>((resolve, reject) => {
    user.forgotPassword({
      onSuccess: (data: CognitoUserSession) => {
        resolve(true)
      },
      onFailure: (err: any) => {
        const handleFailure = async () => {
          if (err.code === "NotAuthorizedException") {
            try {
              await sendSignUpLinkByEmail(email)
              resolve(true)
            } catch (error) {
              console.error("Failed to send sign-up link", error)
              reject(new Error("Failed to send sign-up link"))
            }
          } else {
            reject(new Error("Failed to send password reset email"))
          }
        }

        handleFailure()
      },
    })
  })
}

export const changeUserPassword = async (oldPassword: string, newPassword: string): Promise<boolean> => {
  const user = await getAuthenticatedUser()
  if (!user) {
    return false
  }

  return new Promise<boolean>((resolve, reject) => {
    user.changePassword(oldPassword, newPassword, (err: any) => {
      if (err) {
        console.error(err)
        reject(new Error("Incorrect password"))
      } else {
        resolve(true)
      }
    })
  })
}

export const completePasswordReset = async (email: string, newPassword: string, verificationCode: string) => {
  const user = new CognitoUser({
    Username: email,
    Pool: UserPool,
  })

  return new Promise<boolean>((resolve, reject) => {
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        resolve(true)
      },
      onFailure: (err: any) => {
        if (err.code === "CodeMismatchException" || err.code === "ExpiredCodeException") {
          reject("The link has expired. Please contact your administrator.")
        } else {
          reject(err)
        }
      },
    })
  })
}

export const completeNewPasswordChallenge = async (
  personId: string,
  email: string,
  tempPassword: string,
  newPassword: string,
  firstName: string,
  lastName: string
) => {
  const user = new CognitoUser({
    Username: personId,
    Pool: UserPool,
  })

  const authDetails = new AuthenticationDetails({
    Username: personId,
    Password: tempPassword,
  })

  return new Promise<boolean>((resolve, reject) =>
    user.authenticateUser(authDetails, {
      onSuccess: async (data: CognitoUserSession) => {
        resolve(true)
      },
      onFailure: (err: any) => {
        reject(err)
      },
      newPasswordRequired: (userAttributes: any) => {
        userAttributes.name = `${firstName} ${lastName}`

        // NOTE: Remove immutable attributes from userAttributes. If not
        // removed, the new password challenge will fail.
        if ("email" in userAttributes) {
          delete userAttributes.email
        }

        if ("email_verified" in userAttributes) {
          delete userAttributes.email_verified
        }

        if ("phone_number" in userAttributes) {
          delete userAttributes.phone_number
        }

        if ("phone_number_verified" in userAttributes) {
          delete userAttributes.phone_number_verified
        }

        user.completeNewPasswordChallenge(newPassword, userAttributes, {
          onSuccess: (data: CognitoUserSession) => {
            resolve(true)
          },
          onFailure: (err: any) => {
            if (err.code === "CodeMismatchException" || err.code === "ExpiredCodeException") {
              reject("The link has expired. Please contact your administrator.")
            } else {
              reject(err)
            }
          },
        })
      },
    })
  )
}

export const canEmployeeShop = ({ canShop, periodStartUtc, periodEndUtc }: EnrollmentTimePeriod) =>
  canShop && moment().isBetween(moment(periodStartUtc), moment(periodEndUtc))

export const getCurrentEnrollmentTimePeriod = (enrollmentTimePeriods: EnrollmentTimePeriod[]) =>
  enrollmentTimePeriods.find(enrollmentTimePeriod => canEmployeeShop(enrollmentTimePeriod))

export const fetchShoppingSessionDetails = async (employeeId: string) => {
  try {
    const enrollmentTimePeriods = await getEnrollmentTimePeriods(employeeId, true)
    const currentEnrollmentTimePeriod = getCurrentEnrollmentTimePeriod(enrollmentTimePeriods)

    if (currentEnrollmentTimePeriod) {
      const shoppingSessionDetails = await getShoppingSessions(employeeId, currentEnrollmentTimePeriod.id, true)

      return shoppingSessionDetails[0]
    }
  } catch (error) {
    console.warn("Error fetching shopping session details for employee: ", employeeId, error)
  }
}

export const getUserAttributes = (currentUser: CognitoUser): Promise<Attributes> =>
  new Promise((resolve, reject) => {
    currentUser.getUserAttributes((err, attributes) => {
      if (err) {
        reject(err)
      } else {
        const results: Attributes = {}

        attributes?.forEach(attribute => {
          results[attribute.Name] = attribute.Value
        })
        resolve(results)
      }
    })
  })

export type AuthServiceUser = Record<string, any> | AuthUser

type Attributes = Record<string, any>

export const getAllCompanyAssociations = (user: AuthServiceUser) => {
  try {
    return JSON.parse(user?.["custom:company_associations"])
  } catch (e) {
    console.warn("Failed to parse user's company associations — ", e)
    return []
  }
}

export const getActiveCompany = (user: AuthServiceUser): CompanyAssociation => {
  try {
    if (!user) {
      console.warn("Attempted to get company of invalid user")
      return undefined as never
    }
    const companyAssociations = getAllCompanyAssociations(user)
    const companyIndex = Number(localStorage.getItem(`${user.sub}-current-company-index`) ?? 0)
    const activeCompany = companyAssociations?.[companyIndex]
    const isAdminOnly = activeCompany?.companyId === ALL_COMPANY_ACCESS_ID && companyAssociations?.length === 1
    if (!isValidUuid(activeCompany?.companyId) && !isAdminOnly) {
      return undefined as never
    }

    return activeCompany
  } catch (e) {
    console.warn("Failed to parse user's company associations — ", e)
  }

  return undefined as never
}

export const getStarCompany = (user: AuthServiceUser): CompanyAssociation | null => {
  try {
    return (
      getAllCompanyAssociations(user).find(
        (company: CompanyAssociation) => company.companyId === ALL_COMPANY_ACCESS_ID
      ) ?? null
    )
  } catch (e) {
    console.error("Failed to parse user's company associations — ", e)
  }

  return null
}

export const getCurrentPlanId = async (companyId: Uuid): Promise<string> => {
  try {
    const allHraPlans = await getHraPlan(companyId)
    const currentHraPlanId = allHraPlans?.[0]?.id

    return currentHraPlanId ?? ""
  } catch (e) {
    console.warn("Failed to get current HRA plan id", e)
  }

  return undefined as never
}

export const setActiveCompany = (user: AuthServiceUser, index: number) => {
  if (!user) {
    console.warn("Attempted to set company of invalid user")

    return
  }
  localStorage.setItem(`${user.sub}-current-company-index`, String(index))
  const activeCompany = getActiveCompany(user)

  user.company = activeCompany

  return activeCompany
}

export const hasActiveCompany = (user: AuthServiceUser) => {
  const activeCompany = getActiveCompany(user)

  return Boolean(activeCompany)
}

export const getFreshSessionFromUser = async (cognitoUser: CognitoUser) => {
  let session = await new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.getSession(
      (err: Error | null, cognitoSession: CognitoUserSession | PromiseLike<CognitoUserSession>) => {
        if (err) {
          reject(err)
        } else {
          resolve(cognitoSession)
        }
      }
    )
  })

  const currenIdToken: CognitoIdToken = session.getIdToken()
  const idExp: Date = new Date(currenIdToken.getExpiration() * 1000)
  const expiringSoon: boolean = idExp.getTime() - Date.now() < TOKEN_REFRESH_THRESHOLD_MS

  if (expiringSoon) {
    const refreshToken: CognitoRefreshToken = session?.getRefreshToken()

    if (refreshToken) {
      session = await new Promise<CognitoUserSession>((resolve, reject) => {
        cognitoUser.refreshSession(refreshToken, (err, cognitoSession) => {
          if (err) {
            reject(err)
          } else {
            resolve(cognitoSession)
          }
        })
      })
    }
  }

  return session
}

const retrieveUserInformation = async (
  user: Record<string, any>,
  companyId: Uuid | typeof ALL_COMPANY_ACCESS_ID,
  roles: CompanyRole[]
) => {
  const userPromiseAttributes: any[] = [
    { attribute: "profileData", function: fetchProfileDetails(companyId, user.id) },
    { attribute: "companyHRAPlan", function: getHraPlan(companyId as never) },
  ]

  const userIsEmployee = roles.some(role => role.roleId === EMPLOYEE_EXTERNAL_ROLE_ID)

  if (userIsEmployee) {
    userPromiseAttributes.push({
      attribute: "shoppingSession",
      function: fetchShoppingSessionDetails(user.employeeId),
    })
  }

  await Promise.allSettled(userPromiseAttributes.map(value => value.function)).then(results => {
    results.forEach((result, index) => {
      if (result.status === "fulfilled") {
        user[userPromiseAttributes[index].attribute] = result.value
      } else {
        console.warn(result.reason)
      }
    })
  })
}

const retrieveCompanyInformation = async (user: Record<string, any>, companyId: string) => {
  let planId

  if (user.companyHRAPlan?.length > 0) {
    const hraPlan = user.companyHRAPlan[0]

    planId = hraPlan.id
  }

  const companyInformationAttributes = [
    { attribute: "companyInfo", function: getCompany(companyId) },
    { attribute: "onboardingStatuses", function: getOnboardingStatus(companyId) },
    { attribute: "hraClasses", function: getCurrentClasses(companyId, planId) },
  ]

  await Promise.allSettled(companyInformationAttributes.map(value => value.function)).then(results => {
    results.forEach((result, index) => {
      if (result.status === "fulfilled") {
        user[companyInformationAttributes[index].attribute] = result.value
      } else {
        console.warn(result.reason)
      }
    })
  })
}

export interface FvIdentity {
  id: string
  name: string
  email: string
  env?: string
  roles?: string[]
  customField?: Record<string, any>
}

const setupFvWithCognito = async (userId: string, user: CognitoUser) => {
  const userAttributes = await getUserAttributes(user)
  const fullName = userAttributes.given_name + " " + userAttributes.family_name

  window.$fvIdentity = {
    id: userId,
    name: fullName,
    email: userAttributes.email,
  }
}

const initializeRumUserMonitoring = async (userId: string, user: CognitoUser) => {
  const userAttributes = await getUserAttributes(user)
  const fullName = userAttributes.given_name + " " + userAttributes.family_name

  // Set user info in Datadog RUM
  datadogRum.setUser({
    id: userId,
    name: fullName,
    email: userAttributes.email,
  })
}

export const getSession = async (isRefreshingToken?: boolean): Promise<AuthState> => {
  const cognitoUser: CognitoUser | null = UserPool.getCurrentUser()

  if (!cognitoUser) {
    return {
      isAuthenticated: false,
      isInitialized: true,
      user: null,
      error: "No user found.",
    }
  }
  const session = await getFreshSessionFromUser(cognitoUser)

  const idToken: CognitoIdToken = session?.getIdToken()
  const userId = idToken?.payload?.["cognito:username"] ?? cognitoUser.getUsername()

  try {
    await setupFvWithCognito(userId, cognitoUser)
    await initializeRumUserMonitoring(userId, cognitoUser)
  } catch (e) {
    console.error(e)
  }
  axios.defaults.headers.common.Authorization = `Bearer ${idToken?.getJwtToken()}`

  const attributes = await getUserAttributes(cognitoUser)
  const user = { ...cognitoUser, ...attributes } as AuthServiceUser
  // FUTURE SEG-2121: Determine active company by user selection. Ensure selectedIndex is set correctly.
  const companies = getAllCompanyAssociations(user)

  const hasValidCompany = some(companies, (company: CompanyAssociation) => company.companyId !== ALL_COMPANY_ACCESS_ID)
  const defaultSelectedIndex = hasValidCompany
    ? companies.findIndex((company: CompanyAssociation) => company.companyId !== ALL_COMPANY_ACCESS_ID)
    : 0
  // Check if a selectedIndex exists in local storage; if not, find it based on valid company IDs.
  // has active company will check local storage
  const activeCompany = hasActiveCompany(user) ? getActiveCompany(user) : setActiveCompany(user, defaultSelectedIndex)

  if (!user || !activeCompany) {
    return {
      isAuthenticated: false,
      isInitialized: true,
      user: null,
      error: "User must be added to a company's roster.",
    }
  }

  const employeeId = activeCompany.employeeId

  user.employeeId = employeeId
  user.id = userId
  user.company = activeCompany

  // Flag to avoid calling the endpoints every time the Cognito token gets refreshed
  if (!isRefreshingToken) {
    const { companyId, roles } = activeCompany

    await retrieveUserInformation(user, companyId, roles)
    const userIsAdministrator = isUserAdministrator(roles)

    if (userIsAdministrator) {
      await retrieveCompanyInformation(user, companyId)
    }
    user.keepEndpointData = false
  } else {
    user.keepEndpointData = true
  }

  return {
    isAuthenticated: true,
    isInitialized: true,
    user,
    error: "",
  }
}

export const refreshUserData = async (dispatch: Dispatch<CognitoActions>, isRefreshingToken: boolean) => {
  let userSession = null
  try {
    userSession = await getSession(isRefreshingToken)
  } catch (error) {
    console.error("Failed to refresh user data", error)
  }

  dispatch({
    type: INITIALIZE,
    payload: {
      isAuthenticated: userSession?.isAuthenticated ?? false,
      user: userSession?.user ?? null,
    },
  })

  return userSession
}

export const getUserInChallenge = async () => internals?.userInChallenge ?? null

export const setUserInChallenge = (user: CognitoUser | null, challengeName?: string) => {
  internals.userInChallenge = user
  internals.challengeName = challengeName
}

export const rememberDevice = (user: CognitoUser) =>
  new Promise((resolve, reject) => {
    user.setDeviceStatusRemembered({
      onSuccess: result => {
        resolve(result)
      },
      onFailure: err => {
        reject(err)
      },
    })
  })

export const signInActionCreator =
  (dispatch: Dispatch<CognitoActions>) =>
  async (email: string, password: string, rememberMe: boolean): Promise<CognitoUserSession> => {
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    })

    const authDetails: AuthenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    })

    return new Promise((resolve, reject) =>
      user.authenticateUser(authDetails, {
        onSuccess: async (data: CognitoUserSession) => {
          try {
            const userSession = await refreshUserData(dispatch, false)

            if (userSession?.isAuthenticated) {
              if (rememberMe) {
                await rememberDevice(user)
              }
              resolve(data)
            } else {
              reject({ message: userSession?.error ?? "Failed to authenticate" })
            }
          } catch (error) {
            reject(error)
          }
        },
        onFailure: async err => {
          const message = err.message || "Something went wrong"
          if (err.code === "NotAuthorizedException") {
            try {
              // Try to reinvite the person in the case that they are in a Force change password status in Cognito
              // If they aren't in this status, they won't be reinvited and will need to input the correct password
              // or change it via the forgot password flow
              await sendSignUpLinkByEmail(email)
              reject({ message: "We have sent you an email to finish setting up your user account." })
            } catch (error) {
              console.error("Failed to get user status", error)
              reject({ message })
            }
          } else {
            const emailMfaRequired = message.includes("Email MFA")
            reject({
              message,
              emailMfaRequired,
              cognitoUser: user,
            })
          }
        },
        mfaSetup: (challengeName, challengeParameters) => {
          setUserInChallenge(user, challengeName)
          reject({ message: "TOTP required", mfaSetup: true, cognitoUser: user })
        },
        totpRequired: (challengeName, challengeParameters) => {
          setUserInChallenge(user, challengeName)
          reject({
            message: "TOTP required",
            totpRequired: true,
            cognitoUser: user,
          })
        },
        mfaRequired: (challengeName, challengeParameters) => {
          setUserInChallenge(user, challengeName)
          reject({
            message: "SMS required",
            mfaRequired: true,
            cognitoUser: user,
          })
        },
        newPasswordRequired: () => {
          setUserInChallenge(user)
          reject({ message: "New password required" })
        },
      })
    )
  }

export const isUserSignedUp = async (email: string, password: string): Promise<boolean> => {
  const user: CognitoUser = new CognitoUser({
    Username: email,
    Pool: UserPool,
  })

  const authDetails: AuthenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  })

  return new Promise((resolve, reject) =>
    user.authenticateUser(authDetails, {
      onSuccess: async (data: CognitoUserSession) => {
        resolve(true)
      },
      onFailure: err => {
        const message = err.message || "Something went wrong"
        if (message.includes("Incorrect username or password")) {
          resolve(true)
        } else {
          reject(new Error(err.message))
        }
      },
      newPasswordRequired: () => {
        resolve(false)
      },
    })
  )
}

export const signOutActionCreator = (dispatch: Dispatch<CognitoActions>) => () => {
  const user: CognitoUser | null = UserPool.getCurrentUser()

  if (user) {
    user.signOut()
    window.$fvIdentity = null

    // NOTE: Need to remove this so that when an internal admin user signs out,
    // the next time they sign in, regardless of role, they will be defaulted back
    // to the first company in the list, which will always be there.
    localStorage.removeItem(`${user.getUsername()}-current-company-index`)
    dispatch({ type: SIGN_OUT })
  }
}

export const signUp = (email: string, password: string, firstName: string, lastName: string) =>
  new Promise<void>((resolve, reject) =>
    UserPool.signUp(
      email,
      password,
      [
        new CognitoUserAttribute({ Name: "email", Value: email }),
        new CognitoUserAttribute({
          Name: "name",
          Value: `${firstName} ${lastName}`,
        }),
      ],
      [],
      async err => {
        if (err) {
          reject(err)

          return
        }
        resolve()
        // Set destination URL here
        window.location.href = ""
      }
    )
  )

const sendVerificationCode = (
  dispatch: Dispatch<CognitoActions>,
  cognitoUser: CognitoUser,
  verificationCode: string,
  rememberMe: boolean,
  mfaType: string
) =>
  new Promise((resolve, reject) => {
    cognitoUser.sendMFACode(
      verificationCode,
      {
        onSuccess: async (session, userConfirmationNecessary) => {
          setUserInChallenge(null, undefined)
          try {
            const userSession = await refreshUserData(dispatch, false)

            if (userSession?.isAuthenticated) {
              if (rememberMe) {
                await rememberDevice(cognitoUser)
              }
              resolve({
                message: "User has been verified",
                session,
                userConfirmationNecessary,
              })
            } else {
              reject({ message: userSession?.error ?? "Failed to authenticate" })
            }
          } catch (error) {
            reject(error)
          }
        },
        onFailure: error => {
          reject({
            message: "Wrong verification code",
            error,
          })
        },
      },
      mfaType
    )
  })

export const signInWithMfaActionCreator =
  (dispatch: Dispatch<CognitoActions>) =>
  async ({
    email,
    password,
    verificationCode,
    rememberMe,
    mfaType,
  }: {
    email: string
    password: string
    verificationCode: string
    rememberMe: boolean
    mfaType: string
  }) => {
    const configCognitoUser = await getUserInChallenge()
    if (configCognitoUser) {
      try {
        return await sendVerificationCode(dispatch, configCognitoUser, verificationCode, rememberMe, mfaType)
      } catch (error) {
        console.error("Failed to send verification code", error)
      }
    }

    const cognitoUser: CognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    })

    if (!cognitoUser) {
      console.error("Cognito user found")

      return
    }

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username: email,
          Password: password,
        }),
        {
          onSuccess: session => {
            resolve({ message: "User is already verified" })
          },
          onFailure: error => {
            reject({
              message: "Failed to authenticate",
              error,
            })
          },
          totpRequired: async () => {
            try {
              const result = await sendVerificationCode(dispatch, cognitoUser, verificationCode, rememberMe, mfaType)
              resolve(result)
            } catch (error) {
              reject(error)
            }
          },
        }
      )
    })
  }

export const resetUserMfa = async (personId: string) => {
  try {
    const response = await axios.post(`${appConfig.baseApiUrl}/v1/people/${personId}/reset-mfa`)
    return response.data
  } catch (error) {
    console.error(error)
    throw new Error("Failed to reset MFA")
  }
}

export const setActiveCompanyById = (user: AuthServiceUser, companyId: Uuid) => {
  if (!user) {
    console.warn("Attempted to set company of invalid user")

    return
  }

  getAllCompanyAssociations(user).forEach((company: CompanyAssociation, index: number) => {
    if (company.companyId === companyId) {
      localStorage.setItem(`${user.sub}-current-company-index`, String(index))
    }
  })

  const activeCompany = getActiveCompany(user)
  user.company = activeCompany

  return activeCompany
}
