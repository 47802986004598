import { LoadingContentArea } from "@/components/LoadingContentArea"
import { SelectField } from "@/components/SelectField"
import { StyledCard } from "@/components/StyledCard"
import { ClampedTextField } from "@/components/TextFields"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import {
  UpdateCompanyValues,
  useGetCompany,
  useUpdateCompany,
} from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { BUSINESS_STRUCTURES, INDUSTRIES } from "@/features/CreateCompany/createCompanyConstants"
import { CompanyModel, validateCompany } from "@/features/CreateCompany/createCompanyEndpoints"
import { PhoneNumberField } from "@/features/People/PeopleSettings/PhoneNumberField"
import { ZipCodeField } from "@/features/People/PeopleSettings/ZipCodeField"
import { useNotifications } from "@/services/notificationService"
import { STATE_OPTIONS } from "@/utils/States"
import { Uuid } from "@/utils/types"
import {
  hasErrors,
  validateAle,
  validateBenefitAdministratorFirstName,
  validateBenefitAdministratorLastName,
  validateBusinessStructure,
  validateCity,
  validateCompanyName,
  validateEmail,
  validateIndustry,
  validateMobilePhoneNumber,
  validateState,
  validateStreet1,
  validateStreet2,
  validateZip,
} from "@/utils/validations"
import { Edit, Save } from "@mui/icons-material"
import { Alert, Button, ButtonProps, Grid, Typography } from "@mui/material"
import { Formik } from "formik"
import { forwardRef, RefCallback, useState } from "react"
import { IMaskInput } from "react-imask"
import * as Yup from "yup"

const convertCompanyToFormValues = (company: CompanyModel): UpdateCompanyValues => {
  const { companyInfo, companyAddress, benefitsAdministrator } = company

  return {
    companyName: companyInfo.companyName,
    ein: companyInfo.ein,
    aleQuestion: !!companyInfo.isALE,
    industry: companyInfo.industry,
    businessStructure: companyInfo.companyType,
    companyAddress: companyAddress.streetAddress,
    companyAddressLine2: companyAddress.suiteApt,
    companyCity: companyAddress.city,
    companyState: companyAddress.state,
    companyZip: companyAddress.zip,
    benefitsAdministratorEmail: benefitsAdministrator.email,
    benefitsAdministratorFirstName: benefitsAdministrator.firstName,
    benefitsAdministratorLastName: benefitsAdministrator.lastName,
    benefitsAdministratorPhoneNumber: benefitsAdministrator.phoneNumber,
    autoPayAvailableOption: companyInfo.autoPayAvailableOption ?? false,
    isHrisEnabled: companyInfo.isHrisEnabled ?? false,
    submit: "false",
  }
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const EinInput = forwardRef<HTMLElement, CustomProps>(({ onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    mask="00-0000000"
    inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
    onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
    overwrite
  />
))

const EditButton = (props: ButtonProps) => (
  <Button
    data-qa="edit-general-button"
    type="button"
    variant="contained"
    color="primary"
    startIcon={<Edit />}
    {...props}
  >
    Edit
  </Button>
)

const SaveChangesButton = ({ sx, children, ...props }: ButtonProps) => (
  <Button
    data-qa="edit-general-button"
    type="submit"
    variant="contained"
    color="primary"
    startIcon={<Save />}
    {...props}
  >
    {children || "Save Changes"}
  </Button>
)

const useUpdateCompanyValidations = (companyId: string) =>
  Yup.object({
    companyName: validateCompanyName,
    ein: Yup.string()
      .required("EIN is required")
      .min(10, "EIN must be 9 digits")
      .notOneOf(["00-0000000"], "EIN is invalid")
      .test("isEinValid", "EIN already exists", async value => {
        try {
          if (value?.length === 10) {
            const validationInput = {
              ein: value,
              currentCompanyId: companyId,
            }

            const testValidation = await validateCompany(validationInput)

            return testValidation?.validations?.some(e => e.field === "ein" && e.inUse === true) ? false : true
          }

          return false
        } catch (error) {
          console.error(error)
          console.warn("Error validating EIN")

          return false
        }
      }),
    businessStructure: validateBusinessStructure,
    industry: validateIndustry,
    aleQuestion: validateAle,
    companyAddress: validateStreet1,
    companyAddressLine2: validateStreet2,
    companyCity: validateCity,
    companyState: validateState,
    companyZip: validateZip,
    benefitsAdministratorFirstName: validateBenefitAdministratorFirstName,
    benefitsAdministratorLastName: validateBenefitAdministratorLastName,
    benefitsAdministratorEmail: validateEmail,
    benefitsAdministratorPhoneNumber: validateMobilePhoneNumber.required("Phone Number is required."),
    submit: Yup.string(),
  })

export const CompanyProfile = () => {
  const [generalEnable, setGeneralEnable] = useState(false)
  const [saving, setSaving] = useState(false)
  const { notify } = useNotifications("company-update")
  const { user } = useAuth()
  const companyId = (getActiveCompany(user)?.companyId || "") as Uuid
  const { mutateAsync: updateCompany } = useUpdateCompany(companyId)
  const canEdit = generalEnable && !saving
  const updateCompanyValidations = useUpdateCompanyValidations(companyId)
  const { status, isLoading, data: company, error } = useGetCompany(companyId)

  if (error) return <div>{`Error! ${error}`}</div>
  if (isLoading) {
    return <LoadingContentArea data-qa="loading-company-profile" />
  }
  if (status !== "success") return null

  return (
    <Formik
      initialValues={convertCompanyToFormValues(company)}
      validationSchema={updateCompanyValidations}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          await updateCompany(values)
          resetForm({ values })
          notify("Company updated successfully", "success")
          setGeneralEnable(false)
          setSaving(false)
        } catch (e: any) {
          const message = e.message || "Something went wrong"
          notify("Company update error", "error")
          setStatus({ success: false })
          setErrors({ submit: message })
          setSubmitting(false)
          setSaving(false)
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isValid, dirty }) => (
        <form
          noValidate
          onSubmit={e => {
            if (isValid) {
              setSaving(true)
              handleSubmit(e)
            }
          }}
          data-qa="create-company-setup-form"
        >
          {hasErrors(touched, errors) && !errors.submit && (
            <Alert severity="error" data-qa="field-error-banner-create-company-setup-form">
              Please fill and select missing elements to continue
            </Alert>
          )}
          <StyledCard>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 8 }}>
              <Grid item>
                <Typography variant="h5" textAlign="left">
                  General
                </Typography>
              </Grid>
              <Grid item>
                {generalEnable ? (
                  <SaveChangesButton disabled={saving || !dirty || !isValid}>
                    {saving ? "Saving..." : "Save Changes"}
                  </SaveChangesButton>
                ) : (
                  <EditButton onClick={() => setGeneralEnable(true)} />
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <ClampedTextField
                    disabled={!canEdit}
                    data-qa="benefitsFirstName-textfield"
                    type="text"
                    name="benefitsAdministratorFirstName"
                    label="First Name"
                    required
                    value={values.benefitsAdministratorFirstName}
                    error={Boolean(touched.benefitsAdministratorFirstName && errors.benefitsAdministratorFirstName)}
                    fullWidth
                    helperText={touched.benefitsAdministratorFirstName && errors.benefitsAdministratorFirstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ClampedTextField
                    disabled={!canEdit}
                    data-qa="benefitsLastName-textfield"
                    type="text"
                    name="benefitsAdministratorLastName"
                    label="Last Name"
                    required
                    value={values.benefitsAdministratorLastName}
                    error={Boolean(touched.benefitsAdministratorLastName && errors.benefitsAdministratorLastName)}
                    fullWidth
                    helperText={touched.benefitsAdministratorLastName && errors.benefitsAdministratorLastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ClampedTextField
                    disabled={!canEdit}
                    data-qa="benefitsEmail-textfield"
                    type="text"
                    name="benefitsAdministratorEmail"
                    label="Email"
                    required
                    value={values.benefitsAdministratorEmail}
                    error={Boolean(touched.benefitsAdministratorEmail && errors.benefitsAdministratorEmail)}
                    fullWidth
                    helperText={touched.benefitsAdministratorEmail && errors.benefitsAdministratorEmail}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PhoneNumberField
                    disabled={!canEdit}
                    data-qa="benefitsPhoneNumber-textfield"
                    type="text"
                    name="benefitsAdministratorPhoneNumber"
                    label="Phone Number"
                    required
                    value={values.benefitsAdministratorPhoneNumber}
                    error={Boolean(touched.benefitsAdministratorPhoneNumber && errors.benefitsAdministratorPhoneNumber)}
                    fullWidth
                    helperText={touched.benefitsAdministratorPhoneNumber && errors.benefitsAdministratorPhoneNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectField
                    disabled={!canEdit}
                    data={BUSINESS_STRUCTURES}
                    type="text"
                    name="businessStructure"
                    label="Business Structure"
                    placeholder="Please Select"
                    required
                    value={values.businessStructure}
                    dataQa="businessStructure-dropdown"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectField
                    disabled={!canEdit}
                    data={INDUSTRIES}
                    dataQa="industries-dropdown"
                    type="text"
                    name="industry"
                    label="Industry"
                    required
                    placeholder="Please Select"
                    value={values.industry}
                  />
                </Grid>
                {/*
                FUTURE: Replace this with the RadioCardGroup that is used during create company flow
                <Grid item xs={12}>
                  <SelectField
                    disabled={!canEdit}
                    data={APPLICABLE_LARGE_EMPLOYER_DROPDOWN}
                    type="text"
                    id="aleQuestion"
                    name="aleQuestion"
                    label="Applicable Large Employer"
                    placeholder="Please Select"
                    required
                    value={values.aleQuestion}
                    dataQa="ale-dropdown"
                  />
                </Grid>
                */}
                <Grid item xs={12} sm={6}>
                  <ClampedTextField
                    disabled={!canEdit}
                    data-qa="ein-textfield"
                    type="text"
                    name="ein"
                    label="EIN"
                    value={values.ein}
                    placeholder="xx-xxxxxxx"
                    required
                    InputProps={{ inputComponent: EinInput as any }}
                    error={Boolean(touched.ein && errors.ein)}
                    fullWidth
                    helperText={touched.ein && errors.ein}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </StyledCard>
          <StyledCard>
            <Grid item>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h5" textAlign="left" sx={{ mb: 4 }}>
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ClampedTextField
                    disabled={!canEdit}
                    data-qa="companyName-textfield"
                    type="text"
                    name="companyName"
                    label="Company Name"
                    required
                    value={values.companyName}
                    error={Boolean(touched.companyName && errors.companyName)}
                    fullWidth
                    helperText={touched.companyName && errors.companyName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ClampedTextField
                    disabled={!canEdit}
                    data-qa="companyAddress-textfield"
                    type="text"
                    name="companyAddress"
                    label="Company Address"
                    value={values.companyAddress}
                    error={Boolean(touched.companyAddress && errors.companyAddress)}
                    fullWidth
                    required
                    helperText={touched.companyAddress && errors.companyAddress}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ClampedTextField
                    disabled={!canEdit}
                    data-qa="companyAddress2-textfield"
                    type="text"
                    name="companyAddressLine2"
                    label="Suite/Apt (optional)"
                    value={values.companyAddressLine2}
                    error={Boolean(touched.companyAddressLine2 && errors.companyAddressLine2)}
                    fullWidth
                    helperText={touched.companyAddressLine2 && errors.companyAddressLine2}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ClampedTextField
                    disabled={!canEdit}
                    data-qa="companyCity-textfield"
                    type="text"
                    name="companyCity"
                    label="City"
                    required
                    value={values.companyCity}
                    error={Boolean(touched.companyCity && errors.companyCity)}
                    fullWidth
                    helperText={touched.companyCity && errors.companyCity}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField
                    disabled={!canEdit}
                    dataQa="companyState-dropdown"
                    data={STATE_OPTIONS}
                    type="text"
                    name="companyState"
                    label="State"
                    required
                    value={values.companyState}
                    placeholder="Please Select"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ZipCodeField
                    disabled={!canEdit}
                    data-qa="companyZip-textfield"
                    type="text"
                    name="companyZip"
                    label="ZIP code"
                    required
                    value={values.companyZip}
                    error={Boolean(touched.companyZip && errors.companyZip)}
                    fullWidth
                    helperText={touched.companyZip && errors.companyZip}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </StyledCard>
        </form>
      )}
    </Formik>
  )
}
