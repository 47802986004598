import { EXTRA_STEP, MAX_CHAR_COUNT, MIN_CHAR_COUNT, SELF_ENROLL } from "@/constants"
import {
  COMPLIANCE_FREQUENCIES,
  VARY_BY_FAMILY_SIZE,
  VARY_BY_FAMILY_SIZE_AND_AGE,
} from "@/features/CreateCompany/createCompanyConstants"
import { validateCompany } from "@/features/CreateCompany/createCompanyEndpoints"
import { FUNDING_TYPES, MULTIPLE_CHOICE, TRANSFER_MAXIMUM_AMOUNT_DOLLARS } from "@/features/TCHub/tcHubConstants"
import { isArray, isString } from "lodash"
import * as Yup from "yup"
import { STATES } from "./States"
import { Uuid } from "./types"

export const requiredMessage: Yup.Message = ({ label }) => `${label} is required`

const REGEX_PERSON_NAME = /^[a-zA-Z\u00C0-\u017F\-.',\s]{1,255}$/
const PASSWORD_REQUIRED_MESSAGE = "Password is required"
/* Individual Validations */

const passwordValidation = () =>
  Yup.string()
    .min(
      MIN_CHAR_COUNT,
      "Must be over 12 characters, upper and lowercase letters, with at least 1 special character and at least 1 number"
    )
    .max(MAX_CHAR_COUNT)
    .matches(/[0-9]/, "Password requires at least 1 number")
    .matches(/[a-z]/, "Password requires at least 1 lowercase letter")
    .matches(/[A-Z]/, "Password requires at least 1 uppercase letter")
    .matches(/[-!$%^&@#*()_+|~=`{}[\]:";'<>?,./\\]/, "Password requires at least 1 special character")
    .required(PASSWORD_REQUIRED_MESSAGE)

const confirmPasswordValidation = (originalPasswordField: string) =>
  Yup.string()
    .required(PASSWORD_REQUIRED_MESSAGE)
    .when(originalPasswordField, {
      is: (originalPassword: any) => Boolean(originalPassword && originalPassword.length > 0),
      then: () => Yup.string().oneOf([Yup.ref(originalPasswordField)], "Both passwords need to be the same"),
    })

const confirmNewPasswordValidation = (originalPasswordField: string) =>
  passwordValidation()
    .required(PASSWORD_REQUIRED_MESSAGE)
    .notOneOf([Yup.ref(originalPasswordField)], "Passwords can not be the same")

const termsOfServiceValidation = () => Yup.bool().oneOf([true], "Accept Terms & Conditions is required")

export const changePasswordValidation = () =>
  Yup.object().shape({
    oldPassword: passwordValidation(),
    newPassword: confirmNewPasswordValidation("oldPassword"),
    retypePassword: confirmPasswordValidation("newPassword"),
  })

/* Combined Validations */

export const passwordAndConfirmPasswordValidation = () =>
  Yup.object().shape({
    newPassword: passwordValidation(),
    confirmPassword: confirmPasswordValidation("newPassword"),
  })

export const signUpValidation = () =>
  Yup.object().shape({
    newPassword: passwordValidation(),
    confirmPassword: confirmPasswordValidation("newPassword"),
    agreedToTermsOfService: termsOfServiceValidation(),
  })

export const createCompanySetupValidation = Yup.object().shape({})

const maxDateDateOfBirth = new Date()

maxDateDateOfBirth.setFullYear(maxDateDateOfBirth.getFullYear() - 18)

export const dateOfBirthValidation = Yup.date()
  .nullable()
  .required("Date of birth is required")
  .typeError("Date of birth must be in MM/DD/YYYY format.")
  .test("format-validation", "Date of birth must be in MM/DD/YYYY format.", value =>
    Boolean(value?.getFullYear() && value?.getFullYear() >= 1000)
  )
  .max(new Date(), `Date of birth must be in the past`)
  .max(maxDateDateOfBirth, `Employee must be at least 18 years old.`)

export const hireDateValidation = () => {
  const maxDateHireDate = new Date()

  maxDateHireDate.setDate(maxDateHireDate.getDate() + 365)

  return Yup.date()
    .nullable()
    .required("Hire date is required")
    .typeError("Hire date must be in MM/DD/YYYY format.")
    .test("format-validation", "Hire date must be in MM/DD/YYYY format.", value =>
      Boolean(value?.getFullYear() && value?.getFullYear() >= 1000)
    )
    .max(new Date(), "Employee is already enrolled. Hire date can't be changed.")
    .max(maxDateHireDate, "Hire date must be within 365 days.")
}

export const benefitsEligibleDateValidation = Yup.string().required("Benefits eligible date is required")

/* Create Company Validations */
export const validateCompanyName = Yup.string()
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} characters allowed`)
  .required("Company Name is required")

export const validateBusinessStructure = Yup.string().required("Business Structure is required")

export const validateIndustry = Yup.string().required("Industry is required")

const cacheTest = (asyncValidate: (val: string) => Promise<boolean>) => {
  let _valid = false
  let _value = ""

  return async (value: string) => {
    if (value !== _value) {
      const response = await asyncValidate(value)

      _value = value
      _valid = response

      return response
    }

    return _valid
  }
}

const checkEinDuplicate = async (value?: string) => {
  try {
    if (value?.length === 10) {
      const validationInput = {
        ein: value,
      }

      const testValidation = await validateCompany(validationInput)

      return testValidation?.validations?.some(e => e.field === "ein" && e.inUse === true) ? false : true
    }

    return false
  } catch {
    console.warn("Error validating EIN")

    return false
  }
}

export const checkEinDuplicateWithCompanyId = async (value: string, companyId: string) => {
  try {
    if (value?.length === 10) {
      const validationInput = {
        ein: value,
        currentCompanyId: companyId,
      }
      const testValidation = await validateCompany(validationInput)
      return !testValidation?.validations?.some(e => e.field === "ein" && e.inUse === true)
    }
    return false
  } catch (error) {
    console.error(error)
    console.warn("Error validating EIN")
    return false
  }
}

const einDuplicateTest = cacheTest(checkEinDuplicate)

export const validateEin = Yup.string()
  .required("EIN is required")
  .min(10, "EIN must be 9 digits")
  .notOneOf(["00-0000000"], "Please enter a valid EIN.")
  .test("isEinValid", " ", einDuplicateTest as Yup.TestFunction)

export const validateConfirmEin = Yup.string()
  .required("Confirm EIN is required")
  .min(10, "EIN must be 9 digits")
  .notOneOf(["00-0000000"], "Please enter a valid EIN.")

export const validateBenefitAdministratorFirstName = Yup.string()
  .required("First Name is required")
  .min(1, "Min of 1 characters is required")
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} letters allowed for first name`)
  .matches(REGEX_PERSON_NAME, "First name can only contain alphabetical characters, - and `")

export const validateBenefitAdministratorLastName = Yup.string()
  .required("Last Name is required")
  .min(1, "Min of 1 characters is required")
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} letters allowed for last name`)
  .matches(REGEX_PERSON_NAME, "Last name can only contain alphabetical characters, - and `")

export const validateFirstName = Yup.string()
  .label("First name")
  .required(requiredMessage)
  .min(1, "Min of 1 characters is required")
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} letters allowed for first name`)
  .matches(REGEX_PERSON_NAME, "First name can only contain alphabetical characters, - and `")

export const validateLastName = Yup.string()
  .label("Last name")
  .required(requiredMessage)
  .min(1, "Min of 1 characters is required")
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} letters allowed for last name`)
  .matches(REGEX_PERSON_NAME, "Last name can only contain alphabetical characters, - and `")

export const validateMiddleName = Yup.string()
  .label("Middle name")
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} letters allowed for middle name`)
  .matches(REGEX_PERSON_NAME, "Middle name can only contain alphabetical characters, - and `")
  .notRequired()

export const validatePreferredName = Yup.string()
  .label("Preferred name")
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} letters allowed for preferred name`)
  .matches(REGEX_PERSON_NAME, "Preferred name can only contain alphabetical characters, - and `")
  .notRequired()

export const validateMobilePhoneNumber = Yup.string()
  .required("Mobile phone is required")
  .min(12, "You must enter a 10-digit mobile phone")
  .notOneOf(["+10000000000"], "A valid Phone Number is required")

export const validatePhoneNumber = Yup.lazy(value =>
  value?.length && value.length > 2
    ? Yup.string()
        .label("Phone Number")
        .min(12, "You must enter a 10-digit phone number")
        .notOneOf(["+10000000000"], "A valid Phone Number is required")
    : Yup.string().label("Phone Number")
)

export const validateStreet1 = Yup.string()
  .label("Street Address")
  .min(1, "Min of 1 character is required")
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} characters allowed`)
  .required(requiredMessage)

export const validateStreet2 = Yup.string()
  .label("Street 2")
  .min(1, "Min of 1 character is required")
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} characters allowed`)
  .notRequired()

export const validateZip = Yup.string()
  .label("ZIP code")
  .required(requiredMessage)
  .min(5, "You must enter a 5 digit ZIP code")
  .max(5, "Max of 5 characters allowed.")
  .matches(/^\d{5}$/, {
    message: "Please enter a valid ZIP code",
    excludeEmptyString: false,
  })

export const validateCity = Yup.string()
  .label("City")
  .min(1, "Min of 1 character is required")
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} characters allowed`)
  .required(requiredMessage)
  .matches(/^[A-Za-z -]+$/, {
    message: `City can't contain numbers and only (-) characters are allowed`,
    excludeEmptyString: false,
  })

export const validateCounty = Yup.string()
  .label("County")
  .min(1, "Min of 1 character is required")
  .max(MAX_CHAR_COUNT, `Max of ${MAX_CHAR_COUNT} characters allowed`)
  .required(requiredMessage)
  .matches(/^[a-zA-Z\s-]+$/, {
    message: "County can't contain numbers",
    excludeEmptyString: false,
  })

export const validateState = Yup.string().label("State").defined().required(requiredMessage).oneOf(STATES)

export const validateSelectedZipCode = Yup.object()
  .label("ZIP code")
  .shape({
    zipCode: validateZip,
    countyName: Yup.string().defined().required(),
    fipsCode: Yup.string().defined().required(),
    state: validateState,
  })
  .defined()
  .nullable()
  .required(requiredMessage)

export const validateMailingAddress = Yup.object({
  street1: validateStreet1,
  street2: validateStreet2,
  city: validateCity,
  state: validateState,
  zipCode: validateSelectedZipCode,
})

export const validateClass = Yup.string().required("Class is required")

export const validateAle = Yup.boolean().nullable()

export const validateAleInCreateCompany = Yup.boolean()
  .nullable()
  .test(value => value === false)

export const validateEmail = Yup.string()
  .max(MAX_CHAR_COUNT)
  .email("Must be a valid email")
  .matches(/^[a-zA-Z0-9,+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Email must have a valid structure")
  .required("Email is required")

export const validateEmployeeId = Yup.string()
  .min(1)
  .max(50, "Max of 50 characters allowed")
  .matches(/^[a-zA-Z0-9\s-]+$/, {
    message: "Invalid Employee ID",
    excludeEmptyString: false,
  })

export const ssnRegex = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
export const validateSsn = Yup.string()
  .matches(ssnRegex, {
    message: "Invalid Social Security Number",
  })
  .required("Social Security Number is required")

export const hasErrors = (touched: { [field: string]: boolean }, errors: { [field: string]: string }) => {
  let errorExists = false

  Object.keys(touched)?.forEach(field => {
    if (errors[field]) {
      errorExists = true
    }
  })

  return errorExists
}

export const enableFirstDay = (day: Date | string) => !isString(day) && day?.getUTCDate() !== 1

export const validateTransferDetails = Yup.object({
  company: Yup.object().shape({ id: Yup.string() }).required("Company is required"),
  fromAccountId: Yup.string().required("From Account is required"),
  toAccountId: Yup.string().required("To Account is required"),
  fundingType: Yup.string().required("Funding type selection is required"),
  amount: Yup.number().when("fundingType", {
    is: FUNDING_TYPES.RESERVE_PAYMENT_TO_DISBURSEMENT,
    then: (schema: any) =>
      schema
        .max(TRANSFER_MAXIMUM_AMOUNT_DOLLARS, "Amount exceeds maximum transfer limit of $250,000")
        .required("Amount is required")
        .positive("Amount must be positive"),
  }),
  premiumMonth: Yup.number().when("fundingType", {
    is: FUNDING_TYPES.PREMIUMS_PAYMENT_TO_DISBURSMENT,
    then: (schema: any) => schema.required("Premium month is required"),
  }),
  premiumYear: Yup.number().when("fundingType", {
    is: FUNDING_TYPES.PREMIUMS_PAYMENT_TO_DISBURSMENT,
    then: (schema: any) => schema.required("Premium year is required"),
  }),
  description: Yup.string().required("Description is required").max(50, "Description can not exceed 50 characters"),
})

export const validateJournalEntry = Yup.object({
  company: Yup.object().shape({ id: Yup.string() }).required("Company is required"),
  activityCategory: Yup.string().required("Activity Category is required"),
  activityComments: Yup.string().required("Activity Comments is required"),
  premiumMonth: Yup.number().when("activityCategory", {
    is: (val: string) => val !== "REPLENISH_RESERVE" && val !== "RECORD_EXTERNAL_REFUND",
    then: schema => schema.required("Premium month is required"),
  }),
  premiumYear: Yup.number().when("activityCategory", {
    is: (val: string) => val !== "REPLENISH_RESERVE" && val !== "RECORD_EXTERNAL_REFUND",
    then: schema => schema.required("Premium year is required"),
  }),
  fromAccountCategory: Yup.string().required("Credit Account is required"),
  toAccountCategory: Yup.string().required("Debit Account is required"),
  vendorTransactionId: Yup.string(),
  vendorWebhookTransactionId: Yup.string(),
  vendorWebhookTransactionTimestampUtc: Yup.string(),
  entryTimestampUtc: Yup.string(),
  companyFundingEventId: Yup.string(),
  recurringPremiumId: Yup.string().when("activityCategory", {
    is: (val: string) => val !== "REPLENISH_RESERVE" && val !== "RECORD_EXTERNAL_REFUND",
    then: schema => schema.required("Recurring Premium is required"),
  }),
  amount: Yup.number().required("Amount is required").positive("Amount must be positive"),
})

export const UnassistedPlanSetupValidationSchema = Yup.object({
  hraStartDate: Yup.date().required("HRA start date is required"),
  statementCreationDate: Yup.string().required("Statement creation date is required"),
  eligibleEmployees: Yup.number()
    .required("Amount of eligible employees is required")
    .min(1, "Eligible Employees cannot be less than zero")
    .typeError("Amount of eligible employees must be a number"),
  participatingEmployees: Yup.number()
    .required("Amount of participating employees is required")
    .min(1, "Participating Employees cannot be less than zero")
    .typeError("Amount of participating employees must be a number"),
}).test("participatingEmployees", obj => {
  if (obj.eligibleEmployees >= obj.participatingEmployees) {
    return true
  }

  return new Yup.ValidationError(
    "Participating employees cannot be greater than eligible employees",
    null,
    "participatingEmployees"
  )
})

export const PlanSetupValidationSchema = Yup.object({
  hraStartDate: Yup.date().required("HRA start date is required"),
  statementFrequency: Yup.string().required("Statement frequency is required"),
  statementCreationDate: Yup.date()
    .required("Statement creation date is required")
    .when("hraStartDate", (hraStartDate: any, schema) => {
      if (isArray(hraStartDate) && hraStartDate.length > 0) {
        hraStartDate = hraStartDate[0]
      }
      if (hraStartDate)
        return schema
          .max(
            new Date(new Date(hraStartDate).getFullYear(), new Date(hraStartDate).getMonth() + 1, 0).toISOString(),
            "Select a new statement date within the given month of your HRA start date."
          )
          .min(
            new Date(new Date(hraStartDate).getFullYear(), new Date(hraStartDate).getMonth(), 1).toISOString(),
            "Select a new statement date within the given month of your HRA start date."
          )

      return schema
    }),
  complianceFrequency: Yup.string().oneOf(COMPLIANCE_FREQUENCIES).required("Compliance frequency is required"),
})

export const PlanSetupValidationSchemaTcHub = PlanSetupValidationSchema.shape({
  eligibleEmployees: Yup.number()
    .required("Amount of eligible employees is required")
    .min(1, "Eligible Employees cannot be less than zero")
    .typeError("Amount of eligible employees must be a number"),
  participatingEmployees: Yup.number()
    .required("Amount of participating employees is required")
    .min(1, "Participating Employees cannot be less than zero")
    .typeError("Amount of participating employees must be a number"),
}).test("participatingEmployees", obj => {
  if (obj.eligibleEmployees >= obj.participatingEmployees) {
    return true
  }

  return new Yup.ValidationError(
    "Participating employees cannot be greater than eligible employees",
    null,
    "participatingEmployees"
  )
})

export const AssistedPlanStructureValidationSchema = Yup.object({
  reimbursementStructure: Yup.string().required("Reimbursement structure is required"),
  eligibleForReimbursement: Yup.string().when("reimbursementStructure", {
    is: (reimbursementStructure: any) => reimbursementStructure !== "CUSTOM",
    then: () => Yup.string().required("Reimbursement type is required"),
  }),
  waitingPeriod: Yup.string().when("reimbursementStructure", {
    is: (reimbursementStructure: any) => reimbursementStructure !== "CUSTOM",
    then: () => Yup.string().required("Reimbursement type is required"),
  }),
  employeeAmount: Yup.string().when("reimbursementStructure", {
    is: (reimbursementStructure: any) => reimbursementStructure !== "CUSTOM",
    then: () => Yup.string().required("Reimbursement type is required"),
  }),
  employeeAndSpouseAmount: Yup.string().when("varyByFamilySizeOrAge", (varyByFamilySizeOrAge: any, schema) => {
    if (varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE || varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE_AND_AGE)
      return schema.required("Amount is required")

    return schema
  }),
  employeeAndChildrenAmount: Yup.string().when("varyByFamilySizeOrAge", (varyByFamilySizeOrAge: any, schema) => {
    if (varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE || varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE_AND_AGE)
      return schema.required("Amount is required")

    return schema
  }),
  employeeAndSpouseAndChildrenAmount: Yup.string().when(
    "varyByFamilySizeOrAge",
    (varyByFamilySizeOrAge: any, schema) => {
      if (varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE || varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE_AND_AGE)
        return schema.required("Amount is required")

      return schema
    }
  ),
  hoursPerWeekAmountLetMeCreate: Yup.string().when("reimbursementStructure", {
    is: "CUSTOM",
    then: () => Yup.string().required("Hours per week is required"),
  }),
  monthsPerYearSelectionLetMeCreate: Yup.string().when("reimbursementStructure", {
    is: "CUSTOM",
    then: () => Yup.string().required("Months per year is required"),
  }),
})

export const UnassistedPlanStructureValidationSchema = Yup.object({
  needMoreThanOneClass: Yup.boolean().required("needMoreThanOneClass is required"),
  reimbursementStructure: Yup.string().when("needMoreThanOneClass", {
    is: (needMoreThanOneClass: any) => needMoreThanOneClass !== true,
    then: () => Yup.string().required("Reimbursement type is required"),
  }),
  eligibleForReimbursement: Yup.string().when("reimbursementStructure", {
    is: (reimbursementStructure: any) => reimbursementStructure !== "CUSTOM",
    then: () => Yup.string().required("Reimbursement type is required"),
  }),
  isSpecificGeography: Yup.boolean().required("Geographic area is required"),
  geographyDescription: Yup.string().when("isSpecificGeography", {
    is: true,
    then: schema => schema.required("Specific area is required"),
  }),
  waitingPeriod: Yup.string().when("needMoreThanOneClass", {
    is: (needMoreThanOneClass: any) => needMoreThanOneClass !== true,
    then: () => Yup.string().required("Reimbursement type is required"),
  }),
  employeeAmount: Yup.string().when("reimbursementStructure", {
    is: (reimbursementStructure: any) => reimbursementStructure !== "CUSTOM",
    then: () => Yup.string().required("Reimbursement type is required"),
  }),
  employeeAndSpouseAmount: Yup.string().when("varyByFamilySizeOrAge", (varyByFamilySizeOrAge: any, schema) => {
    if (varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE || varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE_AND_AGE)
      return schema.required("Amount is required")

    return schema
  }),
  employeeAndChildrenAmount: Yup.string().when("varyByFamilySizeOrAge", (varyByFamilySizeOrAge: any, schema) => {
    if (varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE || varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE_AND_AGE)
      return schema.required("Amount is required")

    return schema
  }),
  employeeAndSpouseAndChildrenAmount: Yup.string().when(
    "varyByFamilySizeOrAge",
    (varyByFamilySizeOrAge: any, schema) => {
      if (varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE || varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE_AND_AGE)
        return schema.required("Amount is required")

      return schema
    }
  ),
  hoursPerWeekAmountLetMeCreate: Yup.string().when("reimbursementStructure", {
    is: "CUSTOM",
    then: () => Yup.string().required("Hours per week is required"),
  }),
  monthsPerYearSelectionLetMeCreate: Yup.string().when("reimbursementStructure", {
    is: "CUSTOM",
    then: () => Yup.string().required("Months per year is required"),
  }),
})

export const validateCarrierFilter = Yup.object().shape({
  premiumPullDate: Yup.number()
    .min(1, "Premium pull day should be between 1 and 31")
    .max(31, "Premium pull day should be between 1 and 31"),
  cutOffDate: Yup.number()
    .min(1, "Cut-off date should be between 1 and 31")
    .max(31, "Cut-off date should be between 1 and 31"),
})

export const validateCarrier = Yup.object().shape({
  onExchange: Yup.boolean(),
  offExchange: Yup.boolean(),
  bothExchanges: Yup.boolean(),
  overdraftFeeCents: Yup.number().min(0, "Overdraft should be a positive integer").integer(),
  crossStateCare: Yup.string().required("Cross state care is required"),
  payLater: Yup.string().required("Pay Later is required"),
  onExchangeEnrollment: Yup.string().when("onExchange", {
    is: true,
    then: () => Yup.string().required("Enrollment is required"),
  }),
  onExchangeExtraStepDescription: Yup.string().when(["onExchangeEnrollment", "onExchange"], {
    is: (onExchangeEnrollment: string, onExchange: boolean) => onExchangeEnrollment === EXTRA_STEP && onExchange,
    then: () => Yup.string().required("Extra Step Description is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  onExchangeLink: Yup.string().when(
    ["onExchange", "onExchangeEnrollment"],
    ([onExchange, onExchangeEnrollment], schema) => {
      const baseSchema = schema.url("Link must be a valid URL")

      if ((onExchange as boolean) === true && (onExchangeEnrollment as string) === SELF_ENROLL) {
        return baseSchema.required("Link is required")
      }

      return baseSchema.notRequired()
    }
  ),
  onExchangeCutOffDate: Yup.string().when("onExchange", {
    is: true,
    then: () =>
      Yup.number().min(1, "Cut-off date should be between 1 and 31").max(31, "Cut-off date should be between 1 and 31"),
  }),
  onExchangePremiumPullDate: Yup.string().when("onExchange", {
    is: true,
    then: () =>
      Yup.number()
        .min(1, "Premium pull day should be between 1 and 31")
        .max(31, "Premium pull day should be between 1 and 31"),
  }),
  onExchangeIsAutoPay: Yup.string().when("onExchange", {
    is: true,
    then: () => Yup.string().required("AutoPay is required"),
  }),
  offExchangeEnrollment: Yup.string().when(["offExchange", "bothExchanges"], {
    is: (offExchange: boolean, bothExchanges: boolean) => offExchange || bothExchanges,
    then: () => Yup.string().required("Enrollment is required"),
  }),
  offExchangeExtraStepDescription: Yup.string().when("offExchangeEnrollment", {
    is: EXTRA_STEP,
    then: () => Yup.string().required("Extra Step Description is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  offExchangeLink: Yup.string().when(
    ["offExchange", "bothExchanges", "offExchangeEnrollment"],
    ([offExchange, bothExchanges, offExchangeEnrollment], schema) => {
      const baseSchema = schema.url("Link must be a valid URL")

      if (
        ((offExchange as boolean) === true || (bothExchanges as boolean) === true) &&
        (offExchangeEnrollment as string) === SELF_ENROLL
      ) {
        return baseSchema.required("Link is required")
      }

      return baseSchema.notRequired()
    }
  ),
  offExchangeCutOffDate: Yup.string().when(["offExchange", "bothExchanges"], {
    is: (offExchange: boolean, bothExchanges: boolean) => offExchange || bothExchanges,
    then: () =>
      Yup.number().min(1, "Cut-off date should be between 1 and 31").max(31, "Cut-off date should be between 1 and 31"),
  }),
  offExchangePremiumPullDate: Yup.string().when(["offExchange", "bothExchanges"], {
    is: (offExchange: boolean, bothExchanges: boolean) => offExchange || bothExchanges,
    then: () =>
      Yup.number()
        .min(1, "Premium pull day should be between 1 and 31")
        .max(31, "Premium pull day should be between 1 and 31"),
  }),
  offExchangeIsAutoPay: Yup.string().when(["offExchange", "bothExchanges"], {
    is: (offExchange: boolean, bothExchanges: boolean) => offExchange || bothExchanges,
    then: () => Yup.string().required("AutoPay is required"),
  }),
})

export const verificationCodeValidationSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .required("Verification code is required")
    .length(6, "Verification code must be 6 digits"),
})

export const fundingEventDetailsSchema = Yup.object().shape({
  company: Yup.object().required("Company is required"),
  timePeriod: Yup.string().required("Time period is required"),
  timePeriodLabel: Yup.string().required("Time period label is required"),
  periodStartAt: Yup.date().required("Period start date is required"),
  periodEndAt: Yup.date()
    .required("Period end date is required")
    .when("periodStartAt", (periodStartAt, schema) =>
      schema.min(periodStartAt, "Period end date must be after period start date")
    ),
  status: Yup.string().required("Status is required"),
})

export const validateCarrierQuestions = (isChildQuestion: boolean, fieldType: string) =>
  Yup.object().shape({
    question: Yup.string().required("Question is required").max(1200, "Question must be at most 1200 characters"),
    fieldType: Yup.string().required("Field Type is required"),
    states: Yup.array().min(1, "Must select at least 1 state").required("State is required"),
    scope: Yup.string().required("Scope is required"),
    parentAnswerForVisibility: isChildQuestion
      ? Yup.string().required("Parent Answer For Visibility is required for a child question")
      : Yup.string().notRequired(),
    answerChoices:
      fieldType === MULTIPLE_CHOICE
        ? Yup.array()
            .min(1, "Answer choices are required for multiple choice field")
            .required("Answer choices are required for multiple choice field")
        : Yup.array().notRequired(),
  })

export const recurringPremiumDetailsSchema = Yup.object().shape({
  recurringPremiums: Yup.array().of(
    Yup.object().shape({
      premiumAmountCents: Yup.number().required("Premium amount is required"),
      enrolledAllowanceCents: Yup.number().required("Allowance amount is required"),
      insuranceType: Yup.string().required("Coverage type is required"),
      coverageStartDate: Yup.string().required("Coverage start date is required"),
      coverageEndDate: Yup.string().required("Coverage end date is required"),
      status: Yup.string().required("Status is required"),
    })
  ),
})

export const createBusinessUnitSchema = Yup.object().shape({
  businessUnitName: Yup.string()
    .required("Business Unit name is required")
    .min(3, "Minimum 3 characters for Business Unit Name"),
})

const uuidRegex = new RegExp("^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f][0-9a-f]{3}-[0-9a-f][0-9a-f]{3}-[0-9a-f]{12}$", "i")
export const isValidUuid = (uuid: string) => Boolean(uuid?.toLowerCase?.().trim?.() && uuidRegex.test(uuid))

export const validateReverseJournalEntry = Yup.object({
  journalEntryId: Yup.string<Uuid>()
    .required("Journal Entry ID is required")
    .matches(uuidRegex, "Journal Entry ID must be a valid UUID"),
})

export const copyRecurringPremiumSchema = Yup.object().shape({
  coverageStartDate: Yup.string().required("Coverage start date is required"),
  coverageEndDate: Yup.string().required("Coverage end date is required"),
  isReimbursement: Yup.boolean().nullable(),
})
