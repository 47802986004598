import rocketImage from "@/assets/svg/rocket-launch.svg"
import { Greeting } from "@/components/Greeting"
import { SELF_ENROLL } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { usePrefetchFundingStatus } from "@/features/Funding/fundingService"
import { createDataQa } from "@/utils/dataQa"
import { Uuid } from "@/utils/types"
import { getUserNameAndInitials } from "@/utils/util"
import NoAccountsOutlinedIcon from "@mui/icons-material/NoAccountsOutlined"
import { Box, Button, Card, Grid, SxProps, Typography } from "@mui/material"
import CardContent from "@mui/material/CardContent"
import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { EMPLOYEE_PURCHASED, PERSONAL_INFO, SELF } from "../../benefitsElectionConstants"
import { useShoppingSession, useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { HealthBenefitsElection, ShoppingPersonPayload } from "../../benefitsElectionTypes"
import { mapShoppingPersonPayloadToStore } from "../../benefitsElectionUtils"

export const employeeAvatarStyle = {
  width: { xs: 60, sm: 100 },
  height: { xs: 60, sm: 100 },
  fontSize: { xs: "1.5rem", sm: "2.5rem" },
  border: "4px solid var(--light-primary-contrast, #FFF)",
  boxShadow:
    "0px 2.76726px 2.21381px 0px rgba(0, 0, 0, 0.03), 0px 6.6501px 5.32008px 0px rgba(0, 0, 0, 0.04), 0px 12.52155px 10.01724px 0px rgba(0, 0, 0, 0.05), 0px 22.33631px 17.86905px 0px rgba(0, 0, 0, 0.07), 0px 41.77761px 33.42209px 0px rgba(0, 0, 0, 0.08), 0px 100px 80px 0px rgba(0, 0, 0, 0.11)",
}

export const heroSectionStyle: SxProps = {
  bgcolor: "colors.regionGray",
  minHeight: "20rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 4,
  marginTop: 0,
  marginLeft: 6,
  marginRight: 6,
}

export const wrapperBoxStyle: SxProps = {
  bgcolor: "colors.white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
}

export const contentBoxStyle: SxProps = {
  marginBottom: 16,
  marginTop: 3,
  marginRight: 6,
  marginLeft: 6,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}

export const EmployeeWelcome = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const companyId = user?.company?.companyId as Uuid
  const { userName } = getUserNameAndInitials(user)
  const shoppingSessionId = useShoppingSession()
  const shoppingUrl = useShoppingUrl()

  usePrefetchFundingStatus(companyId)
  // Zustand selectors
  const setShoppingSession = useBenefitsElectionStore(state => state.setCurrentShoppingSession)
  const storedEmployee = useBenefitsElectionStore(state => state.employee)
  const setEmployee = useBenefitsElectionStore(state => state.setEmployee)
  const setFamilyMembers = useBenefitsElectionStore(state => state.setFamilyMembers)
  const setRecommendationAnswers = useBenefitsElectionStore(state => state.setRecommendationAnswers)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setHouseholdIncome = useBenefitsElectionStore(state => state.setHouseholdIncome)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const setSelectedPlan = useBenefitsElectionStore(state => state.setSelectedPlan)

  const syncShoppingPersons = useCallback(
    (employeePayload?: ShoppingPersonPayload, familyMembers?: ShoppingPersonPayload[]) => {
      if (employeePayload) {
        const loadedEmployee = mapShoppingPersonPayloadToStore(employeePayload)

        if (loadedEmployee) {
          // FUTURE: remove preferences override here once we are storing them in the DB
          setEmployee({
            ...loadedEmployee,
            doctors: storedEmployee.doctors,
            hospitals: storedEmployee.hospitals,
            prescriptions: storedEmployee.prescriptions,
          })
        }

        if (employeePayload.annualIncomeCents) {
          setHouseholdIncome(employeePayload.annualIncomeCents / 100)
        }
      }

      if (familyMembers) {
        const loadedFamilyMembers = familyMembers.map(memberPayload => mapShoppingPersonPayloadToStore(memberPayload))

        setFamilyMembers(loadedFamilyMembers)
      }
    },
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEmployee, setFamilyMembers, setHouseholdIncome]
  )

  const syncBenefitElection = useCallback(
    (primaryBenefitElection?: HealthBenefitsElection) => {
      if (primaryBenefitElection) {
        const {
          enrollmentStatus,
          enrollmentType = SELF_ENROLL,
          healthPlanId = "",
          healthPlanName = "",
        } = primaryBenefitElection

        if (enrollmentStatus === EMPLOYEE_PURCHASED) {
          setCurrentStep(PERSONAL_INFO)

          setSelectedPlan({
            id: healthPlanId,
            //@ts-expect-error We don't have full data to create a complete plan, but the necessary to show the page correctly
            carrier: { enrollment: enrollmentType },
            name: healthPlanName,
          })
        }
      }
    },
    [setCurrentStep, setSelectedPlan]
  )

  useEffect(() => {
    if (user?.shoppingSession) {
      const shoppingSessionPayload = user.shoppingSession

      setShoppingSession(shoppingSessionPayload)
      const { shoppingPersons, recommendationAnswers, healthBenefitElections } = shoppingSessionPayload
      const employeePayload = shoppingPersons?.find(shoppingPerson => shoppingPerson.relationship === SELF)
      const familyMembers = shoppingPersons?.filter(shoppingPerson => shoppingPerson.relationship !== SELF)
      const primaryBenefitElection = healthBenefitElections.find(benefitElection => benefitElection.isPrimary)

      syncShoppingPersons(employeePayload, familyMembers)

      if (recommendationAnswers) {
        setRecommendationAnswers(recommendationAnswers)
      }
      // FUTURE: create store objects to store carrier preferences, plan types
      syncBenefitElection(primaryBenefitElection)
    }
  }, [user, syncShoppingPersons, setRecommendationAnswers, setShoppingSession, syncBenefitElection, setCurrentStep])

  return (
    <>
      <Box sx={heroSectionStyle}>
        <Box
          component="img"
          alt="rocket ship taking off"
          data-qa={createDataQa("welcome-header-avatar")}
          src={rocketImage}
          sx={{ width: { xs: "8rem", md: "10.5rem" }, height: { xs: "8rem", md: "10.5rem" } }}
        />
        <Typography variant="h1" fontSize={{ sm: "3rem", xs: "2rem" }} data-qa="welcome-header-greeting">
          Hello, {userName}!
          <Greeting />
        </Typography>
      </Box>

      <Box sx={wrapperBoxStyle}>
        <Box sx={contentBoxStyle}>
          <Card
            variant="outlined"
            sx={{
              width: "fit-content",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              marginTop: { xs: -8, sm: 0 },
            }}
            data-qa="welcome-card"
          >
            <CardContent>
              <Typography>We're ready to help you find the best medical insurance for your health needs.</Typography>
              <Typography sx={{ mt: 5 }}>Next steps:</Typography>
              <Typography component="span">
                <ol style={{ marginTop: 5, marginBottom: 5 }}>
                  <li>Enter your information</li>
                  <li>Shop for your plan</li>
                  <li>Purchase the plan</li>
                </ol>
              </Typography>
              <Typography sx={{ mt: 5 }}>Let's begin!</Typography>
            </CardContent>
          </Card>

          <Grid container sx={{ mt: 3 }} rowSpacing={4}>
            <Grid item xs={12} md={3} order={{ xs: 3, md: 1 }}>
              <Button
                variant="text"
                color="inherit"
                fullWidth
                onClick={() => {
                  navigate("/", { state: { from: "benefitElection-welcome" } })
                }}
                data-qa="welcome-exit-button"
              >
                Exit to Dashboard
              </Button>
            </Grid>
            <Grid item xs={12} md={4} sx={{ marginLeft: "auto" }} order={{ xs: 2, md: 2 }}>
              <Button
                variant="text"
                color="inherit"
                sx={{ marginLeft: "auto" }}
                startIcon={<NoAccountsOutlinedIcon />}
                fullWidth
                data-qa="welcome-not-interested-button"
                onClick={() => {
                  navigate(`/benefits-election/${shoppingSessionId}/waive-coverage`)
                }}
              >
                I'm not interested
              </Button>
            </Grid>
            <Grid item xs={12} md={4} order={{ xs: 1, md: 3 }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  navigate(shoppingUrl + currentStep)
                }}
                data-qa="welcome-start-button"
              >
                Get started
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}
