import {
  ALL_EMPLOYEES,
  CHILDREN,
  EMPLOYEE,
  SPOUSE,
  SPOUSE_AND_CHILDREN,
  VARY_BY_FAMILY,
} from "@/features/CreateCompany/createCompanyConstants"
import { formatDollarToCents, removeCommas } from "@/utils/formatting"
import { Tuple } from "@/utils/types"
import { isEmpty, isEqual } from "lodash"
import {
  CalculateReimbursementRatePayload,
  ClassDiscriminators,
  CustomClassData,
  CustomClassDetails,
  EmployeeSalary,
  EmployeeSeasonality,
  EmployeeTime,
  HraClassModel,
  HraClassModelResponse,
  HraClassPayload,
  PlanStructureFormValues,
  PlanStructureFormValuesPayload,
  PlanStructurePatchRequest,
  Reimbursement,
  ReimbursementValues,
} from "./planStructureTypes"

const AGE_CURVE = 5

const createReimbursements = ({
  ageCurveId,
  kind,
  employee,
  spouse,
  children,
  spouseAndChildren,
}: ReimbursementValues) => {
  const isVaryingByAge = kind === "VARY_BY_AGE" || kind === "VARY_BY_FAMILY_SIZE_AND_AGE"

  const reimbursements: Reimbursement[] = [
    {
      reimbursementType: EMPLOYEE,
      reimbursementAmount: employee,
      isVaryingByAge,
      ageCurveId,
    },
    {
      reimbursementType: SPOUSE,
      reimbursementAmount: spouse,
      isVaryingByAge,
      ageCurveId,
    },
    {
      reimbursementType: CHILDREN,
      reimbursementAmount: children,
      isVaryingByAge,
      ageCurveId,
    },
    {
      reimbursementType: SPOUSE_AND_CHILDREN,
      reimbursementAmount: spouseAndChildren,
      isVaryingByAge,
      ageCurveId,
    },
  ]

  return reimbursements
}

const createTimeStatuses = (isPartTime: boolean, isFullTime: boolean): Tuple<EmployeeTime> => {
  if (!isPartTime && !isFullTime) throw new Error("Either full-time or part-time is required")

  if (isFullTime) {
    return isPartTime ? (["PART_TIME", "FULL_TIME"] as const) : (["FULL_TIME"] as const)
  } else return ["PART_TIME"] as const
}

const createSeasonalityStatuses = (isSeasonal: boolean, isNonSeasonal: boolean): Tuple<EmployeeSeasonality> => {
  if (!isSeasonal && !isNonSeasonal) throw new Error("Either seasonal or non-seasonal is required")

  if (isNonSeasonal) {
    return isSeasonal ? (["SEASONAL", "NON_SEASONAL"] as const) : (["NON_SEASONAL"] as const)
  } else return ["SEASONAL"] as const
}

const createSalaryStatuses = (isSalary: boolean, isNonSalary: boolean): Tuple<EmployeeSalary> => {
  if (!isSalary && !isNonSalary) throw new Error("Either salary or non-salary is required")

  if (isNonSalary) {
    return isSalary ? (["SALARIED", "HOURLY"] as const) : (["HOURLY"] as const)
  } else return ["SALARIED"] as const
}

export const removeCommasFromPayload = (
  payload: CalculateReimbursementRatePayload
): CalculateReimbursementRatePayload => {
  const keys = [
    "employeeAmount",
    "employeeAndSpouseAmount",
    "employeeAndChildrenAmount",
    "employeeAndSpouseAndChildrenAmount",
  ] as const
  keys.forEach(key => {
    payload[key] = removeCommas(payload[key])
  })
  return payload
}

const verifyIsVaryingByAge = (value: string) => value === "VARY_BY_AGE" || value === "VARY_BY_FAMILY_SIZE_AND_AGE"

const verifyAgeCurveId = (value: string) =>
  value === "VARY_BY_AGE" || value === "VARY_BY_FAMILY_SIZE_AND_AGE" ? AGE_CURVE : undefined

export const hraReimbursementStructureElements = [
  {
    title: "All Employees",
    subtitle: "Same Amount",
    value: ALL_EMPLOYEES,
  },
  {
    title: "Vary By Family Size",
    subtitle: "And/Or Age Only",
    value: VARY_BY_FAMILY,
  },
  {
    title: "Let Me Create",
    subtitle: "My Own Classes",
    value: "CUSTOM",
  },
]

export const hraVaryByFamilySizeOrAgeElements = [
  {
    title: "Vary By Age",
    value: "VARY_BY_AGE",
  },
  {
    title: "Vary By Family Size",
    value: "VARY_BY_FAMILY_SIZE",
  },
  {
    title: "Vary By Age and Family Size",
    value: "VARY_BY_FAMILY_SIZE_AND_AGE",
  },
]

export const mapFormValuesToPayload = (formValues: PlanStructureFormValues): PlanStructureFormValuesPayload => {
  const reimbursements: Reimbursement[] = [
    {
      reimbursementAmount: Number(formatDollarToCents(formValues.employeeAmount)),
      reimbursementType: EMPLOYEE,
      isVaryingByAge: verifyIsVaryingByAge(formValues.varyByFamilySizeOrAge),
      ageCurveId: formValues.ageCurveId ?? verifyAgeCurveId(formValues.varyByFamilySizeOrAge),
    },
    {
      reimbursementAmount: Number(formatDollarToCents(formValues.employeeAndSpouseAmount)),
      reimbursementType: SPOUSE,
      isVaryingByAge: verifyIsVaryingByAge(formValues.varyByFamilySizeOrAge),
      ageCurveId: formValues.ageCurveId ?? verifyAgeCurveId(formValues.varyByFamilySizeOrAge),
    },
    {
      reimbursementAmount: Number(formatDollarToCents(formValues.employeeAndChildrenAmount)),
      reimbursementType: CHILDREN,
      isVaryingByAge: verifyIsVaryingByAge(formValues.varyByFamilySizeOrAge),
      ageCurveId: formValues.ageCurveId ?? verifyAgeCurveId(formValues.varyByFamilySizeOrAge),
    },
    {
      reimbursementAmount: Number(formatDollarToCents(formValues.employeeAndSpouseAndChildrenAmount)),
      reimbursementType: SPOUSE_AND_CHILDREN,
      isVaryingByAge: verifyIsVaryingByAge(formValues.varyByFamilySizeOrAge),
      ageCurveId: formValues.ageCurveId ?? verifyAgeCurveId(formValues.varyByFamilySizeOrAge),
    },
  ]

  const hraClass = {
    ...(formValues.classId && { id: formValues.classId }),
    name:
      formValues.reimbursementStructure === "VARY_BY_FAMILY"
        ? (hraVaryByFamilySizeOrAgeElements.find(e => e.value === formValues.varyByFamilySizeOrAge)?.title ?? "")
        : (hraReimbursementStructureElements.find(e => e.value === formValues.reimbursementStructure)?.title ?? ""),
    classStructure:
      formValues.reimbursementStructure === "VARY_BY_FAMILY"
        ? formValues.varyByFamilySizeOrAge
        : formValues.reimbursementStructure,
    waitingPeriod: formValues.waitingPeriod,
    eligibleForReimbursement: formValues.eligibleForReimbursement,
    reimbursements,
    isSpecificGeography: formValues.isSpecificGeography,
    ...(formValues.isSpecificGeography && { geographyDescription: formValues.geographyDescription }),
    isFullTime: formValues.isFullTime,
    isPartTime: formValues.isPartTime,
    isSalary: formValues.isSalary,
    isNonSalary: formValues.isNonSalary,
    isSeasonal: formValues.isSeasonal,
    isNonSeasonal: formValues.isNonSeasonal,
  }

  return hraClass
}

export const mapFormValuesToCreateClassesPayload = (formValues: PlanStructureFormValues) => {
  const hraClass = mapFormValuesToPayload(formValues)

  return {
    hraClasses: [hraClass],
  }
}

export const createHraClassPayload = ({
  ageCurveId,
  customClassName: name,
  reimbursementStructure: classStructure,
  eligibleForReimbursement,
  isPartTime,
  isFullTime,
  isSeasonal,
  isNonSeasonal,
  isSalary,
  isNonSalary,
  isSpecificGeography,
  geographyDescription,
  waitingPeriod,
  employeeAmount: employee,
  employeeAndSpouseAmount: spouse,
  employeeAndChildrenAmount: children,
  employeeAndSpouseAndChildrenAmount: spouseAndChildren,
}: CustomClassDetails) =>
  ({
    name,
    classStructure,

    employeeTimeStatuses: createTimeStatuses(isPartTime, isFullTime),
    employeeSeasonalityStatuses: createSeasonalityStatuses(isSeasonal, isNonSeasonal),
    employeeSalaryStatuses: createSalaryStatuses(isSalary, isNonSalary),
    isSpecificGeography,
    ...(isSpecificGeography && { geographyDescription }),
    waitingPeriod,
    eligibleForReimbursement,
    countyZipDetail: {
      // FUTURE: Correctly populate zipCode data for class where applicable
      // For now, using a tempory value to convey structure
      zipCode: "90210",
      fipsCode: "123456789",
      countyName: "Los Angeles",
      state: "CA",
    },

    reimbursements: createReimbursements({
      ageCurveId,
      kind: classStructure,
      employee,
      spouse,
      children,
      spouseAndChildren,
    }),
  }) as HraClassPayload

export const updateHraClassPayload = (customClass: CustomClassDetails) => {
  const createPayload = createHraClassPayload(customClass) as HraClassModel

  return {
    ...createPayload,
    ...(customClass.classId && { id: customClass.classId }),
  } as HraClassModelResponse
}

export const createClass = ({
  name: customClassName,
  id: classId,
  classStructure: reimbursementStructure,
  isSpecificGeography,
  geographyDescription,
  waitingPeriod,
  eligibleForReimbursement,
  employeeTimeStatuses,
  employeeSalaryStatuses,
  employeeSeasonalityStatuses,
  countyZipDetail,
  reimbursements,
}: HraClassModel) => {
  const isPartTime = employeeTimeStatuses?.includes("PART_TIME")
  const isFullTime = employeeTimeStatuses?.includes("FULL_TIME")
  const isSeasonal = employeeSeasonalityStatuses?.includes("SEASONAL")
  const isNonSeasonal = employeeSeasonalityStatuses?.includes("NON_SEASONAL")
  const isSalary = employeeSalaryStatuses?.includes("SALARIED")
  const isNonSalary = employeeSalaryStatuses?.includes("HOURLY")
  const employee = reimbursements.find(r => r.reimbursementType === EMPLOYEE)?.reimbursementAmount ?? 0
  const spouse = reimbursements.find(r => r.reimbursementType === SPOUSE)?.reimbursementAmount ?? 0
  const children = reimbursements.find(r => r.reimbursementType === CHILDREN)?.reimbursementAmount ?? 0

  const spouseAndChildren =
    reimbursements.find(r => r.reimbursementType === SPOUSE_AND_CHILDREN)?.reimbursementAmount ?? 0

  return {
    customClassName,
    classId,
    reimbursementStructure,
    eligibleForReimbursement,
    isPartTime,
    isFullTime,
    isSalary,
    isNonSalary,
    isSeasonal,
    isNonSeasonal,
    employeeAmount: employee,
    employeeAndSpouseAmount: spouse,
    employeeAndChildrenAmount: children,
    employeeAndSpouseAndChildrenAmount: spouseAndChildren,
    isSpecificGeography,
    geographyDescription,
    waitingPeriod,
  } as CustomClassDetails
}

export const getClassDiscriminators = (details: CustomClassDetails): ClassDiscriminators => {
  const {
    isSpecificGeography,
    geographyDescription,
    isPartTime,
    isFullTime,
    isSalary,
    isNonSalary,
    isSeasonal,
    isNonSeasonal,
    healthBenefits,
  } = details

  return {
    isSpecificGeography,
    geographyDescription,
    isPartTime,
    isFullTime,
    isSalary,
    isNonSalary,
    isSeasonal,
    isNonSeasonal,
    healthBenefits,
  }
}

/**
 * Converts retrieved data to show it in the form existing fields
 * @param existingData
 * @returns formatted data
 */
export const convertDataToFormValues = (existingData: CustomClassDetails): PlanStructureFormValues => {
  const {
    classId = "",
    reimbursementStructure = "",
    eligibleForReimbursement = "",
    waitingPeriod = "",
    employeeAmount = "0",
    employeeAndSpouseAmount = "0",
    employeeAndChildrenAmount = "0",
    employeeAndSpouseAndChildrenAmount = "0",
  } = existingData

  const varyByFamilySizeOrAge = reimbursementStructure.toUpperCase() !== ALL_EMPLOYEES ? reimbursementStructure : ""
  const reimbursementStructureValue = varyByFamilySizeOrAge ? VARY_BY_FAMILY : reimbursementStructure

  return {
    classId,
    reimbursementStructure: reimbursementStructureValue,
    eligibleForReimbursement,
    needMoreThanOneClass: "no-set",
    isFullTime: false,
    isPartTime: false,
    isSalary: false,
    isNonSalary: false,
    isSeasonal: false,
    isNonSeasonal: false,
    isSpecificGeography: "",
    geographyDescription: "",
    waitingPeriod,
    varyByFamilySizeOrAge,
    employeeAmount: employeeAmount.toString(),
    employeeAndSpouseAmount: employeeAndSpouseAmount.toString(),
    employeeAndChildrenAmount: employeeAndChildrenAmount.toString(),
    employeeAndSpouseAndChildrenAmount: employeeAndSpouseAndChildrenAmount.toString(),
    hoursPerWeekAmountLetMeCreate: "",
    monthsPerYearSelectionLetMeCreate: "",
    submit: "false",
  }
}

/**
 * Adds existing custom classes to the patch request for deletion.
 * @param patchRequest
 * @param loadedData data loaded from the API
 */
const addExistingCustomClassesForDeletion = (
  patchRequest: PlanStructurePatchRequest,
  loadedData: HraClassModelResponse[]
) => {
  const customClassesToDelete: string[] = []

  loadedData?.forEach(loadedClass => customClassesToDelete.push(loadedClass.id))
  if (customClassesToDelete.length > 0) {
    patchRequest.deletes = customClassesToDelete
  }
}

/**
 * Adds new custom classes to the patch request for creation
 * @param patchRequest
 * @param customClassData custom classes saved in the store
 */
const addNewCustomClassesToPatchRequest = (
  patchRequest: PlanStructurePatchRequest,
  customClassData: CustomClassData
) => {
  const creates = []

  for (const key in customClassData) {
    if (key.startsWith("localid-")) {
      creates.push(customClassData[key] as CustomClassDetails)
    }
  }
  if (creates.length > 0) {
    patchRequest.creates = creates.map(createHraClassPayload)
  }
}

/**
 * Adds custom classes to the updates array in the patch request if they have been updated.
 * @param patchRequest
 * @param loadedData
 * @param customClassData
 */
const addUpdatedClasesToPatchRequest = (
  patchRequest: PlanStructurePatchRequest,
  loadedData: HraClassModelResponse[],
  customClassData: CustomClassData
) => {
  const updates: CustomClassDetails[] = []
  const loadedCustomClasses = loadedData.map(createClass)

  // Compare loaded classes with local classes to detect changes
  loadedCustomClasses.forEach(loadedCustomClass => {
    const localCustomClass = customClassData[loadedCustomClass.classId!] as CustomClassDetails

    // If the class has not been locally deleted, check for changes
    if (localCustomClass) {
      const namesAreDifferent = loadedCustomClass.customClassName !== localCustomClass.customClassName
      const loadedClassDiscriminators = getClassDiscriminators(loadedCustomClass)
      const localClassDiscriminators = getClassDiscriminators(localCustomClass)

      if (namesAreDifferent || !isEqual(localClassDiscriminators, loadedClassDiscriminators)) {
        updates.push(localCustomClass)
      }
    }
  })
  if (updates.length > 0) {
    patchRequest.updates = updates.map(updateHraClassPayload)
  }
}

const addDeletedClassesToPatchRequest = (patchRequest: PlanStructurePatchRequest, deletedClasses: string[]) => {
  if (deletedClasses && deletedClasses.length > 0) {
    patchRequest.deletes = deletedClasses
  }
}

/**
 * Builds a patch request for updating custom classes in the plan structure.
 * @param options - An object containing various data for creating the patch request.
 * @returns A `PlanStructurePatchRequest` or `null` if no updates are needed.
 */
export const buildCustomClassesPatchRequest = ({
  fixedClassData,
  fixedClassOperation,
  customClassData,
  deletedClasses,
  loadedData,
}: {
  fixedClassData?: PlanStructureFormValues
  fixedClassOperation?: "CREATE" | "UPDATE" | "DELETE"
  customClassData?: CustomClassData
  deletedClasses?: string[]
  loadedData?: HraClassModelResponse[]
}): PlanStructurePatchRequest | null => {
  // Case 1: A fixed class was updated
  if (fixedClassData && fixedClassOperation === "UPDATE") {
    const formValuesPayload = mapFormValuesToPayload(fixedClassData)
    const patchRequest = { updates: [formValuesPayload] }

    return patchRequest
  }

  // Case 2: Custom classes were deleted, and a fixed class was created.
  if (fixedClassData && fixedClassOperation === "CREATE") {
    const formValuesPayload = mapFormValuesToPayload(fixedClassData)
    const patchRequest: PlanStructurePatchRequest = { creates: [formValuesPayload] }

    addExistingCustomClassesForDeletion(patchRequest, loadedData!)

    return patchRequest
  }

  // Case 3: A fixed class was deleted, and custom classes were created.
  if (fixedClassData && fixedClassOperation === "DELETE") {
    const patchRequest: PlanStructurePatchRequest = {}

    patchRequest.deletes = [fixedClassData.classId!]

    addNewCustomClassesToPatchRequest(patchRequest, customClassData!)

    return patchRequest
  }

  // Case 4: A custom class was updated and/or created
  if (customClassData) {
    const patchRequest: PlanStructurePatchRequest = {}

    addNewCustomClassesToPatchRequest(patchRequest, customClassData)
    addUpdatedClasesToPatchRequest(patchRequest, loadedData!, customClassData)
    addDeletedClassesToPatchRequest(patchRequest, deletedClasses!)

    return isEmpty(patchRequest) ? null : patchRequest
  }

  return null
}
