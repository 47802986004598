import astronautImage from "@/assets/svg/astronaut-on-the-moon.svg"
import { AsyncButton } from "@/components/Buttons"
import { PHONE_NUMBER } from "@/constants"
import { useCompanyEmployment } from "@/features/Auth/useAuth"
import { createDataQa } from "@/utils/dataQa"
import { cleanUpUsPhoneNumber } from "@/utils/formatting"
import { Box, Card, CardContent, Link, Typography } from "@mui/material"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { useResetStore } from "../../hooks/useResetStore"
import { isPlanYearRecurringPremiumReady, useGetAllRecurringPremiums } from "../../recurringPremiumsService"
import { useShoppingCompleteStore } from "../../shoppingCompleteStore"
import { contentBoxStyle, heroSectionStyle, wrapperBoxStyle } from "../welcome/EmployeeWelcome"

const maxWidth = "44.5rem"
const dataQa = createDataQa("purchase-complete")

export const Complete = () => {
  useResetStore()

  const { companyId, employmentId } = useCompanyEmployment()
  const shoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const completedPlanYear = useShoppingCompleteStore(state => state.completedPlanYear)

  const { data: ledgerRecurringPremiums } = useGetAllRecurringPremiums(companyId, employmentId, completedPlanYear)
  const isDataReady = isPlanYearRecurringPremiumReady(ledgerRecurringPremiums, completedPlanYear)

  return (
    <>
      <Box
        sx={{
          ...heroSectionStyle,
          justifyContent: "space-around",
          minHeight: "fit-content",
        }}
      >
        <Box
          component="img"
          alt="astronaut planting a flag on the moon"
          src={astronautImage}
          sx={{ width: { xs: "14rem", md: "21.5rem" }, height: { xs: "18rem", md: "21rem" }, mt: { xs: 8, md: 0 } }}
        />
        <Typography
          variant="h1"
          align="center"
          sx={{ fontSize: { md: "3rem", xs: "2rem" }, lineHeight: "120%", maxWidth, marginBottom: 12 }}
          data-qa={createDataQa(dataQa, "congratulations")}
        >
          Great work! Your {completedPlanYear} health coverage is being processed.
        </Typography>
      </Box>

      <Box sx={wrapperBoxStyle}>
        <Box sx={{ ...contentBoxStyle, justifyContent: "space-around" }}>
          <Card
            variant="outlined"
            sx={{ maxWidth, justifyContent: "space-around" }}
            data-qa={createDataQa(dataQa, "card")}
          >
            <CardContent>
              <Typography>
                Your information is being processed. We'll verify your plan is compliant and notify you when your
                application is submitted. If we encounter any issues or need further information, we will contact you.
                Please follow up promptly to ensure your coverage is completed. If you purchased a new plan, expect
                communication from your insurance carrier. For any additional questions, please contact our support team
                via chat or phone at{" "}
                <Link href={`tel:+${cleanUpUsPhoneNumber(PHONE_NUMBER)}`} data-qa="phone-number-button">
                  {PHONE_NUMBER}
                </Link>
                .<br />
                <br />
                <strong>Reminder: Cancel your old plan</strong>
                <br />
                If you chose a new insurance company and/or health plan for {shoppingSession?.planYear}, you will need
                to cancel your old health plan. Contact your insurance carrier and request a cancellation date on the
                day before your new health plan starts to avoid being double charged for coverage.
              </Typography>
            </CardContent>
          </Card>

          <AsyncButton
            isLoading={!isDataReady}
            href="/"
            variant="contained"
            color="primary"
            sx={{ alignSelf: "center", width: { xs: "100%", sm: "fit-content" }, marginY: 4 }}
            data-qa={createDataQa(dataQa, "start-button")}
          >
            Go to Dashboard
          </AsyncButton>
        </Box>
      </Box>
    </>
  )
}
