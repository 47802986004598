import { CheckBoxGroupCard } from "@/components/CheckBoxGroupCard"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { StyledCard } from "@/components/StyledCard"
import {
  ClampedTextField,
  EmailTextField,
  FirstNameTextField,
  LastNameTextField,
  PhoneNumberTextField,
  ZipCodeTextField,
} from "@/components/TextFields"
import { YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { hraHubEditPersonSchema, HraHubEditPersonValues } from "@/features/TCHub/editPersonValidations"
import { useNotifications } from "@/services/notificationService"
import { STATE_OPTIONS, UsaState } from "@/utils/States"
import { Uuid } from "@/utils/types"
import { Delete, KeyboardArrowLeft, Mail, SaveOutlined } from "@mui/icons-material"
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { Formik, FormikProps } from "formik"
import { get, noop } from "lodash"
import { Dispatch, SetStateAction, useState } from "react"
import { useSignUpLink } from "../hooks/useSendSignupLink"
import { useUpdateUserFromHraHub } from "../peopleService"
import { PersonModel } from "../peopleTypes"
import { getRoleValues, ROLE_OPTIONS } from "./PeopleDrawerProfileDetails"

export function getInitialValues(tcHubPersonDetails: PersonModel | undefined): HraHubEditPersonValues {
  return {
    id: (tcHubPersonDetails?.id as Uuid) ?? "00000000-0000-0000-0000-000000000000",
    firstName: tcHubPersonDetails?.firstName ?? "",
    lastName: tcHubPersonDetails?.lastName ?? "",
    employeeId: tcHubPersonDetails?.employments?.[0]?.employeeNumber ?? "",
    homeAddress: {
      id: (tcHubPersonDetails?.addresses?.[0]?.id as Uuid) ?? "00000000-0000-0000-0000-000000000000",
      street1: tcHubPersonDetails?.addresses?.[0]?.street1 ?? "",
      street2: tcHubPersonDetails?.addresses?.[0]?.street2 ?? "",
      city: tcHubPersonDetails?.addresses?.[0]?.city ?? "",
      zip: tcHubPersonDetails?.addresses?.[0]?.zip ?? "",
      county: tcHubPersonDetails?.addresses?.[0]?.county ?? "",
      state: (tcHubPersonDetails?.addresses?.[0]?.state as UsaState) ?? ("" as UsaState),
    },
    email: tcHubPersonDetails?.email ?? "",
    phoneNumber: tcHubPersonDetails?.phoneNumber ?? "",
  }
}

const PersonEditDetailCard = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  companyName,
}: FormikProps<HraHubEditPersonValues> & { companyName: string }) => (
  <StyledCard>
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom data-qa="person-detail-title">
          About
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FirstNameTextField />
      </Grid>
      <Grid item xs={12}>
        <LastNameTextField />
      </Grid>
      <Grid item xs={12}>
        <ClampedTextField
          data-qa="EmployeeID"
          name="employeeId"
          label="Employee ID"
          variant="outlined"
          required={false}
          value={values.employeeId ?? ""}
          error={Boolean(touched.employeeId && errors.employeeId)}
          helperText={touched.employeeId && errors.employeeId}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ClampedTextField
          data-qa="Company"
          label="Company"
          variant="outlined"
          value={companyName}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ClampedTextField
          data-qa="street1"
          name="homeAddress.street1"
          label="Main Address"
          value={values.homeAddress?.street1}
          fullWidth
          required
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(get(touched, `homeAddress.street1`) && get(errors, `homeAddress.street1`))}
          helperText={get(touched, `homeAddress.street1`) && get(errors, `homeAddress.street1`)}
        />
      </Grid>
      <Grid item xs={12}>
        <ClampedTextField
          data-qa="street2"
          name="homeAddress.street2"
          label="Apt, suite, etc."
          value={values.homeAddress?.street2}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(get(touched, `homeAddress.street2`) && get(errors, `homeAddress.street2`))}
          helperText={get(touched, `homeAddress.street2`) && get(errors, `homeAddress.street2`)}
        />
      </Grid>
      <Grid item xs={12}>
        <ClampedTextField
          data-qa="city"
          name="homeAddress.city"
          label="City"
          required
          value={values.homeAddress?.city}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(get(touched, `homeAddress.city`) && get(errors, `homeAddress.city`))}
          helperText={get(touched, `homeAddress.city`) && get(errors, `homeAddress.city`)}
        />
      </Grid>

      <Grid item xs={12}>
        <ZipCodeTextField
          data-qa="zip"
          name="homeAddress.zip"
          label="ZIP Code"
          required
          value={values.homeAddress?.zip}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(get(touched, `homeAddress.zip`) && get(errors, `homeAddress.zip`))}
          helperText={get(touched, `homeAddress.zip`) && get(errors, `homeAddress.zip`)}
          inputProps={{ "data-qa": "zip-code-input" }}
        />
      </Grid>

      <Grid item xs={12}>
        <ClampedTextField
          data-qa="county"
          name="homeAddress.county"
          label="County"
          required
          value={values.homeAddress?.county}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(get(touched, `homeAddress.county`) && get(errors, `homeAddress.county`))}
          helperText={get(touched, `homeAddress.county`) && get(errors, `homeAddress.county`)}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          sx={{ width: "100%" }}
          required
          error={Boolean(get(touched, `homeAddress.state`) && get(errors, `homeAddress.state`))}
        >
          <InputLabel>State</InputLabel>
          <Select
            name="homeAddress.state"
            label="State"
            placeholder="Select State"
            data-qa="state"
            fullWidth
            value={values.homeAddress?.state}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {STATE_OPTIONS.map(state => (
              <MenuItem key={state.value} data-qa={"state-" + state.value} value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.homeAddress?.state ? `${errors.homeAddress?.state ?? ""}` : ""}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <EmailTextField />
      </Grid>
      <Grid item xs={12}>
        <PhoneNumberTextField
          data-qa="phone-number"
          name="phoneNumber"
          label="Phone number"
          required={false}
          fullWidth
          variant="outlined"
          value={values.phoneNumber}
          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
          helperText={touched.phoneNumber && errors.phoneNumber}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  </StyledCard>
)

export interface PeopleDrawerEditProfileProps {
  setIsEditMode: Dispatch<SetStateAction<boolean>>
  person: PersonModel
  company: CompanyModel
  setIsTerminateOpen: Dispatch<SetStateAction<boolean>>
}

export const PeopleDrawerEditProfile = ({
  setIsEditMode,
  person,
  company,
  setIsTerminateOpen,
}: PeopleDrawerEditProfileProps) => {
  const { notify } = useNotifications("edit-person-hra-hub")
  const { handleSendSignUpLink } = useSignUpLink()
  const [isSendingLink, setIsSendingLink] = useState(false)

  const companyName = company?.companyInfo.companyName ?? ""
  const companyId = company?.id

  const { mutateAsync: updatePerson } = useUpdateUserFromHraHub(companyId ?? "", person.id ?? "")
  const handleNavigateBack = () => {
    setIsEditMode(false)
  }
  const roles = person?.roles?.map(role => role.name.toLowerCase()) ?? []
  const roleValues = getRoleValues(roles)
  const benefitsEligible = person?.employments?.[0]?.isEligible ?? false

  const handleSendLink = async () => {
    setIsSendingLink(true)
    try {
      await handleSendSignUpLink(company.id, person.id)
    } finally {
      setIsSendingLink(false)
    }
  }

  return (
    <Formik
      initialValues={getInitialValues(person)}
      validationSchema={hraHubEditPersonSchema}
      onSubmit={async values => {
        try {
          await updatePerson({ payload: values, employmentId: person.employments?.[0].id ?? "" })
          handleNavigateBack()
          notify("Person updated successfully", "success")
        } catch (error: any) {
          const errorMessage =
            error.responseMessage ??
            "Error updating the person. Please try again later. If the issue persists, contact support for assistance."
          notify(errorMessage, "error")
        }
      }}
    >
      {formProps => (
        <form noValidate onSubmit={formProps.handleSubmit} data-qa="edit-user-form">
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button
              startIcon={<KeyboardArrowLeft />}
              color="inherit"
              onClick={() => {
                setIsEditMode(false)
              }}
              sx={{ width: "6rem" }}
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formProps.isSubmitting}
              data-qa="update-user-button"
              endIcon={formProps.isSubmitting ? <CircularProgress size={20} /> : <SaveOutlined />}
            >
              Save Changes
            </Button>
          </Box>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Typography variant="h2tiempos" gutterBottom>
                Profile
              </Typography>
            </Grid>
          </Grid>
          <PersonEditDetailCard {...formProps} companyName={companyName} />
          <Grid item xs={12}>
            <StyledCard>
              <CheckBoxGroupCard
                disabled
                formName="profile-details"
                elements={ROLE_OPTIONS}
                values={roleValues}
                handleChange={noop}
                error={false}
                label="Roles"
              />
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard>
              <RadioGroupCard
                disabled
                name="benefitsEligible"
                formName="profile-details"
                elements={YES_NO_OPTIONS_BOOLEAN}
                value={benefitsEligible}
                handleChange={noop}
                label="Benefits Eligible"
              />
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <Button
                    variant="outlined"
                    data-qa="send-signup-link"
                    onClick={handleSendLink}
                    disabled={isSendingLink}
                    startIcon={isSendingLink ? <CircularProgress size={20} /> : <Mail />}
                  >
                    Send Sign-up Link
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    data-qa="terminate-employee"
                    onClick={() => setIsTerminateOpen(true)}
                    startIcon={<Delete />}
                  >
                    Terminate Employee
                  </Button>
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>
        </form>
      )}
    </Formik>
  )
}
