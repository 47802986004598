import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable, OrderByItemType } from "@/components/Table/Table"
import { SEARCH_THRESHOLD, TABLE_CELL_PADDING } from "@/constants"
import { HraPlanModel } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupTypes"
import { PLAN_SETUP } from "@/features/CreateCompany/createCompanyConstants"
import { CompanyModel, CompanyOnboardingStatus } from "@/features/CreateCompany/createCompanyEndpoints"
import { AUTOPAY_COMPLETED } from "@/features/EmployerOnboarding/employerOnboardingConstants"
import { EmployerOnboardingStatus } from "@/features/EmployerOnboarding/employerOnboardingTypes"
import { TableCell, Typography } from "@mui/material"
import Fuse from "fuse.js"
import { constant } from "lodash"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useCompanies } from "../tcHubService"
import { CompaniesTableHeader } from "../tcHubTypes"
import { TcHubCompaniesDropdown } from "./TcHubCompaniesDropdown"

interface CompanyTableProps {
  companySelector: (companies: CompanyModel[]) => CompanyModel[]
  searchCriteria: string
}

const defaultOrder = { headCellId: "name", direction: "asc" } as const

export const getStatusValue = (onboardingStatus: CompanyOnboardingStatus[] | undefined, key: string) => {
  if (!onboardingStatus || onboardingStatus.length === 0) {
    return ""
  }
  const planSetupStep = onboardingStatus.find(status => status.step === PLAN_SETUP)

  if (planSetupStep?.statusValue) {
    try {
      const statusValue = JSON.parse(planSetupStep.statusValue)

      return statusValue[key] || ""
    } catch (e) {
      console.error("Failed to parse statusValue:", e)

      return ""
    }
  }
}

export const isAutopayOnboardingComplete = (onboardingStatus: EmployerOnboardingStatus[] | undefined) => {
  if (!onboardingStatus || onboardingStatus.length === 0) {
    return false
  }

  const autoPayStep = onboardingStatus.find(status => status.step === AUTOPAY_COMPLETED)

  return autoPayStep?.isComplete || false
}

export const TcHubCompaniesTable = ({ companySelector, searchCriteria }: CompanyTableProps) => {
  const { data: companies, isLoading: isCompaniesLoading } = useCompanies()
  const companiesResult = companies ?? []
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selected, setSelected] = useState<CompanyModel[]>([])
  const [orderBy, setOrderBy] = useState<OrderByItemType[]>([defaultOrder])
  const navigate = useNavigate()

  const fuseOptions = {
    keys: ["companyInfo.companyName", "companyInfo.ein"],
    includeMatches: true,
    threshold: SEARCH_THRESHOLD,
  }

  const fuse = new Fuse(companiesResult, fuseOptions)
  let fuseSearchResults: CompanyModel[] = []

  if (searchCriteria) {
    fuseSearchResults = fuse.search(searchCriteria).map(result => result.item)
  }

  let filteredCompanies: CompanyModel[] = []

  filteredCompanies = searchCriteria.length === 0 ? companiesResult : fuseSearchResults

  const getHraType = (hraPlan: HraPlanModel[] | undefined) => {
    if (hraPlan && hraPlan.length > 0) {
      return hraPlan[0].hraType
    }

    return ""
  }

  const headers: CompaniesTableHeader[] = [
    {
      id: "companyName",
      label: "Company Name",
      field: row => row.companyInfo.companyName,
      sortable: true,
      alignment: "left",
    },
    {
      id: "ein",
      label: "EIN",
      field: row => row.companyInfo.ein,
      sortable: true,
      alignment: "left",
    },
    {
      id: "hraType",
      label: "HRA Type",
      field: row => getHraType(row.hraPlan),
      sortable: true,
      alignment: "left",
    },
    {
      id: "autopay",
      label: "AutoPay Complete",
      field: row => (isAutopayOnboardingComplete(row.companyOnboardingStatus) ? "Yes" : "No"),
      sortable: true,
      alignment: "left",
    },
    {
      id: "packageName",
      label: "Package Name",
      field: row => getStatusValue(row.companyOnboardingStatus, "packageName"),
      sortable: true,
      alignment: "left",
    },
    // FUTURE: Add call to the service level real data *
    { id: "serviceLevel", label: "Service Level", field: constant(""), sortable: true, alignment: "left" },
    { id: "actions", label: "", sortable: false },
  ]

  return isCompaniesLoading ? (
    <SkeletonTableLoader
      data-qa="skeleton-table-loader-companies"
      headerTitles={headers.map(cell => `${cell.label}`)}
      bodyRowsCount={10}
    />
  ) : (
    <BaseTable
      rows={filteredCompanies}
      selected={selected}
      orderBy={orderBy}
      onToggleOrderBy={headId =>
        setOrderBy(state => {
          const result = [] as OrderByItemType[]

          if (state && state.length > 0 && state[0].headCellId === headId) {
            result.push({
              headCellId: headId,
              direction: state[0].direction === "asc" ? "desc" : "asc",
            })
          } else {
            result.push({ headCellId: headId, direction: "asc" })
          }
          if (defaultOrder.headCellId !== headId) {
            result.push(defaultOrder)
          }

          return result
        })
      }
      onToggleSelect={selection =>
        setSelected(state => {
          if (state.some(r => r.id === selection.id)) {
            return state.filter(r => r.id !== selection.id)
          }

          return [...state, selection]
        })
      }
      onToggleSelectAll={() => {
        setSelected(state => (state.length > 0 ? [] : companiesResult))
      }}
      uniqueIdSelector={row => row.id.toString()}
      headCells={headers ?? []}
      onPageChange={changedPage => setPage(changedPage)}
      onRowsPerPageChange={rows => setRowsPerPage(rows)}
      page={page}
      rowsPerPage={rowsPerPage}
      exportCsv
      csvTitle="Companies"
    >
      {({ row, isSelected, toggleSelect, labelId, rowId }) => (
        <>
          <TableCell
            onClick={() => {
              navigate(`${row.id}`)
            }}
            component="th"
            id={labelId}
            scope="row"
            data-qa="company"
          >
            <Typography variant="body1" data-qa="company-name" sx={TABLE_CELL_PADDING}>
              {row.companyInfo.companyName}
            </Typography>
          </TableCell>
          <TableCell data-qa="ein">
            <Typography variant="body1" data-qa="company-ein" sx={TABLE_CELL_PADDING}>
              {row.companyInfo.ein}
            </Typography>
          </TableCell>
          <TableCell data-qa="hra-type">
            <Typography variant="body1" data-qa="company-hra-type" sx={TABLE_CELL_PADDING}>
              {getHraType(row.hraPlan)}
            </Typography>
          </TableCell>
          <TableCell data-qa="autopay">
            <Typography variant="body1" data-qa="company-is-autopay" sx={TABLE_CELL_PADDING}>
              {isAutopayOnboardingComplete(row.companyOnboardingStatus) ? "Yes" : "No"}
            </Typography>
          </TableCell>
          <TableCell data-qa="package-name">
            <Typography variant="body1" data-qa="company-package-name" sx={TABLE_CELL_PADDING}>
              {getStatusValue(row.companyOnboardingStatus, "packageName")}
            </Typography>
          </TableCell>
          {/* FUTURE: Add call to the service level real data */}
          <TableCell data-qa="service-level">
            <Typography variant="body1" data-qa="company-service-level" sx={TABLE_CELL_PADDING} />
          </TableCell>
          <TableCell>
            <TcHubCompaniesDropdown companyId={row.id} />
          </TableCell>
        </>
      )}
    </BaseTable>
  )
}
