import { AsyncButton } from "@/components/Buttons"
import { ClampedTextField } from "@/components/TextFields"
import { useNotifications } from "@/services/notificationService"
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { Form, Formik } from "formik"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import {
  DECLINED_TO_ANSWER,
  WAIVE_COVERAGE_OPTIONS,
  WAIVE_COVERAGE_REASONS,
  WAIVE_COVERAGE_SUCCESS,
} from "../../benefitsElectionConstants"
import { useAddWaiveCoverage, useShoppingSession } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { WaiveCoveragePayload } from "../../benefitsElectionTypes"

const waiveCoverageInitialValues: WaiveCoveragePayload = {
  reason: DECLINED_TO_ANSWER,
  otherReason: "",
}

const waiveCoverageValidationSchema = Yup.object().shape({
  reason: Yup.string().oneOf(WAIVE_COVERAGE_REASONS).optional(),
  otherReason: Yup.string().when("reason", {
    is: "OTHER",
    then: () => Yup.string().required("Reason is required"),
  }),
})

export const WaiveCoverage = () => {
  const { notify } = useNotifications("waive-coverage")

  const navigate = useNavigate()
  const shoppingSessionId = useShoppingSession()
  const shoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const { mutateAsync: addWaiveCoverage, isPending } = useAddWaiveCoverage(shoppingSessionId, shoppingSession.planYear)

  const handleBack = () => {
    navigate(`/benefits-election/${shoppingSessionId}/welcome`)
  }

  const isMediumUp = useMediaQuery(theme => theme.breakpoints.up("md"))

  return (
    <Formik
      initialValues={waiveCoverageInitialValues}
      enableReinitialize
      validationSchema={waiveCoverageValidationSchema}
      onSubmit={async values => {
        try {
          await addWaiveCoverage(values)
          navigate(`/benefits-election/${shoppingSessionId}/${WAIVE_COVERAGE_SUCCESS}`)
        } catch {
          notify("Error Waiving Coverage", "error")
        }
      }}
    >
      {({ errors, handleBlur, handleChange, touched, values, isValid, dirty }) => (
        <Form>
          <Grid container sx={{ backgroundColor: "white", minHeight: "86vh" }}>
            <Box
              sx={{
                padding: 6,
                margin: "auto",
                maxWidth: "50rem",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                gap: 6,
              }}
            >
              <Grid item xs={12} py="1rem" data-qa="waive-coverage-title">
                <Typography variant="h1" fontSize={{ md: "3rem" }} lineHeight="120%">
                  Thinking about waiving coverage? No problem.
                </Typography>
              </Grid>
              <Grid item xs={12} data-qa="waive-coverage-subtitle">
                <Typography variant="body1">
                  Please tell us why you are waiving.{" "}
                  <Typography variant="body1bold">Your feedback helps us improve our services.</Typography>
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} pl=".5rem">
                  <FormControl>
                    <RadioGroup name="reason">
                      {WAIVE_COVERAGE_OPTIONS.map(option => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.label}
                          name="reason"
                          onChange={handleChange}
                          data-qa={`waive-coverage-option-${option.value}`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {values.reason === "OTHER" && (
                  <Grid item xs={12}>
                    <ClampedTextField
                      data-qa="waiveCoverage-otherReason"
                      type="text"
                      name="otherReason"
                      label="Reason"
                      value={values.otherReason}
                      error={Boolean(touched.otherReason && errors.otherReason)}
                      fullWidth
                      required
                      placeholder="Please give us a short description of the reason you’re waiving here..."
                      helperText={touched.otherReason && errors.otherReason}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      sx={{ my: 3 }}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item container justifyContent={isMediumUp ? "flex-end" : "center"} alignItems="center" xs={12}>
                <Grid item pb="1rem">
                  <Button
                    type="button"
                    color="inherit"
                    onClick={handleBack}
                    sx={{ maxWidth: "15rem" }}
                    data-qa="waive-coverage-back-button"
                  >
                    Never mind, I'll look around
                  </Button>
                </Grid>
                <Grid item px="1.5rem" pb="1rem">
                  <AsyncButton
                    isLoading={isPending}
                    type="submit"
                    data-qa="waive-coverage-waive-button"
                    variant="contained"
                    color="primary"
                    disabled={!isValid}
                    sx={{ maxWidth: "15rem" }}
                  >
                    Waive my coverage
                  </AsyncButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
