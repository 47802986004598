import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { toTitleCase } from "@/utils/formatting"
import { ACH_ACCOUNT, CHECKING, CREDIT_CARD, PaymentInformation, PaymentStatusType } from "./types/paymentTypes"

const baseApiUrl = appConfig.baseApiUrl

// Billing portal
export interface BillingPortalUrl {
  paymentDetailsPageUrl: string
}

export const getBillingPortalUrl = async (companyId: string) => {
  try {
    const url = `${baseApiUrl}/billing/v1/company/${companyId}/portal/url`
    const billingPortalUrl = (await axios.get(url)).data as BillingPortalUrl

    return billingPortalUrl
  } catch {
    throw new Error(`Failed to get billing portal url for company: companyId=${companyId}`)
  }
}

// Billing information
// Constants
const expiredMethodStatus: PaymentStatusType = {
  severity: "warning",
  message: "Your payment method is expired. Please update your payment information to continue your HRA subscription.",
}

const invoiceFailedStatus: PaymentStatusType = {
  severity: "error",
  message: "Your payment method failed. Please update your payment information to continue your HRA subscription.",
}

const US_BANK_ACCOUNT = "US_BANK_ACCOUNT"

// Helper functions

const getAccountType = (accountType: string): "Checking" | "Savings" =>
  accountType.trim().toUpperCase() === CHECKING ? "Checking" : "Savings"

/**
 * Checks if the card with the given month and year is expired taking into account the current date
 * @param expirationMonth
 * @param expirationYear
 * @returns boolean indicating if the card is expired
 */
const isCardExpired = (expirationMonth: number, expirationYear: number): boolean => {
  // Last day of the expiration month
  const expirationDate = new Date(expirationYear, expirationMonth, 0)
  const currentDate = new Date()

  return currentDate > expirationDate
}

/**
 * Formats the expiration month to contain a leading zero if necessary
 * @param expirationMonth
 * @returns formated month as a string of length 2
 */
const formatExpirationMonth = (expirationMonth: number) => String(expirationMonth).padStart(2, "0")

/**
 * Formats the expiration year to show only the last two digits of it
 * @param expirationYear
 * @returns formated year as a string of length 2
 */
const formatExpirationYear = (expirationYear: number) => {
  const yearString = String(expirationYear)

  return yearString.substring(yearString.length - 2)
}

/**
 * Builds the corresponding PaymentInformation object depending on
 * if it is a card, an account of if there is not enough information
 * @param data data returned by the billing endpoint
 * @returns PaymentInformation object
 */
const setUpPaymentInformation = (data: any): PaymentInformation => {
  if (!data) {
    return { kind: null }
  }
  if (data.billingType === CREDIT_CARD) {
    const {
      cardBrand,
      cardNumberLastFourDigits,
      isDelinquent,
      expirationMonth,
      expirationYear,
      amountDueCents,
      billingDate,
      billingProviderId,
    } = data

    const shouldShowExpirationMessage = isCardExpired(expirationMonth, expirationYear) ? expiredMethodStatus : undefined

    return {
      kind: CREDIT_CARD,
      props: {
        cardIssuer: toTitleCase(cardBrand),
        cardNumber: `•••• ${cardNumberLastFourDigits}`,
        expirationMonth: formatExpirationMonth(expirationMonth),
        expirationYear: formatExpirationYear(expirationYear),
      },
      paymentStatus: isDelinquent ? invoiceFailedStatus : shouldShowExpirationMessage,
      amountDueCents,
      billingDate,
      billingProviderId,
    }
  } else if (data.billingType === US_BANK_ACCOUNT) {
    const { bankName, accountNumber, accountType, isDelinquent, amountDueCents, billingDate, billingProviderId } = data

    return {
      kind: ACH_ACCOUNT,
      props: {
        institution: bankName,
        accountNumber: `•••• ${accountNumber}`,
        accountType: getAccountType(accountType),
      },
      paymentStatus: isDelinquent ? invoiceFailedStatus : undefined,
      amountDueCents,
      billingDate,
      billingProviderId,
    }
  } else {
    return { kind: null }
  }
}

// Get billing information endpoint call
export const getCompanyBillingInformation = async (companyId: string) => {
  try {
    const url = `${baseApiUrl}/billing/v1/company/${companyId}/billing-information`
    const billingDetails = (await axios.get(url)).data

    return setUpPaymentInformation(billingDetails)
  } catch {
    throw new Error(`Failed to get billing information details for company: companyId=${companyId}`)
  }
}
