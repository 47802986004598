import { RadioGroupElement } from "./components/RadioGroupCard"
import { makeOptions } from "./utils/util"

// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  TCH: "TCH",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
}

// Time
export const TOKEN_REFRESH_THRESHOLD_MS = 300000 // Five Minutes

// Links
export const EXTERNAL_LINKS = {
  SUPPORT: "https://www.takecommandhealth.com/contact-us",
  HELP_CENTER: "https://intercom.help/take-command-health/en",
  PRIVACY: "https://www.takecommandhealth.com/privacy",
  TERMS_OF_SERVICE: "https://www.takecommandhealth.com/terms-of-use",
  TCH_HOME_PAGE: "https://www.takecommandhealth.com/",
  HRA_DESIGN_REQUEST: "https://www.takecommandhealth.com/hra-design-request#submit",
  SCHEDULE_APPT: "https://www.takecommandhealth.com/30-minute-phone-call",
  HRA_ICHRA_GUIDE: "https://www.takecommandhealth.com/ichra-guide",
  HRA_QSEHRA_GUIDE: "https://www.takecommandhealth.com/qsehra-guide",
  JACK_AVATAR: "https://takecommandhealth.s3.amazonaws.com/assets/health-insurance/avatar-jack.jpg?20180323",
  MEDICARE_PARTNER: "https://askchapter.org/partners/take-command-health",
  DENTAL_PARTNER: "https://www.takecommandhealth.com/blog/dental-insurance-and-ichra-qsehra",
  PUBLIC_EXCHANGE: "https://www.healthcare.gov",
  HEALTH_SHERPA: "https://www.healthsherpa.com/?_agent_id=takecommandhealth",
  HOUSEHOLD_INCOME_INFO: "https://www.healthcare.gov/income-and-household-information/income/",
  SERVICE_AGREEMENT: "https://www.takecommandhealth.com/serviceagreement",
  ALE_INFO: "https://www.irs.gov/affordable-care-act/employers/determining-if-an-employer-is-an-applicable-large-employer",
} as const

export const PHONE_NUMBER = "1-855-TCH-HRAS (824-4727)"

// Password requirements
export const MAX_CHAR_COUNT = 255
export const MIN_CHAR_COUNT = 12

// Common constants
export const MALE = "MALE"
export const FEMALE = "FEMALE"
export const NON_BINARY = "NON_BINARY"
export const OTHER = "OTHER"

export const GENDER_VALUES = [MALE, FEMALE] as const

export const GENDER_OPTIONS = makeOptions(GENDER_VALUES)

// HTTP Methods
export const GET = "GET"
export const POST = "POST"
export const PUT = "PUT"
export const PATCH = "PATCH"
export const DELETE = "DELETE"

export const HTTP_METHODS = [GET, POST, PUT, PATCH, DELETE] as const

export const YES = "YES"

export const NO = "NO"

/** @deprecated Please use YES_NO_OPTIONS_BOOLEAN instead **/
export const YES_NO_OPTIONS = [
  {
    title: "No",
    value: NO,
  },
  {
    title: "Yes",
    value: YES,
  },
] as const

export const YES_NO_OPTIONS_BOOLEAN: readonly RadioGroupElement<boolean>[] = [
  {
    title: "Yes",
    value: true,
  },
  {
    title: "No",
    value: false,
  },
] as const

export const ADMINISTRATIVE_ROLES = [
  {
    title: "System Administrator",
    value: "system_admin",
  },
  {
    title: "Administrator",
    value: "admin",
  },
  {
    title: "None",
    value: "none",
  },
] as const

export const YES_NO_VALUES = [YES, NO] as const
export const MAX_RETRY_ATTEMPTS = 2
export const DRAWER_WIDTH = 220

export const ADULT_MINIMUM_AGE = 18
export const MEDICARE_MINIMUM_AGE = 65

export const COMPLETE = "COMPLETE"
export const INCOMPLETE = "INCOMPLETE"

export const MAX_CARDS_PER_ROW = 4

export const HIDE_FLOATING_HELP_PATHS = ["/admin", "/sign-up", "/sign-in", "/pending-plan-setup"] as const

export const SELF_ENROLL = "SELF_ENROLL"
export const EASY_ENROLL = "EASY_ENROLL"
export const EXTRA_STEP = "EXTRA_STEP"

export const ENROLLMENT_TYPES = [EASY_ENROLL, EXTRA_STEP, SELF_ENROLL] as const

export const PLAN_PREMIUM_LABEL = "Plan Premium"

export const HRA_TYPES = ["ICHRA", "QSEHRA"] as const

export const ALL_COMPANY_ACCESS_ID = "*"
export const ALLOWANCE_TIERS = 4

export const SEARCH_THRESHOLD = 0.1

export const TABLE_CELL_PADDING = { padding: ".531rem 1rem" }
