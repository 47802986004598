import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { Uuid } from "@/utils/types"
import { CreateRecurringPremiumRequest, RecurringPremium, RecurringPremiumFamilyMember } from "./recurringPremiumsTypes"

export const getMembers = async (employmentId: Uuid, planYear: number) =>
  (
    await axios.get<RecurringPremiumFamilyMember[]>(
      `${appConfig.baseApiUrl}/benefitelection/v1/employees/${employmentId}/shopping-persons/${planYear}`
    )
  ).data

const getRecurringPremiumUrl = (companyId: Uuid, employmentId: Uuid) =>
  `${appConfig.baseApiUrl}/ledger/v1/recurring-premiums/company/${companyId}/employment/${employmentId}/purchased`

export const getAllRecurringPremiums = async (companyId: Uuid, employmentId: Uuid) =>
  (await axios.get<RecurringPremium[]>(getRecurringPremiumUrl(companyId, employmentId))).data

export const createRecurringPremium = async (
  companyId: Uuid,
  employmentId: Uuid,
  premium: CreateRecurringPremiumRequest
) => (await axios.post<RecurringPremium>(getRecurringPremiumUrl(companyId, employmentId), premium)).data

export const deleteRecurringPremium = async (companyId: Uuid, employmentId: Uuid, premiumId: Uuid) =>
  (await axios.delete<RecurringPremium>(`${getRecurringPremiumUrl(companyId, employmentId)}/${premiumId}`)).data
