import Img404 from "@/assets/img/404-Error-Graphic.png"
import { appConfig } from "@/config"
import { Box, Button, ButtonProps, Grid, Typography } from "@mui/material"
import { Fragment } from "react"
import { Helmet } from "react-helmet-async"

interface ReturnToWebsiteButtonProps extends ButtonProps<"a"> {
  clearLocalStorage?: boolean
}

export const ReturnToWebsiteButton = ({ clearLocalStorage, sx = {}, ...props }: ReturnToWebsiteButtonProps) => (
  <Button
    variant="contained"
    color="primary"
    sx={{ mt: 4, ...sx }}
    href="/"
    onClick={clearLocalStorage ? () => localStorage.clear() : undefined}
    {...props}
  >
    Return to website
  </Button>
)

export interface ErrorPageProps {
  error: Error
}

export const ErrorPage = ({ error }: ErrorPageProps) => (
  <Box
    sx={{
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      overflow: "hidden",
      p: 8,
    }}
  >
    <Helmet title="Error" />
    <Typography variant="h2" align="center" gutterBottom>
      We are investigating.
    </Typography>
    <img src={Img404} alt="Error" />
    <Typography variant="h4" align="center" mt={4}>
      A unrecoverable error occurred.
    </Typography>
    <Typography variant="h4" align="center">
      Click below to attempt recovery.
    </Typography>
    <ReturnToWebsiteButton clearLocalStorage />

    {!appConfig.isProduction && (
      <Grid mt={8}>
        <Typography variant="h5" align="left" gutterBottom>
          Development only section
        </Typography>
        <Typography variant="body1" align="left" gutterBottom>
          {error.message ? `${error.message}` : "An unknown error occurred."}
        </Typography>
        <Typography variant="body1" align="left" gutterBottom>
          {error.stack?.split("\n").map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </Typography>
      </Grid>
    )}
  </Box>
)
