import { Footer } from "@/components/Footer"
import { HaveGroupPlan } from "@/features/HRAPlanDesign/components/HaveGroupPlanForm"
import { KeepGroupPlan } from "@/features/HRAPlanDesign/components/KeepGroupPlanForm"
import { LocationForm } from "@/features/HRAPlanDesign/components/LocationForm"
import { MonthlyAllowance } from "@/features/HRAPlanDesign/components/MonthlyAllowanceForm"
import { NonIndividualPlanReimbursement } from "@/features/HRAPlanDesign/components/NonIndividualPlanReimbursementForm"
import { NumFulltimeEmployees } from "@/features/HRAPlanDesign/components/NumFulltimeEmployeesForm"
import { VariedAllowances } from "@/features/HRAPlanDesign/components/VariedAllowancesForm"
import { RootState } from "@/store"
import { Grid } from "@mui/material"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { HraQuizNavbar } from "../components/HraQuizNavbar"

type CurrentQuestion =
  | "num_ft_ee"
  | "have_group_plan"
  | "keep_group_plan"
  | "monthly_allowance"
  | "non_individual_plan_reimbursement"
  | "location"
  | "varied_allowances"

const renderForm = (currentQuestion: CurrentQuestion) => {
  switch (currentQuestion) {
    case "have_group_plan":
      return <HaveGroupPlan />
    case "keep_group_plan":
      return <KeepGroupPlan />
    case "monthly_allowance":
      return <MonthlyAllowance />
    case "non_individual_plan_reimbursement":
      return <NonIndividualPlanReimbursement />
    case "location":
      return <LocationForm />
    case "varied_allowances":
      return <VariedAllowances />
    case "num_ft_ee":
    default:
      return <NumFulltimeEmployees />
  }
}

const renderHelmetTitle = (currentQuestion: CurrentQuestion) => {
  switch (currentQuestion) {
    case "have_group_plan":
      return "Group Plan"
    case "keep_group_plan":
      return "Keep Group Plan"
    case "monthly_allowance":
      return "QSE max"
    case "non_individual_plan_reimbursement":
      return "Reimburse Non Individual plan"
    case "location":
      return "Location"
    case "varied_allowances":
      return "Varied Allowances"
    case "num_ft_ee":
    default:
      return "FT Employees"
  }
}

interface Params extends Record<string, string | undefined> {
  question: CurrentQuestion
}

export const PreScreenQuestions = () => {
  const navigate = useNavigate()

  const { question } = useParams<Params>()
  const firstPreScreenQuestion = useSelector((state: RootState) => state.HRAPlanDesign.preScreenResponses[0]?.question)

  useEffect(() => {
    const isAlreadyStarted = firstPreScreenQuestion === "NUM_FT_EE"
    const hasQuestion = Boolean(question)

    if (!hasQuestion || !isAlreadyStarted) {
      navigate("/hra-determination/num_ft_ee")
    }
  }, [question, firstPreScreenQuestion, navigate])

  return (
    <>
      <HraQuizNavbar />
      {question && <Helmet title={`HRA Prescreen - ${renderHelmetTitle(question)} `} />}
      <Grid container direction="column" alignItems="center" sx={{ height: "100%" }}>
        {question && <>{renderForm(question)}</>}
      </Grid>
      <Footer />
    </>
  )
}
