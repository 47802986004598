import balls from "@/assets/img/balls.png"
import puppy from "@/assets/img/puppy.png"
import { StyledCard } from "@/components/StyledCard"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { DashboardLayout } from "@/features/Dashboard/components/DashboardLayout"
import { HRIS_STATUS_CONNECTED, HRIS_STATUS_PROCESSING } from "@/features/Hris/hrisTypes"
import { createDataQa } from "@/utils/dataQa"
import { Box, Grid, Typography } from "@mui/material"
import {
  HRIS_EXCLUDED,
  HRIS_NEW_EMPLOYEE,
  HRIS_POSSIBLE_MATCH,
  HRIS_SETTLED,
  useActiveHrisConfig,
  useHrisRoster,
} from "./hrisService"

const HrisImportInProgress = () => (
  <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ p: 6 }}>
    <Grid item>
      <Typography variant="h1" align="center" gutterBottom>
        Import is in progress
      </Typography>
    </Grid>
    <Grid item sx={{ height: "21.25rem", display: "flex", flexDirection: "column", alignContent: "center" }}>
      <Box
        component="img"
        alt="balls"
        data-qa={createDataQa("balls")}
        src={balls}
        sx={{
          "@keyframes rotating": {
            "0%": {
              transform: "rotate(0deg)",
            },
            "100%": {
              transform: "rotate(360deg)",
            },
          },
          "@keyframes bouncing": {
            "0%": {
              marginTop: "0rem",
              marginBottom: "2rem",
            },
            "100%": {
              marginTop: "2rem",
              marginBottom: "0rem",
            },
          },
          animation: "bouncing 1s alternate-reverse infinite, rotating 35s linear infinite",
          height: "6.25rem",
          width: "6.25rem",
          marginRight: "auto",
          marginLeft: "auto",
          objectFit: "contain",
        }}
      />
      <Box
        component="img"
        alt="puppy"
        data-qa={createDataQa("dog-and-ball-image")}
        src={puppy}
        sx={{ height: "13rem", marginLeft: "-5rem", objectFit: "contain" }}
      />
    </Grid>
    <Grid item>
      <Typography align="center" sx={{ maxWidth: "68rem", mt: 4 }}>
        You've selected an assisted integration. Syncing your data can take up to 7 days. <br />
        Finch will securely connect to your HRIS or payroll system to ensure your data is available via API on a weekly
        basis.
      </Typography>
    </Grid>
  </Grid>
)

const HrisImportConnected = () => {
  const { companyId } = useActiveHrisConfig()
  const { data } = useHrisRoster(companyId)
  const roster = data?.roster ?? []
  const totalEmployees = roster.length
  const totalImported = roster.filter(employee => [HRIS_POSSIBLE_MATCH, HRIS_NEW_EMPLOYEE, HRIS_SETTLED]).length
  const totalExcluded = roster.filter(employee => employee.reconcileStatus === HRIS_EXCLUDED).length
  const totalPending = totalEmployees - totalImported - totalExcluded
  return (
    <Grid container direction="column" sx={{ p: 6, maxWidth: "55rem" }}>
      <Grid sx={{ mb: 4 }}>
        <Typography variant="h1" align="left" gutterBottom>
          Good News! We Successfully Imported {totalImported} Employees
        </Typography>
      </Grid>
      <Grid>
        <Typography align="left" sx={{ maxWidth: "68rem" }}>
          {totalPending > 0 && (
            <b> We couldn't import {totalPending} employees-no worries! You can add them manually.</b>
          )}
          {totalPending === 0 && <b> All your employees are imported successfully!</b>}
        </Typography>
      </Grid>
      <Grid>
        <Typography>Here's why this might have happened:</Typography>
        <ul>
          <li>
            <Typography>Address details may need a quick update</Typography>
          </li>
          <li>
            <Typography>A few fields on your HR system might be incomplete</Typography>
          </li>
        </ul>
      </Grid>
      <Grid>You will need to add Classes to some employees as a next step.</Grid>
    </Grid>
  )
}

export const HrisImportPage = () => {
  const { hrisConfig } = useActiveHrisConfig()
  let providerName = hrisConfig?.providerType?.split(":")[1]
  providerName = providerName ? providerName.charAt(0).toUpperCase() + providerName.slice(1) : ""
  const isInProgress = hrisConfig?.status === HRIS_STATUS_PROCESSING
  const isConnected = hrisConfig?.status === HRIS_STATUS_CONNECTED
  return (
    <AuthGuard>
      <DashboardLayout>
        <Typography variant="h1" gutterBottom>
          Import from {providerName}
        </Typography>
        <StyledCard>
          {isInProgress && <HrisImportInProgress />}
          {isConnected && <HrisImportConnected />}
        </StyledCard>
      </DashboardLayout>
    </AuthGuard>
  )
}
