import CloseIcon from "@mui/icons-material/Close"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { useFundingAccounts } from "../TCHub/tcHubService"
import { downloadStatementPdf, getActivityStatements } from "./autopayEndpoints"
import { useAuthToken } from "./autopayService"
import { formatDateRange } from "./autopayUtils"

interface BankingActivityModalProps {
  onClose: () => void
  companyId: string
  open: boolean
}

export const BankingActivityModal = ({ onClose, companyId, open }: BankingActivityModalProps) => {
  const [selectedAccountId, setSelectedAccountId] = useState("")
  const [selectedStatementId, setSelectedStatementId] = useState("")
  const { data: fundingAccounts } = useFundingAccounts(companyId)
  const { data: authTokenData } = useAuthToken(companyId)

  const { data: statements } = useQuery({
    queryKey: ["statements", companyId, selectedAccountId],
    queryFn: () => getActivityStatements(companyId, selectedAccountId),
    enabled: Boolean(companyId && selectedAccountId),
  })

  const handleChangeAccount = (event: SelectChangeEvent) => {
    setSelectedAccountId(event.target.value || "")
  }

  const handleChangeStatement = (event: SelectChangeEvent) => {
    setSelectedStatementId(event?.target.value || "")
  }

  const handleDownload = () => {
    downloadStatementPdf(authTokenData!.token, selectedStatementId)
  }

  return (
    <Dialog
      open={open}
      onClose={(evt, reason) => {
        if (reason === "backdropClick") {
          onClose()
        }
      }}
      scroll="body"
      data-qa="download-banking-activity-modal"
      aria-labelledby="download-banking-activity-modal"
    >
      <DialogTitle>
        <Grid
          container
          sx={{ justifyContent: "space-between", py: 3, alignItems: "center" }}
          className="dialog-headline-container"
        >
          <Typography className="dialog-headline" variant="h3tiempos">
            Download Activity
          </Typography>
          <CloseIcon
            data-qa="activity-download-dialog-close-icon-button"
            className="dialog-close-icon"
            onClick={onClose}
          />
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog-content-container" sx={{ pl: "2rem", pr: "2rem", pb: "1rem", pt: 0 }}>
        <Typography variant="body1" gutterBottom>
          Choose from the list below to download your bank statement.
        </Typography>
        <Grid container spacing={6} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel data-qa="autopay-activity-label" id="funding-accounts-label">
                Account
              </InputLabel>
              <Select
                labelId="autopay-activity-label"
                id="autopay-activity"
                value={selectedAccountId}
                label="Account"
                onChange={handleChangeAccount}
              >
                {fundingAccounts?.map(account => (
                  <MenuItem key={account.fundingEntityId} value={account.fundingEntityId}>
                    {account.name} - {account.accountLast4Digits}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel data-qa="autopay-statement-date-label" id="funding-accounts-label">
                Statement Date
              </InputLabel>
              <Select
                labelId="autopay-statement-date-label"
                id="autopay-statement-date"
                value={selectedStatementId}
                label="Statement Date"
                onChange={handleChangeStatement}
              >
                {statements?.map(statement => (
                  <MenuItem key={statement.statementId} value={statement.statementId}>
                    {formatDateRange(statement.period)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <DialogActions sx={{ mt: 4, mb: 4, ml: 4, justifyContent: "flex-end" }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" data-qa="statement-download-button" onClick={handleDownload}>
            Download
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
