/* ------ AUTO-GENERATED FILE — DO NOT EDIT ------ */
export const ROLES = [
  {
    id: "393b6b92-584d-5223-9a70-9c1dfb32d6d9",
    name: "Administrator",
    permissions: [
      "accounts_account_management",
      "accounts_access_to_division",
      "accounts_access_hras",
      "accounts_client_account_management",
      "accounts_assign_roles",
      "accounts_view_access_to_client_data",
      "accounts_write_access_to_client_data",
      "benefit_administration_medical_expenses_ledger_date_amount",
      "benefit_administration_generate",
      "benefit_administration_classes",
      "benefit_administration_allowance_reimbursement_amount",
      "benefit_administration_curves",
      "benefit_administration_add",
      "benefit_administration_enable_disable",
      "benefit_administration_delete_archive",
      "documents_download_employee_documents",
      "documents_download_company_documents",
      "documents_acknowledge_documents",
      "documents_employee_submission",
      "funding_service_funding_reports",
      "carrier_management_third_party_products",
      "plan_design_plan_design",
      "plan_design_create",
      "plan_design_edit_oe",
      "plan_design_approve_documents",
      "plan_design_view_plan_documents",
      "billing_payment_billing_dashboard",
      "billing_payment_view_invoices",
      "billing_payment_make_payments",
      "billing_payment_edit_payment_information",
      "reimbursements_deductions_access_to_employee_data",
      "integrations_decisely_gusto_brokers_manage_integrations",
      "storefront_shopping_manage_shopping",
    ],
  },
  {
    id: "6ad89fd5-e9a8-5cc1-a242-4af10acc757e",
    name: "Broker",
    permissions: [
      "accounts_account_management_view_only",
      "plan_design_plan_design_view_only",
      "plan_design_view_plan_documents",
      "reimbursements_deductions_access_to_employee_data_view_only",
      "integrations_decisely_gusto_brokers_manage_integrations",
    ],
  },
  {
    id: "b86856ea-7cf7-5481-9a17-3328cc57cda3",
    name: "Employee",
    permissions: [
      "accounts_view_access_to_client_data",
      "accounts_write_access_to_client_data",
      "benefit_administration_medical_expenses_ledger_date_amount",
      "documents_download_employee_documents",
      "documents_employee_submission",
      "plan_design_view_plan_documents",
      "reimbursements_deductions_access_to_employee_data",
      "reimbursements_deductions_medical_expenses_employee",
      "reimbursements_deductions_dispute_claim",
      "storefront_shopping_manage_shopping",
    ],
  },
  {
    id: "bf6e283a-cae1-56da-99f5-38a9815a1aa4",
    name: "Internal Administrator",
    permissions: [
      "accounts_account_management",
      "accounts_set_application_access",
      "accounts_client_account_management",
      "accounts_assign_roles",
      "accounts_view_access_to_client_data",
      "accounts_write_access_to_client_data",
      "benefit_administration_medical_expenses_ledger_date_amount",
      "benefit_administration_generate",
      "benefit_administration_autopay",
      "benefit_administration_classes",
      "benefit_administration_allowance_reimbursement_amount",
      "benefit_administration_curves",
      "benefit_administration_add",
      "benefit_administration_enable_disable",
      "benefit_administration_delete_archive",
      "documents_download_employee_documents",
      "documents_download_company_documents",
      "documents_acknowledge_documents",
      "documents_employee_submission",
      "tc_hub_users",
      "tc_hub_companies",
      "tc_hub_autopay",
      "tc_hub_compliance",
      "tc_hub_carriers",
      "tc_hub_site_settings",
      "tc_hub_accounts",
      "funding_service_manage_funding",
      "funding_service_funding_reports",
      "carrier_management_carrier_integrations",
      "carrier_management_carrier_commissions",
      "carrier_management_on_exchange_partnerships",
      "carrier_management_third_party_products",
      "plan_design_plan_design",
      "plan_design_view_plan_documents",
      "billing_payment_view_invoices",
      "reimbursements_deductions_access_to_employee_data",
      "reimbursements_deductions_write_access_to_employee_data",
      "reimbursements_deductions_dispute_claim",
      "integrations_decisely_gusto_brokers_manage_integrations",
      "storefront_shopping_manage_shopping",
    ],
  },
  {
    id: "8299d9dd-7514-5c8f-b27b-d90869ba4f32",
    name: "Internal Finance",
    permissions: [
      "accounts_view_access_to_client_data",
      "accounts_write_access_to_client_data",
      "documents_employee_submission",
      "carrier_management_carrier_commissions",
      "billing_payment_billing_dashboard",
      "billing_payment_reports",
      "billing_payment_view_invoices",
      "billing_payment_broker_commissions",
    ],
  },
  {
    id: "6e83ee2c-e1f0-56bf-b7a6-afdc83efbb8e",
    name: "Internal Ledger Administrator",
    permissions: ["tc_hub_journal_entry_management", "tc_hub_journal_entry_view"],
  },
  {
    id: "56dc6d91-5a11-5a42-81c0-3c832d48903c",
    name: "Internal Standard User",
    permissions: [
      "accounts_account_management",
      "benefit_administration_add",
      "benefit_administration_enable_disable",
      "documents_download_employee_documents",
      "documents_download_company_documents",
      "documents_acknowledge_documents",
      "documents_employee_submission",
      "tc_hub_site_settings",
      "plan_design_view_plan_documents",
      "billing_payment_view_invoices",
      "reimbursements_deductions_access_to_employee_data",
      "reimbursements_deductions_dispute_claim",
      "integrations_decisely_gusto_brokers_manage_integrations",
    ],
  },
  {
    id: "63132c56-62a4-527f-af54-06771dd103f9",
    name: "Internal System Administrator",
    permissions: [
      "accounts_account_management",
      "accounts_set_application_access",
      "accounts_client_account_management",
      "accounts_holding_account_management",
      "accounts_assign_roles",
      "accounts_view_access_to_client_data",
      "accounts_write_access_to_client_data",
      "benefit_administration_medical_expenses_ledger_date_amount",
      "benefit_administration_generate",
      "benefit_administration_autopay",
      "benefit_administration_classes",
      "benefit_administration_allowance_reimbursement_amount",
      "benefit_administration_curves",
      "benefit_administration_add",
      "benefit_administration_enable_disable",
      "benefit_administration_delete_archive",
      "documents_download_employee_documents",
      "documents_download_company_documents",
      "documents_acknowledge_documents",
      "tc_hub_users",
      "tc_hub_companies",
      "tc_hub_autopay",
      "tc_hub_compliance",
      "tc_hub_carriers",
      "tc_hub_site_settings",
      "tc_hub_accounts",
      "funding_service_manage_funding",
      "funding_service_funding_reports",
      "carrier_management_carrier_integrations",
      "carrier_management_carrier_commissions",
      "carrier_management_on_exchange_partnerships",
      "carrier_management_third_party_products",
      "plan_design_plan_design",
      "plan_design_create",
      "plan_design_edit_oe",
      "plan_design_approve_documents",
      "plan_design_view_plan_documents",
      "billing_payment_billing_dashboard",
      "billing_payment_reports",
      "billing_payment_view_invoices",
      "billing_payment_make_payments",
      "billing_payment_edit_payment_information",
      "billing_payment_broker_commissions",
      "reimbursements_deductions_access_to_employee_data",
      "reimbursements_deductions_write_access_to_employee_data",
      "reimbursements_deductions_medical_expenses_employee",
      "reimbursements_deductions_dispute_claim",
      "integrations_decisely_gusto_brokers_manage_integrations",
      "storefront_shopping_manage_shopping",
    ],
  },
  {
    id: "f72c1cb9-9b99-5f4d-baaf-4fcc5caf24de",
    name: "System Administrator",
    permissions: [
      "accounts_account_management",
      "accounts_set_application_access",
      "accounts_access_to_division",
      "accounts_access_hras",
      "accounts_client_account_management",
      "accounts_assign_roles",
      "accounts_view_access_to_client_data",
      "accounts_write_access_to_client_data",
      "benefit_administration_medical_expenses_ledger_date_amount",
      "benefit_administration_generate",
      "benefit_administration_classes",
      "benefit_administration_allowance_reimbursement_amount",
      "benefit_administration_curves",
      "benefit_administration_add",
      "benefit_administration_enable_disable",
      "benefit_administration_delete_archive",
      "documents_download_employee_documents",
      "documents_download_company_documents",
      "documents_acknowledge_documents",
      "documents_employee_submission",
      "funding_service_funding_reports",
      "carrier_management_third_party_products",
      "plan_design_plan_design",
      "plan_design_create",
      "plan_design_edit_oe",
      "plan_design_approve_documents",
      "plan_design_view_plan_documents",
      "billing_payment_billing_dashboard",
      "billing_payment_view_invoices",
      "billing_payment_make_payments",
      "billing_payment_edit_payment_information",
      "reimbursements_deductions_access_to_employee_data",
      "integrations_decisely_gusto_brokers_manage_integrations",
      "storefront_shopping_manage_shopping",
    ],
  },
] as const

export const ADMINISTRATOR_EXTERNAL = ROLES[0]
export const BROKER_EXTERNAL = ROLES[1]
export const EMPLOYEE_EXTERNAL = ROLES[2]
export const ADMINISTRATOR_INTERNAL = ROLES[3]
export const FINANCE_INTERNAL = ROLES[4]
export const LEDGER_ADMINISTRATOR_INTERNAL = ROLES[5]
export const STANDARD_USER_INTERNAL = ROLES[6]
export const SYSTEM_ADMINISTRATOR_INTERNAL = ROLES[7]
export const SYSTEM_ADMINISTRATOR_EXTERNAL = ROLES[8]

export const PERMISSIONS = [
  {
    id: "accounts_account_management",
    domain: "Accounts",
    description: "Account management",
  },
  {
    id: "accounts_account_management_view_only",
    domain: "Accounts",
    description: "Account management",
  },
  {
    id: "accounts_set_application_access",
    domain: "Accounts",
    description: "Set application access",
  },
  {
    id: "accounts_access_to_division",
    domain: "Accounts",
    description: "Access to division",
  },
  {
    id: "accounts_access_hras",
    domain: "Accounts",
    description: "Access HRAs",
  },
  {
    id: "accounts_client_account_management",
    domain: "Accounts",
    description: "Client account management",
  },
  {
    id: "accounts_holding_account_management",
    domain: "Accounts",
    description: "Holding account management",
  },
  {
    id: "accounts_renewals",
    domain: "Accounts",
    description: "Renewals",
  },
  {
    id: "accounts_assign_roles",
    domain: "Accounts",
    description: "Assign roles",
  },
  {
    id: "accounts_view_access_to_client_data",
    domain: "Accounts",
    description: "View access to client data",
  },
  {
    id: "accounts_write_access_to_client_data",
    domain: "Accounts",
    description: "Write access to client data",
  },
  {
    id: "benefit_administration_reporting",
    domain: "Benefit Administration",
    description: "Reporting",
  },
  {
    id: "benefit_administration_medical_expenses_ledger_date_amount",
    domain: "Benefit Administration",
    description: "Medical expenses ledger date amount",
  },
  {
    id: "benefit_administration_generate",
    domain: "Benefit Administration",
    description: "Generate",
  },
  {
    id: "benefit_administration_autopay",
    domain: "Benefit Administration",
    description: "AutoPay",
  },
  {
    id: "benefit_administration_hra_management",
    domain: "Benefit Administration",
    description: "HRA management",
  },
  {
    id: "benefit_administration_classes",
    domain: "Benefit Administration",
    description: "Classes",
  },
  {
    id: "benefit_administration_allowance_reimbursement_amount",
    domain: "Benefit Administration",
    description: "Allowance reimbursement amount",
  },
  {
    id: "benefit_administration_curves",
    domain: "Benefit Administration",
    description: "Curves",
  },
  {
    id: "benefit_administration_employee_management",
    domain: "Benefit Administration",
    description: "Employee management",
  },
  {
    id: "benefit_administration_add",
    domain: "Benefit Administration",
    description: "Add",
  },
  {
    id: "benefit_administration_enable_disable",
    domain: "Benefit Administration",
    description: "Enable Disable",
  },
  {
    id: "benefit_administration_delete_archive",
    domain: "Benefit Administration",
    description: "Delete Archive",
  },
  {
    id: "documents_download_employee_documents",
    domain: "Documents",
    description: "Download employee documents",
  },
  {
    id: "documents_download_company_documents",
    domain: "Documents",
    description: "Download company documents",
  },
  {
    id: "documents_acknowledge_documents",
    domain: "Documents",
    description: "Acknowledge documents",
  },
  {
    id: "documents_employee_submission",
    domain: "Documents",
    description: "Employee submission",
  },
  {
    id: "tc_hub_users",
    domain: "TC Hub",
    description: "Users",
  },
  {
    id: "tc_hub_companies",
    domain: "TC Hub",
    description: "Companies",
  },
  {
    id: "tc_hub_autopay",
    domain: "TC Hub",
    description: "AutoPay",
  },
  {
    id: "tc_hub_compliance",
    domain: "TC Hub",
    description: "Compliance",
  },
  {
    id: "tc_hub_carriers",
    domain: "TC Hub",
    description: "Carriers",
  },
  {
    id: "tc_hub_site_settings",
    domain: "TC Hub",
    description: "Site Settings",
  },
  {
    id: "tc_hub_accounts",
    domain: "TC Hub",
    description: "Accounts",
  },
  {
    id: "tc_hub_journal_entry_management",
    domain: "TC Hub",
    description: "Journal Entry Management",
  },
  {
    id: "tc_hub_journal_entry_view",
    domain: "TC Hub",
    description: "Journal Entry View",
  },
  {
    id: "funding_service_manage_funding",
    domain: "Funding Service",
    description: "Manage Funding",
  },
  {
    id: "funding_service_funding_reports",
    domain: "Funding Service",
    description: "Funding Reports",
  },
  {
    id: "carrier_management_carrier_integrations",
    domain: "Carrier Management",
    description: "Carrier integrations",
  },
  {
    id: "carrier_management_carrier_commissions",
    domain: "Carrier Management",
    description: "Carrier commissions",
  },
  {
    id: "carrier_management_on_exchange_partnerships",
    domain: "Carrier Management",
    description: "On Exchange partnerships",
  },
  {
    id: "carrier_management_third_party_products",
    domain: "Carrier Management",
    description: "Third party products",
  },
  {
    id: "plan_design_plan_design",
    domain: "Plan Design",
    description: "Plan design",
  },
  {
    id: "plan_design_plan_design_view_only",
    domain: "Plan Design",
    description: "Plan design",
  },
  {
    id: "plan_design_create",
    domain: "Plan Design",
    description: "Create",
  },
  {
    id: "plan_design_edit_oe",
    domain: "Plan Design",
    description: "Edit OE",
  },
  {
    id: "plan_design_approve_documents",
    domain: "Plan Design",
    description: "Approve documents",
  },
  {
    id: "plan_design_view_plan_documents",
    domain: "Plan Design",
    description: "View plan documents",
  },
  {
    id: "billing_payment_billing_dashboard",
    domain: "Billing Payment",
    description: "Billing dashboard",
  },
  {
    id: "billing_payment_reports",
    domain: "Billing Payment",
    description: "Reports",
  },
  {
    id: "billing_payment_view_invoices",
    domain: "Billing Payment",
    description: "View invoices",
  },
  {
    id: "billing_payment_make_payments",
    domain: "Billing Payment",
    description: "Make payments",
  },
  {
    id: "billing_payment_edit_payment_information",
    domain: "Billing Payment",
    description: "Edit payment information",
  },
  {
    id: "billing_payment_broker_commissions",
    domain: "Billing Payment",
    description: "Broker commissions",
  },
  {
    id: "reimbursements_deductions_access_to_employee_data",
    domain: "Reimbursements deductions",
    description: "Access to employee data",
  },
  {
    id: "reimbursements_deductions_access_to_employee_data_view_only",
    domain: "Reimbursements deductions",
    description: "Access to employee data",
  },
  {
    id: "reimbursements_deductions_write_access_to_employee_data",
    domain: "Reimbursements deductions",
    description: "Write access to employee data",
  },
  {
    id: "reimbursements_deductions_medical_expenses_employee",
    domain: "Reimbursements deductions",
    description: "Medical expenses employee",
  },
  {
    id: "reimbursements_deductions_dispute_claim",
    domain: "Reimbursements deductions",
    description: "Dispute claim",
  },
  {
    id: "integrations_decisely_gusto_brokers_manage_integrations",
    domain: "Integrations Decisely Gusto Brokers",
    description: "Manage integrations",
  },
  {
    id: "storefront_shopping_manage_shopping",
    domain: "Storefront Shopping",
    description: "Manage Shopping",
  },
] as const

export const ACCOUNTS_ACCOUNT_MANAGEMENT = PERMISSIONS[0]
export const ACCOUNTS_ACCOUNT_MANAGEMENT_VIEW_ONLY = PERMISSIONS[1]
export const ACCOUNTS_SET_APPLICATION_ACCESS = PERMISSIONS[2]
export const ACCOUNTS_ACCESS_TO_DIVISION = PERMISSIONS[3]
export const ACCOUNTS_ACCESS_HRAS = PERMISSIONS[4]
export const ACCOUNTS_CLIENT_ACCOUNT_MANAGEMENT = PERMISSIONS[5]
export const ACCOUNTS_HOLDING_ACCOUNT_MANAGEMENT = PERMISSIONS[6]
export const ACCOUNTS_RENEWALS = PERMISSIONS[7]
export const ACCOUNTS_ASSIGN_ROLES = PERMISSIONS[8]
export const ACCOUNTS_VIEW_ACCESS_TO_CLIENT_DATA = PERMISSIONS[9]
export const ACCOUNTS_WRITE_ACCESS_TO_CLIENT_DATA = PERMISSIONS[10]
export const BENEFIT_ADMINISTRATION_REPORTING = PERMISSIONS[11]
export const BENEFIT_ADMINISTRATION_MEDICAL_EXPENSES_LEDGER_DATE_AMOUNT = PERMISSIONS[12]
export const BENEFIT_ADMINISTRATION_GENERATE = PERMISSIONS[13]
export const BENEFIT_ADMINISTRATION_AUTOPAY = PERMISSIONS[14]
export const BENEFIT_ADMINISTRATION_HRA_MANAGEMENT = PERMISSIONS[15]
export const BENEFIT_ADMINISTRATION_CLASSES = PERMISSIONS[16]
export const BENEFIT_ADMINISTRATION_ALLOWANCE_REIMBURSEMENT_AMOUNT = PERMISSIONS[17]
export const BENEFIT_ADMINISTRATION_CURVES = PERMISSIONS[18]
export const BENEFIT_ADMINISTRATION_EMPLOYEE_MANAGEMENT = PERMISSIONS[19]
export const BENEFIT_ADMINISTRATION_ADD = PERMISSIONS[20]
export const BENEFIT_ADMINISTRATION_ENABLE_DISABLE = PERMISSIONS[21]
export const BENEFIT_ADMINISTRATION_DELETE_ARCHIVE = PERMISSIONS[22]
export const DOCUMENTS_DOWNLOAD_EMPLOYEE_DOCUMENTS = PERMISSIONS[23]
export const DOCUMENTS_DOWNLOAD_COMPANY_DOCUMENTS = PERMISSIONS[24]
export const DOCUMENTS_ACKNOWLEDGE_DOCUMENTS = PERMISSIONS[25]
export const DOCUMENTS_EMPLOYEE_SUBMISSION = PERMISSIONS[26]
export const TC_HUB_USERS = PERMISSIONS[27]
export const TC_HUB_COMPANIES = PERMISSIONS[28]
export const TC_HUB_AUTOPAY = PERMISSIONS[29]
export const TC_HUB_COMPLIANCE = PERMISSIONS[30]
export const TC_HUB_CARRIERS = PERMISSIONS[31]
export const TC_HUB_SITE_SETTINGS = PERMISSIONS[32]
export const TC_HUB_ACCOUNTS = PERMISSIONS[33]
export const TC_HUB_JOURNAL_ENTRY_MANAGEMENT = PERMISSIONS[34]
export const TC_HUB_JOURNAL_ENTRY_VIEW = PERMISSIONS[35]
export const FUNDING_SERVICE_MANAGE_FUNDING = PERMISSIONS[36]
export const FUNDING_SERVICE_FUNDING_REPORTS = PERMISSIONS[37]
export const CARRIER_MANAGEMENT_CARRIER_INTEGRATIONS = PERMISSIONS[38]
export const CARRIER_MANAGEMENT_CARRIER_COMMISSIONS = PERMISSIONS[39]
export const CARRIER_MANAGEMENT_ON_EXCHANGE_PARTNERSHIPS = PERMISSIONS[40]
export const CARRIER_MANAGEMENT_THIRD_PARTY_PRODUCTS = PERMISSIONS[41]
export const PLAN_DESIGN_PLAN_DESIGN = PERMISSIONS[42]
export const PLAN_DESIGN_PLAN_DESIGN_VIEW_ONLY = PERMISSIONS[43]
export const PLAN_DESIGN_CREATE = PERMISSIONS[44]
export const PLAN_DESIGN_EDIT_OE = PERMISSIONS[45]
export const PLAN_DESIGN_APPROVE_DOCUMENTS = PERMISSIONS[46]
export const PLAN_DESIGN_VIEW_PLAN_DOCUMENTS = PERMISSIONS[47]
export const BILLING_PAYMENT_BILLING_DASHBOARD = PERMISSIONS[48]
export const BILLING_PAYMENT_REPORTS = PERMISSIONS[49]
export const BILLING_PAYMENT_VIEW_INVOICES = PERMISSIONS[50]
export const BILLING_PAYMENT_MAKE_PAYMENTS = PERMISSIONS[51]
export const BILLING_PAYMENT_EDIT_PAYMENT_INFORMATION = PERMISSIONS[52]
export const BILLING_PAYMENT_BROKER_COMMISSIONS = PERMISSIONS[53]
export const REIMBURSEMENTS_DEDUCTIONS_ACCESS_TO_EMPLOYEE_DATA = PERMISSIONS[54]
export const REIMBURSEMENTS_DEDUCTIONS_ACCESS_TO_EMPLOYEE_DATA_VIEW_ONLY = PERMISSIONS[55]
export const REIMBURSEMENTS_DEDUCTIONS_WRITE_ACCESS_TO_EMPLOYEE_DATA = PERMISSIONS[56]
export const REIMBURSEMENTS_DEDUCTIONS_MEDICAL_EXPENSES_EMPLOYEE = PERMISSIONS[57]
export const REIMBURSEMENTS_DEDUCTIONS_DISPUTE_CLAIM = PERMISSIONS[58]
export const INTEGRATIONS_DECISELY_GUSTO_BROKERS_MANAGE_INTEGRATIONS = PERMISSIONS[59]
export const STOREFRONT_SHOPPING_MANAGE_SHOPPING = PERMISSIONS[60]
